export enum IncomeTypesEnum {
  SALARIED = "SALARIED",
  HOURLY = "HOURLY",
  HOURLY_COMMISSIONS = "HOURLY_COMMISSIONS",
  COMMISSIONS = "COMMISSIONS",
  SELF_EMPLOYED = "SELF_EMPLOYED",
  PENSION = "PENSION",
  NONE = "NONE",
}

export enum OtherIncomeEnum {
  INVESTMENT_INCOME = "INVESTMENT_INCOME",
  INTEREST_INCOME = "INTEREST_INCOME",
  ALIMONY = "ALIMONY",
  CHILD_SUPPORT = "CHILD_SUPPORT",
  EMPLOYMENT_EXPENSE = "EMPLOYMENT_EXPENSE",
  OTHER = "OTHER",
}

export enum SalutationsEnEnum {
  MR = "MR",
  MS = "MS",
  MISS = "MISS",
  MRS = "MRS",
  DR = "DR",
  REV = "REV",
  JUDGE = "JUDGE",
}

export enum SalutationsFrEnum {
  MR = "MR",
  MS = "MS",
  MRS = "MRS",
  DR = "DR",
  REV = "REV",
  JUDGE = "JUDGE",
}

export enum CreditScoreQualityEnum {
  EXCELLENT = "EXCELLENT",
  GOOD = "GOOD",
  FAIR = "FAIR",
  POOR = "POOR",
  UNKNOWN = "UNKNOWN",
}

export enum MaritalStatusesEnum {
  SINGLE = "SINGLE",
  MARRIED = "MARRIED",
  DIVORCED = "DIVORCED",
  COMMON_LAW = "COMMON_LAW",
  WIDOWED = "WIDOWED",
  SEPERATED = "SEPERATED",
  OTHER = "OTHER",
}

export enum TenureEnum {
  PERMANENT = "PERMANENT",
  ON_PROBATION = "ON_PROBATION",
  ON_CONTRACT = "ON_CONTRACT",
}

export enum EmploymentTypeEnum {
  FULL_TIME = "FULL_TIME",
  PART_TIME = "PART_TIME",
  SEASONAL = "SEASONAL",
}

export enum AmountFrequencyEnum {
  ANNUALLY = "ANNUALLY",
  SEMIANNUALLY = "SEMIANNUALLY",
  MONTHLY = "MONTHLY",
  SEMIMONTHLY = "SEMIMONTHLY",
  BIWEEKLY = "BIWEEKLY",
  WEEKLY = "WEEKLY",
}

export enum MortgagePaymentFrequencyEnum {
  ANNUALLY = "ANNUALLY",
  SEMIANNUALLY = "SEMIANNUALLY",
  MONTHLY = "MONTHLY",
  SEMIMONTHLY = "SEMIMONTHLY",
  BIWEEKLY = "BIWEEKLY",
  BIWEEKLYACCELERATED = "BIWEEKLYACCELERATED",
  WEEKLY = "WEEKLY",
  WEEKLYACCELERATED = "WEEKLYACCELERATED",
}

export enum RegionEnum {
  ON = "ON",
  QC = "QC",
  AB = "AB",
  BC = "BC",
  MB = "MB",
  NB = "NB",
  NS = "NS",
  NL = "NL",
  PE = "PE",
  SK = "SK",
  NT = "NT",
  YT = "YT",
  NU = "NU",
}

export enum IndustriesEnum {
  BANK_FINANCE = "BANK_FINANCE",
  MANUFACTURING = "MANUFACTURING",
  GOVERNMENT = "GOVERNMENT",
  HEALTH = "HEALTH",
  RETAIL = "RETAIL",
  HIGH_TECH = "HIGH_TECH",
  EDUCATION = "EDUCATION",
  LEISURE_ENTERTAINMENT = "LEISURE_ENTERTAINMENT",
  SERVICES = "SERVICES",
  TRANSPORTATION = "TRANSPORTATION",
  NATURAL_RESOURCES = "NATURAL_RESOURCES",
  CONSTRUCTION = "CONSTRUCTION",
  FARMING = "FARMING",
}

export enum OperatingAsEnum {
  CORPORATION = "CORPORATION",
  PARTNERSHIP = "PARTNERSHIP",
  SOLE_PROPRIETOR = "SOLE_PROPRIETOR",
}

export enum PensionTypeEnum {
  OLD_AGE_SECURITY = "OLD_AGE_SECURITY",
  CANADIAN_PENSION_PLAN = "CANADIAN_PENSION_PLAN",
  EMPLOYER = "EMPLOYER",
  OTHER = "OTHER",
}

export enum RelationToMainApplicantEnum {
  SPOUSE = "SPOUSE",
  COMMON_LAW = "COMMON_LAW",
  RELATED_FAMILY_MEMBER = "RELATED_FAMILY_MEMBER",
  PARENT = "PARENT",
  CHILD = "CHILD",
  SIBLING = "SIBLING",
  GRAND_PARENT = "GRAND_PARENT",
  GRAND_CHILD = "GRAND_CHILD",
  OTHER = "OTHER",
}

export enum ApplicantTypeEnum {
  MAIN_APPLICANT = "MAIN_APPLICANT",
  GUARANTOR = "GUARANTOR",
  CO_APPLICANT = "CO_APPLICANT",
}
