import { NextRouter, useRouter } from "next/router";
import { z } from "zod";

export const useTypedRouter = <T extends z.Schema>(
  schema: T
): NextRouter & { query: z.infer<T> } => {
  const { query, ...router } = useRouter();

  return {
    query: schema.parse(query) as z.infer<typeof schema>,
    ...router,
  };
};

const getRouteArgs = (asPath: string, pathname: string) => {
  // ex value asPath = "/24562/applicant/513892?step=2"
  const [route] = asPath.replace("_tenants/[tenant]/", "").split("?");
  const routeSegments = route.split("/"); // output ["24562", "applicant", "513892"]

  // ex value pathname = "/_tenants/[tenant]/[applicationId]/applicant/[applicantId]"
  const [path] = pathname.replace("_tenants/[tenant]/", "").split("?");
  const pathnameSegments = path.split("/"); // output ["[applicationId]", "applicant", "[applicantId]"]

  // create the route args object from the route and pathname segments
  const routeArgs = pathnameSegments.reduce((acc, curr, index) => {
    if (curr.startsWith("[")) {
      const key = curr.replace(/\[|\]/g, "");
      return {
        ...acc,
        [key]: routeSegments[index],
      };
    }
    return acc;
  }, {});

  return routeArgs; // output { applicationId: "24562", applicantId: "513892" }
};

export const useTypedRouteArgs = <T extends z.Schema>(schema: T) => {
  const { asPath, pathname } = useRouter();

  return schema.parse(getRouteArgs(asPath, pathname)) as z.infer<typeof schema>;
};
