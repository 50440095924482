import { useState } from "react";

import { ButtonIcon } from "@nestoca/ui";
import { useGetApplication } from "@shared/api/hooks/applications";
import { applicantSectionsRouterSchema } from "@shared/constants";
import { useTypedRouter } from "@shared/utils";
import { useRouter } from "next/router";
import { MdClose } from "react-icons/md";

import { useQuestionnaireLayout } from "../questionnaire-layout-mobile";

import { CloseQuestionnaireModal } from "./close-questionnaire-modal";
import styles from "./close-questionnaire.module.scss";

export const CloseQuestionnaire = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { warnBeforeQuit } = useQuestionnaireLayout();
  const { push } = useRouter();

  const {
    query: { applicationId, applicantId },
  } = useTypedRouter(applicantSectionsRouterSchema);

  const { data: application } = useGetApplication({
    id: applicationId ? +applicationId : 0,
  });

  const handleOnClose = () => {
    if (warnBeforeQuit) {
      setIsModalOpen(true);
      return;
    }

    push(
      `/applications/${applicationId}/applicant/${
        applicantId || application?.mainApplicantId
      }`
    );
  };

  return (
    <>
      <ButtonIcon
        aria-label="close-questionnaire"
        variant="ghost"
        size="medium"
        icon={<MdClose size={26} />}
        onClick={handleOnClose}
        className={styles["close-button"]}
        data-dd-action-name="close questionnaire"
        isRound={true}
      />
      <CloseQuestionnaireModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};
