import { datadogRum } from "@datadog/browser-rum";

import { useDatadogContext } from "./use-datadog-context";

import type { TenantClient } from "@nestoca/multi-tenant";

export const useDatadogTenant = (tenant: TenantClient | null) => {
  // The global context data exceeds the recommended 3KiB threshold.
  // More details: https://docs.datadoghq.com/real_user_monitoring/browser/troubleshooting/#customer-data-exceeds-the-recommended-3kib-warning
  const data = tenant
    ? {
        tenantId: tenant.id,
        tenantSlug: tenant.slug,
        auth0: {
          clientId: tenant.auth0.clientId,
        },
      }
    : null;

  useDatadogContext("tenant", data);

  if (tenant) {
    const flags = tenant.featureFlags;

    // NOTE: this will need to be updated to use the new tenant feature flags with config
    // for each flag `addFeatureFlagEvaluation` to Datadog
    // https://docs.datadoghq.com/real_user_monitoring/guide/setup-feature-flag-data-collection/?tab=browser#custom-feature-flag-management
    for (const [key, value] of Object.entries(flags)) {
      datadogRum.addFeatureFlagEvaluation(key, value);
    }
  }
};
