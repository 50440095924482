import {
  getAccountPostingsTransactionsSearchResults,
  getHighlightsResult,
  getComponent,
  getMinimalComponentsRecordsSearchResults,
  getStatementsSearchResults,
  getServicingAssets,
} from "@shared/api";
import { keyFactory } from "@shared/api/hooks/utils";
import {
  AccountPostingsTransactionsSearchPayload,
  AccountPostingsTransactionsSearchResults,
  ComponentPayload,
  MinimalComponentsRecordsSearchPayload,
  MinimalComponentsRecordsSearchResults,
  StatementsSearchPayload,
  StatementsSearchResults,
} from "@shared/constants/mortgages-servicing";
import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  UseInfiniteQueryOptions,
} from "@tanstack/react-query";

export const minimalComponentsKeys = keyFactory("minimal-components");

export const paginatedMinimalComponentsKeys = keyFactory(
  "paginated-minimal-components"
);

export const highlightsResultKeys = keyFactory("highlights-result");

export const componentKeys = keyFactory("component");

export const statementsKeys = keyFactory("statements");

export const accountPostingsTransactionsKeys = keyFactory(
  "account-postings-transactions"
);

/**
 * This hook gets the minimal components records search results
 * @param payload Payload used as query params
 * @param options Query options (select, enabled, placeholderData)
 * @returns The minimal components records search results
 */
export const useGetMinimalComponentsRecordsSearchResults = <
  TResult = MinimalComponentsRecordsSearchResults,
>(
  payload?: MinimalComponentsRecordsSearchPayload,
  options?: Pick<
    UseQueryOptions<MinimalComponentsRecordsSearchResults, unknown, TResult>,
    "select" | "enabled" | "placeholderData"
  >
) =>
  useQuery({
    queryKey: minimalComponentsKeys.list({ payload }),
    queryFn: ({ signal }) =>
      getMinimalComponentsRecordsSearchResults(payload, { signal }),
    ...options,
  });

/**
 * This hook gets the paginated minimal components records search results
 * @param payload Payload used as query params
 * @param options Query options (select, enabled, placeholderData)
 * @returns The paginated minimal components records search results
 */
export const useGetPaginatedMinimalComponentsRecordsSearchResults = <
  TResult = MinimalComponentsRecordsSearchResults,
>(
  payload: MinimalComponentsRecordsSearchPayload,
  options = {} as UseInfiniteQueryOptions<
    MinimalComponentsRecordsSearchResults,
    unknown,
    TResult
  >
) =>
  useInfiniteQuery({
    ...options,
    queryKey: paginatedMinimalComponentsKeys.list({ payload }),
    queryFn: ({ pageParam = 1, signal }) =>
      getMinimalComponentsRecordsSearchResults(
        {
          ...payload,
          page: pageParam as number,
        },
        { signal }
      ),
    initialPageParam: 1,
    getNextPageParam: (allPages, pages) => {
      const totalPages = allPages.totalPages;

      if (pages.length >= totalPages) return undefined;

      return pages.length + 1;
    },
  });

/**
 * This hook gets the highlights result
 * @returns The highlights result
 */
export const useGetHighlightsResult = () =>
  useQuery({
    queryKey: highlightsResultKeys.all,
    queryFn: getHighlightsResult,
  });

/**
 * This hook gets the account postings transactions search results
 * @param payload Payload used as query params
 * @param options Infinite query options
 * @returns The account postings transactions search results
 */
export const useGetAccountPostingsTransactionsSearchResults = <
  TResult = AccountPostingsTransactionsSearchResults,
>(
  payload: AccountPostingsTransactionsSearchPayload,
  options = {} as UseInfiniteQueryOptions<
    AccountPostingsTransactionsSearchResults,
    unknown,
    TResult
  >
) =>
  useInfiniteQuery({
    ...options,
    queryKey: accountPostingsTransactionsKeys.list({ payload }),
    queryFn: ({ pageParam = 1, signal }) =>
      getAccountPostingsTransactionsSearchResults(
        {
          ...payload,
          page: pageParam as number,
        },
        { signal }
      ),
    initialPageParam: 1,
    getNextPageParam: (allPages, pages) => {
      const totalPages = allPages.totalPages;

      if (pages.length >= totalPages) return undefined;

      return pages.length + 1;
    },
  });

/**
 * This hook gets the statements search results
 * @param payload Payload used as query params
 * @param options Infinite query options
 * @returns The statements search results
 */
export const useGetStatementsSearchResults = <
  TResult = StatementsSearchResults,
>(
  payload: StatementsSearchPayload,
  options = {} as UseInfiniteQueryOptions<
    StatementsSearchResults,
    unknown,
    TResult
  >
) =>
  useInfiniteQuery({
    ...options,
    queryKey: statementsKeys.list({ payload }),
    queryFn: ({ pageParam = 1, signal }) =>
      getStatementsSearchResults(
        {
          ...payload,
          page: pageParam as number,
        },
        { signal }
      ),
    initialPageParam: 1,
    getNextPageParam: (allPages, pages) => {
      const totalPages = allPages.totalPages;

      if (pages.length >= totalPages) return undefined;

      return pages.length + 1;
    },
  });

/**
 * This hook gets a single component
 * @param payload Payload used as path params
 * @returns A single component
 */
export const useGetComponent = (payload: ComponentPayload) =>
  useQuery({
    queryKey: componentKeys.detail({ payload }),
    queryFn: ({ signal }) => getComponent(payload, { signal }),
  });

export const useServicingAssets = () =>
  useQuery({
    queryKey: componentKeys.detail(),
    queryFn: getServicingAssets,
  });
