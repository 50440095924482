import { useEffect } from "react";

import { datadogRum } from "@datadog/browser-rum";

type UseDatadogContextProps = typeof datadogRum.setGlobalContextProperty;

export const useDatadogContext: UseDatadogContextProps = (key, property) => {
  useEffect(() => {
    datadogRum.setGlobalContextProperty(key, property);
    return (): void => {
      datadogRum.removeGlobalContextProperty(key);
    };
  }, [key, property]);
};
