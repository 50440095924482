import { PropsWithChildren } from "react";

import { Flex } from "@nestoca/ui";

import styles from "./action-bar-wrapper-mobile.module.scss";

export const ActionBarWrapperMobile = ({ children }: PropsWithChildren) => {
  return (
    <Flex
      gap={4}
      className={styles["action-bar-wrapper-mobile"]}
      direction="row"
    >
      {children}
    </Flex>
  );
};
