import { Flex } from "@nestoca/ui";

import { SidebarLogoNoSSR } from "./sidebar-logo";
import styles from "./sidebar.module.scss";

type Props = {
  isSidebarOpen: boolean;
};

export const Header = ({ isSidebarOpen }: Props) => {
  return (
    <Flex className={styles.header} direction="column" gap={5}>
      <SidebarLogoNoSSR isSidebarOpen={isSidebarOpen} />
    </Flex>
  );
};
