import { Flex, Typography } from "@nestoca/ui";
import { Application, CIRCLE_PROGRESS_TRACKER_PHASES } from "@shared/constants";
import { useCircleProgressTracker } from "@shared/ui";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { statusesIcons } from "./circle-progress-tracker";
import { TrackerPhase } from "./tracker-phase";
import styles from "./tracker-phase.module.scss";

type GettingToKnowType = {
  applicantId: number;
  application: Application;
  isActive: boolean;
  setActivePhase: (activePhase: CIRCLE_PROGRESS_TRACKER_PHASES) => void;
};
export const Funding = ({
  isActive,
  applicantId,
  application,
  setActivePhase,
}: GettingToKnowType) => {
  const { t } = useTranslation("docs");

  const {
    funding: { phaseNumber, completePercent, statusesList },
  } = useCircleProgressTracker({
    application,
    applicantId,
    statusIcons: statusesIcons,
  });

  return (
    <TrackerPhase
      isActive={isActive}
      completePercent={completePercent}
      totalCompletePercent={completePercent}
      phaseNumber={phaseNumber}
      phase={CIRCLE_PROGRESS_TRACKER_PHASES.FUNDING}
      setActivePhase={setActivePhase}
    >
      <Flex
        gap={3}
        direction="column"
        align="start"
        justify="center"
        className={styles["phase__list"]}
      >
        {statusesList &&
          statusesList.map((state) => {
            return (
              <Flex key={state.name} align="center" gap={2}>
                <Flex align="center" shrink={0}>
                  {state.icon}
                </Flex>
                <Typography
                  className={clsx(styles["phase__status"], {
                    [styles["phase__status--active"]]:
                      state.status !== "pending",
                  })}
                >
                  {t(state.name)}
                </Typography>
              </Flex>
            );
          })}
      </Flex>
    </TrackerPhase>
  );
};
