import { useEffect } from "react";

import { useTenantFlags } from "@nestoca/multi-tenant";
import {
  ErrorMessage,
  Flex,
  FormElement,
  Input,
  PhoneInput,
} from "@nestoca/ui";
import { KeepI18ExprHappy } from "@shared/utils";
import { useFormContext, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import styles from "./user-settings-modal.module.scss";

export const SettingsForm = ({
  setIsFormDirty,
}: {
  setIsFormDirty: (isFormDirty: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { enableEditAccountInfo } = useTenantFlags();

  const {
    register,
    formState: { errors, isDirty },
    control,
  } = useFormContext();

  useEffect(() => setIsFormDirty(isDirty), [isDirty, setIsFormDirty]);

  return (
    <Flex gap={2} direction="column">
      <Flex gap={2} direction="column">
        <h2 className={styles.title}>{t("name")}</h2>
        <Flex direction="column" gap={3}>
          <FormElement isInvalid={!!errors.firstName} isDisabled>
            <Input
              isDisabled
              placeholder={t("firstName") as string}
              e2ePrefix={"firstName"}
              {...register("firstName")}
            />
            {!!errors.firstName && (
              <ErrorMessage e2ePrefix={"firstName"}>
                {t(`${errors.firstName.message}` as KeepI18ExprHappy)}
              </ErrorMessage>
            )}
          </FormElement>
          <FormElement isInvalid={!!errors.lastName} isDisabled>
            <Input
              isDisabled
              placeholder={t("lastName") as string}
              e2ePrefix={"lastName"}
              {...register("lastName")}
            />
            {!!errors.lastName && (
              <ErrorMessage e2ePrefix={"lastName"}>
                {t(`${errors.lastName.message}` as KeepI18ExprHappy)}
              </ErrorMessage>
            )}
          </FormElement>
        </Flex>
      </Flex>
      <Flex gap={2} direction="column">
        <h2 className={styles.title}>{t("contactDetails")}</h2>
        <Flex direction="column" gap={3}>
          <FormElement
            isInvalid={!!errors.phone}
            isDisabled={!enableEditAccountInfo}
          >
            <Controller
              control={control}
              name="phone"
              render={({ field }) => (
                <PhoneInput
                  placeholder={t("userPhoneNumber")}
                  isDisabled={!enableEditAccountInfo}
                  {...field}
                  onChange={(value) => {
                    field.onChange(value ?? "");
                  }}
                />
              )}
            />
            {!!errors.phone && (
              <ErrorMessage e2ePrefix="phone">
                {t(`${errors.phone.message}` as KeepI18ExprHappy)}
              </ErrorMessage>
            )}
          </FormElement>
          <FormElement isInvalid={!!errors.email} isDisabled>
            <Input
              isDisabled
              placeholder={t("emailAddress") as string}
              e2ePrefix={"email"}
              {...register("email")}
            />
            {!!errors.email && (
              <ErrorMessage e2ePrefix={"email"}>
                {t(`${errors.email.message}` as KeepI18ExprHappy)}
              </ErrorMessage>
            )}
          </FormElement>
        </Flex>
      </Flex>
    </Flex>
  );
};
