import { useState, useRef, useEffect } from "react";

import { Auth0Client } from "@auth0/auth0-spa-js";

import type { Auth0ClientOptions } from "@auth0/auth0-spa-js";
import type { TenantClient } from "@nestoca/multi-tenant";

const LOGIN_OPTIONS = { appState: { returnTo: "/" } };

export const useE2EAuth = (tenant: TenantClient | null) => {
  const didInitialise = useRef(false);

  const [client] = useState(() => {
    if (!tenant || !tenant?.auth0) {
      return;
    }

    const clientOpts: Auth0ClientOptions = {
      cacheLocation: "localstorage",
      domain: tenant.publicDomain || tenant.auth0.domain,
      clientId: tenant.auth0.clientId,
      useRefreshTokens: true,
      useRefreshTokensFallback: true,
      authorizationParams: {
        redirect_uri: `${tenant.auth0.redirectUri}/callback`,
        organization: tenant.auth0.organization || undefined,
        audience: tenant.auth0.audience || "https://nesto.ca/api",
        // * NOTE:
        // These 2 are what it is different from the Auth0Provider
        //
        connection: "Username-Password-Cypress",
        passwordless: false,
      },
    };

    return new Auth0Client(clientOpts);
  });

  useEffect(() => {
    // waiting for client and tenant to be ready
    // run once only
    if (!client || didInitialise.current) {
      return;
    }

    didInitialise.current = true;

    // SEND THE USER TO THE LOGIN PAGE FOR E2E TESTING
    (async () => {
      client.loginWithRedirect(LOGIN_OPTIONS);
    })();
  }, [didInitialise, client]);
};
