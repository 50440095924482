import React, { useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { GateProvider } from "@nestoca/gate-react";
import { useAnalytics } from "@shared/analytics";
import { getAuthToken } from "@shared/api/client";
import { currentUser } from "@shared/store";
import { IdleTimeout, useAutoRefreshToken } from "@shared/ui";
import { useSetAtom } from "jotai";

interface UserProviderProps {
  children: React.ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const { track } = useAnalytics();
  const { isLoading, isAuthenticated, user, logout } = useAuth0();
  const [permissions, setPermissions] = useState<string[]>([]);
  const authToken = getAuthToken();

  const setCurrentUser = useSetAtom(currentUser);

  useAutoRefreshToken({
    setCurrentUser,
    setPermissions,
  });

  // when want Auth0 `Auth0Provider` to redirect to the login page
  if (isLoading || !isAuthenticated) {
    return <>{children}</>;
  }

  // when authenticated wait for `getAccessTokenSilently` to be resolved
  if (isAuthenticated && (!authToken || !user)) {
    return null;
  }

  return (
    <>
      <GateProvider user={user} permissions={permissions}>
        <>{children}</>
      </GateProvider>
      <IdleTimeout track={track} logout={logout} />
    </>
  );
};
