import { useMemo } from "react";

import { Application, TargetProperty } from "@shared/constants";
import { formatAddress } from "@shared/utils";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

export const useGetApplicationDetails = ({
  applications,
  applicationId,
}: {
  applications?: Application[];
  applicationId: number;
}) => {
  const { basePath } = useRouter();
  const { t } = useTranslation("applications");

  const addApplicantUrl = useMemo(() => {
    return basePath === "/documents"
      ? `${window.location.origin}/applications/${applicationId}/applicant/create`
      : `/applications/${applicationId}/applicant/create`;
  }, [applicationId, basePath]);

  const { property, type, index } = applications?.find((app) => {
    return app.id === applicationId;
  }) as Application & { index: number };

  const formattedAddress = useMemo(
    () => (property: TargetProperty | undefined) => {
      return formatAddress(property?.address, "street");
    },
    []
  );

  const selectedAddress = formattedAddress(property);
  const noAddress = `${t(type)} ${index}`;

  return {
    addApplicantUrl,
    selectedAddress,
    noAddress,
  };
};
