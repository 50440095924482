import {
  Chip,
  Box,
  Flex,
  Skeleton,
  Typography,
  SkeletonShape,
} from "@nestoca/ui";
import clsx from "clsx";
import { BsFillArrowRightCircleFill, BsHouse } from "react-icons/bs";

import styles from "./application-card.module.scss";

export const ApplicationCardSkeleton = () => (
  <Flex className={styles.card} gap={3}>
    <Flex gap={3}>
      <BsHouse size={20} />
      <Flex direction="column" gap={1}>
        <Skeleton
          className={clsx(
            styles["card-address"],
            styles["card__skeleton-address"]
          )}
        />
        <Skeleton
          className={clsx(styles["card-info"], styles["card__skeleton-info"])}
        />
        <Skeleton
          className={clsx(styles["card-info"], styles["card__skeleton-info"])}
        />
      </Flex>
    </Flex>
    <Flex align="end" justify="between" direction="column">
      <Skeleton
        shape={SkeletonShape.SQUARE}
        className={styles["card__skeleton-badge"]}
      />
      <BsFillArrowRightCircleFill className={styles["card-arrow"]} />
    </Flex>
  </Flex>
);
