import { useAuth0 } from "@auth0/auth0-react";
import { Sidebar as SidebarUI, SidebarProps } from "@nestoca/ui";
import { isSidebarOpen as isSidebarOpenAtom } from "@shared/store";
import clsx from "clsx";
import { useAtom } from "jotai";

import { Footer } from "./footer";
import { Header } from "./header";
import styles from "./sidebar.module.scss";

export const Sidebar = ({ children, className, style }: SidebarProps) => {
  const [isSidebarOpen] = useAtom(isSidebarOpenAtom);
  const { isAuthenticated } = useAuth0();

  return (
    <SidebarUI
      className={clsx(styles.sidebar, className)}
      narrow={!isSidebarOpen}
      style={style}
    >
      <SidebarUI.Header>
        <Header isSidebarOpen={isSidebarOpen} />
      </SidebarUI.Header>
      <SidebarUI.Content>{children}</SidebarUI.Content>
      <SidebarUI.Footer>{isAuthenticated && <Footer />}</SidebarUI.Footer>
    </SidebarUI>
  );
};
