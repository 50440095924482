import { useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useTenant } from "@nestoca/multi-tenant";
import { getAuthToken } from "@shared/api/client";
import { useGetAccount } from "@shared/api/hooks/account";
import { useGetApplication } from "@shared/api/hooks/applications";
import { useQueue, useTypedRouteArgs } from "@shared/utils";
import Debug from "debug";
import { useRouter } from "next/router";
import { z } from "zod";

import { getHumanMortgageType } from "./utils";

import type { LoggedInBaseEvent, EventPayload } from "./types";
import type { SupportedLocales } from "@shared/constants";

const debug = Debug("nesto:analytics");

const schema = z.object({
  applicationId: z.coerce.number().default(0),
});

const sendEvent = (payload: EventPayload) => {
  if (typeof window.gtm === "function") {
    debug("track", payload.event, payload);
    window.gtm(payload);
  }
};

export const useAnalytics = () => {
  const { queue, removeAt, add } = useQueue<{
    event: EventPayload;
    dep: boolean | string;
  }>();
  const { applicationId } = useTypedRouteArgs(schema);
  const { asPath, locale } = useRouter();
  const tenant = useTenant();
  const { isAuthenticated } = useAuth0();
  const authToken = getAuthToken();

  // Do we have all the data we need to do the query?
  const hasTokenReady = isAuthenticated && !!authToken;
  // *** Note: both next query hook will use isFetching to determine if the query is ready
  // *** isLoading returns true when you're fetching data for the first time. On the other hand,
  // *** isFetching will be true anytime there's an ongoing data fetching process, not just for the first time.
  const { data: account, isFetching: isFetchingAccount } = useGetAccount(
    undefined,
    hasTokenReady
  );
  const { data: application, isFetching: isFetchingApplication } =
    useGetApplication({
      // Do query only if applicationId is available and we have token in memory
      id: hasTokenReady ? applicationId : 0,
    });

  const track = (
    payload: EventPayload,
    waitFor: "none" | "all" | "account" | "application" = "none"
  ) => {
    add({ event: payload, dep: waitFor });
  };

  useEffect(() => {
    if (queue.length > 0) {
      const basePayload: LoggedInBaseEvent = {
        page_url: asPath,
        language_site: locale as SupportedLocales,
        tenant: tenant?.slug || "n/a",
        user_id: account?.id || 0,
        mortgage_type: application?.type
          ? getHumanMortgageType(application.type)
          : undefined,
      };

      const indexesToRemove: number[] = [];

      queue.forEach((payload, i) => {
        const isPayloadReady = payload.dep === "none";
        const isAccountReady =
          payload.dep === "account" && !isFetchingAccount && account?.id;
        const isApplicationReady =
          payload.dep === "application" &&
          !isFetchingAccount &&
          application?.type;
        const isAllReady =
          payload.dep === "all" &&
          isPayloadReady &&
          isAccountReady &&
          isApplicationReady;

        const itemCanBeSent =
          isPayloadReady || isAccountReady || isApplicationReady || isAllReady;

        if (itemCanBeSent) {
          sendEvent({ ...basePayload, ...payload.event });
          // remove current item from the queue
          indexesToRemove.push(i);
        }
      });

      if (indexesToRemove.length > 0) {
        removeAt(indexesToRemove);
      }
    }
  }, [
    queue,
    asPath,
    locale,
    tenant?.slug,
    account?.id,
    application?.type,
    isFetchingAccount,
    isFetchingApplication,
    removeAt,
  ]);

  const consent = (consent: boolean) => {
    if (typeof window.gtag === "function") {
      debug("consent", "update", {
        ad_storage: consent,
        analytics_storage: consent,
      });
      window?.gtag("consent", "update", {
        ad_storage: consent,
        analytics_storage: consent,
      });
    }
  };

  return {
    track,
    consent,
  };
};
