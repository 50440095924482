import { z } from "zod";

import { AccountDeletionReason } from "./account.enum";

export const deleteAccountSchema = z.object({
  reason: z.nativeEnum(AccountDeletionReason, {
    errorMap: () => ({ message: "form:error.required" }),
  }),
  comments: z.string().trim().nullable().optional(),
});

export type DeleteAccountFormData = z.infer<typeof deleteAccountSchema>;
