import { ColumnFiltersState, SortingState } from "@tanstack/react-table";

import { ApplicationState } from "./state.enum";

import type { Address, Applicant } from "./applicant.types";
import type {
  APPLICATION_STATE_GROUP,
  CLOSED_LOST_CATEGORIES,
  Product,
  TargetProperty,
  AmountFrequency,
  MortgageFrequency,
  CLOSED_LOST_REASONS,
  TransactionTypeEnum,
  RegionEnum,
} from "@shared/constants";

type AccountRole =
  | "borrower"
  | "broker"
  | "realtor"
  | "financialadvisor"
  | "advisor";

export type Account = {
  id: number;
  rid: string;
  role: AccountRole;
  email: string;
  created: string;
  updated: string;
  firstName: string;
  lastName: string;
  phone: string;
  preferredLanguage: "en" | "fr";
  region: string;
  partner?: string;
  partnerSpecified?: boolean;
  partnerAgreement?: boolean;
  partnerAgreementSpecified?: boolean;
  preferredLanguageSpecified: boolean;
  firstNameSpecified: boolean;
  lastNameSpecified: boolean;
  phoneSpecified: boolean;
  regionSpecified: false;
  tenant: {
    id: number;
    slug: string;
  };
};

export type ServicingAsset = {
  id: number;
  details: {
    property: {
      address: Address;
    };
  };
  loans: {
    applicationId: number;
  }[];
};

export type ApplicationEvent = string;

export type Application = {
  id: number;
  mainApplicantId: number;
  assignedAdvisor: AssignedAdvisor;
  contactMade: string | null | undefined;
  created: string;
  updated: string;
  type: TransactionTypeEnum;
  validEvents: string[];
  applicants: Record<number, Applicant>;
  property?: TargetProperty;
  locked: boolean;
  salesforceQueueOutboundCallNow?: boolean;
  salesforceQueueComfortableSpeakingFrench?: boolean;
  salesforceApplicationSubmittedByAgent?: boolean;
  referralSnapshot: ReferralSnapshot;
  // Old property value
  /** deprecated */
  propertyToPurchase?: TargetProperty;
  preQualificationIdSpecified: boolean;
  preQualificationId: number;
  product?: Product | null;
  subPartnerID: number;
  mortgage?: ApplicationMortgage;
  sdrQualified?: boolean;
  linkedServicingAsset?: ServicingAsset;
  isNonDigital: boolean;
  heloc?: HelocApplication;
} & State;

export type ApplicationStateGroup = keyof typeof APPLICATION_STATE_GROUP;

export type ClosedLostCategories = typeof CLOSED_LOST_CATEGORIES;
export type ClosedLostReasons = typeof CLOSED_LOST_REASONS;

export type ClosedLostCategory = ClosedLostCategories[number];
export type ClosedLostReason = ClosedLostReasons[number];

export type ClosedApplicationState = {
  applicationState: ApplicationState.Closed;
  closedLostCategory: ClosedLostCategory;
  closedLostReason: ClosedLostReason;
};

export type ReferralSnapshot = {
  consultantIdentityId: string | null;
  tenantReferralId: number | null;
};

export type OtherApplicationState = {
  applicationState: Exclude<ApplicationState, ApplicationState.Closed>;
  closedLostCategory?: never;
  closedLostReason?: never;
};

export type State = ClosedApplicationState | OtherApplicationState;

// Kept only because CMA code still uses it (ApplicationTypeEnum/ApplicationType)
export enum ApplicationTypeEnum {
  NEW = "NEW",
  RENEWAL = "RENEWAL",
  REFINANCE = "REFINANCE",
}

export const ApplicationTypeEnumValues = Object.values(ApplicationTypeEnum);

export type ApplicationType = (typeof ApplicationTypeEnumValues)[number];

export type Lender =
  | "RBC"
  | "CIBC"
  | "TD"
  | "SCOTIABANK"
  | "NATIONAL_BANK"
  | "DESJARDINS"
  | "BMO"
  | "B2B_LAURENTIAN"
  | "MCAP"
  | "FIRST_NATIONAL"
  | "EQUITABLE_BANK"
  | "STREET_CAPITAL"
  | "LENDWISE"
  | "MERIX"
  | "CMLS"
  | "ALTERNA"
  | "RADIUS_FINANCIAL"
  | "DUCA"
  | "HOME_TRUST"
  | "ICICI_BANK"
  | "INDUSTRIAL_ALLIANCE"
  | "MANULIFE"
  | "MEREDIAN"
  | "OPTIMUM_MORTGAGE"
  | "PC_FINANCIALS"
  | "PRIVATE"
  | "OTHER";

export interface CreateApplication {
  type: TransactionTypeEnum;
  subParnerId: number;
}

export interface ApplicationMortgage {
  firstPaymentDate: string;
  maturityDate: string;
  payment: AmountFrequency<MortgageFrequency>;
  monthlyPayment: AmountFrequency<MortgageFrequency>;
  product?: Product;
  amortizationYears: number;
  amortizationMonths: number;
  finalRate: number;
  closingDate?: string;
  mortgageAmountPremiumsIncluded: number;
}

export interface ApplicationSteps {
  applicantInformation: boolean;
  registeredAddress: boolean;
  income: boolean;
  otherIncome: boolean;
  otherProperties: boolean;
  bankingDetails: boolean;
  subjectProperty: boolean;
  downpayment: boolean;
}

export type ApplicantNavigationStates = {
  applicantInformation: boolean;
  registeredAddress: boolean;
  income: boolean;
  otherIncome: boolean;
  otherProperties: boolean;
  bankingDetails: boolean;
};

export type ApplicantStates = {
  [id: string]: ApplicantNavigationStates;
};
export type SubjectPropertyStates = {
  subjectProperty: boolean;
};

export type DownpaymentStates = {
  downpayment: boolean;
};

export type NavigationStates = SubjectPropertyStates &
  DownpaymentStates &
  ApplicantStates;

export type AssignedAdvisor = {
  id: number;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
};

export type ApplicationsPayload = {
  sorting?: SortingState;
  filters?: ColumnFiltersState;
  pageIndex?: number;
  pageSize?: number;
  searchQuery?: string;
};

export type ApplicationsResponse = {
  results: Application[];
  pageCount: number;
  totalResults: number;
};

export type LienOptions = "FIRST_LIEN" | "SECOND_LIEN";

export type HelocProductType = {
  id: number;
  tenantId: number;
  name: string;
  family: string;
  purpose: string;
  creditScoreMin: number;
  creditScoreMax: number;
  tdsMax: number;
  gdsMax: number;
  lenderId: number;
  lenderName: string;
  lenderType: string;
  investorId: number;
  rate: number;
  rateBase: number;
  ratePrimeVariance: number;
  bestRate: number;
  commissionBPS: number;
  presentationPdfUrlsByLanguage: {
    en: string;
    fr: string;
  };
  transactionTypes: string[];
  logoURL: string;
  regionCode: string;
  externalId: string;
  version: number;
  created: Date;
  updated: Date;
  ltvCreditLimitMax: number;
  ltvCombinedMax: number;
  creditLimitMin: number;
  creditLimitMax: number;
  rank: number;
  standalone: boolean;
  limitToFirstRankInvestor: boolean;
  rateDiscountType: string;
  clientMinimumAssets: number;
  baseRate: number;
  purposeDescription: string;
  product?: {
    family: string;
    investor: string;
    name: string;
  };
};

export type HelocApplication = {
  helocId: number;
  applicationId: number;
  helocLoanNumber: string;
  helocAmount: number;
  gds: number;
  tds: number;
  helocLtv: number;
  cltv: number;
  lien: LienOptions;
  transactionType: ApplicationType;
  finalRate: number;
  rateDiscountBPS: number;
  finalPrimeVariance: number;
  cashbackPaidAmount: number;
  hasCollateralCharge?: boolean;
  commitmentIssuedDate?: string;
  commitmentExpiryDate?: string;
  dischargeFee: number;
  transferFee: number;
  product: HelocProductType;
  created: Date;
  updated: Date;
  closingDate: string;
  initialUsedBalance: number;
  initialDrawdownAmount: number;
  firstPaymentDate: string;
};

export type CommunicationPreferencesType =
  | "newsletters"
  | "documentCenter"
  | "rateDrop"
  | "advisorsCommunication"
  | "reminders";

export type CommunicationPreferences = Record<
  CommunicationPreferencesType,
  { email?: boolean; sms?: boolean }
>;

export type DownPaymentByApplicant = {
  applicantId: number;
  firstName: string;
  lastName: string;
  amount: number;
};

export type DownPayment = {
  applicants: Record<number, DownPaymentByApplicant>;
  applicationId: number;
  total: number;
};

export enum GeolocationEnum {
  IP_API = `ipapi.co`,
  IP_INFO = `ipinfo.io`,
}

type PostalCode =
  | `${string}${number}${string} ${number}${string}${number}`
  | `${string}${number}${string}${number}${string}${number}`
  | `${string}${number}${string}`
  // eslint-disable-next-line @typescript-eslint/ban-types
  | (string & {})
  | undefined;

type Geolocation = {
  ip: string;
  city: string;
  region: string;
  region_code: RegionEnum;
  country: string;
  country_code: string;
  error: false | any;
  latitude: number;
  longitude: number;
  postal: PostalCode;
};

export type GeolocationResponse = {
  [GeolocationEnum.IP_API]: Geolocation;
  [GeolocationEnum.IP_INFO]: Geolocation;
};

export type CallPreferencesPayload = {
  callPreference: string | null;
};

export type ApplicationChangeStateEvent =
  | "SUBMIT"
  | "SUBMIT_TO_LENDERS_DATA_EXCHANGE"
  | "DELETE";
