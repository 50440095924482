import { Chip, Flex, Typography } from "@nestoca/ui";
import { PROGRESS_STAGES_STATUSES } from "@shared/constants";
import { KeepI18ExprHappy } from "@shared/utils";
import clsx from "clsx";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { BsArrowsAngleExpand, BsCheckCircleFill } from "react-icons/bs";

import styles from "./progress-step.module.scss";

type propsType = {
  status: PROGRESS_STAGES_STATUSES;
  name: string;
  icon: React.ReactNode;
  onClick: () => void;
  inModal: boolean;
};

export const ProgressStep = ({
  status,
  name,
  icon,
  onClick,
  inModal,
}: propsType) => {
  const { t } = useTranslation("docs");
  const { locale } = useRouter();

  return (
    <Flex
      onClick={onClick}
      className={clsx(styles.step, {
        [styles["modal-step"]]: inModal,
      })}
    >
      <Flex gap={3} className={styles["step__status-container"]}>
        {status === PROGRESS_STAGES_STATUSES.COMPLETE ? (
          <BsCheckCircleFill
            color={"var(--color-status-success-foreground-saturated)"}
          />
        ) : (
          icon
        )}
        <Flex
          direction="column"
          gap={1}
          className={clsx(styles["step__status"], {
            [styles["step__status-modal"]]: inModal,
          })}
        >
          <Typography size={0} height={1}>
            {t(`progressTracker.${name}` as KeepI18ExprHappy)}
          </Typography>
          <Chip
            label={t(status)}
            className={clsx(
              styles[`chip-${status}`],
              styles[`chip-${locale}`],
              { [styles["chip-modal"]]: inModal }
            )}
          />
        </Flex>
      </Flex>
      <BsArrowsAngleExpand size={12} className={styles["step-expand"]} />
    </Flex>
  );
};
