import { useContext } from "react";

import { PartnerContext } from "./partner-provider";

export const usePartner = () => {
  const context = useContext(PartnerContext);

  if (context === undefined)
    throw new Error(
      "usePartnerContext must be used within an PartnerContext Provider"
    );

  return context;
};
