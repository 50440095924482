import { ReactNode } from "react";

import { Box, Flex, Modal, Typography, useBreakpointValue } from "@nestoca/ui";
import { CIRCLE_PROGRESS_TRACKER_PHASES } from "@shared/constants";
import { KeepI18ExprHappy } from "@shared/utils";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { AdvisorContact } from "../advisor-contact";
import desktopStyles from "../progress-modal.module.scss";

import { ActiveModal } from "./circle-progress-tracker";
import { PhaseHeader } from "./phase-header";
import styles from "./tracker-modal.module.scss";

type ModalType = {
  applicationId: number;
  isModalOpen: boolean;
  setActiveModal: (activeModal: ActiveModal) => void;
  phaseNumber: number;
  phase: CIRCLE_PROGRESS_TRACKER_PHASES;
  completePercent: number | [number, number];
  totalCompletePercent: number;
  children: ReactNode;
};

export const PhaseModal = ({
  applicationId,
  isModalOpen,
  setActiveModal,
  phaseNumber,
  phase,
  completePercent,
  totalCompletePercent,
  children,
}: ModalType) => {
  const { t } = useTranslation("docs");
  const isMobile = useBreakpointValue({ default: true, md: false });

  return (
    <Modal
      visible={isModalOpen}
      className={clsx({
        [styles["modal"]]: isMobile,
        [desktopStyles["progress-modal"]]: !isMobile,
      })}
      onClose={() => {
        setActiveModal(null);
      }}
      e2ePrefix={phase}
    >
      <Modal.Header
        className={clsx({
          [desktopStyles["progress-modal-header"]]: !isMobile,
        })}
      >
        {!isMobile && (
          <Flex direction="column">
            <Box className={desktopStyles["progress-modal-text"]}>
              {`${t("phase")} ${phaseNumber}`}
            </Box>
            <Box className={desktopStyles["progress-modal-header-name"]}>
              {t(`progress.stage.${phase}.name` as KeepI18ExprHappy)}
            </Box>
          </Flex>
        )}
      </Modal.Header>
      <Modal.Body
        className={clsx({
          [styles.body]: isMobile,
          [desktopStyles["progress-modal-body"]]: !isMobile,
        })}
      >
        {isMobile && (
          <>
            <PhaseHeader
              phaseNumber={phaseNumber}
              phase={phase}
              isActive={false}
              completePercent={completePercent}
              totalCompletePercent={totalCompletePercent}
              inModal={true}
            />
            <Flex
              direction="column"
              gap={2}
              className={styles["modal__description"]}
            >
              <Typography weight={7} size={2}>
                {t("progress.modal.descriptionHeading")}
              </Typography>
              {children}
            </Flex>
          </>
        )}
        {!isMobile && (
          <>
            <Flex
              direction="column"
              gap={2}
              className={styles["modal__description"]}
            >
              <Typography weight={7} size={2}>
                {t("progress.modal.descriptionHeading")}
              </Typography>
              {children}
              <AdvisorContact applicationId={applicationId} />
            </Flex>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
