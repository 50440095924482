import { useState, useEffect } from "react";

import { Modal, Typography } from "@nestoca/ui";
import { onlineManager } from "@tanstack/react-query";
import { Trans } from "react-i18next";

export const ConnectivityListener = () => {
  const [isOnline, setIsOnline] = useState(onlineManager.isOnline());
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);

    const unsubscribe = onlineManager.subscribe(() => {
      setIsOnline(onlineManager.isOnline());
    });

    return () => unsubscribe();
  }, []);

  if (!isClient) return null;

  return (
    <Modal visible={!isOnline}>
      <Modal.Body>
        <Typography>
          <Trans
            components={{ bold: <strong /> }}
            i18nKey="connectivity.offline"
          />
        </Typography>
      </Modal.Body>
    </Modal>
  );
};
