import { Button, Flex, Modal, ModalProps, Typography } from "@nestoca/ui";
import { Trans, useTranslation } from "react-i18next";

import styles from "./idle-modal.module.scss";

type IdleModalProps = Pick<ModalProps, "visible" | "onClose"> & {
  handleLogout: () => void;
  handleStillHere: () => void;
  remainingTime: number | string;
};

export const IdleModal = ({
  visible,
  onClose,
  handleLogout,
  handleStillHere,
  remainingTime,
}: IdleModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      visible={visible}
      closeOnOutsideClick
      onClose={onClose}
      className={styles["idle-modal"]}
    >
      <Modal.Header>
        <Typography size={2} weight={7}>
          {t("idleModal.title")}
        </Typography>
      </Modal.Header>
      <Modal.Body className={styles["idle-modal__body"]}>
        <Typography className={styles["idle-modal__text"]}>
          <Trans
            t={t}
            i18nKey="idleModal.content"
            values={{ counter: remainingTime }}
            components={[<strong key={0} />]}
          />
        </Typography>
      </Modal.Body>
      <Modal.Footer>
        <Flex
          align="center"
          justify="end"
          className={styles["idle-modal__footer"]}
          gap={2}
        >
          <Button onClick={handleStillHere} data-dd-action-name="still here">
            {t("idleModal.button")}
          </Button>
          <Button
            variant="ghost"
            onClick={handleLogout}
            data-dd-action-name="logout"
          >
            {t("signOut")}
          </Button>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};
