import { useMemo, useState } from "react";

import { useTenantSetting } from "@nestoca/multi-tenant";
import {
  ButtonIcon,
  Chip,
  Flex,
  Typography,
  Grid,
  useBreakpointValue,
} from "@nestoca/ui";
import {
  Application,
  ApplicationState,
  APP_STATUSES_MAP,
  TRANSACTION_TYPE_LABEL,
} from "@shared/constants";
import { RateTypes } from "@shared/constants/application/product.const";
import { DatadogMask } from "@shared/datadog";
import { DeleteApplicationModal } from "@shared/ui/components/delete-application-modal";
import { KeepI18ExprHappy, formatAddress } from "@shared/utils";
import clsx from "clsx";
import Link from "next/link";
import { Trans, useTranslation } from "react-i18next";
import {
  BsFillArrowRightCircleFill,
  BsHouse,
  BsHouseFill,
  BsTrash,
} from "react-icons/bs";

import styles from "./application-card.module.scss";

type Props = {
  application: Application;
  href: URL | string;
  index: number;
  deleteApplicationMode?: boolean;
};

export const ApplicationCard = ({
  application,
  href,
  index,
  deleteApplicationMode,
}: Props) => {
  const { product, property, locked, created } = application;
  const { t } = useTranslation("applications");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const isMobile = useBreakpointValue({ default: true, md: false });
  const { value: enableDeleteApplication } = useTenantSetting(
    "enableDeleteApplication"
  );

  // *** NOTE: This is a workaround to avoid calling the API for each application
  // ***       to get the funded and closed status.
  // ***       This will avoid doing N+1 calls to the API.
  // ***       These hooks are derived from the single application API call.
  // ***       and cannot be call in this UI since they will create a performance issue.
  // ***       we already have the complete application object as a prop so we can use it.
  // const { data: isFunded } = useGetApplicationFundedStatus(application.id);
  // const { data: isClosed } = useGetApplicationClosedStatus(application.id);
  const isFunded = application.applicationState === "FUNDED";
  const isClosed = ["CLOSED", "EXPIRED"].includes(application.applicationState);

  const formattedAddress = useMemo(() => {
    return formatAddress(property?.address, "street");
  }, [property]);

  const formattedProduct = useMemo(() => {
    if (!product?.term && !product?.type) return;

    const type = t(RateTypes[product?.type] as KeepI18ExprHappy);
    const [term] = product.term.split("_");
    return {
      type,
      term,
    };
  }, [product, t]);

  const dateCreated = new Date(created).toISOString().split("T")[0];

  const text =
    APP_STATUSES_MAP[
      locked ? ApplicationState.Locked : application.applicationState
    ];

  const editable = isMobile && deleteApplicationMode;
  const showTrashIcon =
    enableDeleteApplication && !isFunded && (editable || !isMobile);

  return (
    <>
      <Link href={href} passHref legacyBehavior>
        <Grid
          className={clsx(styles.card, {
            [styles["card--disabled"]]: isFunded && editable,
          })}
          as="a"
          data-testid="application-card"
          data-dd-aaction-name="address card"
          data-linkbutton
          gap={2}
        >
          {isFunded ? (
            <BsHouseFill
              size={20}
              color="var(--color-green-600)"
              className={styles["card-house"]}
            />
          ) : (
            <BsHouse size={20} className={styles["card-house"]} />
          )}
          <Flex
            direction="column"
            gap={1}
            className={styles["card-address-container"]}
          >
            {!formattedAddress ? (
              <Typography
                size={2}
                className={styles["card-address"]}
                e2ePrefix="address-replacement"
              >
                {t(
                  TRANSACTION_TYPE_LABEL[application.type] as KeepI18ExprHappy
                )}{" "}
                {index + 1}
              </Typography>
            ) : (
              <DatadogMask
                as={Typography}
                className={styles["card-address"]}
                data-dd-aaction-name="address"
              >
                {formattedAddress}
              </DatadogMask>
            )}
            <Typography
              size={0}
              className={styles["card-info"]}
              e2ePrefix="product-name"
            >
              <Trans
                t={t}
                i18nKey={
                  product?.type ? "application.term" : "application.noProduct"
                }
                values={{
                  term: formattedProduct?.term,
                  type: formattedProduct?.type,
                }}
              />
            </Typography>
            {!isFunded && (
              <Typography
                className={styles["card-info"]}
                e2ePrefix="date-created"
              >
                <Trans
                  t={t}
                  i18nKey={"application.dateCreated"}
                  values={{
                    dateCreated,
                  }}
                />
              </Typography>
            )}
          </Flex>
          <Chip
            className={styles["card-chip"]}
            label={t(text as KeepI18ExprHappy)}
            size="large"
            variant={
              (isFunded && "success") || (isClosed && "error") || "default"
            }
          />
          <Flex
            className={clsx(styles["card-icons"], {
              [styles["card-icons--edit"]]: editable,
            })}
            gap={3}
            justify="end"
            align="center"
          >
            {showTrashIcon && (
              <ButtonIcon
                aria-label="delete application"
                icon={<BsTrash key="btn-trash" />}
                isRound={!isMobile}
                size="xsmall"
                variant={isMobile ? "critical" : "ghost"}
                className={styles["card-trash"]}
                onClick={(e) => {
                  e.preventDefault();
                  setDeleteModalOpen(true);
                }}
              />
            )}
            <BsFillArrowRightCircleFill className={styles["card-arrow"]} />
          </Flex>
        </Grid>
      </Link>
      <DeleteApplicationModal
        applicationId={application.id}
        onClose={() => setDeleteModalOpen(false)}
        visible={deleteModalOpen}
      />
    </>
  );
};
