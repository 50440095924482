import { Flex } from "@nestoca/ui";
import React from "react";
import { Product } from "./product";
import { ProgressBar } from "@shared/ui/components/progress-bar";
import { getProgressValue } from "@shared/ui/components/progress-bar/utils/utils";
import { Property } from "./property";
import { SelectApplicationCardProps } from "../select-application-card";
import { Application } from "@shared/constants";

export interface BodyProps
  extends Pick<SelectApplicationCardProps, "showProgressBar"> {
  application: Application;
}

export const SelectApplicationCardBody = ({
  application,
  showProgressBar,
}: BodyProps) => {
  const { property, product } = application;
  return (
    <Flex direction="column">
      <Flex direction="column">
        {showProgressBar && (
          <ProgressBar value={getProgressValue(application)} />
        )}
        <Property property={property} />
        <Product product={product} />
      </Flex>
    </Flex>
  );
};
