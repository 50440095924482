import { z } from "zod";

export const mortgageTypeRouteEnum = z.enum([
  "new-mortgage",
  "refinance",
  "renewal",
]);

export const mortgageTypeEnum = z.enum(["NEW", "REFINANCE", "RENEWAL"]);

export const toMortgageTypeFromParam = <
  MTP extends RouteMortgageType = "new-mortgage" | "renewal" | "refinance",
  MT extends MortgageType = MTP extends "new-mortgage"
    ? "NEW"
    : MTP extends "renewal"
      ? "RENEWAL"
      : MTP extends "refinance"
        ? "REFINANCE"
        : never,
>(
  routeParam: MTP
) => {
  switch (routeParam) {
    case "refinance":
      return "REFINANCE" as MT;
    case "renewal":
      return "RENEWAL" as MT;
    default:
      return "NEW" as MT;
  }
};

export const gaqRouteParamsSchema = z.object({
  mortgageType: mortgageTypeRouteEnum
    .default("new-mortgage")
    .transform(toMortgageTypeFromParam),
});

export type RouteMortgageType = z.infer<typeof mortgageTypeRouteEnum>;
export type GaqRouteParams = z.infer<typeof gaqRouteParamsSchema>;
export type MortgageType = z.infer<typeof mortgageTypeEnum>;

export const allMortgageTypeRouteParams = z.object({
  mortgageType: mortgageTypeRouteEnum
    .default("new-mortgage")
    .transform(toMortgageTypeFromParam),
});
export type AllMortgageTypeRouteParams = z.infer<
  typeof allMortgageTypeRouteParams
>;

// Refinance and Renewal at the same time
export const refiRouteParamsSchema = z.object({
  mortgageType: z
    .enum(["refinance", "renewal"])
    .default("renewal")
    .transform((val) => toMortgageTypeFromParam<"refinance" | "renewal">(val)),
});
export type RefiRouteParams = z.infer<typeof refiRouteParamsSchema>;

// New mortgage only
export const newRouteParamsSchema = z.object({
  mortgageType: z
    .enum(["new-mortgage"])
    .default("new-mortgage")
    .transform((val) => toMortgageTypeFromParam<"new-mortgage">(val)),
});
export type NewRouteParams = z.infer<typeof newRouteParamsSchema>;

// Renewal only
export const renewalRouteParamsSchema = z.object({
  mortgageType: z
    .enum(["renewal"])
    .default("renewal")
    .transform((val) => toMortgageTypeFromParam<"renewal">(val)),
});
export type RenewalRouteParams = z.infer<typeof renewalRouteParamsSchema>;

// Refinance only
export const refinanceRouteParamsSchema = z.object({
  mortgageType: z
    .enum(["refinance"])
    .default("refinance")
    .transform((val) => toMortgageTypeFromParam<"refinance">(val)),
});
export type RefinanceRouteParams = z.infer<typeof refiRouteParamsSchema>;
