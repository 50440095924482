import { useEffect } from "react";

import { datadogRum } from "@datadog/browser-rum";

import { DEFAULT_USER } from "../constants";

import type { User } from "../types";

export const useDatadogUser = (user?: User) => {
  const newUser = user ?? DEFAULT_USER;

  useEffect(() => {
    datadogRum.setUser(newUser);
    return (): void => {
      datadogRum.removeUser();
    };
  }, [newUser]);
};
