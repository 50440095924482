import * as z from "zod";

// *** NOTE: importing from @shared/utils is not working ***
// *** so we are importing with relative path the functions here ***
// It create a circular dependency
import { addTenYears, currentDate, isValidYear } from "../../utils/dates";
import { getAsNumber } from "../../utils/get-as-number";
import { isEmpty } from "../../utils/is-empty";
import {
  FundPurposeEnum,
  HeatingTypeEnum,
  MAX_LENDING_AMOUNT_RATIO,
  SewageTypeEnum,
  TargetPropertyStyleEnum,
  TargetPropertyTenureEnum,
  TargetPropertyTypeEnum,
  WaterTypeEnum,
  addressSchema,
  rentalIncomeAmountFrequency,
  rentalIncomeAmountFrequencyOptional,
} from "../application";
import { amountSchema, LenderEnum } from "../generic";
import {
  amountUnitSchema,
  coOwnersheepFeesOptional,
  garageSchema,
} from "../target-property";

const DATE_VALIDATION_REGEX =
  /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/;

export const purchaseDateSchema = z
  .string({
    required_error: "form:error.required",
    invalid_type_error: "form:error.required",
  })
  .trim()
  .regex(DATE_VALIDATION_REGEX, {
    message: "form:error.invalidDate",
  })
  .refine(
    (value) => {
      // Get the current date in YYYY-MM-DD format
      return value <= currentDate;
    },
    {
      message: "form:error.invalidDate",
    }
  );

export const maturityDateSchema = z
  .string({
    required_error: "form:error.required",
    invalid_type_error: "form:error.required",
  })
  .trim()
  .regex(DATE_VALIDATION_REGEX, {
    message: "form:error.invalidDate",
  })
  .refine((value) => value >= currentDate, {
    message: "form:error.dateShouldBeFuture",
  })
  .refine((value) => value <= addTenYears(), {
    message: "form:error.invalidDate",
  });

export const additionalFundAmountSchema = z.preprocess(
  (v) => (isEmpty(v) ? null : getAsNumber(v)),
  z
    .number({
      required_error: "form:error.required",
      invalid_type_error: "form:error.required",
      coerce: false,
    })
    .min(1, { message: "form:error.required" })
);

export const baseSchema = (tenant?: string) =>
  z
    .object({
      setRegisteredAddress: z.any().optional(),
      estimatedPropertyValue: amountSchema,
      purchasePrice: amountSchema,
      rentalIncome: rentalIncomeAmountFrequencyOptional.nullable().optional(),
      purchaseDate: purchaseDateSchema,
      purpose: z.enum(
        [
          "OWNER_OCCUPIED",
          "OWNER_OCCUPIED_AND_RENTAL",
          "RENTAL",
          "SECONDARY_RESIDENCE",
        ],
        {
          errorMap: () => {
            return { message: "form:error.required" };
          },
        }
      ),
      address: addressSchema,
      selectedAddress: z.any(),
      yearBuilt: z.preprocess(
        (v) => (isEmpty(v) ? null : getAsNumber(v)),
        z.number({ invalid_type_error: "form:error.required" }).refine(
          (data) => {
            return isValidYear(data);
          },
          {
            message: "form:error.invalidYear",
          }
        )
      ),
      propertyStyle: z.nativeEnum(TargetPropertyStyleEnum, {
        errorMap: () => {
          return { message: "form:error.required" };
        },
      }),
      propertyType: z.nativeEnum(TargetPropertyTypeEnum, {
        errorMap: () => {
          return { message: "form:error.required" };
        },
      }),
      tenure: z.nativeEnum(TargetPropertyTenureEnum, {
        errorMap: () => {
          return { message: "form:error.required" };
        },
      }),
      schoolAndMunicipalTaxes: z.object({
        amount: amountSchema,
        year: z
          .preprocess(
            (v) => (isEmpty(v) ? null : getAsNumber(v)),
            z.number({
              required_error: "form:error.required",
              invalid_type_error: "form:error.required",
            })
          )
          .refine(
            (v) => {
              // latest version 3.22.2 of zod has a bug with the preprocessor
              // so we will do the convertion here again to be sure we have the right type
              // https://github.com/colinhacks/zod/issues/2671
              const value = isEmpty(v) ? null : getAsNumber(v);

              if (!value) {
                return false;
              }

              return value > 0;
            },
            {
              message: "form:error.required",
            }
          ),
      }),
      livingSpace: amountUnitSchema,
      lotSize: amountUnitSchema,
      heatingType: z.nativeEnum(HeatingTypeEnum, {
        errorMap: () => {
          return { message: "form:error.required" };
        },
      }),
      heatingCostIncluded: z.boolean().nullable(),
      heatingCost: z
        .object({
          amount: z
            .preprocess(
              (v) => (isEmpty(v) ? null : getAsNumber(v)),
              z
                .number({
                  required_error: "required",
                  invalid_type_error: "type",
                  coerce: false,
                })
                .max(9_999_999, {
                  message: "form:error.maxDollarAmount",
                })
                .nullable()
            )
            .optional(),
          frequency: z.preprocess(
            (v) => (isEmpty(v) ? "" : v),
            z
              .enum(
                [
                  "WEEKLY",
                  "BIWEEKLY",
                  "MONTHLY",
                  "SEMIMONTHLY",
                  "SEMIANNUALLY",
                  "ANNUALLY",
                  "",
                ],
                {
                  errorMap: () => {
                    return { message: "form:error.required" };
                  },
                }
              )
              .optional()
          ),
        })
        .nullable(),
      coOwnershipFees: coOwnersheepFeesOptional,
      waterType: z.nativeEnum(WaterTypeEnum, {
        errorMap: () => {
          return { message: "form:error.required" };
        },
      }),
      sewageType: z.nativeEnum(SewageTypeEnum, {
        errorMap: () => {
          return { message: "form:error.required" };
        },
      }),
      garage: garageSchema,
      mortgages: z
        .object({
          balance: amountSchema,
          interestRate: z
            .number({
              required_error: "form:error.required",
              invalid_type_error: "form:error.required",
              coerce: false,
            })
            .max(100, { message: "form:error.maxInterest" }),
          interestRateType: z.enum(
            ["FIXED", "VARIABLE", "ADJUSTABLE", "CAPPED_VARIABLE"],
            {
              errorMap: () => {
                return { message: "form:error.required" };
              },
            }
          ),
          lender: z.nativeEnum(LenderEnum, {
            errorMap: () => {
              return { message: "form:error.required" };
            },
          }),
          maturityDate: maturityDateSchema,
          mortgageType: z.enum(["STANDARD", "HELOC"], {
            errorMap: () => {
              return { message: "form:error.required" };
            },
          }),
          payment: z.object({
            amount: z
              .preprocess(
                (v) => (isEmpty(v) ? null : getAsNumber(v)),
                z
                  .number({
                    required_error: "form:error.missingAmount",
                    invalid_type_error: "form:error.missingAmount",
                    coerce: false,
                  })
                  .max(9_999_999, { message: "form:error.maxDollarAmount" })
              )
              .refine(
                (v) => {
                  // latest version 3.22.2 of zod has a bug with the preprocessor
                  // so we will do the convertion here again to be sure we have the right type
                  // https://github.com/colinhacks/zod/issues/2671
                  const value = isEmpty(v) ? null : getAsNumber(v);

                  if (!value) {
                    return false;
                  }

                  return value > 0;
                },
                {
                  message: "form:error.missingAmount",
                }
              )
              .refine(
                (v) => {
                  // latest version 3.22.2 of zod has a bug with the preprocessor
                  // so we will do the convertion here again to be sure we have the right type
                  // https://github.com/colinhacks/zod/issues/2671
                  const value = isEmpty(v) ? null : getAsNumber(v);
                  if (!value) {
                    return false;
                  }
                  return value <= 9999999;
                },
                {
                  message: "form:error.maxDollarAmount",
                }
              ),
            frequency: z.enum(
              [
                "WEEKLY",
                "ACCELERATED_WEEKLY",
                "BIWEEKLY",
                "ACCELERATED_BIWEEKLY",
                "SEMIMONTHLY",
                "MONTHLY",
              ],
              {
                errorMap: () => {
                  return { message: "form:error.missingFrequency" };
                },
              }
            ),
          }),
          termType: z.enum(["OPEN", "CLOSED", "CONVERTABLE"], {
            errorMap: () => {
              return { message: "form:error.required" };
            },
          }),
        })
        .array()
        .optional()
        .nullable(),
      additionalFundAmount: z.any(),
      fundPurpose: z.nativeEnum(FundPurposeEnum, {
        errorMap: () => {
          return { message: "form:error.required" };
        },
      }),
    })
    .superRefine((values, ctx) => {
      const propertyValue = values.estimatedPropertyValue;
      const mortgageBalance = values.mortgages?.[0].balance || 0;

      const percentage = MAX_LENDING_AMOUNT_RATIO;

      const maxAdditionalFund = propertyValue * percentage - mortgageBalance;

      const isOk = z
        .number()
        .max(maxAdditionalFund > 0 ? Math.round(maxAdditionalFund) : 0)
        .safeParse(values.additionalFundAmount);

      if (!isOk.success) {
        isOk.error.issues.forEach((issue) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `form:error.customMaxAmount`,
            path: ["additionalFundAmount", ...issue.path],
          });
        });
      }
    })
    .and(purposeUnion);

export const purposeUnion = z.discriminatedUnion("purpose", [
  z.object({
    purpose: z.enum(["OWNER_OCCUPIED", "SECONDARY_RESIDENCE"], {
      required_error: "form:error.required",
    }),
    rentalIncome: rentalIncomeAmountFrequencyOptional.nullable().optional(),
  }),
  z.object({
    purpose: z.enum(["OWNER_OCCUPIED_AND_RENTAL", "RENTAL"], {
      errorMap: () => {
        return { message: "form:error.required" };
      },
    }),
    rentalIncome: rentalIncomeAmountFrequency,
  }),
]);

// create has additional field setRegisteredAddress
export const createPropertySchema = (tenant?: string) =>
  z
    .discriminatedUnion("setRegisteredAddress", [
      z.object({
        setRegisteredAddress: z.literal(true),
      }),
      z.object({
        setRegisteredAddress: z.literal(false),
        address: addressSchema,
      }),
    ])
    .and(baseSchema(tenant));

export const createRefinancePropertySchema = (tenant?: string) =>
  z.union([baseSchema(tenant), createPropertySchema(tenant)]);

export const refinancePropertySchema = createRefinancePropertySchema();

export type RefinancePropertyForm = z.infer<typeof refinancePropertySchema>;
