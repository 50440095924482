import {
  AssetTypeEnum,
  FinancialInstitutionEnum,
  FamilyRelationshipEnum,
} from "./asset.enum";

export const MAX_VALUE = 10_000_000;

export const financialTypes = [
  AssetTypeEnum.SAVINGS,
  AssetTypeEnum.TFSA,
  AssetTypeEnum.RRSP,
  AssetTypeEnum.NON_REGISTERED_INVESTMENTS,
] as const;

export const financialInstitutions = [
  FinancialInstitutionEnum.RBC,
  FinancialInstitutionEnum.TD,
  FinancialInstitutionEnum.SCOTIA_BANK,
  FinancialInstitutionEnum.BMO,
  FinancialInstitutionEnum.CIBC,
  FinancialInstitutionEnum.NBC,
  FinancialInstitutionEnum.DESJARDINS,
  FinancialInstitutionEnum.SUNLIFE,
  FinancialInstitutionEnum.HSBC,
  FinancialInstitutionEnum.TANGERINE,
  FinancialInstitutionEnum.IG,
  FinancialInstitutionEnum.QUESTTRADE,
  FinancialInstitutionEnum.WEALTH_SIMPLE,
  FinancialInstitutionEnum.ETRADE,
  FinancialInstitutionEnum.EQB,
  FinancialInstitutionEnum.ITRADE,
  FinancialInstitutionEnum.QTRADE_INVESTOR,
  FinancialInstitutionEnum.LAURENTIAN,
] as const;

export const ASSET_TYPES_LABEL: Record<AssetTypeEnum, string> = {
  [AssetTypeEnum.SAVINGS]: "form:assetType.savings",
  [AssetTypeEnum.TFSA]: "form:assetType.tfsa",
  [AssetTypeEnum.RRSP]: "form:assetType.rrsp",
  [AssetTypeEnum.NON_REGISTERED_INVESTMENTS]:
    "form:assetType.nonRegisteredInvestments",
  [AssetTypeEnum.GIFT]: "form:assetType.gift",
  [AssetTypeEnum.VEHICLE]: "form:assetType.vehicle",
  [AssetTypeEnum.PROPERTY]: "form:assetType.property",
  [AssetTypeEnum.OTHER]: "form:assetType.other",
};

export const ASSET_TYPES_OPTIONS = [
  {
    label: "form:assetType.savings",
    value: AssetTypeEnum.SAVINGS,
  },
  {
    label: "form:assetType.tfsa",
    value: AssetTypeEnum.TFSA,
  },
  {
    label: "form:assetType.rrsp",
    value: AssetTypeEnum.RRSP,
  },
  {
    label: "form:assetType.nonRegisteredInvestments",
    value: AssetTypeEnum.NON_REGISTERED_INVESTMENTS,
  },
  {
    label: "form:assetType.gift",
    value: AssetTypeEnum.GIFT,
  },
  {
    label: "form:assetType.vehicle",
    value: AssetTypeEnum.VEHICLE,
  },
  // we don't allow to create property assets.
  // it is created automatically when a user creates a property with status after sale to "sold"
  // {
  //   label: "form:assetType.property",
  //   value: AssetTypeEnum.PROPERTY,
  // },
  {
    label: "form:assetType.other",
    value: AssetTypeEnum.OTHER,
  },
];

export const FAMILY_RELATIONSHIP_OPTIONS = [
  {
    label: "form:familyRelationship.father",
    value: FamilyRelationshipEnum.FATHER,
  },
  {
    label: "form:familyRelationship.mother",
    value: FamilyRelationshipEnum.MOTHER,
  },
  {
    label: "form:familyRelationship.child",
    value: FamilyRelationshipEnum.CHILD,
  },
  {
    label: "form:familyRelationship.brother",
    value: FamilyRelationshipEnum.BROTHER,
  },
  {
    label: "form:familyRelationship.sister",
    value: FamilyRelationshipEnum.SISTER,
  },
  {
    label: "form:familyRelationship.grandFather",
    value: FamilyRelationshipEnum.GRAND_FATHER,
  },
  {
    label: "form:familyRelationship.grandMother",
    value: FamilyRelationshipEnum.GRAND_MOTHER,
  },
  {
    label: "form:familyRelationship.uncle",
    value: FamilyRelationshipEnum.UNCLE,
  },
  {
    label: "form:familyRelationship.aunt",
    value: FamilyRelationshipEnum.AUNT,
  },
  // cousin ?
];

export const ASSET_LABELS: Record<AssetTypeEnum, string> = {
  [AssetTypeEnum.SAVINGS]: "asset.savings",
  [AssetTypeEnum.TFSA]: "asset.tfsa",
  [AssetTypeEnum.RRSP]: "asset.rrsp",
  [AssetTypeEnum.NON_REGISTERED_INVESTMENTS]: "asset.nonRegistered",
  [AssetTypeEnum.GIFT]: "asset.gift",
  [AssetTypeEnum.VEHICLE]: "asset.vehicle",
  [AssetTypeEnum.PROPERTY]: "asset.property",
  [AssetTypeEnum.OTHER]: "asset.other",
};

export const FINANCIAL_INSTITUTION_LABELS: Record<
  FinancialInstitutionEnum,
  string
> = {
  [FinancialInstitutionEnum.BMO]: "applications:financialInstitution.bmo",
  [FinancialInstitutionEnum.CIBC]: "applications:financialInstitution.cibc",
  [FinancialInstitutionEnum.DESJARDINS]:
    "applications:financialInstitution.desjardins",
  [FinancialInstitutionEnum.EQB]: "applications:financialInstitution.eqb",
  [FinancialInstitutionEnum.ETRADE]: "applications:financialInstitution.etrade",
  [FinancialInstitutionEnum.HSBC]: "applications:financialInstitution.hsbc",
  [FinancialInstitutionEnum.IG]: "applications:financialInstitution.ig",
  [FinancialInstitutionEnum.ITRADE]: "applications:financialInstitution.itrade",
  [FinancialInstitutionEnum.LAURENTIAN]:
    "applications:financialInstitution.laurentian",
  [FinancialInstitutionEnum.NBC]: "applications:financialInstitution.nbc",
  [FinancialInstitutionEnum.QTRADE_INVESTOR]:
    "applications:financialInstitution.qtrade_investor",
  [FinancialInstitutionEnum.QUESTTRADE]:
    "applications:financialInstitution.questtrade",
  [FinancialInstitutionEnum.RBC]: "applications:financialInstitution.rbc",
  [FinancialInstitutionEnum.SCOTIA_BANK]:
    "applications:financialInstitution.scotia_bank",
  [FinancialInstitutionEnum.SUNLIFE]:
    "applications:financialInstitution.sunlife",
  [FinancialInstitutionEnum.TANGERINE]:
    "applications:financialInstitution.tangerine",
  [FinancialInstitutionEnum.TD]: "applications:financialInstitution.td",
  [FinancialInstitutionEnum.WEALTH_SIMPLE]:
    "applications:financialInstitution.wealth_simple",
  [FinancialInstitutionEnum.OTHER]: "applications:financialInstitution.other",
};

export const FINANCIAL_INSTITUTION_OPTIONS = [
  // Sorted ASC + other last
  {
    value: FinancialInstitutionEnum.BMO,
    label: FINANCIAL_INSTITUTION_LABELS[FinancialInstitutionEnum.BMO],
  },
  {
    value: FinancialInstitutionEnum.CIBC,
    label: FINANCIAL_INSTITUTION_LABELS[FinancialInstitutionEnum.CIBC],
  },
  {
    value: FinancialInstitutionEnum.DESJARDINS,
    label: FINANCIAL_INSTITUTION_LABELS[FinancialInstitutionEnum.DESJARDINS],
  },
  {
    value: FinancialInstitutionEnum.EQB,
    label: FINANCIAL_INSTITUTION_LABELS[FinancialInstitutionEnum.EQB],
  },
  {
    value: FinancialInstitutionEnum.ETRADE,
    label: FINANCIAL_INSTITUTION_LABELS[FinancialInstitutionEnum.ETRADE],
  },
  {
    value: FinancialInstitutionEnum.HSBC,
    label: FINANCIAL_INSTITUTION_LABELS[FinancialInstitutionEnum.HSBC],
  },
  {
    value: FinancialInstitutionEnum.IG,
    label: FINANCIAL_INSTITUTION_LABELS[FinancialInstitutionEnum.IG],
  },
  {
    value: FinancialInstitutionEnum.ITRADE,
    label: FINANCIAL_INSTITUTION_LABELS[FinancialInstitutionEnum.ITRADE],
  },
  {
    value: FinancialInstitutionEnum.LAURENTIAN,
    label: FINANCIAL_INSTITUTION_LABELS[FinancialInstitutionEnum.LAURENTIAN],
  },
  {
    value: FinancialInstitutionEnum.NBC,
    label: FINANCIAL_INSTITUTION_LABELS[FinancialInstitutionEnum.NBC],
  },
  {
    value: FinancialInstitutionEnum.QTRADE_INVESTOR,
    label:
      FINANCIAL_INSTITUTION_LABELS[FinancialInstitutionEnum.QTRADE_INVESTOR],
  },
  {
    value: FinancialInstitutionEnum.QUESTTRADE,
    label: FINANCIAL_INSTITUTION_LABELS[FinancialInstitutionEnum.QUESTTRADE],
  },
  {
    value: FinancialInstitutionEnum.RBC,
    label: FINANCIAL_INSTITUTION_LABELS[FinancialInstitutionEnum.RBC],
  },
  {
    value: FinancialInstitutionEnum.SCOTIA_BANK,
    label: FINANCIAL_INSTITUTION_LABELS[FinancialInstitutionEnum.SCOTIA_BANK],
  },
  {
    value: FinancialInstitutionEnum.SUNLIFE,
    label: FINANCIAL_INSTITUTION_LABELS[FinancialInstitutionEnum.SUNLIFE],
  },
  {
    value: FinancialInstitutionEnum.TANGERINE,
    label: FINANCIAL_INSTITUTION_LABELS[FinancialInstitutionEnum.TANGERINE],
  },
  {
    value: FinancialInstitutionEnum.TD,
    label: FINANCIAL_INSTITUTION_LABELS[FinancialInstitutionEnum.TD],
  },
  {
    value: FinancialInstitutionEnum.WEALTH_SIMPLE,
    label: FINANCIAL_INSTITUTION_LABELS[FinancialInstitutionEnum.WEALTH_SIMPLE],
  },
  {
    value: FinancialInstitutionEnum.OTHER,
    label: FINANCIAL_INSTITUTION_LABELS[FinancialInstitutionEnum.OTHER],
  },
];
