import { useCallback, useEffect, useRef } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";
import { Role } from "@nestoca/gate-core";
import { setAuthToken } from "@shared/api/client";
import { jwtDecode } from "jwt-decode";

import type { IdToken, User as Auth0User } from "@auth0/auth0-react";

interface UseCheckTokenProps {
  setCurrentUser?: (user: Auth0User) => void;
  setPermissions: (permissions: string[]) => void;
  setRole?: (role: Role) => void;
}

export const useCheckToken = ({
  setCurrentUser,
  setPermissions,
  setRole,
}: UseCheckTokenProps) => {
  const currentToken = useRef<string | null>(null);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  return useCallback(async () => {
    try {
      if (!isAuthenticated) {
        return;
      }

      const token = await getAccessTokenSilently();
      const isSameToken = token === currentToken.current;

      // *** Note: only continue if we have a different token so we don't sent permissions
      // ***       to the gate provider unnecessarily on every N seconds and re-render
      // ***       the entire app.
      if (!!token && isSameToken) {
        return;
      }

      currentToken.current = token;

      const {
        exp,
        permissions,
        "http://n.ca/user_role": role,
      } = jwtDecode<IdToken>(token);

      user && setCurrentUser?.(user);
      setAuthToken(token);
      setPermissions(permissions);
      setRole?.(role);

      if (!exp) return;

      const willExpireInFiveMinutes = Date.now() >= (exp - 5 * 60) * 1000;
      if (willExpireInFiveMinutes) {
        const newToken = await getAccessTokenSilently({ cacheMode: "off" });
        setAuthToken(newToken);
        setPermissions(permissions);
      }
    } catch (e) {
      // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
      // Auth0 will automatically do this when the user's token expires

      // https://nestoca.atlassian.net/browse/OG-6655
      // https://github.com/auth0/auth0-react/blob/75ad76e01672fc533fbed2fb63d9887fd83d6fc4/EXAMPLES.md?plain=1#L63-L79
      // https://community.auth0.com/t/why-is-authentication-lost-after-refreshing-my-single-page-application/56276

      let message = "Unknown error";
      let stack: string | undefined;
      if (e instanceof Error) {
        message = e.message;
        stack = e.stack;
      }

      // Send to DD to track how often this happens
      datadogRum.addAction("login_required", {
        message,
        stack,
      });
    }
  }, [
    getAccessTokenSilently,
    isAuthenticated,
    setCurrentUser,
    setPermissions,
    user,
    currentToken,
    setRole,
  ]);
};

export const useAutoRefreshToken = ({
  setCurrentUser,
  setPermissions,
  setRole,
}: UseCheckTokenProps) => {
  const checkToken = useCheckToken({
    setCurrentUser,
    setPermissions,
    setRole,
  });

  useEffect(() => {
    checkToken();
    const interval = setInterval(checkToken, 1000 * 5);

    return () => clearInterval(interval);
  }, [checkToken]);
};
