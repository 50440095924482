import { ComponentProps } from "react";

import { useInsertionEffectWithLayoutFallback } from "./hooks";
import { withCache } from "./with-cache-elm";

import type { Cache } from "../cache-elm";

const isBrowser = typeof document !== "undefined";

interface LoadStylesProps extends ComponentProps<"link"> {
  href: string;
  id: string;
  flushBefore?: boolean;
}

export const LoadStyles = withCache(
  ({ href, id, flushBefore }: LoadStylesProps, cache) => {
    if (!isBrowser) {
      return (
        <LoadStylesSSR
          href={href}
          id={id}
          cache={cache}
          flushBefore={flushBefore}
        />
      );
    }

    if (isBrowser) {
      return (
        <LoadStylesClient
          href={href}
          id={id}
          cache={cache}
          flushBefore={flushBefore}
        />
      );
    }

    return null;
  }
);

const LoadStylesSSR = ({
  href,
  cache,
  flushBefore,
}: LoadStylesProps & { cache?: Cache }) => {
  if (flushBefore) {
    cache?.flush();
  }
  // insert href into cache so we can add it on the header of _document.jsx
  cache?.insert(href);

  return null;
};

const LoadStylesClient = ({
  href,
  cache,
  flushBefore,
}: LoadStylesProps & { cache?: Cache }) => {
  useInsertionEffectWithLayoutFallback(() => {
    if (flushBefore) {
      cache?.flush();
    }
    cache?.insert(href);
    cache?.hydrate();
    return () => {
      cache?.flush();
    };
  }, [href]);

  return null;
};
