import { PropsWithChildren } from "react";

import { Box } from "@nestoca/ui";

import styles from "./locked-banner.module.scss";

export const LockedBannerWrapper = ({ children }: PropsWithChildren) => {
  return (
    <Box className={styles["main-wrapper"]}>
      <Box className={styles["width-wrapper"]}>{children}</Box>
    </Box>
  );
};
