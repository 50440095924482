import { Flex, Heading, Typography } from "@nestoca/ui";
import { useTranslation } from "next-i18next";

export const Maintenance = () => {
  const { t } = useTranslation("common");
  return (
    <main>
      <Flex
        direction="column"
        justify="center"
        align="center"
        style={{
          height: "100vh",
          padding: "0 1rem",
        }}
      >
        <Heading size={3} as="h2">
          {t("maintenance.title")}
        </Heading>
        <Typography>{t("maintenance.content")}</Typography>
      </Flex>
    </main>
  );
};
