import React from "react";

import Script, { ScriptProps } from "next/script";

import { usePageViews } from "./use-page-views";

interface GoogleTagManagerProps {
  strategy?: ScriptProps["strategy"];
  gtagUrl?: string;
  consent?: "granted" | "denied";
  gtmId: string;
  dataLayerName?: string;
  nonce?: string;
}

export const GoogleTagManager: React.FC<GoogleTagManagerProps> = ({
  gtagUrl = "https://www.googletagmanager.com/gtm.js",
  strategy = "afterInteractive",
  consent = "granted",
  gtmId,
  dataLayerName = "dataLayer",
  nonce,
}) => {
  const _gtmId = process.env.NEXT_PUBLIC_GTM_ID ?? gtmId;
  // Rename the data layer
  //https://developers.google.com/tag-platform/tag-manager/web/datalayer#gtag.js
  const dl = dataLayerName != "dataLayer" ? "&l=" + dataLayerName : "";

  usePageViews({ gtmId: _gtmId });

  if (!_gtmId) {
    return null;
  }

  return (
    <>
      <Script strategy={strategy} async src={`${gtagUrl}?id=${_gtmId}${dl}`} />
      <Script id="nextjs-google-tag-manager" strategy={strategy} nonce={nonce}>
        {`
                window.dataLayerName = "${dataLayerName}";
                window.${dataLayerName} = window.${dataLayerName} || [];
                window.${dataLayerName}.push({'gtm.start':new Date().getTime(),event:'gtm.js'});

                function gtag(){ window.${dataLayerName}.push(arguments); }
                function gtm(eventPayload){
                    window.${dataLayerName}.push(eventPayload);
                }

                gtag('config', '${_gtmId}', {
                  page_path: window.location.pathname,
                });

                ${
                  // https://developers.google.com/tag-platform/devguides/consent#tag-manager_1
                  consent === "denied"
                    ? `gtag('consent', 'default', {
                        'ad_storage': 'denied',
                        'analytics_storage': 'denied'
                        });`
                    : ``
                }
            `}
      </Script>
    </>
  );
};
