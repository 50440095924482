import { useState, useEffect, useMemo } from "react";

import { useTenantSetting } from "@nestoca/multi-tenant";
import { Button, useBreakpointValue } from "@nestoca/ui";
import {
  useGetApplicantType,
  useGetCoApplicants,
} from "@shared/api/hooks/applications";
import {
  applicantSectionsRouterSchema,
  ApplicantTypeEnum,
} from "@shared/constants";
import { useTypedRouter } from "@shared/utils";
import { useTranslation } from "react-i18next";
import { BsPersonDashFill } from "react-icons/bs";

import { RemoveCoApplicantModal } from "./remove-co-applicant-modal";

export const RemoveCoApplicantButton = ({
  setDeleteCoApplicantView,
  disabled,
}: {
  setDeleteCoApplicantView?: () => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation("applications");
  const {
    query: { applicationId, applicantId },
  } = useTypedRouter(applicantSectionsRouterSchema);
  const { value: isRemoveCoApplicantEnabled } = useTenantSetting(
    "enableRemovingCoApplicant"
  );
  const isMobile = useBreakpointValue({ default: true, md: false });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: coApplicants } = useGetCoApplicants(applicationId);
  const { data: selectedApplicantType } = useGetApplicantType(
    applicationId,
    applicantId
  );

  const isSelectedUserMainApplicant =
    selectedApplicantType === ApplicantTypeEnum.MAIN_APPLICANT;

  const showRemoveCoApplicant = useMemo(() => {
    return isSelectedUserMainApplicant && isRemoveCoApplicantEnabled;
  }, [isSelectedUserMainApplicant, isRemoveCoApplicantEnabled]);

  useEffect(() => {
    if (!coApplicants?.length) {
      setIsModalOpen(false);
    }
  }, [coApplicants?.length]);

  if (!showRemoveCoApplicant) {
    return null;
  }

  return (
    <>
      {coApplicants?.length !== 0 && (
        <Button
          disabled={disabled}
          variant="critical"
          aria-label="remove-co-applicant"
          e2ePrefix="remove-co-applicant"
          size={isMobile ? "large" : "medium"}
          data-dd-action-name="remove co-applicant"
          onClick={() => {
            setIsModalOpen(true);
            setDeleteCoApplicantView?.();
          }}
        >
          {t("removeApplicant")}
          <BsPersonDashFill />
        </Button>
      )}
      {!isMobile && (
        <RemoveCoApplicantModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </>
  );
};
