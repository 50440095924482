import { IdToken } from "@auth0/auth0-react";
import { getAuthToken } from "@shared/api/client";
import { jwtDecode } from "jwt-decode";

/**
 * The `ig` tenant has a special permission that allows corporate users access to all applications.
 * This is used to determine if the user is a corporate user.
 *
 * @returns true if the user is a corporate user, false otherwise
 */
export const isCorporateUser = () => {
  const token = getAuthToken();
  if (!token) return;

  const claims = jwtDecode<IdToken>(token);

  // If the user does not have the access_groups claim, they are not a corporate user
  // only IG advisors have this claim
  if (!claims["http://n.ca/access_groups"]) return false;

  return !!claims["http://n.ca/access_groups"].includes("*");
};
