import { Fragment } from "react";

import { useTenantSetting } from "@nestoca/multi-tenant";
import { Grid, Typography, Flex, Box } from "@nestoca/ui";
import { useTranslation } from "react-i18next";

import { Checkbox } from "./checkbox";
import styles from "./communication-preferences.module.scss";

export type Preferences = {
  id: string;
  label: string;
  email?: boolean;
  sms?: boolean;
};

export const CommunicationPreferences = ({
  showTitle = false,
}: {
  showTitle?: boolean;
}) => {
  const { value: communicationPreference } = useTenantSetting(
    "communicationPreference"
  );
  const { t } = useTranslation("common");

  const preferences: Preferences[] = [
    {
      id: "newsletters",
      label: t("communicationPreferences.newsletters"),
      email: true,
    },
    {
      id: "documentCenter",
      label: t("communicationPreferences.documentCentre"),
      email: true,
      sms: true,
    },
    {
      id: "rateDrop",
      label: t("communicationPreferences.rateDrop"),
      email: true,
    },
    {
      id: "advisorsCommunication",
      label: t("communicationPreferences.advisorsCommunication"),
      email: true,
      sms: true,
    },
    {
      id: "reminders",
      label: t("communicationPreferences.reminders"),
      email: true,
    },
  ];

  if (!communicationPreference) return null;

  return (
    <Box className={styles["communication-preferences"]}>
      {showTitle && (
        <Typography size={1} weight={7}>
          {t("communicationPreferences.title")}
        </Typography>
      )}
      <Grid
        className={styles["communication-preferences__grid"]}
        e2ePrefix="communication-preferences"
      >
        <span />
        <Flex
          justify="center"
          className={styles["communication-preferences__method"]}
        >
          <Typography weight={7} size={0}>
            {t("communicationPreferences.method.email")}
          </Typography>
        </Flex>
        <Flex
          justify="center"
          className={styles["communication-preferences__method"]}
        >
          <Typography weight={7} size={0}>
            {t("communicationPreferences.method.sms")}
          </Typography>
        </Flex>
        {preferences.map((input) => {
          return (
            <Fragment key={input.id}>
              <Flex direction="column">
                <Typography
                  weight={7}
                  size={0}
                  className={styles["communication-preferences__type"]}
                >
                  {input.label}
                </Typography>
                {["documentCenter", "advisorsCommunication"].includes(
                  input.id
                ) && (
                  <Typography
                    size={"00"}
                    className={styles["communication-preferences__note"]}
                  >
                    {t("communicationPreferences.note")}
                  </Typography>
                )}
              </Flex>
              {input.email ? (
                <Checkbox setting={input} method="email" />
              ) : (
                <span />
              )}
              {input.sms ? <Checkbox setting={input} method="sms" /> : <span />}
            </Fragment>
          );
        })}
      </Grid>
    </Box>
  );
};
