import {
  CoOwnershipFeesFrequency,
  FundPurposeEnum,
  GarageSizeEnum,
  GarageTypeEnum,
  HeatingTypeEnum,
  SewageTypeEnum,
  SpaceUnitsEnum,
  TargetPropertyConstructionTypeEnum,
  TargetPropertyPurposeEnum,
  TargetPropertyStyleEnum,
  TargetPropertyTenureEnum,
  TargetPropertyTypeEnum,
  WaterTypeEnum,
} from "./property.enum";

export const PURPOSE_OPTIONS = [
  {
    label: "target-property:purpose.ownerOccupied",
    value: TargetPropertyPurposeEnum.OWNER_OCCUPIED,
  },
  {
    label: "target-property:purpose.ownerOccupiedRental",
    value: TargetPropertyPurposeEnum.OWNER_OCCUPIED_AND_RENTAL,
  },
  {
    label: "target-property:purpose.rental",
    value: TargetPropertyPurposeEnum.RENTAL,
  },
  {
    label: "target-property:purpose.secondaryResidence",
    value: TargetPropertyPurposeEnum.SECONDARY_RESIDENCE,
  },
];

export const PROPERTY_TYPE = [
  {
    label: "target-property:type.detached",
    value: TargetPropertyTypeEnum.DETACHED,
  },
  {
    label: "target-property:type.semi-detached",
    value: TargetPropertyTypeEnum.SEMI_DETACHED,
  },
  {
    label: "target-property:type.duplex-detached",
    value: TargetPropertyTypeEnum.DUPLEX_DETACHED,
  },
  {
    label: "target-property:type.duplex-semi-detached",
    value: TargetPropertyTypeEnum.DUPLEX_SEMI_DETACHED,
  },
  {
    label: "target-property:type.row-housing",
    value: TargetPropertyTypeEnum.ROW_HOUSING,
  },
  {
    label: "target-property:type.apartment-low-rise",
    value: TargetPropertyTypeEnum.APARTMENT_LOW_RISE,
  },
  {
    label: "target-property:type.apartment-hi-rise",
    value: TargetPropertyTypeEnum.APARTMENT_HIGH_RISE,
  },
  {
    label: "target-property:type.mobile",
    value: TargetPropertyTypeEnum.MOBILE,
  },
  {
    label: "target-property:type.tri-plex-detached",
    value: TargetPropertyTypeEnum.TRI_PLEX_DETACHED,
  },
  {
    label: "target-property:type.tri-plex-semi-detached",
    value: TargetPropertyTypeEnum.TRI_PLEX_SEMI_DETACHED,
  },
  {
    label: "target-property:type.stacked",
    value: TargetPropertyTypeEnum.STACKED,
  },
  {
    label: "target-property:type.modular-home-detached",
    value: TargetPropertyTypeEnum.MODULAR_HOME_DETACHED,
  },
  {
    label: "target-property:type.modular-home-semi-detached",
    value: TargetPropertyTypeEnum.MODULAR_HOME_SEMI_DETACHED,
  },
  {
    label: "target-property:type.four-plex-detached",
    value: TargetPropertyTypeEnum.FOUR_PLEX_DETACHED,
  },
  {
    label: "target-property:type.four-plex-semi-detached",
    value: TargetPropertyTypeEnum.FOUR_PLEX_SEMI_DETACHED,
  },
  {
    label: "target-property:type.seasonal-home",
    value: TargetPropertyTypeEnum.SEASONAL_HOME,
  },
];

export const PROPERTY_STYLE = [
  {
    value: TargetPropertyStyleEnum.ONE_STOREY,
    label: "target-property:style.bungalow",
  },
  {
    value: TargetPropertyStyleEnum.BI_LEVEL,
    label: "target-property:style.biLevel",
  },
  {
    value: TargetPropertyStyleEnum.TWO_STORY,
    label: "target-property:style.twoStory",
  },
  {
    value: TargetPropertyStyleEnum.SPLIT_LEVEL,
    label: "target-property:style.split",
  },
  {
    value: TargetPropertyStyleEnum.STOREY_AND_A_HALF,
    label: "target-property:style.storeyHalf",
  },
  {
    value: TargetPropertyStyleEnum.THREE_STOREY,
    label: "target-property:style.threeStorey",
  },
  {
    value: TargetPropertyStyleEnum.OTHER,
    label: "target-property:style.other",
  },
];

export const CONSTRUCTION_TYPE = [
  {
    value: TargetPropertyConstructionTypeEnum.NEW_BUILDING,
    label: "target-property:construction-type.newBuild",
  },
  {
    value: TargetPropertyConstructionTypeEnum.EXISTING_BUILDING,
    label: "target-property:construction-type.existingBuild",
  },
];

export const PROPERTY_TENURE = [
  {
    value: TargetPropertyTenureEnum.FREE_HOLD,
    label: "target-property:tenure.freeHold",
  },
  {
    value: TargetPropertyTenureEnum.CONDO,
    label: "target-property:tenure.condo",
  },
  {
    value: TargetPropertyTenureEnum.LEASE_HOLD,
    label: "target-property:tenure.leaseHold",
  },
];

export const SPACE_UNITS = [
  {
    value: SpaceUnitsEnum.SQ_FEET,
    label: "target-property:space-units.squarefeet",
  },
  {
    value: SpaceUnitsEnum.SQ_METERS,
    label: "target-property:space-units.squaremeters",
  },
];

export const HEATING_TYPE = [
  {
    value: HeatingTypeEnum.ELECTRIC,
    label: "target-property:heating.electric",
  },
  {
    value: HeatingTypeEnum.FORCED_AIR_OIL_GAS_ELECTRIC,
    label: "target-property:heating.oil",
  },
  {
    value: HeatingTypeEnum.HOT_WATER,
    label: "target-property:heating.hotWater",
  },
  {
    value: HeatingTypeEnum.OTHER,
    label: "target-property:other",
  },
];

export const SEWAGE_TYPE = [
  {
    value: SewageTypeEnum.MUNICIPAL,
    label: "target-property:sewage.municipal",
  },
  { value: SewageTypeEnum.SEPTIC, label: "target-property:sewage.septic" },
  {
    value: SewageTypeEnum.HOLDING_TANK,
    label: "target-property:sewage.holdingTank",
  },
];

export const WATER_TYPE = [
  { value: WaterTypeEnum.MUNICIPAL, label: "target-property:water.municipal" },
  { value: WaterTypeEnum.WELL, label: "target-property:water.well" },
];

export const GARAGE_SIZETYPE = [
  {
    value: GarageSizeEnum.SINGLE,
    label: "target-property:garage.space.single",
  },
  {
    value: GarageSizeEnum.DOUBLE,
    label: "target-property:garage.space.double",
  },
  {
    value: GarageSizeEnum.TRIPLE,
    label: "target-property:garage.space.triple",
  },
];

export const GARAGE_TYPE = [
  {
    value: GarageTypeEnum.DETACHED,
    label: "target-property:garage.type.detached",
  },
  {
    value: GarageTypeEnum.ATTACHED,
    label: "target-property:garage.type.attached",
  },
];

export const CO_OWNERSHIP_FEES_FREQENCY = [
  {
    value: CoOwnershipFeesFrequency.MONTHLY,
    label: "form:frequency.monthly",
  },
  {
    value: CoOwnershipFeesFrequency.ANNUALLY,
    label: "form:frequency.annually",
  },
];

export const FUNDS_PURPOSE = [
  {
    value: FundPurposeEnum.HOME_IMPROVEMENT,
    label: "refinance-property:fundPurpose.homeImprovement",
  },
  {
    value: FundPurposeEnum.DEBT_CONSOLIDATION,
    label: "refinance-property:fundPurpose.debtConsolidation",
  },
  {
    value: FundPurposeEnum.INVESTMENT,
    label: "refinance-property:fundPurpose.investment",
  },
  {
    value: FundPurposeEnum.GIFT_TO_RELATIVE,
    label: "refinance-property:fundPurpose.giftToRelative",
  },
];
