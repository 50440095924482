import { z } from "zod";

export const applicantSectionsRouterSchema = z.object({
  applicationId: z.coerce.number().default(0),
  applicantId: z.coerce.number().default(0),
  incomeId: z.coerce.number().nullable().default(0),
  otherIncomeId: z.coerce.number().nullable().default(0),
  propertyId: z.coerce.number().nullable().default(0),
  addressId: z.coerce.number().nullable().default(0),
});
