import axios from "axios";

const DEFAULT_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

interface initParams {
  baseURL: string;
  withHeaders?: boolean;
}

export const init = ({ baseURL, withHeaders = true }: initParams) => {
  const api = axios.create({
    baseURL,
    headers: withHeaders ? DEFAULT_HEADERS : undefined,
    timeout: 25000,
  });

  let authToken: string | undefined;

  const setAuthToken = (token: string) => {
    if (!token) return;

    authToken = token;

    api.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
  };

  const getAuthToken = () => authToken;

  const setBackendBaseUrl = (url: string) => {
    api.defaults.baseURL = url;
  };

  const getHeaders = () => api.defaults.headers;

  const setCommonHeaders = (headers: Record<string, string>) => {
    api.defaults.headers.common = {
      ...api.defaults.headers.common,
      ...headers,
    };
  };

  return {
    api,
    setBackendBaseUrl,
    setAuthToken,
    getAuthToken,
    authToken,
    getHeaders,
    setCommonHeaders,
  };
};
