import { useAuth0 } from "@auth0/auth0-react";
import { Flex } from "@nestoca/ui";

import { AuthenticatedMobileHeader } from "./authenticated-mobile-navbar";
import { AuthenticatedHeader } from "./authenticated-navbar";
import { NavbarLogo } from "./navbar-logo";
import styles from "./navbar.module.scss";

export const Header = () => {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return (
      <Flex className={styles.header} direction="row" align="center">
        <NavbarLogo />
      </Flex>
    );
  }

  return (
    <>
      <AuthenticatedHeader />
      <AuthenticatedMobileHeader />
    </>
  );
};
