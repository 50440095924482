import * as z from "zod";

// *** NOTE: importing from @shared/utils is not working ***
// *** so we are importing with relative path the functions here ***
// It create a circular dependency
import { getAsNumber } from "../../utils/get-as-number";
import { isEmpty } from "../../utils/is-empty";
import { optionalNumber } from "../generic/optional-number.validation";

import { addressSchema } from "./address.validation";
import {
  AmountFrequencyEnum,
  EmploymentTypeEnum,
  IndustriesEnum,
  OperatingAsEnum,
  PensionTypeEnum,
  TenureEnum,
} from "./applicant.enum";

const MAX_EMPLOYED_YEARS = 99;
const MAX_EMPLOYER_NAME_LENGTH = 40;

export const maxEmployerNameLengthValid = (value: string) => {
  return value.length <= MAX_EMPLOYER_NAME_LENGTH;
};

export const amountFrequencySchema = z.object({
  amount: z.preprocess(
    (v): number | null => {
      const processed = isEmpty(v) ? null : getAsNumber(v);

      if (!processed) {
        return null;
      }

      return processed;
    },
    z
      .number({
        required_error: "form:error.required",
        invalid_type_error: "form:error.required",
        coerce: false,
      })
      .max(9_999_999)
  ),
  frequency: z.nativeEnum(AmountFrequencyEnum, {
    errorMap: () => {
      return { message: "form:error.required" };
    },
  }),
});

// This one is both optional or if 1 is filled, the other must be filled
// BE returns `bonus: {amount: 0, frequency: ""}`

export const amountFrequencyOptional = z
  .object({
    amount: z
      .preprocess(
        (v) => (isEmpty(v) ? null : getAsNumber(v)),
        z
          .number({
            required_error: "form:error.required",
            invalid_type_error: "type",
            coerce: false,
          })
          .max(9_999_999)
          .nullable()
      )
      .optional(),
    frequency: z.preprocess(
      (v) => (isEmpty(v) ? "" : v),
      z
        .enum([
          "WEEKLY",
          "BIWEEKLY",
          "MONTHLY",
          "SEMIANNUALLY",
          "SEMIMONTHLY",
          "ANNUALLY",
          "",
        ])
        .optional()
    ),
  })
  // make sure if one is filled, the other is filled
  .refine(
    (schema) => {
      // frequency is not empty and amount is null / empty field
      if (!isEmpty(schema.frequency) && isEmpty(schema.amount)) {
        return false;
      }

      return true;
    },
    {
      message: "form:error.required",
      path: ["amount"],
    }
  )
  .refine(
    (schema) => {
      // we want to support negative values for the amount and just check it is not 0
      return schema.amount && schema.amount !== 0 ? !!schema.frequency : true;
    },
    {
      message: "form:error.required",
      path: ["frequency"],
    }
  )
  .nullable();

export const phoneNumberRegex = /^\+1-?(\d{3})-?\d{3}-?\d{4}/;

export const yearsMonthsEmployedSchema = z
  .object({
    employedYears: optionalNumber.refine(
      (data) => {
        if (data) {
          return data <= MAX_EMPLOYED_YEARS;
        }
        return true;
      },
      {
        message: "form:error.bigNumberOfYears",
      }
    ),
    employedMonths: optionalNumber,
  })
  .refine(
    (schema) => {
      return !(
        (!schema.employedYears || schema.employedYears === 0) &&
        (!schema.employedMonths || schema.employedMonths === 0)
      );
    },
    {
      message: "form:inIndustry.error",
      path: ["employedYears"],
    }
  )
  .refine(
    (schema) => {
      return !(
        (!schema.employedYears || schema.employedYears === 0) &&
        (!schema.employedMonths || schema.employedMonths === 0)
      );
    },
    {
      message: "form:inIndustry.error",
      path: ["employedMonths"],
    }
  );

export const tellUsMoreSchema = z
  .object({
    jobTitle: z.string().trim().min(1, { message: "form:error.required" }),
    industry: z.nativeEnum(IndustriesEnum, {
      required_error: "form:error.required",
    }),
  })
  .and(yearsMonthsEmployedSchema);

export const incomeNoneSchema = z
  .object({
    isCurrent: z.boolean({
      required_error: "form:error.required",
    }),
  })
  .and(yearsMonthsEmployedSchema);

const generalSchema = z.object({
  isCurrent: z.boolean({
    required_error: "form:error.required",
  }),
  employmentType: z.nativeEnum(EmploymentTypeEnum, {
    required_error: "form:error.required",
  }),
  pensionType: z.nativeEnum(PensionTypeEnum, {
    required_error: "form:error.required",
    invalid_type_error: "form:error.required",
  }),
  tenure: z.nativeEnum(TenureEnum, {
    required_error: "form:error.required",
  }),
  salary: z
    .object({
      base: amountFrequencySchema,
      bonus: amountFrequencyOptional,
      commission: amountFrequencyOptional,
      overtime: amountFrequencyOptional,
    })
    .partial(),
  jobTitle: z.string().trim().min(1, { message: "form:error.required" }),
  industry: z.nativeEnum(IndustriesEnum),
  employedMonths: optionalNumber,
  employedYears: optionalNumber,
  employer: z
    .object({
      name: z
        .string({ required_error: "form:error.required" })
        .trim()
        .nullable(),
      phone: z
        .string({ required_error: "form:error.required" })
        .trim()
        .min(1, { message: "form:error.required" })
        .optional()
        .nullable(),
      address: z.optional(addressSchema).nullable(),
    })
    .nullish(),
  selfEmployed: z.object({
    operatingAs: z.nativeEnum(OperatingAsEnum, {
      required_error: "form:error.required",
    }),
    grossRevenue: amountFrequencySchema,
    grossRevenuePreviousYear: amountFrequencySchema,
  }),
  salaryPreviousYear: z.object({
    base: amountFrequencySchema,
    bonus: z.optional(amountFrequencyOptional).nullable(),
    commission: z.optional(amountFrequencyOptional).nullable(),
    overtime: z.optional(amountFrequencyOptional).nullable(),
  }),
  hasGuaranteedHours: z.boolean({
    required_error: "form:error.required",
    invalid_type_error: "form:error.required",
  }),
});

export const pensionIncomeSchema = z
  .object({
    pensionType: z
      .nativeEnum(PensionTypeEnum, {
        required_error: "form:error.required",
        invalid_type_error: "form:error.required",
      })
      .nullable()
      .optional(),
    employer: z
      .object({
        name: z.string().trim().nullable().optional(),
      })
      .optional(),
    salary: z.object({
      base: amountFrequencySchema,
    }),
  })
  .superRefine((values, ctx) => {
    if (["EMPLOYER", "OTHER"].includes(values.pensionType as string)) {
      const isOk = z
        .string({
          required_error: "form:error.required",
          invalid_type_error: "form:error.required",
        })
        .trim()
        .min(1, { message: "form:error.required" })
        .refine(maxEmployerNameLengthValid, {
          message: "form:error.tooLong",
        })
        .safeParse(values.employer?.name);

      if (!isOk.success) {
        isOk.error.issues.forEach((issue) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: issue.message,
            path: ["employer.name", ...issue.path],
          });
        });
      }
    }
  })
  .and(yearsMonthsEmployedSchema);

export const applicantIncomeExtendedSchema = z
  .discriminatedUnion("incomeType", [
    z.object({
      incomeType: z.enum(["SALARIED"]),
      ...generalSchema.omit({
        selfEmployed: true,
        salaryPreviousYear: true,
        hasGuaranteedHours: true,
        pensionType: true,
      }).shape,
    }),
    z.object({
      incomeType: z.enum(["HOURLY", "HOURLY_COMMISSIONS"]),
      ...generalSchema.omit({
        selfEmployed: true,
        pensionType: true,
      }).shape,
    }),
    z.object({
      incomeType: z.enum(["COMMISSIONS"]),
      ...generalSchema.omit({
        selfEmployed: true,
        hasGuaranteedHours: true,
        pensionType: true,
      }).shape,
    }),
    z.object({
      incomeType: z.enum(["SELF_EMPLOYED"]),
      ...generalSchema.omit({
        employmentType: true,
        tenure: true,
        hasGuaranteedHours: true,
        pensionType: true,
      }).shape,
    }),
    z.object({
      incomeType: z.enum(["PENSION"]),
      ...generalSchema.pick({
        isCurrent: true,
        pensionType: true,
        salary: true,
        employer: true,
        employedMonths: true,
        employedYears: true,
      }).shape,
    }),
    z.object({
      incomeType: z.enum(["NONE"]),
      ...generalSchema.pick({
        isCurrent: true,
        employedMonths: true,
        employedYears: true,
      }).shape,
    }),
  ])
  .superRefine((values, ctx) => {
    if (
      [
        "SALARIED",
        "HOURLY",
        "HOURLY_COMMISSIONS",
        "COMMISSIONS",
        "SELF_EMPLOYED",
      ].includes(values.incomeType) ||
      (values.incomeType === "PENSION" &&
        ["EMPLOYER", "OTHER"].includes(values.pensionType))
    ) {
      // test employer name
      const isOk = z
        .string()
        .trim()
        .min(1, { message: "form:error.required" })
        .refine(maxEmployerNameLengthValid, {
          message: "form:error.tooLong",
        })
        .safeParse("employer" in values && values.employer?.name);

      if (!isOk.success) {
        isOk.error.issues.forEach((issue) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: issue.message,
            path: ["employer.name", ...issue.path],
          });
        });
      }
    }
  })
  .superRefine((values, ctx) => {
    if (
      [
        "SALARIED",
        "HOURLY",
        "HOURLY_COMMISSIONS",
        "COMMISSIONS",
        "SELF_EMPLOYED",
      ].includes(values.incomeType)
    ) {
      // test employer phone number
      const isOk = z
        .string({
          required_error: "form:error.required",
        })
        .trim()
        .regex(phoneNumberRegex, "form:error.invalid")
        .safeParse("employer" in values && values.employer?.phone);

      if (!isOk.success) {
        isOk.error.issues.forEach((issue) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: issue.message,
            path: ["employer.phone", ...issue.path],
          });
        });
      }
    }
  });

const incomeTypeSchema = z.enum([
  "SALARIED",
  "HOURLY",
  "HOURLY_COMMISSIONS",
  "COMMISSIONS",
  "SELF_EMPLOYED",
  "PENSION",
  "NONE",
]);

const typeSchema = generalSchema.extend({
  incomeType: incomeTypeSchema,
});

export type ApplicantIncomeFormData = z.infer<typeof typeSchema>;

export type TypeIncomeTypes = z.infer<typeof incomeTypeSchema>;
