import { Flex, FlexProps, ariaAttr } from "@nestoca/ui";
import clsx from "clsx";

import styles from "./reachable.module.scss";
import { useReachableItem } from "./use-reachable-item";

export type ReachableItemContentProps = FlexProps;

export const ReachableItemContent = ({
  className,
  children,
  ...rest
}: ReachableItemContentProps) => {
  const { contentId, headerId, isUnreached, isClosed } = useReachableItem();

  return (
    <Flex
      id={contentId}
      className={clsx(
        styles.reachable__item__content,
        {
          [styles["reachable__item__content--unreached"]]: isUnreached,
        },
        className
      )}
      direction="column"
      aria-hidden={ariaAttr(isClosed)}
      aria-describedby={headerId}
      hidden={ariaAttr(isClosed)}
      role="region"
      tabIndex={-1}
      {...rest}
    >
      {children}
    </Flex>
  );
};

ReachableItemContent.id = "ReachableItemContent";
