import { DidomiSDK } from "@didomi/react";
import { useRouter } from "next/router";

import type { IDidomiSDKProps } from "@didomi/react";

type DidomiProviderProps = IDidomiSDKProps;

const LOCALE_FALLBACK = "en";
const LOCALES_FALLBACK = ["en", "fr"];

/**
 * This provider is a wrapper to define the allowed language of the cookie consent banner
 * to force the language of the banner to match the language of my website
 *
 * Props reference
 * @ref: https://github.com/didomi/react#configuration-options-props
 *
 * Hack to force language
 * @ref: https://support.didomi.io/how-to-force-the-language-of-my-banner-with-the-language-of-my-website
 */
export const DidomiProvider = (props: DidomiProviderProps) => {
  const { locale, defaultLocale, locales } = useRouter();

  const _defaultLocale = defaultLocale ?? LOCALE_FALLBACK;
  const _locale = locale ?? _defaultLocale;
  const _locales = locales ?? LOCALES_FALLBACK;
  const isLocaleSupported = _locales.includes(_locale);

  // Forcing language hack see reference
  // otherwise if the client browser is another language than the one of the website
  // the banner will be displayed in is first browser language
  // first browser lang is `Italian` Didomi will show `Italian` banner instead of our supported languages
  const didomiConfig = {
    languages: {
      enabled: isLocaleSupported ? [_locale] : _locales,
      default: isLocaleSupported ? _locale : LOCALE_FALLBACK,
    },
  };

  return (
    <DidomiSDK
      config={didomiConfig}
      gdprAppliesGlobally={false}
      sdkPath="https://sdk.privacy-center.org/"
      {...props}
    />
  );
};
