import { useMemo } from "react";

import { Button, Flex, Tooltip, Typography } from "@nestoca/ui";
import { useGetApplicationsByApplicantId } from "@shared/api/hooks/applications";
import { ApplicationState } from "@shared/constants";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

import styles from "./burger-menu.module.scss";

export const DeleteAccount = ({
  setMenuOpen,
}: {
  setMenuOpen: (menuOpen: boolean) => void;
}) => {
  const { data: applications } = useGetApplicationsByApplicantId();
  const router = useRouter();
  const { t } = useTranslation("common");

  const isDeleteAccountDisabled = useMemo(() => {
    const appInProgress = applications?.find((application) => {
      return ![ApplicationState.Created, ApplicationState.Closed].includes(
        application.applicationState
      );
    });
    return !!appInProgress;
  }, [applications]);

  return (
    <>
      <Button
        variant="primary"
        disabled={isDeleteAccountDisabled}
        onClick={() => {
          router.push(`${window.location.origin}/delete-account`).then(() => {
            setMenuOpen(false);
          });
        }}
        data-tooltip-id={"delete-account"}
        className={styles["delete-button"]}
        data-dd-action-name="delete account"
      >
        {t("accountDeletion.button")}
      </Button>
      {isDeleteAccountDisabled && (
        <Tooltip
          id={"delete-account"}
          className={styles["delete-button__tooltip"]}
        >
          <Flex direction="column">
            <Typography size={0} weight={7}>
              {t("accountDeletion.feedback.applicationInProgress")}
            </Typography>
            <Typography
              weight={4}
              className={styles["delete-button__tooltip-text"]}
            >
              {t("accountDeletion.feedback.applicationInProgress1")}
            </Typography>
          </Flex>
        </Tooltip>
      )}
    </>
  );
};
