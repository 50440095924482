import type { Cache, CacheOptions, FlushFn, InsertFn } from "./types";

const isBrowser = typeof document !== "undefined";

const caches = new Map<CacheOptions["id"], Cache>();

function createLinkElement(options: CacheOptions): HTMLLinkElement {
  const tag = document.createElement("link");
  tag.setAttribute("data-n-stylesheet", options.id);
  tag.setAttribute("rel", "stylesheet");
  if (options.nonce !== undefined) {
    tag.setAttribute("nonce", options.nonce);
  }
  tag.appendChild(document.createTextNode(""));
  return tag;
}

export const createCache = (options: CacheOptions) => {
  const { id, nonce } = options;

  let cache = caches.get(id);

  if (cache) {
    return cache;
  }

  let tags: HTMLLinkElement[] = [];

  let insert: InsertFn;
  let flush: FlushFn;
  let hydrate: FlushFn;

  if (isBrowser) {
    insert = (href) => {
      const tag =
        document.querySelector<HTMLLinkElement>(
          `link[data-n-stylesheet="${options.id}"]`
        ) ?? createLinkElement(options);
      tag.href = href;
      tags.push(tag);
    };

    flush = () => {
      tags.forEach((tag) => {
        tag.remove();
      });

      // empty tags array
      tags = [];

      caches.has(id) && caches.delete(id);
    };

    hydrate = () => {
      tags.forEach((tag) => {
        document.head.appendChild(tag);
      });
      return void 0;
    };
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    insert = (href) => {
      //   do nothing on server
    };

    flush = () => {
      tags = [];

      caches.has(id) && caches.delete(id);
      cache = undefined;
    };

    hydrate = () => {
      return;
    };
  }

  const elements = () => tags;

  cache = {
    id,
    nonce,
    insert,
    flush,
    hydrate,
    elements,
  };

  caches.set(id, cache);

  return cache;
};
