import {
  Flex,
  Typography,
  ButtonIcon,
  Banner,
  useBreakpointValue,
} from "@nestoca/ui";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { BsCheckCircleFill } from "react-icons/bs";
import { IoCloseOutline } from "react-icons/io5";

import styles from "./remove-co-applicant-modal.module.scss";
import {
  NotificationStatus,
  useNotification,
} from "./remove-co-applicant-provider";

export const RemoveCoApplicantNotification = ({
  status,
  applicantId,
}: {
  status: NotificationStatus;
  applicantId: number;
}) => {
  const { t } = useTranslation("applications");
  const isMobile = useBreakpointValue({ default: true, md: false });
  const { clearNotification } = useNotification();

  if (status === "error") {
    return (
      <AnimatePresence>
        <motion.div
          initial={{ x: 200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: 200, opacity: 0 }}
        >
          <Banner variant="error">
            {t("dataUpdatedFailure")}
            <ButtonIcon
              variant="ghost"
              aria-label="close remove-coapplicant error"
              icon={<IoCloseOutline size="16" />}
              isRound={true}
              className={styles["close-toast-icon"]}
              onClick={() => clearNotification(applicantId)}
            />
          </Banner>
        </motion.div>
      </AnimatePresence>
    );
  }

  return (
    <AnimatePresence>
      <motion.div
        initial={{ x: 200, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 200, opacity: 0 }}
      >
        <Flex
          align="center"
          gap={isMobile ? 4 : 3}
          className={clsx(
            styles["remove-co-applicant-modal__applicant"],
            styles["remove-co-applicant-modal__applicant--success"]
          )}
        >
          <BsCheckCircleFill
            color="var(--color-status-success-foreground-saturated)"
            size="32"
          />
          <Typography
            weight={7}
            size={isMobile ? "00" : 0}
            className={styles["remove-co-applicant-modal__applicant-text"]}
          >
            {t("deleteApplicant.success")}
          </Typography>
          <ButtonIcon
            variant="ghost"
            aria-label="close remove co-applicant success"
            icon={<IoCloseOutline size="16" />}
            isRound={true}
            className={styles["close-toast-icon"]}
            onClick={() => clearNotification(applicantId)}
            size="medium"
          />
        </Flex>
      </motion.div>
    </AnimatePresence>
  );
};
