import { Button, Typography, Flex } from "@nestoca/ui";
import { useTranslation } from "react-i18next";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";

import {
  ApplicationSelectorOption,
  applicationSelectorOptionType,
} from "../application-selector/application-selector-option";

import styles from "./change-application-modal-body.module.scss";

type ChangeApplicationModalBodyPropTypes = {
  changeApplicationView: boolean;
  setChangeApplicationView: (isModalOpen: boolean) => void;
  applicationId: number;
  getApplicationOptions: applicationSelectorOptionType[];
  onClickItem?: () => void;
};

export const ChangeApplicationModalBody = ({
  changeApplicationView,
  setChangeApplicationView,
  applicationId,
  getApplicationOptions,
  onClickItem,
}: ChangeApplicationModalBodyPropTypes) => {
  const { t } = useTranslation("applications");

  return (
    <>
      <Flex gap={1} direction="column" align="start">
        <Button
          size="large"
          variant="ghost"
          onClick={() => setChangeApplicationView(!changeApplicationView)}
          className={styles["change-application__back-button"]}
          data-dd-action-name="back"
        >
          <BsFillArrowLeftCircleFill size={24} />
          <Typography size={0} weight={7}>
            {t("back", { ns: "form" })}
          </Typography>
        </Button>
        <Typography
          size={0}
          weight={7}
          className={styles["change-application__title"]}
        >
          {t("change")}
        </Typography>
      </Flex>
      <Flex direction="column" gap={3}>
        {getApplicationOptions?.map((option) => {
          return (
            <ApplicationSelectorOption
              key={option.id}
              id={option.id}
              address={option.address}
              type={option.type}
              index={option.index}
              stage={option.stage}
              applicantId={option.applicantId}
              applicationId={applicationId}
              onClick={onClickItem}
            />
          );
        })}
      </Flex>
    </>
  );
};
