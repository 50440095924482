import {
  ApplicationState,
  PROGRESS_STAGES_MAP,
  PROGRESS_STAGES_STATUSES,
  CIRCLE_PROGRESS_TRACKER_PHASES,
  CIRCLE_PROGRESS_PHASES_MAP,
  APP_CIRCLE_PROGRESS_STATUSES_MAP,
  CIRCLE_PROGRESS_STATUSES,
  APP_CIRCLE_PROGRESS_STATUSES_GROUPS,
  CIRCLE_PROGRESS_STAGES_GROUPS,
  PROGRESS_BAR_STATUS_GROUPS,
} from "@shared/constants";

import type { ProgressIconsType, ProgressItem } from "./progress-tracker.types";

const filteredStatusGroups = Object.fromEntries(
  Object.entries(PROGRESS_BAR_STATUS_GROUPS).filter(([key]) => key !== "CLOSED")
);

const closedStatusGroup = Object.fromEntries(
  Object.entries(PROGRESS_BAR_STATUS_GROUPS).filter(([key]) => key === "CLOSED")
);

export const getCurrentProgressArrayForExternalBroker = (
  applicationState: ApplicationState,
  icons: ProgressIconsType
): ProgressItem[] => {
  const statusGroups = [
    ApplicationState.Closed,
    ApplicationState.Expired,
  ].includes(applicationState)
    ? closedStatusGroup
    : filteredStatusGroups;

  return getProgressArray(applicationState, icons, statusGroups);
};

export const getCurrentProgressArray = (
  applicationState: ApplicationState,
  icons: ProgressIconsType
): ProgressItem[] => {
  return getProgressArray(applicationState, icons, PROGRESS_STAGES_MAP);
};

export const getProgressArray = (
  applicationState: ApplicationState,
  icons: ProgressIconsType,
  statusMap: Record<string, ApplicationState[]>
) => {
  let hasActive = false;

  return Object.keys(statusMap).map((state) => {
    let status: PROGRESS_STAGES_STATUSES;

    if (
      [ApplicationState.Closed, ApplicationState.Expired].includes(
        applicationState
      )
    ) {
      return {
        name: state,
        // if state group is closed, then only have one status, so it should be active
        status:
          state === "CLOSED"
            ? PROGRESS_STAGES_STATUSES.ACTIVE
            : PROGRESS_STAGES_STATUSES.PENDING,
        icon: icons[state],
      };
    }

    if (statusMap[state].includes(applicationState)) {
      status =
        applicationState === ApplicationState.Funded
          ? PROGRESS_STAGES_STATUSES.COMPLETE
          : PROGRESS_STAGES_STATUSES.ACTIVE;
      hasActive = true;
    } else {
      status = hasActive
        ? PROGRESS_STAGES_STATUSES.PENDING
        : PROGRESS_STAGES_STATUSES.COMPLETE;
    }

    return {
      name: state,
      status,
      icon: icons[state],
    };
  });
};

export const getCurrentProgressStage = (applicationState: ApplicationState) => {
  const stage = Object.keys(CIRCLE_PROGRESS_PHASES_MAP).find((stage) =>
    CIRCLE_PROGRESS_PHASES_MAP[
      stage as CIRCLE_PROGRESS_TRACKER_PHASES
    ].includes(applicationState)
  );
  return stage;
};

export const getCircleTrackerStagesList = (
  applicationState: ApplicationState,
  icons: ProgressIconsType
) => {
  let hasActive = false;

  return Object.entries(APP_CIRCLE_PROGRESS_STATUSES_GROUPS).map(
    ([stateName, appStates]) => {
      let status: PROGRESS_STAGES_STATUSES;

      if (appStates.includes(applicationState)) {
        status =
          applicationState === "FUNDED" || applicationState === "NOTARY_ALERTED"
            ? PROGRESS_STAGES_STATUSES.COMPLETE
            : PROGRESS_STAGES_STATUSES.ACTIVE;

        hasActive = true;
      } else {
        status = hasActive
          ? PROGRESS_STAGES_STATUSES.PENDING
          : PROGRESS_STAGES_STATUSES.COMPLETE;

        if (applicationState === "NOTARY_ALERTED" && hasActive) {
          status = PROGRESS_STAGES_STATUSES.ACTIVE;
        }
      }

      if (applicationState === ApplicationState.Created) {
        status = PROGRESS_STAGES_STATUSES.PENDING;
      }
      return {
        name: APP_CIRCLE_PROGRESS_STATUSES_MAP[
          stateName as CIRCLE_PROGRESS_STATUSES
        ],
        stateName: stateName,
        status: status,
        icon: icons[status],
      };
    }
  );
};

export const getStagesListByPhase = (
  applicationState: ApplicationState,
  icons: ProgressIconsType,
  phase:
    | CIRCLE_PROGRESS_TRACKER_PHASES.FUNDING
    | CIRCLE_PROGRESS_TRACKER_PHASES.LENDER_APPROVAL
) => {
  const stagesList = getCircleTrackerStagesList(applicationState, icons);

  return stagesList.filter((state) =>
    CIRCLE_PROGRESS_STAGES_GROUPS[phase].includes(state.stateName)
  );
};

export const getTotalCompletePercent = (
  completePercent: number,
  activeStates: boolean
) => {
  if (completePercent) {
    return completePercent;
  } else if (activeStates) {
    return 0;
  } else {
    return;
  }
};
