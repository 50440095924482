import { ApplicationState } from "@shared/constants";

export const getApplicationStateI18nKey = (
  applicationState: ApplicationState
) => {
  switch (applicationState) {
    case ApplicationState.Locked:
      return "state.locked";
    case ApplicationState.Submitted:
      return "state.submitted";
    case ApplicationState.Created:
      return "state.in_progress";
    case ApplicationState.Funded:
      return "state.funded";
    case ApplicationState.Closed:
      return "state.closed";
    case ApplicationState.Expired:
      return "state.expired";
    default:
      return "";
  }
};
