import { z } from "zod";

// *** NOTE: importing from @shared/utils is not working ***
// *** so we are importing with relative path the functions here ***
// It create a circular dependency
import { isEmpty } from "../../utils/is-empty";

export const optionalNumber = z
  .preprocess(
    (v) => (isEmpty(v) ? null : (v as string)),
    z
      .number({
        required_error: "form:error.required",
        invalid_type_error: "type",
        coerce: false,
      })
      .nullable()
  )
  .nullable();
