import { useMemo } from "react";

import { Box, Skeleton } from "@nestoca/ui";
import { useGetApplicationsByApplicantId } from "@shared/api/hooks/applications";
import {
  Application,
  TargetProperty,
  TRANSACTION_TYPE_LABEL,
} from "@shared/constants";
import { DatadogMask } from "@shared/datadog";
import {
  formatAddress,
  KeepI18ExprHappy,
  useTypedRouteArgs,
} from "@shared/utils";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import styles from "../application-selector/application-selector.module.scss";

const schema = z.object({
  applicationId: z.coerce.number().optional(),
});

export const Address = () => {
  const { t } = useTranslation("applications");

  const { data: applications, isPending } = useGetApplicationsByApplicantId();
  const { applicationId } = useTypedRouteArgs(schema);

  const selectedApplication = applications?.find((app) => {
    return app.id === applicationId;
  }) as Application & { index: number };

  const formattedAddress = useMemo(
    () => (property: TargetProperty | undefined) => {
      return formatAddress(property?.address, "street");
    },
    []
  );

  const selectedAddress = formattedAddress(selectedApplication?.property);
  const applicationType = isPending
    ? t("application.noAddress")
    : t(TRANSACTION_TYPE_LABEL[selectedApplication?.type] as KeepI18ExprHappy);

  return (
    <DatadogMask
      as={Box}
      className={styles["dashboard-address"]}
      id="address"
      data-dd-action-name="selected address"
    >
      {isPending && <Skeleton width={150} height={24} />}
      {!isPending && !!selectedAddress
        ? selectedAddress
        : `${applicationType} ${selectedApplication?.index ?? ""}`}
    </DatadogMask>
  );
};
