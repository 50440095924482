import { PropsWithChildren } from "react";

import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";

export const ContentTransition = ({ children }: PropsWithChildren) => {
  const { asPath } = useRouter();

  return (
    <AnimatePresence mode="wait" initial={false}>
      <motion.div
        key={asPath}
        initial={{ y: -200, opacity: 0, scaleY: 0.75 }}
        animate={{ y: 0, opacity: 1, scaleY: 1 }}
        exit={{ y: -200, opacity: 0, scaleY: 0.75 }}
        transition={{ duration: 0.5 }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};
