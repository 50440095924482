import {
  Select,
  FormElement,
  ErrorMessage,
  CurrencyInput,
  Grid,
  Input,
  machineFormatCurrency,
} from "@nestoca/ui";
import { isEmpty } from "@shared/utils";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useTranslatedOptions } from "../../hooks";

import styles from "./amount-frequency.module.scss";

import type { UseFormReturn, FieldError } from "react-hook-form";

interface OptionItem {
  value: string;
  label: string;
  [key: string]: unknown;
}

interface AmountFrequencyProps {
  // use any here because this component is used in a few places with different types
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: UseFormReturn<any>["control"];
  amount: {
    name: string;
    placeholder: string;
    error?: FieldError;
    e2ePrefix?: string;
  };
  frequency: {
    name: string;
    placeholder: string;
    error?: FieldError;
    options?: OptionItem[];
    isClearable?: boolean;
    e2ePrefix?: string;
  };
  isCurrency?: boolean;
}

export const AmountFrequency = ({
  control,
  isCurrency = true,
  amount: {
    name: amountName,
    placeholder: amountPlaceholder,
    error: amountError,
    e2ePrefix: amountE2ePrefix,
  },
  frequency: {
    name: frequencyName,
    placeholder: frequencyPlaceholder,
    error: frequencyError,
    options,
    isClearable = false,
    e2ePrefix: frequencyE2ePrefix,
  },
}: AmountFrequencyProps) => {
  const { t } = useTranslation();
  const frequencyOptions = useTranslatedOptions(options || []);
  const e2ePrefixAmount = amountE2ePrefix
    ? `${amountE2ePrefix}-amount`
    : "amount";
  const e2ePrefixFrequency = frequencyE2ePrefix
    ? `${frequencyE2ePrefix}-frequency`
    : "frequency";

  return (
    <>
      <Grid className={styles["amount-frequency"]}>
        <FormElement isInvalid={!!amountError}>
          <Controller
            control={control}
            name={amountName}
            render={({ field }) => {
              return isCurrency ? (
                <CurrencyInput
                  placeholder={t(`${amountPlaceholder}`) as string}
                  e2ePrefix={e2ePrefixAmount}
                  {...field}
                />
              ) : (
                <Input
                  placeholder={t(`${amountPlaceholder}`) as string}
                  step="2"
                  {...field}
                  e2ePrefix={e2ePrefixAmount}
                  value={
                    !isEmpty(field.value)
                      ? machineFormatCurrency(String(field.value))
                      : ""
                  }
                />
              );
            }}
          />
          {!!amountError && (
            <ErrorMessage e2ePrefix={e2ePrefixAmount}>
              {t(`${amountError.message}`)}
            </ErrorMessage>
          )}
        </FormElement>
        <FormElement isInvalid={!!frequencyError}>
          <Controller
            control={control}
            name={frequencyName}
            render={({ field }) => (
              <Select
                placeholder={t(`${frequencyPlaceholder}`) as string}
                options={frequencyOptions}
                isInvalid={!!frequencyError}
                isClearable={isClearable}
                {...field}
                value={field.value || ""}
                onChange={async (value) => {
                  field.onChange(value);
                }}
                e2ePrefix={e2ePrefixFrequency}
              />
            )}
          />
          {!!frequencyError && (
            <ErrorMessage e2ePrefix={e2ePrefixFrequency}>
              {t(`${frequencyError.message}`)}
            </ErrorMessage>
          )}
        </FormElement>
      </Grid>
    </>
  );
};
