import { Button, Flex, Typography } from "@nestoca/ui";
import { CIRCLE_PROGRESS_TRACKER_PHASES } from "@shared/constants";
import { Trans, useTranslation } from "react-i18next";

import { ActiveModal } from "./circle-progress-tracker";
import { PhaseModal } from "./phase-modal";
import styles from "./tracker-modal.module.scss";

type ModalType = {
  applicationId: number;
  isModalOpen: boolean;
  setActiveModal: (activeModal: ActiveModal) => void;
  phaseNumber: number;
  phase: CIRCLE_PROGRESS_TRACKER_PHASES;
  completePercent: number | [number, number];
  totalCompletePercent: number;
};

export const GettingToKnowModal = ({
  applicationId,
  isModalOpen,
  setActiveModal,
  phaseNumber,
  phase,
  completePercent,
  totalCompletePercent,
}: ModalType) => {
  const { t } = useTranslation("docs");

  const lenderApprovalButton = (
    <Button
      onClick={() => {
        setActiveModal("lender_approval");
      }}
      variant="ghost"
      className={styles["lender-button"]}
      key={0}
      e2ePrefix="lender-approval"
    >
      {t("progress.stage.lender_approval.name")}
    </Button>
  );

  const description = (
    <Flex direction="column" gap={5}>
      <Typography className={styles["modal__text"]}>
        {t("progress.stage.getting_to_know.description1")}
      </Typography>
      <Typography className={styles["modal__text"]}>
        {t("progress.stage.getting_to_know.description2")}
      </Typography>
      <Typography className={styles["modal__text"]}>
        <Trans
          t={t}
          i18nKey="progress.stage.getting_to_know.description3"
          components={[lenderApprovalButton]}
        />
      </Typography>
    </Flex>
  );

  return (
    <PhaseModal
      applicationId={applicationId}
      isModalOpen={isModalOpen}
      setActiveModal={setActiveModal}
      phaseNumber={phaseNumber}
      phase={phase}
      completePercent={completePercent}
      totalCompletePercent={totalCompletePercent}
    >
      {description}
    </PhaseModal>
  );
};
