import { useTenant } from "@nestoca/multi-tenant";
import { Divider, HStack, Typography } from "@nestoca/ui";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { BsTelephoneForward, BsEnvelope } from "react-icons/bs";
import { IoOpenOutline } from "react-icons/io5";

import styles from "../burger-menu/burger-menu.module.scss";

export const SupportLinks = () => {
  const tenant = useTenant();
  const { locale } = useRouter();
  const { t } = useTranslation("common");

  // *** NOTE: This is a temporary fix for the tenant slug. ***
  // *** This should be removed once the tenant Settings is created. ***
  // *** https://nestoca.atlassian.net/browse/OG-6951 ***
  const isNesto = tenant?.slug === "nesto";
  
  if (!tenant) {
    return null;
  }

  return (
    <>
      <Divider />
      <Link
        href={
          locale === "en"
            ? tenant.privacyPolicyURL
            : tenant.privacyPolicyFRURL || ""
        }
        target="_blank"
        className={styles["burger-menu__link-wrapper"]}
      >
        <HStack className={styles["burger-menu__link"]} justify="between">
          <Typography size={0} weight={5}>
            {t("privacyPolicy")}
          </Typography>
          <IoOpenOutline />
        </HStack>
      </Link>
      {isNesto && (
        <>
          <Divider />
          <Link
            href={`tel:${t("userSettings.support.phone")}`}
            className={styles["burger-menu__link"]}
          >
            <Typography size={0} weight={7}>
              {t("userSettings.support.title")}
            </Typography>
            <HStack
              className={styles["burger-menu__link"]}
              justify="between"
              align="center"
            >
              <Typography size={0} weight={5}>
                {t("userSettings.support.phone")}
              </Typography>
              <BsTelephoneForward />
            </HStack>
          </Link>
          <Divider />
          <Link href={`mailto:${t("userSettings.support.email")}`}>
            <HStack className={styles["burger-menu__link"]} justify="between">
              <Typography size={0} weight={5}>
                {t("userSettings.support.email")}
              </Typography>
              <BsEnvelope />
            </HStack>
          </Link>
        </>
      )}
    </>
  );
};
