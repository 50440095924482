export enum ApplicationState {
  // created states
  Created = "CREATED",
  Submitted = "SUBMITTED",
  InProgress = "IN_PROGRESS",
  // submitted state
  UnderRevision = "UNDER_REVISION",
  Reviewed = "REVIEWED",
  NotesSubmitted = "NOTES_SUBMITTED",
  LenderSubmitted = "LENDER_SUBMITTED",
  LenderApproved = "LENDER_APPROVED",
  PendingCommitmentSignature = "PENDING_COMMITMENT_SIGNATURE",
  PendingConditions = "PENDING_CONDITIONS",
  // 👇 Approved states; no files can be deleted starting from here
  Complete = "COMPLETE",
  NotaryAlerted = "NOTARY_ALERTED",
  // funded state
  Funded = "FUNDED",
  Closed = "CLOSED",
  Expired = "EXPIRED",
  Locked = "LOCKED",

  Deleted = "DELETED",
}

export const ACTIVE_APPLICATION_STATE = [
  ApplicationState.Created,
  ApplicationState.Submitted,
  ApplicationState.UnderRevision,
  ApplicationState.Reviewed,
  ApplicationState.NotesSubmitted,
  ApplicationState.LenderSubmitted,
  ApplicationState.LenderApproved,
  ApplicationState.PendingCommitmentSignature,
  ApplicationState.PendingConditions,
  ApplicationState.Complete,
  ApplicationState.NotaryAlerted,
];

export type ActiveApplicationStateType =
  (typeof ACTIVE_APPLICATION_STATE)[number];

export const FUNDED_APPLICATION_STATE = [ApplicationState.Funded];

export const CLOSED_APPLICATION_STATE = [
  ApplicationState.Closed,
  ApplicationState.Expired,
];

export const RENEWAL_APPLICATION_STATUSES = [
  "RENEWAL_CREATED",
  "RENEWAL_AGREEMENT_SENT",
  "RENEWAL_ACCEPTED",
  "RENEWAL_COMPLETED",
  "RENEWAL_CLOSED",
] as const;

export const STANDARD_APPLICATION_STATUSES = [
  "REFERRAL_COMPLETED",
  "APPLICATION_IN_PROGRESS",
  "CONTACT_MADE",
  "SUBMITTED_TO_UNDERWRITING",
  "CONDITIONAL_APPROVAL",
  "FINAL_APPROVAL_CONFIRMED",
  "FUNDED",
  "CLOSED",
  "DELETED",
] as const;

export const HUB_APPLICATION_STATUSES = [
  "APPLICATION_RECEIVED",
  "UNDERWRITING_IN_PROGRESS",
  "CONDITIONALLY_APPROVED",
  "FILE_COMPLETE",
  "FUNDED",
] as const;

export type StandardApplicationStatusType =
  (typeof STANDARD_APPLICATION_STATUSES)[number];

export type RenewalApplicationStatusType =
  (typeof RENEWAL_APPLICATION_STATUSES)[number];

export type HubApplicationStatusType =
  (typeof HUB_APPLICATION_STATUSES)[number];
