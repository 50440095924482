import { ReactElement } from "react";

import { Flex, Typography } from "@nestoca/ui";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { BsCheckCircleFill, BsFillArrowRightCircleFill } from "react-icons/bs";

import { SectionCardWrapper } from "./section-card-wrapper";
import styles from "./section-card.module.scss";

export type CardStatus = "complete" | "incomplete" | "next";

export type SectionCardType = {
  link: string;
  title: string;
  status: CardStatus;
  name: string;
  onClickFunc?: () => void;
};

const Icon: Record<SectionCardType["status"], ReactElement> = {
  complete: (
    <BsCheckCircleFill
      color="var(--color-status-success-foreground-saturated)"
      size="1.5em"
    />
  ),
  next: <BsFillArrowRightCircleFill size="1.5em" />,
  incomplete: <></>,
};

export const SectionCard = ({
  link,
  title,
  status,
  name,
  onClickFunc,
}: SectionCardType) => {
  const { t } = useTranslation("applications");

  return (
    <SectionCardWrapper
      name={name}
      link={link}
      onClick={onClickFunc}
      className={clsx([styles.card], {
        [styles["card--complete"]]: status === "complete",
        [styles["card--next"]]: status === "next",
      })}
    >
      <Typography size={0} weight={7} className={styles.title}>
        {title}
      </Typography>
      <Flex direction="column" gap={2}>
        {Icon[status]}
        <Typography size={"00"}>{t(status)}</Typography>
      </Flex>
    </SectionCardWrapper>
  );
};
