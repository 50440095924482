import { PropsWithChildren } from "react";

import { Modal } from "@nestoca/ui";

import styles from "./delete-application-modal.module.scss";

type Props = {
  visible: boolean;
  onClose: () => void;
};

export const DeleteApplicationModalContainer = ({
  visible,
  onClose,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <Modal
      visible={visible}
      closeOnOutsideClick
      onClose={onClose}
      className={styles["delete-application-modal"]}
    >
      <Modal.Header className={styles["delete-application-modal__header"]} />
      {children}
    </Modal>
  );
};
