import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from "react";

import { Application } from "@shared/constants";

interface State {
  application: Application;
  setApplication: Dispatch<SetStateAction<Application>>;
}

export const ApplicationContext = createContext<State>({
  application: {},
} as State);

interface ApplicationContextProps {
  application?: Application;
}

const ApplicationProvider = ({
  application = {} as Application,
  children,
}: PropsWithChildren<ApplicationContextProps>) => {
  const [_application, setApplication] = useState<Application>(application);
  return (
    <ApplicationContext.Provider
      value={{
        application: _application,
        setApplication,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

const useApplicationContext = () => {
  const context = useContext(ApplicationContext);

  if (context === undefined)
    throw new Error(
      "useApplicationContext must be used within an Application Provider"
    );

  return context;
};

export { ApplicationProvider, useApplicationContext };
