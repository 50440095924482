import { useState } from "react";

import { useGate } from "@nestoca/gate-react";
import { Box, Flex, Typography } from "@nestoca/ui";
import {
  Application,
  PROGRESS_STAGES,
  PROGRESS_STAGES_STATUSES,
  PROGRESS_BAR_STATUSES,
} from "@shared/constants";
import { KeepI18ExprHappy } from "@shared/utils";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { BsChevronRight, BsFileEarmark } from "react-icons/bs";

import { ProgressModal } from "./progress-modal";
import { progressIcons } from "./progress-tracker";
import styles from "./progress-tracker-mobile.module.scss";
import { ProgressItem } from "./progress-tracker.types";
import {
  getCurrentProgressArray,
  getCurrentProgressArrayForExternalBroker,
} from "./utils";

type PropsType = {
  application: Application;
  className?: string;
};
export const ProgressTrackerMobile = ({
  application,
  className,
}: PropsType) => {
  const { t } = useTranslation("docs");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const gate = useGate();
  const isExternalBroker = gate.getRole() === "externalbroker";

  const progressArray = isExternalBroker
    ? getCurrentProgressArrayForExternalBroker(
        application?.applicationState,
        progressIcons
      )
    : getCurrentProgressArray(application?.applicationState, progressIcons);

  const closedExtBrokerApplication = progressArray.length === 1;
  const isExtBrokerAppClosed = isExternalBroker && closedExtBrokerApplication;

  const isActiveOrFunded = (state: ProgressItem) =>
    state.status === PROGRESS_STAGES_STATUSES.ACTIVE ||
    state.name === PROGRESS_BAR_STATUSES.FUNDED;

  const isActiveOrFinalApproval = (state: ProgressItem) =>
    state.status === PROGRESS_STAGES_STATUSES.ACTIVE ||
    state.name === PROGRESS_STAGES.FINAL_APPROVAL;

  // if there is no item with active status in progress array,
  // it means that application is in final approval/funded stage with complete status
  const currentState = progressArray.find((state) => {
    if (isExtBrokerAppClosed) {
      state.name === PROGRESS_BAR_STATUSES.CLOSED;
    } else if (isExternalBroker) {
      return isActiveOrFunded(state);
    }

    return isActiveOrFinalApproval(state);
  });

  return (
    <>
      <Box
        className={clsx(styles["tracker-wrapper"], className)}
        onClick={() => setIsModalOpen(true)}
      >
        <Flex className={styles.tracker}>
          <Flex gap={3} align="center">
            <BsFileEarmark />
            <Flex direction="column">
              <Typography className={styles["tracker-name"]}>
                {t("progressTracker")}
              </Typography>
              <Typography size={0}>
                {t(`progressTracker.${currentState?.name}` as KeepI18ExprHappy)}
              </Typography>
            </Flex>
          </Flex>
          <BsChevronRight size={24} color={"var(--color-tomato-500)"} />
        </Flex>
      </Box>
      {currentState && (
        <ProgressModal
          applicationId={application.id}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          stage={currentState}
        />
      )}
    </>
  );
};
