import {
  Application,
  ApplicationTypeEnum,
  targetPropertyNotFoundCompletedSchema,
  targetPropertyCompletedSchema,
} from "@shared/constants";
import { refinancePropertySchema } from "@shared/constants/refinance-property";
import { renewingPropertySchema } from "@shared/constants/renewing-property";

export const isTargetPropertyComplete = ({
  property,
  propertyToPurchase,
  type: applicationType,
}: Application) => {
  const targetProperty = property || propertyToPurchase;
  if (!targetProperty) return false;

  const schema = targetProperty.isFound
    ? targetPropertyCompletedSchema
    : targetPropertyNotFoundCompletedSchema;

  const { NEW, REFINANCE } = ApplicationTypeEnum;

  try {
    if (applicationType === NEW) {
      schema.parse(targetProperty);
    } else if (applicationType === REFINANCE) {
      refinancePropertySchema.parse(targetProperty);
    } else {
      renewingPropertySchema.parse(targetProperty);
    }
    return true;
  } catch (error) {
    return false;
  }
};
