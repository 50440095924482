import { FinancialInstitutionEnum } from "../asset";

import {
  AmountFrequencyEnum,
  CreditScoreQualityEnum,
  EmploymentTypeEnum,
  IndustriesEnum,
  MaritalStatusesEnum,
  OperatingAsEnum,
  OtherIncomeEnum,
  RegionEnum,
  SalutationsEnEnum,
  SalutationsFrEnum,
  TenureEnum,
  PensionTypeEnum,
  RelationToMainApplicantEnum,
  IncomeTypesEnum,
} from "./applicant.enum";
import { OtherIncomeType } from "./applicant.types";

export const INCOME_TYPES_LABELS: Record<IncomeTypesEnum, string> = {
  [IncomeTypesEnum.SALARIED]: "form:incomeType.salaried",
  [IncomeTypesEnum.HOURLY]: "form:incomeType.hourly",
  [IncomeTypesEnum.HOURLY_COMMISSIONS]: "form:incomeType.hourlyCommissions",
  [IncomeTypesEnum.COMMISSIONS]: "form:incomeType.commission",
  [IncomeTypesEnum.SELF_EMPLOYED]: "form:incomeType.selfEmployed",
  [IncomeTypesEnum.PENSION]: "form:incomeType.pension",
  [IncomeTypesEnum.NONE]: "form:incomeType.noIncome",
};

export const OTHER_INCOME_LABELS: Record<OtherIncomeType, string> = {
  [OtherIncomeEnum.INVESTMENT_INCOME]: "incomeType.investment",
  [OtherIncomeEnum.INTEREST_INCOME]: "incomeType.interest",
  [OtherIncomeEnum.CHILD_SUPPORT]: "incomeType.childSupport",
  [OtherIncomeEnum.ALIMONY]: "incomeType.alimony",
  [OtherIncomeEnum.EMPLOYMENT_EXPENSE]: "incomeType.employment",
  [OtherIncomeEnum.OTHER]: "other",
};

export const SALUTATION_EN_OPTIONS = [
  {
    value: SalutationsEnEnum.MR,
    label: "form:salutations.mr",
  },
  {
    value: SalutationsEnEnum.MRS,
    label: "form:salutations.mrs",
  },
  {
    value: SalutationsEnEnum.MS,
    label: "form:salutations.ms",
  },
  {
    value: SalutationsEnEnum.MISS,
    label: "form:salutations.miss",
  },
  {
    value: SalutationsEnEnum.DR,
    label: "form:salutations.dr",
  },
  {
    value: SalutationsEnEnum.REV,
    label: "form:salutations.rev",
  },
  {
    value: SalutationsEnEnum.JUDGE,
    label: "form:salutations.judge",
  },
];

export const SALUTATION_FR_OPTIONS = [
  {
    value: SalutationsFrEnum.MR,
    label: "form:salutations.mr",
  },
  {
    value: SalutationsFrEnum.MRS,
    label: "form:salutations.mrs",
  },
  {
    value: SalutationsFrEnum.MS,
    label: "form:salutations.ms",
  },
  {
    value: SalutationsFrEnum.DR,
    label: "form:salutations.dr",
  },
  {
    value: SalutationsFrEnum.REV,
    label: "form:salutations.rev",
  },
  {
    value: SalutationsFrEnum.JUDGE,
    label: "form:salutations.judge",
  },
];

// This one is special since we don't have a 1:1 match on the options
// `MISS` is not a valid option in French
export const SALUTATION_OPTIONS = {
  en: SALUTATION_EN_OPTIONS,
  fr: SALUTATION_FR_OPTIONS,
};

export const CREDITSCORE_QUALITY_OPTIONS = [
  {
    value: CreditScoreQualityEnum.UNKNOWN,
    label: "form:creditScoreQuality.unknown",
  },
  {
    value: CreditScoreQualityEnum.EXCELLENT,
    label: "form:creditScoreQuality.excellent",
  },
  {
    value: CreditScoreQualityEnum.GOOD,
    label: "form:creditScoreQuality.good",
  },
  {
    value: CreditScoreQualityEnum.FAIR,
    label: "form:creditScoreQuality.fair",
  },
  {
    value: CreditScoreQualityEnum.POOR,
    label: "form:creditScoreQuality.poor",
  },
];

export const MARITAL_STATUSES_OPTIONS = [
  {
    value: MaritalStatusesEnum.SINGLE,
    label: "form:maritalStatus.single",
  },
  {
    value: MaritalStatusesEnum.MARRIED,
    label: "form:maritalStatus.married",
  },
  {
    value: MaritalStatusesEnum.DIVORCED,
    label: "form:maritalStatus.divorced",
  },
  {
    value: MaritalStatusesEnum.COMMON_LAW,
    label: "form:maritalStatus.commonLaw",
  },
  {
    value: MaritalStatusesEnum.WIDOWED,
    label: "form:maritalStatus.widowed",
  },
  {
    value: MaritalStatusesEnum.SEPERATED,
    label: "form:maritalStatus.seperated",
  },
  {
    value: MaritalStatusesEnum.OTHER,
    label: "form:maritalStatus.other",
  },
];

export const TENURE_OPTIONS = [
  {
    label: "form:tenure.permanent",
    value: TenureEnum.PERMANENT,
  },
  {
    label: "form:tenure.probation",
    value: TenureEnum.ON_PROBATION,
  },
  {
    label: "form:tenure.contract",
    value: TenureEnum.ON_CONTRACT,
  },
];

export const INCOME_TYPES_OPTIONS = [
  {
    label: "form:incomeType.salaried",
    value: "SALARIED",
  },
  {
    label: "form:incomeType.hourly",
    value: "HOURLY",
  },
  {
    label: "form:incomeType.hourlyCommissions",
    value: "HOURLY_COMMISSIONS",
  },
  {
    label: "form:incomeType.commission",
    value: "COMMISSIONS",
  },
  {
    label: "form:incomeType.selfEmployed",
    value: "SELF_EMPLOYED",
  },
  {
    label: "form:incomeType.pension",
    value: "PENSION",
  },
  {
    label: "form:incomeType.noIncome",
    value: "NONE",
  },
];

export const EMPLOYMENT_TYPE_OPTIONS = [
  {
    label: "form:employmentType.fullTime",
    value: EmploymentTypeEnum.FULL_TIME,
  },
  {
    label: "form:employmentType.partTime",
    value: EmploymentTypeEnum.PART_TIME,
  },
  {
    label: "form:employmentType.seasonal",
    value: EmploymentTypeEnum.SEASONAL,
  },
];

export const INCOME_FREQUENCY_OPTIONS = [
  {
    label: "form:frequency.annually",
    value: AmountFrequencyEnum.ANNUALLY,
  },
  {
    label: "form:frequency.semiannually",
    value: AmountFrequencyEnum.SEMIANNUALLY,
  },
  {
    label: "form:frequency.monthly",
    value: AmountFrequencyEnum.MONTHLY,
  },
  {
    label: "form:frequency.semi-monthly",
    value: AmountFrequencyEnum.SEMIMONTHLY,
  },
  {
    label: "form:frequency.weekly",
    value: AmountFrequencyEnum.WEEKLY,
  },
  {
    label: "form:frequency.biweekly",
    value: AmountFrequencyEnum.BIWEEKLY,
  },
];

export const COUNTRIES_OPTIONS = [
  {
    label: "form:address.country.CA",
    value: "CA",
  },
  {
    label: "form:address.country.US",
    value: "US",
  },
];

export const PROVINCES_OPTIONS = [
  {
    value: RegionEnum.ON,
    label: "provinces:ON",
  },
  {
    value: RegionEnum.QC,
    label: "provinces:QC",
  },
  {
    value: RegionEnum.AB,
    label: "provinces:AB",
  },
  {
    value: RegionEnum.BC,
    label: "provinces:BC",
  },
  {
    value: RegionEnum.MB,
    label: "provinces:MB",
  },
  {
    value: RegionEnum.NB,
    label: "provinces:NB",
  },
  {
    value: RegionEnum.NS,
    label: "provinces:NS",
  },
  {
    value: RegionEnum.NL,
    label: "provinces:NL",
  },
  {
    value: RegionEnum.PE,
    label: "provinces:PE",
  },
  {
    value: RegionEnum.SK,
    label: "provinces:SK",
  },
  {
    value: RegionEnum.NT,
    label: "provinces:NT",
  },
  {
    value: RegionEnum.YT,
    label: "provinces:YT",
  },
  {
    value: RegionEnum.NU,
    label: "provinces:NU",
  },
];

// EN: https://www23.statcan.gc.ca/imdb/p3VD.pl?Function=getVD&TVD=53971
// FR: https://www23.statcan.gc.ca/imdb/p3VD_f.pl?Function=getVD&TVD=53971
export const US_STATES_OPTIONS = [
  { value: "AL", label: "us-states:AL" },
  { value: "AK", label: "us-states:AK" },
  { value: "AZ", label: "us-states:AZ" },
  { value: "AR", label: "us-states:AR" },
  { value: "CA", label: "us-states:CA" },
  { value: "CO", label: "us-states:CO" },
  { value: "CT", label: "us-states:CT" },
  { value: "DE", label: "us-states:DE" },
  { value: "DC", label: "us-states:DC" },
  { value: "FL", label: "us-states:FL" },
  { value: "GA", label: "us-states:GA" },
  { value: "HI", label: "us-states:HI" },
  { value: "ID", label: "us-states:ID" },
  { value: "IL", label: "us-states:IL" },
  { value: "IN", label: "us-states:IN" },
  { value: "IA", label: "us-states:IA" },
  { value: "KS", label: "us-states:KS" },
  { value: "KY", label: "us-states:KY" },
  { value: "LA", label: "us-states:LA" },
  { value: "ME", label: "us-states:ME" },
  { value: "MD", label: "us-states:MD" },
  { value: "MA", label: "us-states:MA" },
  { value: "MI", label: "us-states:MI" },
  { value: "MN", label: "us-states:MN" },
  { value: "MS", label: "us-states:MS" },
  { value: "MO", label: "us-states:MO" },
  { value: "MT", label: "us-states:MT" },
  { value: "NE", label: "us-states:NE" },
  { value: "NV", label: "us-states:NV" },
  { value: "NH", label: "us-states:NH" },
  { value: "NJ", label: "us-states:NJ" },
  { value: "NM", label: "us-states:NM" },
  { value: "NY", label: "us-states:NY" },
  { value: "NC", label: "us-states:NC" },
  { value: "ND", label: "us-states:ND" },
  { value: "OH", label: "us-states:OH" },
  { value: "OK", label: "us-states:OK" },
  { value: "OR", label: "us-states:OR" },
  { value: "PA", label: "us-states:PA" },
  { value: "RI", label: "us-states:RI" },
  { value: "SC", label: "us-states:SC" },
  { value: "SD", label: "us-states:SD" },
  { value: "TN", label: "us-states:TN" },
  { value: "TX", label: "us-states:TX" },
  { value: "UT", label: "us-states:UT" },
  { value: "VT", label: "us-states:VT" },
  { value: "VA", label: "us-states:VA" },
  { value: "WA", label: "us-states:WA" },
  { value: "WV", label: "us-states:WV" },
  { value: "WI", label: "us-states:WI" },
  { value: "WY", label: "us-states:WY" },
];

export const INDUSTRY_OPTIONS = [
  {
    value: IndustriesEnum.BANK_FINANCE,
    label: "form:industry.bank",
  },
  {
    value: IndustriesEnum.MANUFACTURING,
    label: "form:industry.manufacturing",
  },
  {
    value: IndustriesEnum.GOVERNMENT,
    label: "form:industry.government",
  },
  {
    value: IndustriesEnum.HEALTH,
    label: "form:industry.health",
  },
  {
    value: IndustriesEnum.RETAIL,
    label: "form:industry.retail",
  },
  {
    value: IndustriesEnum.HIGH_TECH,
    label: "form:industry.highTech",
  },
  {
    value: IndustriesEnum.EDUCATION,
    label: "form:industry.education",
  },
  {
    value: IndustriesEnum.LEISURE_ENTERTAINMENT,
    label: "form:industry.leisure",
  },
  {
    value: IndustriesEnum.SERVICES,
    label: "form:industry.services",
  },
  {
    value: IndustriesEnum.TRANSPORTATION,
    label: "form:industry.transportation",
  },
  {
    value: IndustriesEnum.NATURAL_RESOURCES,
    label: "form:industry.naturalResources",
  },
  {
    value: IndustriesEnum.CONSTRUCTION,
    label: "form:industry.construction",
  },
  {
    value: IndustriesEnum.FARMING,
    label: "form:industry.farming",
  },
];

export const BANKING_INSTITUTION_OPTIONS = [
  {
    value: FinancialInstitutionEnum.BMO,
    label: "applications:financialInstitution.bmo",
  },
  {
    value: FinancialInstitutionEnum.CIBC,
    label: "applications:financialInstitution.cibc",
  },
  {
    value: FinancialInstitutionEnum.DESJARDINS,
    label: "applications:financialInstitution.desjardins",
  },
  {
    value: FinancialInstitutionEnum.HSBC,
    label: "applications:financialInstitution.hsbc",
  },
  {
    value: FinancialInstitutionEnum.IG,
    label: "applications:financialInstitution.ig",
  },
  {
    value: FinancialInstitutionEnum.LAURENTIAN,
    label: "applications:financialInstitution.laurentian",
  },
  {
    value: FinancialInstitutionEnum.NBC,
    label: "applications:financialInstitution.nbc",
  },
  {
    value: FinancialInstitutionEnum.RBC,
    label: "applications:financialInstitution.rbc",
  },
  {
    value: FinancialInstitutionEnum.SCOTIA_BANK,
    label: "applications:financialInstitution.scotia_bank",
  },
  {
    value: FinancialInstitutionEnum.SUNLIFE,
    label: "applications:financialInstitution.sunlife",
  },
  {
    value: FinancialInstitutionEnum.TANGERINE,
    label: "applications:financialInstitution.tangerine",
  },
  {
    value: FinancialInstitutionEnum.TD,
    label: "applications:financialInstitution.td",
  },
  {
    value: FinancialInstitutionEnum.OTHER,
    label: "applications:financialInstitution.other",
  },
];

export const OPERATING_AS_OPTIONS = [
  {
    value: OperatingAsEnum.CORPORATION,
    label: "form:selfEmployed.operatingAs.corporation",
  },
  {
    value: OperatingAsEnum.PARTNERSHIP,
    label: "form:selfEmployed.operatingAs.partnership",
  },
  {
    value: OperatingAsEnum.SOLE_PROPRIETOR,
    label: "form:selfEmployed.operatingAs.soloproprietor",
  },
];

export const PENSION_TYPE_OPTIONS = [
  {
    value: PensionTypeEnum.OLD_AGE_SECURITY,
    label: "form:pension.type.oldAgeSecurity",
  },
  {
    value: PensionTypeEnum.CANADIAN_PENSION_PLAN,
    label: "form:pension.type.canadianPensionPlan",
  },
  {
    value: PensionTypeEnum.EMPLOYER,
    label: "form:pension.type.employerPension",
  },
  {
    value: PensionTypeEnum.OTHER,
    label: "form:pension.type.other",
  },
];

export const OTHER_INCOME_TYPES = [
  {
    value: OtherIncomeEnum.INVESTMENT_INCOME,
    label: "form:otherIncomeType.investment",
  },
  {
    value: OtherIncomeEnum.INTEREST_INCOME,
    label: "form:otherIncomeType.interest",
  },
  {
    value: OtherIncomeEnum.CHILD_SUPPORT,
    label: "form:otherIncomeType.childSupport",
  },
  {
    value: OtherIncomeEnum.ALIMONY,
    label: "form:otherIncomeType.alimony",
  },
  {
    value: OtherIncomeEnum.EMPLOYMENT_EXPENSE,
    label: "form:otherIncomeType.employment",
  },
  {
    value: OtherIncomeEnum.OTHER,
    label: "form:otherIncomeType.other",
  },
];

export const RELATIONSHIP_MAIN_APPLICANT_OPTIONS = [
  {
    value: RelationToMainApplicantEnum.SPOUSE,
    label: "form:relationToMainApplicant.spouse",
  },
  {
    value: RelationToMainApplicantEnum.COMMON_LAW,
    label: "form:relationToMainApplicant.commonLaw",
  },
  {
    value: RelationToMainApplicantEnum.RELATED_FAMILY_MEMBER,
    label: "form:relationToMainApplicant.familyMember",
  },
  {
    value: RelationToMainApplicantEnum.PARENT,
    label: "form:relationToMainApplicant.parent",
  },
  {
    value: RelationToMainApplicantEnum.CHILD,
    label: "form:relationToMainApplicant.child",
  },
  {
    value: RelationToMainApplicantEnum.SIBLING,
    label: "form:relationToMainApplicant.sibling",
  },
  {
    value: RelationToMainApplicantEnum.GRAND_PARENT,
    label: "form:relationToMainApplicant.grandparent",
  },
  {
    value: RelationToMainApplicantEnum.GRAND_CHILD,
    label: "form:relationToMainApplicant.grandchild",
  },
  {
    value: RelationToMainApplicantEnum.OTHER,
    label: "form:relationToMainApplicant.other",
  },
];
