export enum PROGRESS_STAGES_STATUSES {
  ACTIVE = "active",
  PENDING = "pending",
  COMPLETE = "complete",
}

export enum PROGRESS_STAGES {
  IN_PROGRESS = "in_progress",
  SUBMITTED = "submitted",
  CONDITIONAL_APPROVAL = "conditional_approval",
  FINAL_APPROVAL = "final_approval",
}

export enum CIRCLE_PROGRESS_TRACKER_PHASES {
  GETTING_TO_KNOW = "getting_to_know",
  LENDER_APPROVAL = "lender_approval",
  FUNDING = "funding",
}

export enum CIRCLE_PROGRESS_STATUSES {
  SUBMITTED = "SUBMITTED",
  REVIEW = "REVIEW",
  LENDER_SUBMITTED = "LENDER_SUBMITTED",
  LENDER_APPROVED = "LENDER_APPROVED",
  PENDING_SIGNATURE = "PENDING_SIGNATURE",
  PENDING_CONDITIONS = "PENDING_CONDITIONS",
  COMPLETE = "COMPLETE",
  FUNDED = "FUNDED",
}

export enum PROGRESS_BAR_STATUSES {
  APPLICATION_RECEIVED = "APPLICATION_RECEIVED",
  UNDERWRITING_IN_PROGRESS = "UNDERWRITING_IN_PROGRESS",
  CONDITIONALLY_APPROVED = "CONDITIONALLY_APPROVED",
  FILE_COMPLETE = "FILE_COMPLETE",
  FUNDED = "FUNDED",
  CLOSED = "CLOSED",
}

export enum TransactionTypeEnum {
  NEW = "NEW",
  CONSTRUCTION_LOAN = "CONSTRUCTION_LOAN",
  PORT = "PORT",
  ASSUMPTION = "ASSUMPTION",
  PRE_APPROVAL = "PRE_APPROVAL",
  REFINANCE = "REFINANCE",
  READVANCE = "READVANCE",
  CHANGE_OF_COVENANT = "CHANGE_OF_COVENANT",
  RENEWAL = "RENEWAL",
  TRANSFER = "TRANSFER",
  PURCHASE_WITH_IMPROVEMENT = "PURCHASE_WITH_IMPROVEMENT",
  BRIDGE = "BRIDGE",
}

export type TransactionType = keyof typeof TransactionTypeEnum;
