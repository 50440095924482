import { Card } from "@nestoca/ui";
import { Application } from "@shared/constants";

import { SelectApplicationCardBody } from "./body";
import { SelectApplicationCardFooter } from "./footer";
import SelectApplicationCardHeader from "./header/header";
import styles from "./select-application-card.module.scss";

export interface SelectApplicationCardProps {
  showProgressBar?: boolean;
  application: Application;
}

export const SelectApplicationCard = ({
  showProgressBar = false,
  application,
}: SelectApplicationCardProps) => {
  const { applicationState, id } = application;

  return (
    <Card className={styles["select-application-card"]}>
      <SelectApplicationCardHeader state={applicationState} id={id} />
      <SelectApplicationCardBody
        application={application}
        showProgressBar={showProgressBar}
      />
      <SelectApplicationCardFooter application={application} />
    </Card>
  );
};
