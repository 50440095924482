import { Fragment } from "react";

import { HStack } from "@nestoca/ui";

import { ChipFilterItem } from "./chip-filters-item";
import { ChipFilter } from "./chip-filters.types";

interface ChipFiltersProps {
  filters: ChipFilter<string>[];
  onSelectFilter?: (filter: ChipFilter) => void;
  renderItem?: RenderItemFn;
  activeItemValue?: ChipFilter["value"] | Record<string, boolean>;
}

export const defaultRenderItem = ({
  filter,
  onSelectFilter,
  activeItemValue,
}: {
  filter: ChipFilter;
  onSelectFilter?: (filter: ChipFilter) => void;
  activeItemValue?: ChipFilter["value"] | Record<string, boolean>;
}) => {
  return (
    <ChipFilterItem
      filter={filter}
      onClick={onSelectFilter}
      activeItemValue={activeItemValue}
    />
  );
};

export type RenderItemFn = typeof defaultRenderItem;

export const ChipFilters = ({
  filters,
  renderItem = defaultRenderItem,
  onSelectFilter,
  activeItemValue,
}: ChipFiltersProps) => {
  return (
    <HStack gap={2}>
      {filters.map((filter) => (
        <Fragment key={`${filter.value}`}>
          {renderItem({ filter, onSelectFilter, activeItemValue })}
        </Fragment>
      ))}
    </HStack>
  );
};
