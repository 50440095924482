import { createContext, useContext } from "react";
import type { Dispatch, SetStateAction } from "react";

interface QuestionnaireLayoutContextData {
  warnBeforeQuit: boolean;
  setWarnBeforeQuit: Dispatch<SetStateAction<boolean>>;
}

export const QuestionnaireLayoutContext = createContext<
  QuestionnaireLayoutContextData | undefined
>(undefined);

export const useQuestionnaireLayout = () => {
  const questionnaireLayoutContext = useContext(QuestionnaireLayoutContext);

  if (questionnaireLayoutContext === undefined) {
    throw new Error(
      "useLayoutContext must be inside a QuestionnaireLayoutProvider"
    );
  }
  return questionnaireLayoutContext;
};
