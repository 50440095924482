import { Banner, Typography, VStack } from "@nestoca/ui";
import { DownPaymentByApplicant } from "@shared/constants";
import { DatadogMask } from "@shared/datadog";
import { formatCurrency, formatPercentage } from "@shared/utils";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

export interface DownPaymentProps {
  /** Down Payment Amount */
  downPayment?: number;
  /** Percentage of the down payment 0-100 */
  percentage?: number;
  missingPurchasePrice: boolean;
  className?: string;
  downPaymentByApplicant?: DownPaymentByApplicant[];
}

export const DownPaymentBanner = ({
  downPayment = 0,
  percentage = 0,
  missingPurchasePrice = true,
  className,
  downPaymentByApplicant,
}: DownPaymentProps) => {
  const { locale } = useRouter();
  const { t } = useTranslation("down-payment");

  return (
    <Banner showIcon={false} e2ePrefix="down-payment" className={className}>
      <VStack gap={5} align="start">
        <VStack align="start" gap={2}>
          <Typography size={0}>
            {t("banner.total", { ns: "down-payment" })}
          </Typography>
          <DatadogMask
            as={Typography}
            size="0"
            weight={7}
            data-dd-action-name="down payment amount"
          >
            {formatCurrency(downPayment, locale)}
          </DatadogMask>
        </VStack>
        {!missingPurchasePrice && (
          <VStack align="start" gap={2}>
            <Typography size={0}>
              {t("banner.percentage", { ns: "down-payment" })}
            </Typography>
            <DatadogMask
              as={Typography}
              size="0"
              weight={7}
              data-dd-action-name="down payment percentage"
            >
              {formatPercentage(percentage, { locale })}
            </DatadogMask>
          </VStack>
        )}
        {downPaymentByApplicant?.map(
          ({ firstName, lastName, amount, applicantId }) => {
            return (
              <VStack align="start" gap={2} key={applicantId}>
                <DatadogMask
                  as={Typography}
                  size="0"
                  data-dd-action-name="applicant name"
                >
                  {firstName} {lastName}
                </DatadogMask>
                <DatadogMask
                  as={Typography}
                  size="0"
                  weight={7}
                  data-dd-action-name="down payment amount by applicant"
                >
                  {formatCurrency(amount, locale)}
                </DatadogMask>
              </VStack>
            );
          }
        )}
      </VStack>
    </Banner>
  );
};
