import { useMemo, useState } from "react";

import { Banner, Button } from "@nestoca/ui";
import { useApplicationDisabled } from "@shared/ui";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import { LockedApplicationModal } from "../locked-application-modal/locked-application-modal";

import styles from "./locked-banner.module.scss";

export const LockedBanner = ({ applicationId }: { applicationId: number }) => {
  const { t } = useTranslation("applications");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    isFunded,
    isLocked,
    isClosed,
    isSubmittedToUnderwriter,
    isConditionalApproval,
    isFinalApproval,
  } = useApplicationDisabled(applicationId);

  const heading = useMemo(() => {
    return (
      <>
        {isFunded && t("fundedApplication.modal.heading")}
        {isClosed && t("closedApplication.modal.heading", { ns: "docs" })}
        {isSubmittedToUnderwriter &&
          t("submittedToUnderwriterApplication.modal.heading")}
        {isConditionalApproval &&
          t("conditionalApprovalApplication.modal.heading")}
        {isFinalApproval && t("finalApprovalApplication.modal.heading")}
        {!isFunded &&
          !isClosed &&
          !isSubmittedToUnderwriter &&
          !isConditionalApproval &&
          !isFinalApproval &&
          isLocked &&
          t("lockedApplication.modal.heading")}
      </>
    );
  }, [
    isFunded,
    isClosed,
    isLocked,
    isSubmittedToUnderwriter,
    isConditionalApproval,
    isFinalApproval,
    t,
  ]);

  return (
    <>
      <Banner className={styles.banner}>
        {heading}
        <Button
          variant="critical"
          size={isMobile ? "small" : "medium"}
          onClick={() => setIsModalOpen(true)}
          data-dd-action-name="open locked application modal"
        >
          {t("viewMore", { ns: "docs" })}
        </Button>
      </Banner>
      <LockedApplicationModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        applicationId={applicationId}
        isFunded={isFunded}
        isLocked={isLocked}
        isClosed={isClosed}
        heading={heading}
      />
    </>
  );
};
