import type { Frequency, MortgageFrequency } from "./amount-frequency.types";

export const FREQUENCY_LABELS: Record<Frequency, string> = {
  ANNUALLY: "frequency.yr",
  SEMIANNUALLY: "frequency.sa",
  MONTHLY: "frequency.mo",
  SEMIMONTHLY: "frequency.sm",
  BIWEEKLY: "frequency.bw",
  WEEKLY: "frequency.wkly",
};

export const MORTGAGE_FREQUENCY_LABELS: Record<MortgageFrequency, string> = {
  ANNUALLY: "frequency.yr",
  SEMIANNUALLY: "frequency.sa",
  MONTHLY: "frequency.mo",
  SEMIMONTHLY: "frequency.sm",
  BIWEEKLY: "frequency.bw",
  BIWEEKLYACCELERATED: "frequency.biweeklyAcc",
  WEEKLY: "frequency.wkly",
  WEEKLYACCELERATED: "frequency.weeklyAcc",
};
