import { createContext, useContext, useState } from "react";
import type { PropsWithChildren } from "react";

import { TOAST_AUTOCLOSE_DELAY_IN_MS } from "@shared/constants";

export type NotificationStatus = "success" | "error";

interface Notification {
  status: NotificationStatus;
  applicantId: number;
  timeout?: NodeJS.Timeout;
}

interface RemoveCoApplicantNotificationContextData {
  notifications: Notification[];
  showNotification: (status: NotificationStatus, applicantId: number) => void;
  clearNotification: (applicantId: number) => void;
}

const removeNotificationByApplicantId = (
  notifications: Notification[],
  applicantId: number
): Notification[] => {
  return notifications.filter(
    (notification) => notification.applicantId !== applicantId
  );
};

const RemoveCoApplicantNotificationContext = createContext<
  RemoveCoApplicantNotificationContextData | undefined
>(undefined);

export const RemoveCoApplicantNotificationProvider = ({
  children,
}: PropsWithChildren) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const showNotification = (
    status: NotificationStatus,
    applicantId: number
  ) => {
    const existingNotification = notifications.find(
      (notification) => notification.applicantId === applicantId
    );

    if (existingNotification) {
      clearTimeout(existingNotification.timeout);
    }

    const timeout = setTimeout(() => {
      setNotifications((prevNotifications) =>
        removeNotificationByApplicantId(prevNotifications, applicantId)
      );
    }, TOAST_AUTOCLOSE_DELAY_IN_MS);

    const newNotification = { status, applicantId, timeout };

    setNotifications((prevNotifications) => [
      ...removeNotificationByApplicantId(prevNotifications, applicantId),
      newNotification,
    ]);
  };

  const clearNotification = (applicantId: number) => {
    setNotifications((prevNotifications) => {
      prevNotifications.forEach((notification) => {
        if (notification.applicantId === applicantId) {
          clearTimeout(notification.timeout);
        }
      });

      return removeNotificationByApplicantId(prevNotifications, applicantId);
    });
  };

  return (
    <RemoveCoApplicantNotificationContext.Provider
      value={{ notifications, showNotification, clearNotification }}
    >
      {children}
    </RemoveCoApplicantNotificationContext.Provider>
  );
};

export const useNotification = () => {
  const notificationContext = useContext(RemoveCoApplicantNotificationContext);

  if (notificationContext === undefined) {
    throw new Error(
      "useNotification must be inside a RemoveCoApplicantNotificationProvider"
    );
  }

  return notificationContext;
};
