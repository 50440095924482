import { SupportedTenant, TenantSlug } from "@nestoca/multi-tenant";

import {
  ADDRESS_MAPPED_FIELDS,
  ADDRESS_SECTIONS,
  ALL_TRANSACTION_TYPES,
  APPLICATION_TYPES_MAP,
  ApplicationState,
  isPropertyAsset,
  mainApplicantInformationSchema,
  MAIN_APPLICATION_TYPES,
  TransactionTypeEnum,
} from "../constants";

import { getSumYearsMonths } from "./dates";

import type {
  Applicant,
  Application,
  Asset,
  AssetWithApplicant,
  OtherProperty,
  TargetProperty,
  Account,
  Address,
  PropertyAsset,
} from "../constants";
import type { RegisteredAddress } from "../constants/application/register-address";

export const isApplicationActive = (applicationState: ApplicationState) =>
  ![
    ApplicationState.Funded,
    ApplicationState.Closed,
    ApplicationState.Expired,
    ApplicationState.Locked,
  ].includes(applicationState);

export const isApplicationFunded = (applicationState: ApplicationState) =>
  applicationState === ApplicationState.Funded;

export const isApplicationClosed = (applicationState: ApplicationState) =>
  applicationState === ApplicationState.Closed;

export const isTargetPropertyRental = (targetProperty?: TargetProperty) =>
  ["RENTAL", "OWNER_OCCUPIED_AND_RENTAL"].includes(
    targetProperty?.purpose || ""
  );

export const isTargetPropertyOnlyRental = (targetProperty?: TargetProperty) =>
  targetProperty?.purpose === "RENTAL";

export const isOtherPropertyRentalAfterTransaction = (
  otherProperty?: OtherProperty
) =>
  ["RENTAL", "OWNER_OCCUPIED_AND_RENTAL"].includes(
    otherProperty?.afterTransactionPurpose || ""
  );

export const isOtherPropertyOnlyRentalAfterTransaction = (
  otherProperty?: OtherProperty
) => otherProperty?.afterTransactionPurpose === "RENTAL";

export const isOtherPropertySoldAfterTransaction = (
  otherProperty?: OtherProperty
) => otherProperty?.afterTransactionPurpose === "SOLD";

export const getTargetPropertyValue = (
  applicationType: TransactionTypeEnum,
  targetProperty?: TargetProperty
) =>
  targetProperty
    ? applicationType === TransactionTypeEnum.NEW
      ? targetProperty.purchasePrice
      : targetProperty.estimatedPropertyValue
    : 0;

// get all properties
export const allApplicantProperties = (application: Application) =>
  Object.values(application.applicants).reduce(
    (acc: OtherProperty[], applicant) => [
      ...acc,
      ...applicant.properties.map((property) => ({
        ...property,
      })),
    ],
    []
  ) || [];

// get property by id
export const getPropertyById =
  (propertyId: number) => (application: Application) =>
    allApplicantProperties(application).find(
      (property) => property.id === propertyId
    );

const getApplicantAssetWithAugmentation =
  <T extends Asset>(augementation: (applicant: Applicant, asset: Asset) => T) =>
  (application: Application, applicantId: number) => {
    const assetsWithSoldProperty: T[] = [];

    const applicant = application.applicants[applicantId];

    if (applicant) {
      applicant.allAssets.forEach((asset) => {
        if (isPropertyAsset(asset)) {
          // if asset is type property and retal we don't want to include it
          const property = getPropertyById(asset.existingPropertyId)(
            application
          );
          // is sold after transaction
          const isSold = isOtherPropertySoldAfterTransaction(property);

          if (isSold) {
            assetsWithSoldProperty.push(augementation(applicant, asset));
          }
          return;
        }

        assetsWithSoldProperty.push(augementation(applicant, asset));
      });
    }

    return assetsWithSoldProperty;
  };

const getAssetWithAugmentation =
  <T extends Asset>(augementation: (applicant: Applicant, asset: Asset) => T) =>
  (application: Application) => {
    const assetsWithSoldProperty: T[] = [];

    Object.keys(application.applicants).forEach((applicantId) => {
      getApplicantAssetWithAugmentation(augementation)(
        application,
        Number(applicantId)
      ).forEach((asset) => {
        assetsWithSoldProperty.push(asset);
      });
    });

    return assetsWithSoldProperty;
  };

export const getApplicantAssets =
  (applicantId: number) => (application: Application) =>
    getApplicantAssetWithAugmentation<Asset>((applicant, asset) => ({
      ...asset,
      applicantId: applicant.applicantId,
    }))(application, applicantId);

export const getAllAsset = (application: Application) =>
  getAssetWithAugmentation<Asset>((applicant, asset) => ({
    ...asset,
    applicantId: applicant.applicantId,
  }))(application);

export const getAllAssetsWithApplicant = (application: Application) =>
  getAssetWithAugmentation<AssetWithApplicant>((applicant, asset) => ({
    ...asset,
    applicantId: applicant.applicantId,
    applicant: {
      firstName: applicant.firstName ?? "",
      lastName: applicant.lastName ?? "",
      applicantId: applicant.applicantId,
    },
  }))(application);

const getListOfAssets = (application: Application) => {
  return (
    Object.values(application.applicants).reduce(
      (acc: Asset[], applicant) => [
        ...acc,
        ...applicant.allAssets.map((asset) => ({
          ...asset,
          applicantId: applicant.applicantId,
        })),
      ],
      []
    ) || []
  );
};

export const getAssetById = (assetId: number) => (application: Application) => {
  const assets = getListOfAssets(application);

  return assets.find((asset) => asset.id === assetId);
};

export const getAssetByPropertyId =
  (propertyId: number) => (application: Application) => {
    const assets = getListOfAssets(application);

    return (assets as PropertyAsset[]).find(
      (asset: PropertyAsset) => asset.existingPropertyId === propertyId
    );
  };

export const getApplicantName = (
  applicant: Applicant | Account | undefined,
  unknownName: string
) => {
  if (!applicant) {
    return unknownName;
  }

  const { firstName, lastName } = applicant;

  return firstName && lastName
    ? `${firstName} ${lastName}`
    : firstName || lastName || unknownName;
};

export const getApplicationTypeByTransactionType = (
  applicationType: Application["type"]
) => {
  const type = Object.entries(APPLICATION_TYPES_MAP).find(
    ([, transactionTypes]) => {
      return transactionTypes.includes(applicationType);
    }
  );

  return type ? (type[0] as TransactionTypeEnum) : TransactionTypeEnum.NEW;
};

export const getApplicationsByType =
  (type: Application["type"]) => (applications: Application[]) =>
    applications.filter(
      (application) =>
        getApplicationTypeByTransactionType(application.type) === type
    );

export const getApplicationsByTransactionType =
  (transactionType: Application["type"]) => (applications: Application[]) =>
    applications.filter((application) => application.type === transactionType);

export const getFirstApplications = (applications: Application[]) =>
  applications?.[0];

export const isApplicantValid = (applicant: Applicant) => {
  const { success } = mainApplicantInformationSchema.safeParse(applicant);

  return success;
};

/**
 * Returns true if the total years and months are greater than or equal to 3 years.
 * @param years the number of years
 * @param months the number of months
 */
export const isAddressHistoryMoreThanThreeYears = (
  years: number,
  months: number
) => {
  const MONTHS_IN_A_YEAR = 12;
  const THREE_YEARS = MONTHS_IN_A_YEAR * 3;

  return Boolean(years * MONTHS_IN_A_YEAR + months >= THREE_YEARS);
};

/**
 * Aggregate function that formats the registered addresses and validates the sum
 * of the address history in years and months.
 * @param addresses the list of registered addresses to check
 */
export const isRegisteredAddressValid = (addresses: RegisteredAddress[]) => {
  const registeredAddresses = getFormattedRegisteredAddresses(addresses);
  const { years, months } = getSumYearsMonths(registeredAddresses);

  return isAddressHistoryMoreThanThreeYears(years, months);
};

/**
 * Takes a list of RegisteredAddresses and returns the full address objects along with the
 * formatted `address` nested object.
 *
 * @param addresses the list of registered addresses to format
 */
export const getFormattedRegisteredAddresses = (
  addresses: RegisteredAddress[]
) =>
  addresses.map(
    (address) =>
      ({
        ...address,
        address: getAddressNoAutoFillFields(
          address.address,
          `${ADDRESS_SECTIONS.RegisteredAddress}_${address.id}`
        ),
        // TODO: fix this type cast. returned object contains shorthand properties not compatible with RegisteredAddress type
      }) as RegisteredAddress
  );

/**
 * Takes an address and returns a copy with formatted object keys.
 * The keys are formatted as `registeredAddress_${id}_${field}`, where `id` is the address id
 * and `field` is the address field expected by the backend (`ADDRESS_MAPPED_FIELDS`).
 *
 * @param address the full address
 * @param section the address section
 */
export const getAddressNoAutoFillFields = (address: Address, section = "") => {
  if (section)
    return {
      [`${section}_${ADDRESS_MAPPED_FIELDS.StreetNumber}`]:
        address.streetNumber,
      [`${section}_${ADDRESS_MAPPED_FIELDS.Street}`]: address.street,
      [`${section}_${ADDRESS_MAPPED_FIELDS.Unit}`]: address.unit,
      [`${section}_${ADDRESS_MAPPED_FIELDS.City}`]: address.city,
      [`${section}_${ADDRESS_MAPPED_FIELDS.StateCode}`]: address.stateCode,
      [`${section}_${ADDRESS_MAPPED_FIELDS.PostalCode}`]: address.postalCode,
      [`${section}_${ADDRESS_MAPPED_FIELDS.CountryCode}`]: address.countryCode,
    };

  return {
    [ADDRESS_MAPPED_FIELDS.StreetNumber]: address.streetNumber,
    [ADDRESS_MAPPED_FIELDS.Street]: address.street,
    [ADDRESS_MAPPED_FIELDS.Unit]: address.unit,
    [ADDRESS_MAPPED_FIELDS.City]: address.city,
    [ADDRESS_MAPPED_FIELDS.StateCode]: address.stateCode,
    [ADDRESS_MAPPED_FIELDS.PostalCode]: address.postalCode,
    [ADDRESS_MAPPED_FIELDS.CountryCode]: address.countryCode,
  };
};

export const isBankingDetailsValid = (applicant: Applicant) =>
  !!applicant.primaryBankingInstitution ||
  !!applicant.primaryBankingInstitutionOther;

export const getApplicationTypesByTenant = (
  tenantSlug: TenantSlug | undefined
) => {
  const applicationTypesByTenant: Record<
    SupportedTenant,
    ReadonlyArray<Application["type"]>
  > = {
    nesto: MAIN_APPLICATION_TYPES,
    ig: ALL_TRANSACTION_TYPES,
    "ig-dev": ALL_TRANSACTION_TYPES,
  };

  return (
    applicationTypesByTenant[
      tenantSlug as keyof typeof applicationTypesByTenant
    ] || []
  );
};
