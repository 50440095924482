import { Flex, Typography } from "@nestoca/ui";
import { useGetAccount } from "@shared/api/hooks/account";
import { getUserInitials } from "@shared/ui/utils";

import styles from "./sidebar.module.scss";

export const InitialsAvatar = () => {
  const { data: account } = useGetAccount();

  const initials = getUserInitials(account?.lastName, account?.firstName, "");

  if (!initials) {
    return null;
  }

  return (
    <Flex className={styles["initials-avatar"]} align="center" justify="center">
      <Typography size={0} weight={7} textColor="var(--color-black)">
        {initials}
      </Typography>
    </Flex>
  );
};
