import { atomWithStorage, createJSONStorage } from "jotai/utils";
import { z } from "zod";

type StringOrNull = string | null;

const partnerSchema = z
  .enum([
    // Original colors
    "nesto",
    // Partners with co-branded
    "eqb",
    "ferique",
    "fhg",
    "ia",
    "optimize",
    "quadrus",
    "canadalife",
    // next partners doesn't have co-branded
    "assetdirect",
    "billdr",
    "borrowell",
    "brookfield",
    "cbns",
    "chrisallard",
    "clearscore",
    "clicassure",
    "communities",
    "comparemortgages",
    "creditKarma",
    "door2door",
    "dwelly",
    "educfinance",
    "fido",
    "forbesadvisor",
    "greatWestLife",
    "guideHabitation",
    "hardbacon",
    "helenelauzier",
    "hellosafe",
    "home",
    "IG_WM",
    "justo",
    "laurentien",
    "lenderforall",
    "loanscanada",
    "manulife",
    "milesopedia",
    "moneygenius",
    "moneywise",
    "movemeto",
    "movingwaldo",
    "mtlguidecondo",
    "myabode",
    "myChoice",
    "mylo",
    "nerdwallet",
    "nestoreferral",
    "planhub",
    "point2homes",
    "pretsquebec",
    "properly",
    "proprioDirect",
    "realtor",
    "redflagdeals",
    "rentals",
    "rentfund",
    "rew",
    "rogersbank",
    "royalLepage",
    "santasellshouses",
    "smarterloans",
    "sonnet",
    "squareone",
    "suttonSummit",
    "Tangerine",
    "TD",
    "tlg",
    "uni",
    "unreserved",
    "viaCapitale",
    "vistoo",
    "wealthrocket",
    "wealthSimple",
    "worldsource",
    "zolo",
  ])
  .default("nesto")
  .catch("nesto");

export type Partner = z.infer<typeof partnerSchema>;

export const analyticsSchema = z
  .object({
    clickId: z.string().optional(),
    ext_pid: z.string().optional(),
    partner: partnerSchema,
    subPartnerId: z.coerce.number().default(0),
  })
  .transform(({ clickId, ext_pid, partner, subPartnerId }) => ({
    // renamed marketing team query params to internal name
    affiliateMarketingId: clickId,
    impressionsTrackingId: ext_pid,
    partner,
    subPartnerId,
  }));

export type Analytics = z.infer<typeof analyticsSchema>;

const storage = createJSONStorage<StringOrNull>(() => sessionStorage);
const storagePartner = createJSONStorage<Partner>(() => sessionStorage);
const storagesubPartnerId = createJSONStorage<number>(() => sessionStorage);

const STORAGE_PREFIX = "n:";

const defaultValue: StringOrNull = null;
const partnerDefaultValue: Partner = "nesto";
const subPartnerIdDefaultValue = 0;

export const affiliateMarketingIdAtom = atomWithStorage<StringOrNull>(
  `${STORAGE_PREFIX}affiliateMarketingId`,
  defaultValue,
  storage
);

export const impressionsTrackingIdAtom = atomWithStorage<StringOrNull>(
  `${STORAGE_PREFIX}impressionsTrackingId`,
  defaultValue,
  storage
);

export const partnerAtom = atomWithStorage(
  `${STORAGE_PREFIX}partner`,
  partnerDefaultValue,
  storagePartner
);

export const subPartnerIdAtom = atomWithStorage(
  `${STORAGE_PREFIX}subPartnerId`,
  subPartnerIdDefaultValue,
  storagesubPartnerId
);
