import { isEmptyArray } from "./is-empty-array";
import { isEmptyObject } from "./is-empty-object";
import { isEmptyString } from "./is-empty-string";
import { isNullOrUndefined } from "./is-null-or-undefined";

export const isEmpty = (value: unknown) => {
  return (
    isNullOrUndefined(value) ||
    (typeof value === "string" && isEmptyString(value)) ||
    (typeof value === "number" && isNaN(value)) ||
    (Array.isArray(value) && isEmptyArray(value)) ||
    (typeof value === "object" && isEmptyObject(value))
  );
};
