import React from "react";
import { Typography } from "@nestoca/ui";
import { useTranslation } from "next-i18next";

export const Product = ({ product }: any) => {
  const { t } = useTranslation("applications");
  // TODO: translate product name
  const label = product ? "Product" : t("product.noProductSelected");

  return <Typography size={0}>{label}</Typography>;
};
