import type { PropsWithChildren } from "react";
import { useEffect, createContext, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { getAuthToken } from "@shared/api/client";
import { useGetAccount } from "@shared/api/hooks/account";
import { useMutateAccount } from "@shared/api/hooks/account";
import { useRouter } from "next/router";

import { PartnerStyles } from "./partner-styles";

interface PartnerContextData {
  partner: Partner;
  isConsentRequired: boolean;
}

export const PartnerContext = createContext<PartnerContextData | undefined>(
  undefined
);

// TODO: remove this to use the Partners from constants when merged
export type Partner =
  | "eqb"
  | "ferique"
  | "fhg"
  | "ia"
  | "myabode"
  | "nesto"
  | "optimize"
  | "quadrus";

export const PartnerProvider = ({
  children,
  partnerSSR,
}: PropsWithChildren<{ partnerSSR: Partner }>) => {
  const { isAuthenticated } = useAuth0();
  const authToken = getAuthToken();
  const { data: account } = useGetAccount(
    undefined,
    isAuthenticated && !!authToken
  );
  const { mutate: updateAccount } = useMutateAccount();
  const { query } = useRouter();
  const [partner, setPartner] = useState<Partner>(partnerSSR);

  // TODO: Note this effect to override the partner with the account partner
  // would be deprecated and removed in the future, when we get the partner from the selected application
  useEffect(() => {
    if (account && query?.partner && query.partner !== account.partner) {
      updateAccount({
        ...account,
        partner: query.partner as Partner,
      });
      setPartner(query.partner as Partner);
    }

    if (account && !query?.partner) {
      setPartner((account.partner as Partner) || partnerSSR);
    }
  }, [query.partner, account, updateAccount, partnerSSR]);

  const getIsConsentRequired = (partner: Partner) => {
    return ["ferique", "fhg"].includes(partner);
  };

  return (
    <PartnerContext.Provider
      value={{
        partner,
        isConsentRequired: getIsConsentRequired(partner),
      }}
    >
      <PartnerStyles partnerSSR={partner} />
      {children}
    </PartnerContext.Provider>
  );
};
