import React from "react";
import styles from "./progress-bar.module.scss";

export interface ProgressBarProps {
  value: number;
}

export const ProgressBar = ({ value }: ProgressBarProps) => {
  return (
    <div className={styles["progress-bar"]}>
      <div
        className={styles["progress-bar--completed"]}
        style={{ width: `${value}%` }}
      />
    </div>
  );
};
