import { Flex, Spinner as SpinnerUI } from "@nestoca/ui";
import React from "react";

import styles from "./spinner.module.scss";

export const Spinner = () => {
  return (
    <Flex align="center" justify="center" className={styles.spinner}>
      <SpinnerUI size="4rem" />
    </Flex>
  );
};
