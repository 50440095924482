import { useEffect, useRef } from "react";

import { datadogRum } from "@datadog/browser-rum";

import { DEFAULT_REPLAY_SAMPLE_RATE, DEFAULT_SAMPLE_RATE } from "../constants";

import { useDatadogUser } from "./use-datadog-user";

import type { User } from "../types";
import type { RumInitConfiguration } from "@datadog/browser-rum";

interface UseDatadogProps
  extends Omit<
    RumInitConfiguration,
    | "enableExperimentalFeatures"
    | "replica"
    // remove deprecated so we dont start usgin them
    | "proxyUrl"
    | "premiumSampleRate"
    | "allowedTracingOrigins"
    | "tracingSampleRate"
    | "replaySampleRate"
    | "sampleRate"
    | "trackInteractions"
  > {
  readonly enabled?: boolean;
  readonly sessionReplayRecording?: boolean;
  readonly user?: User;
}

export const useDatadog = ({
  user,
  enabled,
  sessionReplayRecording,
  sessionReplaySampleRate = DEFAULT_REPLAY_SAMPLE_RATE,
  sessionSampleRate = DEFAULT_SAMPLE_RATE,
  ...rest
}: UseDatadogProps) => {
  const isInitialized = useRef(false);

  useEffect(() => {
    if (!enabled || isInitialized.current) {
      return;
    }

    isInitialized.current = true;

    datadogRum.init({
      sessionReplaySampleRate,
      sessionSampleRate,
      enableExperimentalFeatures: ["feature_flags"],
      ...rest,
    });
  }, [
    enabled,
    sessionReplaySampleRate,
    sessionSampleRate,
    rest,
    isInitialized,
  ]);

  useEffect((): VoidFunction | undefined => {
    if (!enabled || !sessionReplayRecording) {
      return;
    }

    datadogRum.startSessionReplayRecording();
    return (): void => {
      datadogRum.stopSessionReplayRecording();
    };
  }, [enabled, sessionReplayRecording]);

  useDatadogUser(user);
};
