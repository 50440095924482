import { PropsWithChildren } from "react";

import { Box, ariaAttr } from "@nestoca/ui";
import clsx from "clsx";

import styles from "./lockable.module.scss";

interface LockableProps {
  locked?: boolean;
}

export const Lockable = ({
  locked = false,
  children,
}: PropsWithChildren<LockableProps>) => {
  return (
    <Box className={clsx(styles["lockable"])} aria-disabled={ariaAttr(locked)}>
      {children}
    </Box>
  );
};
