import { DocumentCategoryEnum } from "./category.enum";
import { DocumentState } from "./document.types";
import { DocumentStateEnum, DocumentStatusEnum } from "./state.enum";
import { DocumentStep } from "./step.enum";

export const DOCUMENT_STEP_LABELS = {
  [DocumentStep.All]: "form:documents.type.all",
  [DocumentStep.Urgent]: "form:documents.type.urgent",
  [DocumentStep.Additional]: "form:documents.type.additional",
};

// This enum is returing numbers, which we can't used in a select component
// since html `<form />` values are strings. So we need to convert the enum to a string
export const DOCUMENTS_TYPE_OPTIONS = [
  {
    value: `${DocumentStep.All}`,
    label: DOCUMENT_STEP_LABELS[DocumentStep.All],
  },
  {
    value: `${DocumentStep.Urgent}`,
    label: DOCUMENT_STEP_LABELS[DocumentStep.Urgent],
  },
  {
    value: `${DocumentStep.Additional}`,
    label: DOCUMENT_STEP_LABELS[DocumentStep.Additional],
  },
];

export const DOCUMENT_SECTION_LABELS = {
  [DocumentCategoryEnum.ALL]: "docs:documents.category.all",
  [DocumentCategoryEnum.INCOMES]: "docs:documents.category.incomes",
  [DocumentCategoryEnum.IDENTIFICATION]:
    "docs:documents.category.identification",
  [DocumentCategoryEnum.PROPERTIES]: "docs:documents.category.properties",
  [DocumentCategoryEnum.FINAL_DOCUMENTS]:
    "docs:documents.category.finalDocuments",
  [DocumentCategoryEnum.FINANCIALS]: "docs:documents.category.financials",
  [DocumentCategoryEnum.LOW_RATE_GUARANTEE]:
    "docs:documents.category.lowRateGuarantee",
  [DocumentCategoryEnum.OTHER]: "docs:documents.category.other",
};

export const DOCUMENTS_SECTION_OPTIONS = [
  {
    value: DocumentCategoryEnum.ALL,
    label: DOCUMENT_SECTION_LABELS[DocumentCategoryEnum.ALL],
  },
  {
    value: DocumentCategoryEnum.INCOMES,
    label: DOCUMENT_SECTION_LABELS[DocumentCategoryEnum.INCOMES],
  },
  {
    value: DocumentCategoryEnum.IDENTIFICATION,
    label: DOCUMENT_SECTION_LABELS[DocumentCategoryEnum.IDENTIFICATION],
  },
  {
    value: DocumentCategoryEnum.PROPERTIES,
    label: DOCUMENT_SECTION_LABELS[DocumentCategoryEnum.PROPERTIES],
  },
  {
    value: DocumentCategoryEnum.FINAL_DOCUMENTS,
    label: DOCUMENT_SECTION_LABELS[DocumentCategoryEnum.FINAL_DOCUMENTS],
  },
  {
    value: DocumentCategoryEnum.FINANCIALS,
    label: DOCUMENT_SECTION_LABELS[DocumentCategoryEnum.FINANCIALS],
  },
  {
    value: DocumentCategoryEnum.LOW_RATE_GUARANTEE,
    label: DOCUMENT_SECTION_LABELS[DocumentCategoryEnum.LOW_RATE_GUARANTEE],
  },
  {
    value: DocumentCategoryEnum.OTHER,
    label: DOCUMENT_SECTION_LABELS[DocumentCategoryEnum.OTHER],
  },
];

/**
 * This is the label for the document state for the customer
 * it shows the same text state for both broker approved and lender accepted
 */
export const DOCUMENT_STATE_LABELS_CUSTOMER: Record<DocumentState, string> = {
  ALL: "form:documents.type.all",
  DOCUMENT_EMPTY: "form:documents.state.empty",
  DOCUMENT_RECEIVED: "form:documents.state.received",
  DOCUMENT_BROKER_UNAPPROVED: "form:documents.state.unapproved",
  DOCUMENT_DISABLED: "form:documents.state.disabled",
  // Show same label for both states
  DOCUMENT_BROKER_APPROVED: "form:documents.state.approved",
  DOCUMENT_LENDER_ACCEPTED: "form:documents.state.approved",
};

export const DOCUMENTS_STATE_OPTIONS = [
  {
    value: DocumentStatusEnum.ALL,
    label: "form:documents.type.all",
  },
  {
    value: DocumentStatusEnum.OUTSTANDING,
    label: "form:documents.state.empty",
  },
  {
    value: DocumentStatusEnum.RECEIVED,
    label: "form:documents.state.received",
  },
  {
    value: DocumentStatusEnum.UNAPPROVED,
    label: "form:documents.state.unapproved",
  },
  {
    value: DocumentStatusEnum.APPROVED,
    label: "form:documents.state.approved",
  },
];

export const DOCUMENT_STATES_MAP: Record<
  DocumentStatusEnum,
  DocumentStateEnum[]
> = {
  [DocumentStatusEnum.ALL]: [DocumentStateEnum.ALL],
  [DocumentStatusEnum.OUTSTANDING]: [DocumentStateEnum.DOCUMENT_EMPTY],
  [DocumentStatusEnum.RECEIVED]: [DocumentStateEnum.DOCUMENT_RECEIVED],
  [DocumentStatusEnum.UNAPPROVED]: [
    DocumentStateEnum.DOCUMENT_BROKER_UNAPPROVED,
  ],
  [DocumentStatusEnum.APPROVED]: [
    DocumentStateEnum.DOCUMENT_APPROVED,
    DocumentStateEnum.LENDER_APPROVED,
    DocumentStateEnum.DOCUMENT_BROKER_APPROVED,
    DocumentStateEnum.DOCUMENT_LENDER_ACCEPTED,
  ],
};
