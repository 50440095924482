import { useMemo } from "react";

import { TransactionTypeEnum } from "@shared/constants";
import { useTranslation } from "react-i18next";

import type { ChipFilter } from "@components/chip-filters";
import type { Application } from "@shared/constants";

interface FilterWithCount extends ChipFilter<TransactionTypeEnum> {
  count: number;
}

export const useApplicationsFilters = (applications: Application[]) => {
  const { t } = useTranslation("common");

  const filters = useMemo(() => {
    // All the possible filters
    const filters: FilterWithCount[] = [
      {
        label: `transactionType.NEW`,
        value: TransactionTypeEnum.NEW,
        count: 0,
      },
      {
        label: `transactionType.CONSTRUCTION_LOAN`,
        value: TransactionTypeEnum.CONSTRUCTION_LOAN,
        count: 0,
      },
      {
        label: `transactionType.PORT`,
        value: TransactionTypeEnum.PORT,
        count: 0,
      },
      {
        label: `transactionType.ASSUMPTION`,
        value: TransactionTypeEnum.ASSUMPTION,
        count: 0,
      },
      {
        label: `transactionType.PRE_APPROVAL`,
        value: TransactionTypeEnum.PRE_APPROVAL,
        count: 0,
      },
      {
        label: `transactionType.REFINANCE`,
        value: TransactionTypeEnum.REFINANCE,
        count: 0,
      },
      {
        label: `transactionType.READVANCE`,
        value: TransactionTypeEnum.READVANCE,
        count: 0,
      },
      {
        label: `transactionType.CHANGE_OF_COVENANT`,
        value: TransactionTypeEnum.CHANGE_OF_COVENANT,
        count: 0,
      },
      {
        label: `transactionType.RENEWAL`,
        value: TransactionTypeEnum.RENEWAL,
        count: 0,
      },
      {
        label: `transactionType.TRANSFER`,
        value: TransactionTypeEnum.TRANSFER,
        count: 0,
      },
      {
        label: `transactionType.PURCHASE_WITH_IMPROVEMENT`,
        value: TransactionTypeEnum.PURCHASE_WITH_IMPROVEMENT,
        count: 0,
      },
      {
        label: `transactionType.BRIDGE`,
        value: TransactionTypeEnum.BRIDGE,
        count: 0,
      },
    ];

    // counter for each application type
    const defaultCounter = {
      [TransactionTypeEnum.NEW]: 0,
      [TransactionTypeEnum.CONSTRUCTION_LOAN]: 0,
      [TransactionTypeEnum.PORT]: 0,
      [TransactionTypeEnum.ASSUMPTION]: 0,
      [TransactionTypeEnum.PRE_APPROVAL]: 0,
      [TransactionTypeEnum.REFINANCE]: 0,
      [TransactionTypeEnum.READVANCE]: 0,
      [TransactionTypeEnum.CHANGE_OF_COVENANT]: 0,
      [TransactionTypeEnum.RENEWAL]: 0,
      [TransactionTypeEnum.TRANSFER]: 0,
      [TransactionTypeEnum.PURCHASE_WITH_IMPROVEMENT]: 0,
      [TransactionTypeEnum.BRIDGE]: 0,
    } as Record<TransactionTypeEnum, number>;

    const applicationTypeCounter =
      applications?.reduce((acc, application) => {
        if (acc[application.type]) {
          acc[application.type] += 1;
        } else {
          acc[application.type] = 1;
        }
        return acc;
      }, defaultCounter) || defaultCounter;

    // only show the filter if there is at least one application of that type
    return filters
      .filter((filter) => applicationTypeCounter[filter.value] > 0)
      .map((filter) => ({
        ...filter,
        // Add counter to the label
        label: `${t(filter.label, {
          count: applicationTypeCounter[filter.value],
        })} (${applicationTypeCounter[filter.value]})`,
        count: applicationTypeCounter[filter.value],
      }));
  }, [applications, t]);

  return filters;
};
