import {
  Applicant,
  Application,
  ApplicationSteps,
  RegisteredAddress,
} from "@shared/constants";
import { getApplicationTypeByTransactionType } from "@shared/utils";
import {
  isApplicantValid,
  isBankingDetailsValid,
  isRegisteredAddressValid,
} from "@shared/utils";

import {
  getDownPaymentAmount,
  isApplicationComplete,
  isDownPaymentValid,
} from "./application";
import { isIncomeValid } from "./employment";
import { isTargetPropertyComplete } from "./property";

/**
 * Returns the sum of years and months for which we have registered addresses.
 * This is used to check if we have enough address history to proceed with the application.
 *
 * @param addresses the list of registered addresses to check
 * @returns the number of years and months for which we have registered addresses
 */
export const getSumYearsMonths = (addresses: RegisteredAddress[]) => {
  const years = addresses
    ? addresses.reduce((acc, { occupiedYears = 0 }) => acc + occupiedYears, 0)
    : 0;

  const months = addresses
    ? addresses.reduce((acc, { occupiedMonths = 0 }) => acc + occupiedMonths, 0)
    : 0;

  return formatYearsMonths(years, months);
};

export const formatYearsMonths = (
  enteredYears: number,
  enteredMonths: number
): { years: number; months: number } => {
  const accMonths = enteredYears * 12 + enteredMonths;
  return {
    years: parseInt((accMonths / 12).toString()),
    months: accMonths % 12,
  };
};

/**
 * Takes an application and its main applicant and returns an object containing
 * all application steps and their completion status.
 *
 * @param application {Application} the application to check
 * @param mainApplicant {Applicant} the application's main applicant
 */
export const getValidSteps = ({
  application,
  mainApplicant,
}: {
  application: Application;
  mainApplicant: Applicant;
}): ApplicationSteps => {
  return {
    applicantInformation: isApplicantValid(mainApplicant),
    registeredAddress: isRegisteredAddressValid(mainApplicant.addresses),
    subjectProperty: isTargetPropertyComplete(application),
    income: isIncomeValid(mainApplicant.income.employments),
    otherIncome: mainApplicant.otherIncomesSpecified,
    otherProperties: mainApplicant.propertiesSpecified,
    downpayment: isDownPaymentValid(
      getApplicationTypeByTransactionType(application.type),
      getDownPaymentAmount(application.applicants),
      application.property || application.propertyToPurchase
    ),
    bankingDetails: isBankingDetailsValid(mainApplicant),
  };
};

/**
 * Takes an application and returns a percentage based on the total number of
 * completed steps.
 *
 * In the website, this function also returns the total number of steps, total
 * number of completed steps, and number of missing steps. I feel like there
 * are better ways to get this information, but we'll have to see once we start the new application.
 *
 * @param application the application to check
 */
export const getProgressValue = (application: Application): number => {
  if (isApplicationComplete(application)) return 1;

  const { applicants, mainApplicantId } = application;

  const mainApplicant = applicants[mainApplicantId];
  const validSteps = getValidSteps({ application, mainApplicant });

  const steps = Object.entries(validSteps);

  /**
   * 100% progress is only allowed when application is submitted.
   * We add +1 here to ensure that the progress bar won't reach 100% before submission.
   */
  const totalSteps = steps.length + 1;

  const progress = steps.filter(([, stepValue]) => stepValue).length;

  const percentage = Math.floor((progress / totalSteps) * 100);

  return percentage;
};
