import { forwardRef } from "react";

import * as ScrollArea from "@radix-ui/react-scroll-area";
import clsx from "clsx";

import styles from "./scroll-area.module.scss";

export const ScrollAreaRoot = forwardRef<
  HTMLDivElement,
  ScrollArea.ScrollAreaProps
>(({ className, type = "scroll", ...rest }, ref) => (
  <ScrollArea.Root
    ref={ref}
    className={clsx(styles.ScrollAreaRoot, className)}
    type={type}
    {...rest}
  />
));

export const ScrollAreaViewport = forwardRef<
  HTMLDivElement,
  ScrollArea.ScrollAreaViewportProps
>(({ className, ...rest }, ref) => (
  <ScrollArea.Viewport
    ref={ref}
    className={clsx(styles.ScrollAreaViewport, className)}
    {...rest}
  />
));

export const ScrollAreaScrollbar = forwardRef<
  HTMLDivElement,
  ScrollArea.ScrollAreaScrollbarProps
>(({ className, ...rest }, ref) => (
  <ScrollArea.Scrollbar
    ref={ref}
    className={clsx(styles.ScrollAreaScrollbar, className)}
    {...rest}
  />
));

export const ScrollAreaThumb = forwardRef<
  HTMLDivElement,
  ScrollArea.ScrollAreaThumbProps
>(({ className, ...rest }, ref) => (
  <ScrollArea.Thumb
    ref={ref}
    className={clsx(styles.ScrollAreaThumb, className)}
    {...rest}
  />
));
