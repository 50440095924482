import { useCallback, useEffect, useState } from "react";

import { EventPayload } from "@shared/analytics";
import {
  INACTIVITY_TIMEOUT_MIN,
  PROMPT_BEFORE_LOGOUT_MIN,
} from "@shared/constants";
import { useIdleTimer } from "react-idle-timer";

import { IdleModal } from "../modals";

import type { LogoutOptions } from "@auth0/auth0-react";

interface SessionIdlerProps {
  timeout?: number;
  track?: (eventPayload: EventPayload) => void;
  logout: (options?: LogoutOptions | undefined) => void;
}

const defaultTimeout = INACTIVITY_TIMEOUT_MIN();
const promptBeforeIdle = PROMPT_BEFORE_LOGOUT_MIN();

export const IdleTimeout = ({
  timeout = defaultTimeout,
  track,
  logout,
}: SessionIdlerProps) => {
  const [remainingTime, setRemainingTime] = useState<number | string>(timeout);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLogout = useCallback(() => {
    track?.({ event: "logout" });
    logout({
      logoutParams: {
        /**
         * TODO: This is temporary for a hotfix to prod. We need to figure out a better way to customize this.
         * Maybe an env variable?
         */
        returnTo: `${window.location.origin}/`,
        federated: true,
      },
    });
  }, [logout, track]);

  const onIdle = () => {
    setIsModalOpen(false);
    handleLogout();
  };

  const onActive = () => {
    setIsModalOpen(false);
  };

  const onPrompt = () => {
    setIsModalOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
      "focus",
    ],
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const remainingTimeInSeconds = Math.ceil(getRemainingTime() / 1000);

      // calculate minutes and seconds from remaining time
      const minutes = Math.floor(remainingTimeInSeconds / 60);
      const seconds = remainingTimeInSeconds % 60;

      // format the time as "mm:ss"
      const formattedTime = `${String(minutes).padStart(2, "0")}:${String(
        seconds
      ).padStart(2, "0")}`;

      setRemainingTime(formattedTime);
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime]);

  // resets timer to initial state
  const handleStillHere = () => {
    activate();
    setIsModalOpen(false);
  };

  return (
    <IdleModal
      visible={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      handleLogout={handleLogout}
      handleStillHere={handleStillHere}
      remainingTime={remainingTime}
    />
  );
};
