import { nanoid } from "nanoid";

const localStoageKey = "lcdClientKey";

// Old website PR reference https://github.com/nestoca/website/pull/574/files
// TODO when implementing LaunchDarkly epic, we'll need to save this into cookie instead to read the value on the server for `getServerSideProps`
// https://www.npmjs.com/package/@launchdarkly/node-server-sdk
// sent init LD init({user: {key: 'user-key'}}) // look like some legacy init not supported anymore by LD
export const getUserKey = (): string => {
  const { localStorage } = window;
  const key = localStorage.getItem(localStoageKey);

  if (key) return key;

  // 36 characters long as thats the longest legnth the BE supports
  // the longer the length the lower the probability of the collision
  const uuid = nanoid(36);
  localStorage.setItem(localStoageKey, uuid);

  return uuid;
};
