import { api } from "@shared/api/client";
import { ServicingAsset } from "@shared/constants";
import {
  AccountPostingsTransactionsSearchPayload,
  AccountPostingsTransactionsSearchResults,
  HighlightsResult,
  Component,
  ComponentPayload,
  MinimalComponentsRecordsSearchPayload,
  MinimalComponentsRecordsSearchResults,
  StatementsSearchPayload,
  StatementsSearchResults,
} from "@shared/constants/mortgages-servicing";
import { serializeObjectArrayValues } from "@shared/utils";

/**
 * This function gets the minimal components records search results
 * @param payload Payload used as query params
 * @returns The minimal components records search results
 */
export const getMinimalComponentsRecordsSearchResults = async (
  payload: MinimalComponentsRecordsSearchPayload | undefined,
  { signal }: { signal?: AbortSignal } = {}
) => {
  const { orderBy, ...rest } = payload || {};

  // Since the API expects a string we need to join the arrays
  const queryParams = serializeObjectArrayValues<
    Omit<MinimalComponentsRecordsSearchPayload, "orderBy">
  >(rest || {});

  const { data } = await api.get<MinimalComponentsRecordsSearchResults>(
    "/components/search",
    {
      params: { ...queryParams, orderBy },
      signal,
    }
  );

  return data;
};

/**
 * This function gets highlights result
 * @returns The highlights result
 */
export const getHighlightsResult = async ({
  signal,
}: {
  signal: AbortSignal;
}) => {
  const { data } = await api.get<HighlightsResult>(`/components/highlights`, {
    signal,
  });

  return data;
};

/**
 * This function gets the account postings transactions search results
 * @param payload Payload used as query/path params
 * @returns The account postings transactions search results
 */
export const getAccountPostingsTransactionsSearchResults = async (
  payload: AccountPostingsTransactionsSearchPayload,
  { signal }: { signal?: AbortSignal } = {}
) => {
  const { referenceNumber, ...rest } = payload;

  // Since the API expects a string we need to join the arrays
  const queryParams =
    serializeObjectArrayValues<
      Omit<AccountPostingsTransactionsSearchPayload, "referenceNumber">
    >(rest);

  const { data } = await api.get<AccountPostingsTransactionsSearchResults>(
    `/components/byreferencenumber/${referenceNumber}/transactions`,
    { params: queryParams, signal }
  );

  return data;
};

/**
 * This function gets the statements search results
 * @param payload Payload used as query/path params
 * @returns The statements search results
 */
export const getStatementsSearchResults = async (
  payload: StatementsSearchPayload,
  { signal }: { signal?: AbortSignal } = {}
) => {
  const { referenceNumber, ...rest } = payload;

  // Since the API expects a string we need to join the arrays
  const queryParams =
    serializeObjectArrayValues<
      Omit<StatementsSearchPayload, "referenceNumber">
    >(rest);

  const { data } = await api.get<StatementsSearchResults>(
    `/components/byreferencenumber/${referenceNumber}/statements`,
    { params: queryParams, signal }
  );

  return data;
};

/**
 * This function gets a single component
 * @param payload Payload used as path params
 * @returns A single component
 */
export const getComponent = async (
  payload: ComponentPayload,
  { signal }: { signal?: AbortSignal } = {}
) => {
  const { referenceNumber } = payload;

  const { data } = await api.get<Component>(
    `/components/byreferencenumber/${referenceNumber}`,
    { signal }
  );

  return data;
};

export const getServicingAssets = async () => {
  const { data } = await api.get<ServicingAsset[] | null>(
    "/servicingportalgateway/assets"
  );

  return data;
};
