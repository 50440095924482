import { PropsWithChildren } from "react";

import {
  FormElement,
  Select,
  ErrorMessage,
  CurrencyInput,
  Grid,
  SelectOptionProps,
} from "@nestoca/ui";
import clsx from "clsx";

import styles from "./amount-currency.module.scss";

export interface AmountCurrencyInputProps {
  value: string | number;
  onChange: (inputValue: string | number) => unknown;
  name: string;
  placeholder?: string;
  isInvalid?: boolean;
  errorMessage?: string;
}

export interface AmountCurrencySelectProps extends AmountCurrencyInputProps {
  options: SelectOptionProps[];
}

export interface AmountCurrencyWrapperProps {
  className?: string;
}

export const AmountCurrencyInput = ({
  value,
  onChange,
  name,
  placeholder,
  isInvalid,
  errorMessage,
}: AmountCurrencyInputProps) => (
  <FormElement isInvalid={isInvalid}>
    <CurrencyInput
      className={styles.input}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      name={name}
    />
    {isInvalid && <ErrorMessage>{errorMessage}</ErrorMessage>}
  </FormElement>
);

export const AmountCurrencySelect = ({
  options,
  value,
  onChange,
  name,
  placeholder,
  isInvalid,
  errorMessage,
}: AmountCurrencySelectProps) => (
  <FormElement isInvalid={isInvalid}>
    <Select
      placeholder={placeholder}
      name={name}
      options={options}
      value={value}
      onChange={onChange}
    />
    {isInvalid && <ErrorMessage>{errorMessage}</ErrorMessage>}
  </FormElement>
);

export const AmountCurrencyWrapper = ({
  children,
  className,
}: PropsWithChildren<AmountCurrencyWrapperProps>) => (
  <Grid className={clsx(className, styles.wrapper)} gap={2}>
    {children}
  </Grid>
);

export const AmountCurrency = Object.assign(AmountCurrencyWrapper, {
  Input: AmountCurrencyInput,
  Select: AmountCurrencySelect,
});
