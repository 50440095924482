import { useState } from "react";

import { Box, ButtonIcon, Flex } from "@nestoca/ui";
import { MdClose } from "react-icons/md";

import { NavbarLogo } from "../navbar-logo";

import styles from "./burger-menu.module.scss";
import { HomeBurgerMenu } from "./home";
import { Settings } from "./settings/settings";

export type BurgerMenuPageType = "HOME" | "SETTINGS";

export const BurgerMenu = ({
  setMenuOpen,
}: {
  setMenuOpen: (menuOpen: boolean) => void;
}) => {
  const [menuPage, setMenuPage] = useState<BurgerMenuPageType>("HOME");

  return (
    <Box as="nav" className={styles["burger-menu"]} data-testid="burger-menu">
      <Flex
        className={styles["burger-menu__header"]}
        direction="row"
        align="center"
        justify="between"
      >
        <NavbarLogo />
        <ButtonIcon
          variant="ghost"
          icon={<MdClose size={26} />}
          aria-label="close burger menu"
          onClick={() => setMenuOpen(false)}
          size="medium"
          data-testid="close-burger-menu"
          data-dd-action-name="close burger menu"
          className={styles["burger-menu__close-button"]}
          isRound={true}
        />
      </Flex>
      <Box className={styles["burger-menu__content"]}>
        <BurgerMenuPage
          menuPage={menuPage}
          setMenuPage={setMenuPage}
          setMenuOpen={setMenuOpen}
        />
      </Box>
    </Box>
  );
};

const BurgerMenuPage = ({
  menuPage,
  setMenuPage,
  setMenuOpen,
}: {
  menuPage: BurgerMenuPageType;
  setMenuPage: (modal: BurgerMenuPageType) => void;
  setMenuOpen: (menuOpen: boolean) => void;
}) => {
  switch (menuPage) {
    case "HOME":
      return (
        <HomeBurgerMenu setMenuOpen={setMenuOpen} setMenuPage={setMenuPage} />
      );

    case "SETTINGS":
      return <Settings setMenuOpen={setMenuOpen} setMenuPage={setMenuPage} />;

    default:
      return (
        <HomeBurgerMenu setMenuOpen={setMenuOpen} setMenuPage={setMenuPage} />
      );
  }
};
