import { ReactNode } from "react";

import { Flex, useBreakpointValue } from "@nestoca/ui";
import clsx from "clsx";

import styles from "./delete-account-wrapper.module.scss";
export const DeleteAccountWrapper = ({ children }: { children: ReactNode }) => {
  const isMobile = useBreakpointValue({ default: true, md: false });

  return (
    <Flex
      justify="center"
      className={clsx(styles["delete-page"], {
        [styles["delete-page--mobile"]]: isMobile,
      })}
    >
      <Flex className={styles["confirmation-wrapper"]}>{children}</Flex>
    </Flex>
  );
};
