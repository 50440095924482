import { forwardRef } from "react";
import type { PropsWithChildren } from "react";

import {
  PolymorphicComponentPropsWithRef,
  PolymorphicRef,
} from "../../types/polymorhphic-component-types";

export interface _DatadogPrivacyProps {
  readonly level: "allow" | "hidden" | "mask-user-input" | "mask";
}

export type DatadogPrivacyProps<C extends React.ElementType> =
  PolymorphicComponentPropsWithRef<C, PropsWithChildren<_DatadogPrivacyProps>>;

type DatadogPrivacyComponent = <C extends React.ElementType = "span">(
  props: DatadogPrivacyProps<C>
) => React.ReactElement | null;

export const DatadogPrivacy: DatadogPrivacyComponent = forwardRef(
  <C extends React.ElementType = "span">(
    {
      as: Component = "span",
      children,
      level,
      className,
      ...rest
    }: DatadogPrivacyProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    return (
      <Component
        {...rest}
        ref={ref}
        className={`dd-privacy-${level}${className ? ` ${className}` : ""}`}
        data-dd-privacy={level}
      >
        {children}
      </Component>
    );
  }
);
