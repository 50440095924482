import { FieldError } from "react-hook-form";

import { AmountFrequencyError } from "../application";

export const isAmountFrequencyErrors = (
  rentalIncomeErr: FieldError | AmountFrequencyError | undefined
): rentalIncomeErr is AmountFrequencyError => {
  return (
    (rentalIncomeErr &&
      ("amount" in rentalIncomeErr || "frequency" in rentalIncomeErr)) ||
    false
  );
};
