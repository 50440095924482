import { z } from "zod";

import { getAsNumber, isEmpty } from "../../utils";

export const amountSchema = z
  .preprocess(
    (v) => (isEmpty(v) ? null : getAsNumber(v)),
    z
      .number({
        required_error: "form:error.required",
        invalid_type_error: "form:error.required",
        coerce: false,
      })
      .max(9_999_999, { message: "form:error.maxDollarAmount" })
      .min(1, { message: "form:error.required" })
  )
  .refine(
    (v) => {
      // latest version 3.22.2 of zod has a bug with the preprocessor
      // so we will do the convertion here again to be sure we have the right type
      // https://github.com/colinhacks/zod/issues/2671
      const value = isEmpty(v) ? null : getAsNumber(v);

      if (!value) {
        return false;
      }

      return value <= 9_999_999;
    },
    {
      message: "form:error.maxDollarAmount",
    }
  )
  .refine(
    (v) => {
      // latest version 3.22.2 of zod has a bug with the preprocessor
      // so we will do the convertion here again to be sure we have the right type
      // https://github.com/colinhacks/zod/issues/2671
      const value = isEmpty(v) ? null : getAsNumber(v);

      if (!value) {
        return false;
      }

      return value > 0;
    },
    {
      message: "form:error.required",
    }
  );
