import { useTenantFlags } from "@nestoca/multi-tenant";
import {
  Flex,
  Typography,
  FormElement,
  ErrorMessage,
  Input,
} from "@nestoca/ui";
import { KeepI18ExprHappy } from "@shared/utils";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import styles from "../burger-menu.module.scss";

import { UserInfoField } from "./settings";

export const UserDetailsEditable = ({
  field,
  edit,
}: {
  field: UserInfoField;
  edit: boolean;
}) => {
  const { t } = useTranslation();
  const { enableEditAccountInfo } = useTenantFlags();

  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext();

  const { id, label, disabled } = field;
  const value = getValues(id);

  return (
    <>
      {edit && enableEditAccountInfo ? (
        <FormElement
          className={styles["settings__user-details-item-edit"]}
          isInvalid={!!errors[id]}
          isDisabled={disabled}
        >
          <Input
            e2ePrefix={id}
            type="text"
            placeholder={label}
            {...register(id)}
          />
          {!!errors[id] && (
            <ErrorMessage e2ePrefix={id}>
              {t(`${errors?.[id]?.message}` as KeepI18ExprHappy)}
            </ErrorMessage>
          )}
        </FormElement>
      ) : (
        <Flex
          key={id}
          direction="column"
          className={styles["settings__user-details-item"]}
        >
          <Typography size="00" weight={5}>
            {label}
          </Typography>
          <Typography size={0} weight={7}>
            {value}
          </Typography>
        </Flex>
      )}
    </>
  );
};
