import type { ComponentType } from "react";

import dynamic from "next/dynamic";

import type { SidebarLogoProps } from "./sidebar-logo-ui";

/**
 * Lazy-loaded version of the SidebarLogo component.
 * @see SidebarLogo
 * Nextjs dynamic import is used to lazy load the component.
 * we use this strategy because nextjs does create an HTML file (cached)
 * of the layout output in .next/cache directory. first time when the page is rendered.
 * TLDR: avoid layout shift with wrong tenant logo
 */
export const SidebarLogoNoSSR: ComponentType<SidebarLogoProps> = dynamic(
  () => import("./sidebar-logo-ui").then((mod) => mod.SidebarLogo),
  {
    ssr: false,
  }
);
