import { Box, Flex, Chip } from "@nestoca/ui";
import { useAnalytics } from "@shared/analytics";
import {
  useGetCoApplicants,
  useGetMainApplicant,
} from "@shared/api/hooks/applications";
import { Applicant } from "@shared/constants";
import { DatadogMask } from "@shared/datadog";
import { getApplicantName } from "@shared/utils";
import clsx from "clsx";
import Link from "next/link";
import { useTranslation } from "react-i18next";

import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from "../scroll-area";

import styles from "./applicant-selector.module.scss";

interface ApplicantSelectorTabsProps {
  applicationId: number;
  selectedApplicantId?: number;
  getApplicantHref?: (applicant: Applicant) => string;
}

const getApplicantHrefDefault =
  (applicationId: number) => (applicant: Applicant) =>
    `/applications/${applicationId}/applicant/${applicant.applicantId}`;

export const ApplicantSelectorTabs = ({
  applicationId,
  selectedApplicantId = 0,
  getApplicantHref,
}: ApplicantSelectorTabsProps) => {
  const { t } = useTranslation("common");
  const { data: mainApplicant } = useGetMainApplicant(applicationId);
  const { data: coApplicants } = useGetCoApplicants(applicationId);
  const { track } = useAnalytics();

  const mainApplicantHref = mainApplicant
    ? getApplicantHref?.(mainApplicant) ||
      getApplicantHrefDefault(applicationId)(mainApplicant)
    : "";

  const trackAnalyticsEvent = (applicantId: number) => {
    track({
      event: "switch_applicant",
      applicant_id: applicantId,
    });
  };

  return (
    <Box
      id="applicant-selector-tabs-container"
      className={clsx(styles.tabs, styles.tabs__container)}
    >
      <ScrollAreaRoot>
        <ScrollAreaViewport>
          <Box
            id="applicant-selector-tabs-wrapper"
            className={styles.tabs__wrapper}
          >
            <Flex className={styles.tabs__list}>
              {mainApplicant && (
                <Link
                  href={mainApplicantHref}
                  scroll={false}
                  onClick={() => trackAnalyticsEvent(mainApplicant.applicantId)}
                  className={clsx(styles.tab, {
                    [styles.active]:
                      mainApplicant.applicantId === selectedApplicantId,
                  })}
                  data-linkbutton
                >
                  <DatadogMask data-dd-action-name="main applicant">
                    {getApplicantName(
                      mainApplicant,
                      t("unknownName", { ns: "common" })
                    )}
                  </DatadogMask>
                  <Chip
                    label={t(
                      mainApplicant.guarantor ? "guarantor" : "mainApplicant"
                    )}
                  />
                </Link>
              )}
              {coApplicants?.map((applicant) => {
                const { applicantId, guarantor } = applicant;
                const applicantHref =
                  getApplicantHref?.(applicant) ||
                  getApplicantHrefDefault(applicationId)(applicant);
                const applicantName = getApplicantName(
                  applicant,
                  t("unknownName", { ns: "common" })
                );

                return (
                  <Link
                    key={`applicant-tab-link-${applicant.applicantId}`}
                    href={applicantHref}
                    scroll={false}
                    onClick={() => trackAnalyticsEvent(applicantId)}
                    className={clsx(styles.tab, {
                      [styles.active]: applicantId === selectedApplicantId,
                    })}
                  >
                    <DatadogMask data-dd-action-name="co-applicant">
                      {applicantName}
                    </DatadogMask>
                    <Chip label={t(guarantor ? "guarantor" : "coApplicant")} />
                  </Link>
                );
              })}
            </Flex>
          </Box>
        </ScrollAreaViewport>
        <ScrollAreaScrollbar orientation="horizontal">
          <ScrollAreaThumb />
        </ScrollAreaScrollbar>
      </ScrollAreaRoot>
    </Box>
  );
};
