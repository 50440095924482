export enum ADDRESS_SECTIONS {
  TargetProperty = "targetProperty",
  RefinanceProperty = "refinanceProperty",
  RenewingProperty = "renewingProperty",
  RegisteredAddress = "registeredAddress",
  OtherProperties = "otherProperties",
}

export enum ADDRESS_MAPPED_FIELDS {
  StreetNumber = "sN",
  Street = "s",
  Unit = "u",
  City = "c",
  StateCode = "sC",
  PostalCode = "pC",
  CountryCode = "cC",
}

export enum TargetPropertyPurposeEnum {
  OWNER_OCCUPIED = "OWNER_OCCUPIED",
  OWNER_OCCUPIED_AND_RENTAL = "OWNER_OCCUPIED_AND_RENTAL",
  RENTAL = "RENTAL",
  SECONDARY_RESIDENCE = "SECONDARY_RESIDENCE",
}

export enum TargetPropertyTypeEnum {
  DETACHED = "DETACHED",
  SEMI_DETACHED = "SEMI_DETACHED",
  DUPLEX_DETACHED = "DUPLEX_DETACHED",
  DUPLEX_SEMI_DETACHED = "DUPLEX_SEMI_DETACHED",
  ROW_HOUSING = "ROW_HOUSING",
  APARTMENT_LOW_RISE = "APARTMENT_LOW_RISE",
  APARTMENT_HIGH_RISE = "APARTMENT_HIGH_RISE",
  MOBILE = "MOBILE",
  TRI_PLEX_DETACHED = "TRI_PLEX_DETACHED",
  TRI_PLEX_SEMI_DETACHED = "TRI_PLEX_SEMI_DETACHED",
  STACKED = "STACKED",
  MODULAR_HOME_DETACHED = "MODULAR_HOME_DETACHED",
  MODULAR_HOME_SEMI_DETACHED = "MODULAR_HOME_SEMI_DETACHED",
  FOUR_PLEX_DETACHED = "FOUR_PLEX_DETACHED",
  FOUR_PLEX_SEMI_DETACHED = "FOUR_PLEX_SEMI_DETACHED",
  SEASONAL_HOME = "SeasonalHome",
}

export enum TargetPropertyStyleEnum {
  ONE_STOREY = "ONE_STOREY",
  BI_LEVEL = "BI_LEVEL",
  TWO_STORY = "TWO_STORY",
  SPLIT_LEVEL = "SPLIT_LEVEL",
  STOREY_AND_A_HALF = "STOREY_AND_A_HALF",
  THREE_STOREY = "THREE_STOREY",
  OTHER = "OTHER",
}

export enum TargetPropertyConstructionTypeEnum {
  NEW_BUILDING = "NEW_BUILDING",
  EXISTING_BUILDING = "EXISTING_BUILDING",
}

export enum TargetPropertyTenureEnum {
  FREE_HOLD = "FREE_HOLD",
  CONDO = "CONDO",
  LEASE_HOLD = "LEASE_HOLD",
}

export enum SpaceUnitsEnum {
  SQ_FEET = "SQ_FEET",
  SQ_METERS = "SQ_METERS",
}

export enum HeatingTypeEnum {
  ELECTRIC = "ELECTRIC",
  FORCED_AIR_OIL_GAS_ELECTRIC = "FORCED_AIR_OIL_GAS_ELECTRIC",
  HOT_WATER = "HOT_WATER",
  OTHER = "OTHER",
}
export enum SewageTypeEnum {
  MUNICIPAL = "MUNICIPAL",
  SEPTIC = "SEPTIC",
  HOLDING_TANK = "HOLDING_TANK",
}

export enum WaterTypeEnum {
  MUNICIPAL = "MUNICIPAL",
  WELL = "WELL",
}

export enum GarageSizeEnum {
  SINGLE = "SINGLE",
  DOUBLE = "DOUBLE",
  TRIPLE = "TRIPLE",
}

export enum GarageTypeEnum {
  DETACHED = "DETACHED",
  ATTACHED = "ATTACHED",
}

export enum CoOwnershipFeesFrequency {
  MONTHLY = "MONTHLY",
  ANNUALLY = "ANNUALLY",
  NONE = "NONE",
}

export enum FundPurposeEnum {
  HOME_IMPROVEMENT = "HOME_IMPROVEMENT",
  DEBT_CONSOLIDATION = "DEBT_CONSOLIDATION",
  INVESTMENT = "INVESTMENT",
  GIFT_TO_RELATIVE = "GIFT_TO_RELATIVE",
}
