import React, { ComponentType } from "react";

import { datadogRum } from "@datadog/browser-rum";
import { useGate } from "@nestoca/gate-react";
import { Grid, Flex, Heading } from "@nestoca/ui";
import { useTranslation } from "react-i18next";

const isServer = typeof window === "undefined";

export enum PERMISSIONS {
  ACCESS_HUB = "access:hub",
}

export const DefaultOnUnauthorized: React.ComponentType<
  UnauthorizedProps
> = () => {
  const { t } = useTranslation("common");

  return (
    <Grid
      style={{
        placeContent: "center",
        minHeight: "100dvh",
        maxWidth: "100dvw",
      }}
    >
      <Flex direction="column" align="center">
        <Heading size={4} weight={6}>
          {t("unauthorized.title")}
        </Heading>
        <Heading size={6}>{t("unauthorized.subtitle")}</Heading>
      </Flex>
    </Grid>
  );
};

export type UnauthorizedProps = {
  requiredPermissions: string[];
  permissions: string[];
};

interface WithPermissionsOptionsBase {
  permissions: string[];
  onUnauthorized?: (props: UnauthorizedProps) => void;
  fallback?: React.ComponentType<UnauthorizedProps>;
}

interface WithPermissionsOptionsOnUnauthorized
  extends WithPermissionsOptionsBase {
  onUnauthorized: (props: UnauthorizedProps) => void;
}

interface WithPermissionsOptionsFallback extends WithPermissionsOptionsBase {
  fallback?: React.ComponentType<UnauthorizedProps>;
}

export type WithPermissionsOptions =
  | WithPermissionsOptionsOnUnauthorized
  | WithPermissionsOptionsFallback;

export const withPermissions =
  ({
    permissions: requiredPermissions,
    onUnauthorized,
    fallback: Fallback,
  }: WithPermissionsOptions) =>
  <P extends object>(WrappedComponent: ComponentType<P>) => {
    const AuthorizedComponent = (props: P) => {
      const gate = useGate();

      const isAllowed = requiredPermissions.every((permission) =>
        gate.allows(permission)
      );

      if (!isAllowed && !isServer) {
        const permissions = Object.keys(gate.getPermissions());
        console.warn(
          "Unauthorized: all required permission(s) %o cannot be found in %o",
          requiredPermissions,
          permissions
        );

        const userRole = gate.getRole();

        // Send Datadog RUM event to collect metric data how many times a user is unauthorized
        datadogRum.addAction("unauthorized", {
          requiredPermissions,
          permissions,
          userRole,
        });

        if (onUnauthorized) {
          onUnauthorized({
            requiredPermissions,
            permissions,
          });

          return null;
        }

        const UnauthorizedComponent = Fallback || DefaultOnUnauthorized;

        const unauthorizedProps: UnauthorizedProps = {
          requiredPermissions,
          permissions,
        };

        return <UnauthorizedComponent {...unauthorizedProps} />;
      }

      return isAllowed ? <WrappedComponent {...props} /> : null;
    };

    return AuthorizedComponent;
  };
