import pino from "pino";

// create pino logger
const logger = pino({
  browser: {
    // *** NOTE ***
    // The nextJS Middleware will go to the browser API as well
    // Middleware currently only supports the Edge runtime.
    // The Node.js runtime can not be used.
    // https://nextjs.org/docs/app/building-your-application/rendering/edge-and-nodejs-runtimes
    // show log levels as labels instead of numbers
    // https://getpino.io/#/docs/browser?id=write-function-object
    write: {
      info: function (o) {
        const chunk = Object.assign({}, o, { level: "info" });
        console.info(JSON.stringify(chunk));
      },
      warn: function (o) {
        const chunk = Object.assign({}, o, { level: "warn" });
        console.warn(JSON.stringify(chunk));
      },
      error: function (o) {
        const chunk = Object.assign({}, o, { level: "error" });
        console.error(JSON.stringify(chunk));
      },
      fatal: function (o) {
        const chunk = Object.assign({}, o, { level: "fatal" });
        console.error(JSON.stringify(chunk));
      },
      debug: function (o) {
        const chunk = Object.assign({}, o, { level: "debug" });
        console.debug(JSON.stringify(chunk));
      },
      trace: function (o) {
        const chunk = Object.assign({}, o, { level: "trace" });
        console.trace(JSON.stringify(chunk));
      },
    },
  },
  // level can be one of these
  // LOG_LEVEL: "trace" | "debug" | "info" | "warn" | "error" | "fatal" | "silent"
  // Value: 10 | 20 | 30 | 40 | 50 | 60 | Infinity
  // @ref: https://getpino.io/#/docs/api?id=loggerlevel-string-gettersetter
  level: process.env.LOG_LEVEL || "info",
  base: {
    version: process.env.IMAGE_TAG || "latest",
    env: process.env.ENV,
    // env: process.env.NODE_ENV,
    // could be nice to have the commit sha here
    // revision: process.env.GITHUB_COMMIT_SHA,
  },
  // Formatter is call in Node.js runtime `serverSideProps` and `getStaticProps`
  // we need to use the browser write API to add the level on browser and edge (serverless / nextjs middleware)
  // https://getpino.io/#/docs/help?id=log-levels-as-labels-instead-of-numbers
  // https://benlimmer.com/2020/10/23/make-pino-log-messages-appear-with-the-correct-status-in-datadog/
  formatters: {
    level(level: string) {
      return { level };
    },
  },
});

export default logger;
