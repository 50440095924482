import { Box, Button, Flex, Modal, Typography } from "@nestoca/ui";
import { Application } from "@shared/constants";
import Link from "next/link";
import { useRouter } from "next/router";
import { Trans, useTranslation } from "react-i18next";
import { BsArrowLeft, BsArrowRight, BsPlusCircleFill } from "react-icons/bs";

import styles from "./open-applications-modal.module.scss";

type PropsType = {
  isModalOpen: boolean;
  closeModal: () => void;
  applications: Application[];
  getApplicationUrl: (application: Application) => URL | string;
};

export const OpenApplicationsModal = ({
  isModalOpen,
  closeModal,
  applications,
  getApplicationUrl,
}: PropsType) => {
  const { t } = useTranslation("applications");
  const { locale } = useRouter();
  const linkLocale = locale === "en" ? "" : `/${locale}`;

  return (
    <Modal
      visible={isModalOpen}
      className={styles.modal}
      closeOnOutsideClick
      onClose={closeModal}
    >
      <Modal.Header className={styles["modal__header"]} />
      <Modal.Body>
        <Box>
          <Typography size={3} weight={7}>
            <Trans
              t={t}
              i18nKey={"openApplicationsModal.header"}
              values={{
                applicationsCount: applications.length,
                applications: t("openApplicationsModal.applicationsCount", {
                  count: applications.length,
                }),
              }}
              components={[
                <span
                  key={0}
                  style={
                    applications.length > 1
                      ? { color: "var(--color-primary-a-500)" }
                      : {}
                  }
                />,
              ]}
            />
          </Typography>
        </Box>
        <Flex gap={3} direction="column">
          <Box>
            <Typography size={0}>
              <Trans
                t={t}
                i18nKey={"openApplicationsModal.applicationMessage"}
                count={applications.length}
                components={[<strong key={0} />]}
              />
            </Typography>
          </Box>
          <Box>
            <Typography size={0}>
              <Trans
                t={t}
                i18nKey={"openApplicationsModal.applicationsMessage"}
                components={[<strong key={0} />]}
              />
            </Typography>
          </Box>
        </Flex>
      </Modal.Body>
      <Modal.Footer>
        <Flex gap={3} className={styles.buttons}>
          {applications.length === 1 ? (
            <Link
              passHref
              href={getApplicationUrl(applications[0])}
              className={styles.button}
              legacyBehavior
            >
              <Button as="a" size="large" rightIcon={<BsArrowRight />}>
                {t("openApplicationsModal.existingApplicationButton")}
              </Button>
            </Link>
          ) : (
            <Button
              size="large"
              leftIcon={<BsArrowLeft />}
              onClick={closeModal}
              className={styles.button}
            >
              {t("openApplicationsModal.backToPortfolioButton")}
            </Button>
          )}
          <a href={`/getaquote${linkLocale}`} className={styles.button}>
            <Button
              size="large"
              variant="secondary"
              rightIcon={<BsPlusCircleFill />}
            >
              {t("createApplicationButton")}
            </Button>
          </a>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};
