import { CircleTracker, Flex, Typography } from "@nestoca/ui";
import { CIRCLE_PROGRESS_TRACKER_PHASES } from "@shared/constants";
import { KeepI18ExprHappy } from "@shared/utils";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { IoCheckmarkSharp } from "react-icons/io5";

import styles from "./tracker-phase.module.scss";

type PhaseHeaderType = {
  isActive: boolean;
  phaseNumber: number;
  phase: CIRCLE_PROGRESS_TRACKER_PHASES;
  completePercent: number | [number, number];
  totalCompletePercent: number | undefined;
  inModal?: boolean;
};

export const PhaseHeader = ({
  phaseNumber,
  phase,
  isActive,
  completePercent,
  totalCompletePercent,
  inModal = false,
}: PhaseHeaderType) => {
  const { t } = useTranslation("applications");

  // totalCompletePercent === 0 means that phase hase active state but doesn't have completed,
  // so work on this phase has started and will be displayed as "0% Complete"
  const completePercentToDisplay =
    totalCompletePercent === 0 ||
    (totalCompletePercent && totalCompletePercent < 1)
      ? `${Math.floor(totalCompletePercent * 100)}%`
      : "";

  return (
    <Flex
      gap={3}
      align="center"
      className={clsx(styles["phase__header"], {
        [styles["phase__header--modal"]]: inModal,
      })}
      data-testid={`phase-header-${phase}`}
    >
      <Flex shrink={0}>
        {typeof completePercent === "number" ? (
          <CircleTracker
            strokeColor={
              completePercent === 1
                ? "var(--color-green-500)"
                : "var(--color-primary-b-500)"
            }
            percentage={completePercent as number}
            style="single"
            size={isActive ? "medium" : "small"}
            icon={
              completePercent === 1 ? (
                <IoCheckmarkSharp color={"var(--color-green-500)"} size={20} />
              ) : undefined
            }
          />
        ) : (
          <CircleTracker
            strokeColor={[
              "var(--color-primary-b-500)",
              "var(--color-green-500)",
            ]}
            percentage={completePercent as [number, number]}
            style="double"
            size={isActive ? "medium" : "small"}
            icon={
              totalCompletePercent === 1 ? (
                <IoCheckmarkSharp color={"var(--color-green-500)"} size={20} />
              ) : undefined
            }
          />
        )}
      </Flex>
      <Flex direction="column">
        <Typography className={styles["phase__text"]}>{`${t(
          "phase"
        )} ${phaseNumber}`}</Typography>
        <Typography
          className={clsx(styles["phase__name"], {
            [styles["phase__name--open"]]: isActive,
          })}
          weight={7}
        >
          {t(`progress.stage.${phase}.name` as KeepI18ExprHappy)}
        </Typography>
        <Typography
          className={clsx(styles["phase__text"], {
            [styles["phase__text--open"]]: isActive,
          })}
        >
          {totalCompletePercent !== undefined
            ? `${completePercentToDisplay} ${t("complete")}`
            : t("notStarted")}
        </Typography>
      </Flex>
    </Flex>
  );
};
