export enum DocumentStateEnum {
  ALL = "ALL",
  DOCUMENT_EMPTY = "DOCUMENT_EMPTY",
  DOCUMENT_RECEIVED = "DOCUMENT_RECEIVED",
  DOCUMENT_BROKER_UNAPPROVED = "DOCUMENT_BROKER_UNAPPROVED",
  DOCUMENT_DISABLED = "DOCUMENT_DISABLED",
  DOCUMENT_BROKER_APPROVED = "DOCUMENT_BROKER_APPROVED",
  DOCUMENT_LENDER_ACCEPTED = "DOCUMENT_LENDER_ACCEPTED",
  // state for DOCUMENT_BROKER_APPROVED && DOCUMENT_LENDER_ACCEPTED
  DOCUMENT_APPROVED = "DOCUMENT_APPROVED",
  LENDER_APPROVED = "LENDER_APPROVED",
}

export enum DocumentStatusEnum {
  ALL = "ALL",
  OUTSTANDING = "OUTSTANDING",
  RECEIVED = "RECEIVED",
  UNAPPROVED = "UNAPPROVED",
  APPROVED = "APPROVED",
}
