import { api } from "@shared/api/client";

import type { Applicant, CoApplicantCreate } from "@shared/constants";

export const updateApplicantInfo = (
  applicationId: number,
  applicantId: number,
  params: Applicant
) =>
  api.put(`/applications/${applicationId}/applicants/${applicantId}`, params);

export const createCoApplicant = (
  applicationId: number,
  params: CoApplicantCreate
) => api.post(`/applications/${applicationId}/applicants`, params);

export const deleteCoApplicant = (applicationId: number, applicantId: number) =>
  api.delete(`/applications/${applicationId}/applicants/${applicantId}`);

export const setOtherIncomesSpecified = (
  applicationId: number,
  applicantId: number,
  params: { specified: boolean }
) =>
  api.put(
    `/applications/${applicationId}/applicants/${applicantId}/other-incomes-specified`,
    params
  );

export const setOwnedPropertiesSpecified = (
  applicationId: number,
  applicantId: number,
  params: { specified: boolean }
) =>
  api.put(
    `/applications/${applicationId}/applicants/${applicantId}/properties-specified`,
    params
  );
