import { Center, VStack, Button, Heading, Typography } from "@nestoca/ui";
import { useTranslation } from "next-i18next";

export const ErrorFallback = () => {
  const { t } = useTranslation("common");

  return (
    <Center>
      <VStack>
        <Heading size={1}>{t("boundaryError.title")}</Heading>
        <Typography>{t("boundaryError.content")}</Typography>
        <Button
          as="a"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          href="/"
          data-dd-action-name="back"
        >
          {t("boundaryError.backButton")}
        </Button>
      </VStack>
    </Center>
  );
};
