export function getCookie(name: string) {
  // run this on client side only
  if (typeof window === "undefined") {
    return;
  }

  const cookies = "; " + document.cookie;
  const cvals = cookies.split("; " + name + "=");

  if (cvals && cvals.length > 1) {
    return cvals.pop()?.split(";")[0];
  }
}

export type ParsedUtmz = {
  source: string;
  medium: string;
  campaign: string;
  content?: string;
  term?: string;
  gclid?: string;
  dclid?: string;
  channel: string;
  wbraid?: string;
  msclkid?: string;
};

const keyMap = {
  utmcsr: "source",
  utmcmd: "medium",
  utmccn: "campaign",
  utmcct: "content",
  utmctr: "term",
  utmgclid: "gclid",
  utmdclid: "dclid",
  utmwbraid: "wbraid",
  msclkid: "msclkid",
  channel: "channel",
} as const;

/**
 * Parse utmz cookie value to object
 * From: `utmcsr=(direct)|utmcmd=(none)|utmccn=(not set)|...`
 * To: `{utmcsr: "local", utmcmd: "(none)", utmccn: "(not set)", ...}`
 * @param storedVals
 * @returns Record<string, string | number>
 */
export const parseUtmzCookie = (storedVals?: string): ParsedUtmz => {
  // These are the default values from google cookies
  const values: ParsedUtmz = {
    source: "(direct)",
    medium: "(not set)",
    campaign: "(not set)",
    channel: "Direct",
  };

  // Logic coming from Greg's utm cookie replicator
  // @ref https://www.bounteous.com/insights/2017/12/18/utmz-cookie-replicator-gtm/
  (storedVals ?? "").split("|").forEach((storedValue) => {
    const [key, val] = storedValue.split("=");
    const _key = key.split(".").pop() as keyof typeof keyMap;
    values[keyMap[_key] ?? _key] = decodeURIComponent(val ?? "");
  });

  return values;
};

export const getGAClientId = () => {
  try {
    // This variable will return the full value of the cookie, e.g., GA1.1.40032303.1671533621
    const clientId = getCookie("ga4_ga");
    // remove the first 6 characters `GA1.1.`
    const gaId = clientId?.substring(6) ?? "";

    return gaId;
  } catch {
    return "";
  }
};
