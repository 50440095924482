import { useTenant } from "@nestoca/multi-tenant";
import { Flex, Typography } from "@nestoca/ui";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation, Trans } from "next-i18next";

import styles from "./privacy-phone-footer.module.scss";

export const PrivacyFooter = () => {
  const tenant = useTenant();
  const { locale } = useRouter();
  const { t } = useTranslation("common");

  return (
    <Flex direction="column" className={styles["privacy-phone-footer"]} gap={5}>
      <Flex justify="center">
        <Typography size={0}>
          <Trans
            t={t}
            i18nKey={"privacyPolicyCTA"}
            ns="common"
            components={{
              a: (
                <Link
                  href={
                    locale === "en"
                      ? tenant?.privacyPolicyURL || ""
                      : tenant?.privacyPolicyFRURL || ""
                  }
                  target="_blank"
                  className={`${styles["privacy-phone-footer__link"]} noicon`}
                />
              ),
            }}
          />
        </Typography>
      </Flex>
    </Flex>
  );
};
