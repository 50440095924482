import { useContext } from "react";

import { PartnerContext } from "./partner-provider";
import { LoadStyles } from "./theme/react";

import type { Partner } from "./partner-provider";

const mapPartnerStyles: Record<Partner, string> = {
  eqb: "/tenant/theme.eqb.css",
  ferique: "/tenant/theme.ferique.css",
  fhg: "/tenant/theme.fhg.css",
  ia: "/tenant/theme.ia.css",
  ig: "/tenant/theme.ig.css",
  myabode: "/tenant/theme.myabode.css",
  optimize: "/tenant/theme.optimize.css",
  quadrus: "/tenant/theme.quadrus.css",
  nesto: ``, // empty no override for nesto
};

// Partner is not a tenant but a co-branding experience apply over the current tenant scheme
interface PartnerStylesProps {
  partnerSSR: Partner;
}

export const PartnerStyles = ({ partnerSSR }: PartnerStylesProps) => {
  const ctx = useContext(PartnerContext);
  const href = mapPartnerStyles[ctx?.partner || partnerSSR] || "";

  if (href) {
    return <LoadStyles id={`partner`} href={href} />;
  }
  return null;
};
