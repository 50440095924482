import { useState, useRef, useEffect } from "react";

import { Button, Flex, Typography, Box } from "@nestoca/ui";
import {
  useGetApplicantType,
  useGetApplicationsByApplicantId,
} from "@shared/api/hooks/applications";
import { ApplicantTypeEnum } from "@shared/constants";
import { Contact, useApplicationDisabled } from "@shared/ui";
import {
  RemoveCoApplicantButton,
  RemoveCoApplicantModalBody,
  useGetApplicationDetails,
} from "@shared/ui";
import clsx from "clsx";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import { BsFillPersonPlusFill, BsArrowRightCircle } from "react-icons/bs";

import styles from "./application-settings-modal.module.scss";
import { ChangeApplicationModalBody } from "./change-application-modal-body";

import type { ApplicationSettingsModalProps } from "./application-settings-modal";

export const ApplicationSettingsModalBody = ({
  applicantId,
  applicationId,
  getApplicationOptions,
  onClose,
}: ApplicationSettingsModalProps & {
  applicantId: number;
}) => {
  const { t } = useTranslation("applications");

  const [modalHeight, setModalHeight] = useState<number | string>("auto");

  const [changeApplicationView, setChangeApplicationView] = useState(false);
  const [deleteCoApplicantView, setDeleteCoApplicantView] = useState(false);

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setModalHeight(modalRef.current?.clientHeight || "auto");
  }, [modalHeight]);

  const { applicationDisabled } = useApplicationDisabled(applicationId);

  const { data: selectedApplicantType } = useGetApplicantType(
    applicationId,
    applicantId
  );
  const { data: applications } = useGetApplicationsByApplicantId();

  const { addApplicantUrl, selectedAddress, noAddress } =
    useGetApplicationDetails({
      applications,
      applicationId,
    });

  if (changeApplicationView) {
    return (
      <Box style={{ height: modalHeight }}>
        <ChangeApplicationModalBody
          onClickItem={onClose}
          applicationId={applicationId}
          setChangeApplicationView={setChangeApplicationView}
          changeApplicationView={changeApplicationView}
          getApplicationOptions={getApplicationOptions}
        />
      </Box>
    );
  }

  if (deleteCoApplicantView) {
    return (
      <Box style={{ height: modalHeight }}>
        <RemoveCoApplicantModalBody
          setDeleteCoApplicantView={setDeleteCoApplicantView}
        />
      </Box>
    );
  }

  return (
    <Flex direction="column" gap={4} ref={modalRef}>
      {applications?.length !== 1 && (
        <Button
          variant="ghost"
          className={clsx(
            styles["modal__content-wrapper"],
            styles["change-application-button"]
          )}
          onClick={() => setChangeApplicationView(!changeApplicationView)}
          aria-label={`${t("change")}`}
          data-dd-action-name="change application"
        >
          <Flex direction="column" gap={1}>
            <Typography size="00" weight={5}>
              {selectedAddress ? selectedAddress : noAddress}
            </Typography>
            <Typography size={0} weight={7}>
              {t("change")}
            </Typography>
          </Flex>
          <BsArrowRightCircle />
        </Button>
      )}
      <Contact
        applicationId={applicationId}
        className={styles["modal__content-wrapper"]}
        showAsList={true}
      />
      <RemoveCoApplicantButton
        disabled={applicationDisabled}
        setDeleteCoApplicantView={() => setDeleteCoApplicantView(true)}
      />
      {/*
       * *** NOTE: Ideally we should know the applicant type of the logged in customer
       * but it is not available in the API yet. So we are using the selected applicant type
       */}
      {selectedApplicantType === ApplicantTypeEnum.MAIN_APPLICANT &&
        (applicationDisabled ? (
          <Button
            disabled={applicationDisabled}
            data-dd-action-name="add applicant"
          >
            {t("addApplicant")}
          </Button>
        ) : (
          <Link href={addApplicantUrl} passHref legacyBehavior>
            <Button
              variant="primary"
              as="a"
              data-dd-action-name="add applicant"
            >
              {t("addApplicant")}
              <BsFillPersonPlusFill />
            </Button>
          </Link>
        ))}
    </Flex>
  );
};
