import { financialTypes } from "./asset.const";
import { AssetTypeEnum, FinancialInstitutionEnum } from "./asset.enum";

import type {
  BaseAsset,
  GiftAsset,
  PropertyAsset,
  VehicleAsset,
  FinancialAsset,
  FinancialOtherAsset,
  FinancialInstitutionAsset,
  OtherAsset,
  CreateAsset,
  AssetResponse,
} from "./asset";

export const isCreateAsset = (data: Partial<BaseAsset>): data is CreateAsset =>
  !("id" in data);

export const isGiftAsset = (data: Partial<BaseAsset>): data is GiftAsset =>
  data.type === AssetTypeEnum.GIFT;

export const isPropertyAsset = (
  data: Partial<BaseAsset>
): data is PropertyAsset => data.type === AssetTypeEnum.PROPERTY;

export const isVehicleAsset = (
  data: Partial<BaseAsset>
): data is VehicleAsset => data.type === AssetTypeEnum.VEHICLE;

export const isFinancialAsset = (
  data: Partial<BaseAsset>
): data is FinancialAsset => financialTypes.includes(data.type ?? "");

export const isFinancialOtherAsset = (
  data: Partial<BaseAsset>
): data is FinancialOtherAsset =>
  isFinancialAsset(data) && data.institution === FinancialInstitutionEnum.OTHER;

export const isFinancialInstitutionAsset = (
  data: Partial<BaseAsset>
): data is FinancialInstitutionAsset =>
  isFinancialAsset(data) && data.institution !== FinancialInstitutionEnum.OTHER;

export const isOtherAsset = (data: Partial<BaseAsset>): data is OtherAsset =>
  data.type === AssetTypeEnum.OTHER;

export const isAssetResponse = (
  data: Partial<BaseAsset>
): data is AssetResponse => !isCreateAsset(data) && !("applicantId" in data);
