import { Button, CircleTracker, Flex, Typography } from "@nestoca/ui";
import { Application, CIRCLE_PROGRESS_TRACKER_PHASES } from "@shared/constants";
import { useCircleProgressTracker } from "@shared/ui";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { IoCheckmarkSharp } from "react-icons/io5";

import { ActiveModal } from "./circle-progress-tracker";
import { TrackerPhase } from "./tracker-phase";
import styles from "./tracker-phase.module.scss";

type GettingToKnowType = {
  applicantId: number;
  application: Application;
  isActive: boolean;
  setActivePhase: (activePhase: CIRCLE_PROGRESS_TRACKER_PHASES) => void;
  setActiveModal: (activeModal: ActiveModal) => void;
};
export const GettingToKnow = ({
  isActive,
  applicantId,
  application,
  setActivePhase,
  setActiveModal,
}: GettingToKnowType) => {
  const { t } = useTranslation("applications");

  const {
    getting_to_know: {
      phaseNumber,
      questionnaireCount,
      documentsCount,
      completePercent,
    },
  } = useCircleProgressTracker({
    application,
    applicantId,
  });

  return (
    <TrackerPhase
      isActive={isActive}
      completePercent={[
        questionnaireCount.completePercent,
        documentsCount.completePercent,
      ]}
      phaseNumber={phaseNumber}
      phase={CIRCLE_PROGRESS_TRACKER_PHASES.GETTING_TO_KNOW}
      setActivePhase={setActivePhase}
      totalCompletePercent={completePercent}
    >
      <Flex direction="column" className={styles["phase__body"]} gap={5}>
        <Flex gap={2} align="center" justify="center">
          <Flex
            direction="column"
            align="start"
            data-testid="questionnaires-count"
          >
            <CircleTracker
              strokeColor={
                questionnaireCount.completePercent === 1
                  ? "var(--color-green-500)"
                  : "var(--color-primary-b-500)"
              }
              percentage={questionnaireCount.completePercent as number}
              style="single"
              size="small"
              icon={
                questionnaireCount.completePercent === 1 ? (
                  <IoCheckmarkSharp
                    color={"var(--color-primary-b-500)"}
                    size={20}
                  />
                ) : undefined
              }
            />
            <Typography className={styles["phase__text--open"]}>
              {t("questionnaires")}
            </Typography>
            <Typography
              className={clsx(
                styles.numbers,
                styles["numbers--questionnaire-count"]
              )}
            >{`${questionnaireCount.completed}/${questionnaireCount.total}`}</Typography>
          </Flex>
          <Flex direction="column" align="start" data-testid="documents-count">
            <CircleTracker
              strokeColor={"var(--color-green-500)"}
              percentage={documentsCount.completePercent as number}
              style="single"
              size="small"
              icon={
                documentsCount.completePercent === 1 ? (
                  <IoCheckmarkSharp
                    color={"var(--color-green-500)"}
                    size={20}
                  />
                ) : undefined
              }
            />
            <Typography className={styles["phase__text--open"]}>
              {t("documents")}
            </Typography>
            <Typography
              className={clsx(
                styles.numbers,
                styles["numbers--documents-count"]
              )}
            >{`${documentsCount.completed}/${documentsCount.total}`}</Typography>
          </Flex>
        </Flex>
        <Button
          variant="ghost"
          onClick={() => setActiveModal("getting_to_know")}
          className={styles["phase__button"]}
          e2ePrefix="getting-to-know-details"
        >
          {t("moreDetails")}
        </Button>
      </Flex>
    </TrackerPhase>
  );
};
