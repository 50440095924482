import { api } from "@shared/api/client";
import { GeolocationResponse } from "@shared/constants";

export const getGeolocation = async ({
  signal,
}: { signal?: AbortSignal } = {}) => {
  const { data } = await api.get<GeolocationResponse>(`/geolocation/all`, {
    signal,
  });

  return data;
};
