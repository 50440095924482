import { useCallback, useState } from "react";

/**
 * This method mutates the array and returns a list of removed items
 * @param array
 * @param itemToRemoveIndex
 * @returns
 */
const removeItemsAt = <ArrayT>(
  array: ArrayT[],
  itemToRemoveIndex: number[]
) => {
  const removedItems: ArrayT[] = [];

  itemToRemoveIndex.sort(function (a, b) {
    return b - a;
  });

  for (let i = itemToRemoveIndex.length - 1; i >= 0; i--) {
    removedItems.push(array[itemToRemoveIndex[i]]);
    array.splice(itemToRemoveIndex[i], 1);
  }

  return removedItems;
};

export function useQueue<QueueItemT = unknown>(
  initialValue: QueueItemT[] = []
) {
  const [queue, setQueue] = useState<QueueItemT[]>(initialValue);

  const add = useCallback((element: QueueItemT) => {
    setQueue((q) => [...q, element]);
  }, []);

  const remove = useCallback(() => {
    let removedElement;

    setQueue(([first, ...q]) => {
      removedElement = first;
      return q;
    });

    return removedElement;
  }, []);

  const removeAt = useCallback((index: number | number[]) => {
    const indexes = Array.isArray(index) ? index : [index];
    let removedElements: QueueItemT[] = [];

    setQueue((q) => {
      const cloneCollection = [...q];

      // `removeItemsAt` mutates the array and returns a list of removed items
      removedElements = removeItemsAt(cloneCollection, indexes);

      return cloneCollection;
    });

    return removedElements;
  }, []);

  const clear = useCallback(() => {
    setQueue([]);
  }, []);

  return {
    add,
    remove,
    removeAt,
    clear,
    first: queue[0],
    last: queue[queue.length - 1],
    size: queue.length,
    queue,
  };
}
