import { useEffect, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useTenant } from "@nestoca/multi-tenant";
import { useGetAccount } from "@shared/api/hooks/account";
import { useGetApplication } from "@shared/api/hooks/applications";
import { useTypedRouter } from "@shared/utils";
import Debug from "debug";
import { z } from "zod";

import { pageView } from "./page-view";
import { getHumanMortgageType } from "./utils";

const debug = Debug("nesto:analytics");

interface UsePageViewsProps {
  gtmId?: string;
  ignoreHashChange?: boolean;
}

const schema = z.object({
  applicationId: z.coerce.number().default(0),
});

export const usePageViews = ({
  gtmId,
  ignoreHashChange,
}: UsePageViewsProps) => {
  const { query, locale, events } = useTypedRouter(schema);
  const [applicationId, setApplicationId] = useState<number | undefined>(
    query.applicationId
  );
  const tenant = useTenant();
  const { isAuthenticated } = useAuth0();
  const { data: account } = useGetAccount(undefined, isAuthenticated);
  // wait to be authenticated to get the application
  // we need the authorization header to get the application
  // reacr-query will not send the request if we have `0` as the id
  const isEnabled = isAuthenticated && applicationId;

  const { data: application } = useGetApplication({
    id: isEnabled ? applicationId : 0,
  });

  useEffect(() => {
    if (query.applicationId) {
      setApplicationId(query.applicationId);
    }
  }, [query.applicationId, setApplicationId]);

  useEffect(() => {
    const handleRouteChange = (url: URL): void => {
      const payload = {
        url: url.toString(),
        locale,
        tenant: tenant?.slug || "n/a",
        userId: account?.id || 0,
        mortgageType: application?.type
          ? getHumanMortgageType(application?.type)
          : undefined,
      };

      debug("pageView", payload);

      pageView(payload, gtmId);
    };

    events.on("routeChangeComplete", handleRouteChange);

    if (!ignoreHashChange) {
      events.on("hashChangeComplete", handleRouteChange);
    }

    return () => {
      events.off("routeChangeComplete", handleRouteChange);

      if (!ignoreHashChange) {
        events.off("hashChangeComplete", handleRouteChange);
      }
    };
  }, [
    events,
    account?.id,
    application?.type,
    gtmId,
    ignoreHashChange,
    locale,
    tenant?.slug,
  ]);
};
