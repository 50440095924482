import { useEffect } from "react";

import { Typography, Flex, Button, useBreakpointValue } from "@nestoca/ui";
import { useGetCoApplicants } from "@shared/api/hooks/applications";
import { applicantSectionsRouterSchema } from "@shared/constants";
import { useTypedRouter } from "@shared/utils";
import { Trans, useTranslation } from "react-i18next";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";

import styles from "./remove-co-applicant-modal.module.scss";
import { RemoveCoApplicantName } from "./remove-co-applicant-name";
import { RemoveCoApplicantNotification } from "./remove-co-applicant-notification";
import {
  RemoveCoApplicantNotificationProvider,
  useNotification,
} from "./remove-co-applicant-provider";

type RemoveCoApplicantProps = {
  setDeleteCoApplicantView?: (deleteCoApplicantView: boolean) => void;
};

export const RemoveCoApplicantModalBody = ({
  setDeleteCoApplicantView,
}: RemoveCoApplicantProps) => (
  <RemoveCoApplicantNotificationProvider>
    <RemoveCoApplicant setDeleteCoApplicantView={setDeleteCoApplicantView} />
  </RemoveCoApplicantNotificationProvider>
);

const RemoveCoApplicant = ({
  setDeleteCoApplicantView,
}: RemoveCoApplicantProps) => {
  const { t } = useTranslation("applications");
  const {
    query: { applicationId },
  } = useTypedRouter(applicantSectionsRouterSchema);
  const { data: coApplicants } = useGetCoApplicants(applicationId);
  const isMobile = useBreakpointValue({ default: true, md: false });

  const { notifications } = useNotification();

  useEffect(() => {
    if (!coApplicants?.length) {
      setDeleteCoApplicantView?.(false);
    }
  }, [coApplicants?.length, setDeleteCoApplicantView]);

  if (!coApplicants?.length) {
    return null;
  }

  return (
    <>
      {isMobile && (
        <Button
          size="large"
          variant="ghost"
          onClick={() => setDeleteCoApplicantView?.(false)}
          className={styles["back-button"]}
          data-dd-action-name="back"
        >
          <BsFillArrowLeftCircleFill size={24} />
          <Typography size={0} weight={7}>
            {t("back", { ns: "form" })}
          </Typography>
        </Button>
      )}
      <Flex direction="column" gap={isMobile ? 6 : 5}>
        <Flex direction="column" gap={isMobile ? 2 : 1}>
          <Typography size={1} weight={7}>
            {t("deleteApplicant.title")}
          </Typography>
          <Typography size={0}>
            <Trans
              t={t}
              components={{ bold: <strong /> }}
              i18nKey="deleteApplicant.description"
            />
          </Typography>
        </Flex>
        <Flex direction="column" gap={isMobile ? 3 : 4}>
          {notifications.map(({ applicantId, status }) => (
            <RemoveCoApplicantNotification
              key={applicantId}
              status={status}
              applicantId={applicantId}
            />
          ))}
          {coApplicants?.map(({ applicantId, firstName, lastName }) => (
            <RemoveCoApplicantName
              key={applicantId}
              applicantId={applicantId}
              firstName={firstName}
              lastName={lastName}
            />
          ))}
        </Flex>
      </Flex>
    </>
  );
};
