import { useState, useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Button, Flex, ButtonIcon } from "@nestoca/ui";
import { useBreakpointValue } from "@nestoca/ui";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { GiHamburgerMenu } from "react-icons/gi";

import { AuthenticatedHeader } from "./authenticated-navbar";
import { BurgerMenuGAQ } from "./burger-menu/burger-menu-gaq";
import { NavbarLogo } from "./navbar-logo";
import styles from "./navbar.module.scss";

export const HeaderGAQ = () => {
  const { t } = useTranslation("common");
  const { isAuthenticated } = useAuth0();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const { locale, asPath, query } = useRouter();
  const nextLocale = locale === "en" ? "fr" : "en";
  const isMobile = useBreakpointValue({ default: true, md: false });

  useEffect(() => {
    setIsClient(true);
  }, []);

  // disable scroll when menu is open
  useEffect(() => {
    const body = document.body;

    if (menuOpen) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "visible";
    }

    return () => {
      body.style.overflow = "visible";
    };
  }, [menuOpen]);

  if (!isClient) return null;

  if (isMobile) {
    return (
      <Flex
        className={styles.header}
        direction="row"
        align="center"
        justify="between"
      >
        <NavbarLogo />
        <ButtonIcon
          variant="ghost"
          aria-label="open burger menu"
          icon={<GiHamburgerMenu />}
          size="medium"
          onClick={() => setMenuOpen(true)}
          data-testid="open-burger-menu"
          data-dd-action-name="open burger menu"
          className={styles["burger-menu-button"]}
          isRound={true}
        />
        {menuOpen && <BurgerMenuGAQ setMenuOpen={setMenuOpen} />}
      </Flex>
    );
  }

  if (isAuthenticated) {
    return <AuthenticatedHeader />;
  }

  return (
    <Flex
      className={styles.header}
      direction="row"
      align="center"
      justify="between"
    >
      <NavbarLogo />
      <Flex align="center" gap={5}>
        <Link
          as={asPath}
          href={{
            pathname: asPath,
            query: { ...query },
          }}
          locale={nextLocale}
          data-testid="header-language-switch"
        >
          {nextLocale.toUpperCase()}
        </Link>
        {!isAuthenticated && (
          <Button
            as="a"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore - polymorphic prop not recognized by typescript
            href="/"
            size="medium"
            e2ePrefix="header-login"
          >
            {t("login")}
          </Button>
        )}
        {}
      </Flex>
    </Flex>
  );
};
