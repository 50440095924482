import { Auth0CLientContext } from "@nestoca/multi-tenant";

export type App = "FINANCIAL_DASHBOARD" | "DOCUMENTS" | "CMA";

export type Auth0Client = Record<App, Auth0CLientContext>;

export const AUTH0_CLIENT: Auth0Client = {
  FINANCIAL_DASHBOARD: "tenant-internal",
  DOCUMENTS: "external",
  CMA: "external",
} as const;
