import * as z from "zod";

export const schema = z.object({
  firstName: z.string().trim().min(1, { message: "form:error.required" }),
  lastName: z.string().trim().min(1, { message: "form:error.required" }),
  email: z
    .string()
    .trim()
    .email({ message: "form:error.invalidEmail" })
    .min(6, { message: "form:error.required" }),
  phone: z
    .string({ required_error: "form:error.required" })
    .trim()
    .min(10, { message: "form:error.required" })
    .max(18, { message: "form:error.invalid" }),
  preferredLanguage: z.enum(["en", "fr"]),
});

export type SettingsFormData = z.infer<typeof schema>;
