import { Modal } from "@nestoca/ui";

import { ProgressModalContent } from "./progress-modal-content";
import styles from "./progress-modal.module.scss";
import { ProgressItem } from "./progress-tracker.types";

type PropsType = {
  applicationId: number;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  stage: ProgressItem;
};

export const ProgressModal = ({
  applicationId,
  isModalOpen,
  setIsModalOpen,
  stage,
}: PropsType) => {
  return (
    <Modal
      visible={isModalOpen}
      className={styles["progress-modal"]}
      closeOnOutsideClick
      onClose={() => {
        setIsModalOpen(false);
      }}
    >
      <ProgressModalContent
        applicationId={applicationId}
        stage={stage}
        setIsModalOpen={setIsModalOpen}
      />
    </Modal>
  );
};
