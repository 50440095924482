import { createContext, useContext, forwardRef, Ref } from "react";
import type { ReactNode } from "react";

import { createCache } from "../cache";

import type { Cache } from "../cache";

const isBrowser = typeof document !== "undefined";

const CacheContext = createContext(
  typeof HTMLElement !== "undefined" ? createCache({ key: "css" }) : undefined
);

const withCacheSSR = function withCacheSSR<T, Props = unknown>(
  func: (props: Props, cache: Cache | undefined) => ReactNode
) {
  return function (props: Props) {
    let cache = useContext(CacheContext);
    if (!cache) {
      cache = createCache({ key: "css" });

      return (
        <CacheContext.Provider value={cache}>
          {func(props, cache)}
        </CacheContext.Provider>
      );
    }

    return func(props, cache);
  };
};

const withCacheClient = function withEmotionCache<T, Props = unknown>(
  func: (props: Props, cache: Cache | undefined, ref: Ref<T>) => ReactNode
) {
  return forwardRef<T, Props>((props, ref) => {
    // the cache will never be null in the browser
    let cache = useContext(CacheContext);
    if (!cache) {
      cache = createCache({ key: "css" });

      return (
        <CacheContext.Provider value={cache}>
          {func(props as Props, cache, ref)}
        </CacheContext.Provider>
      );
    }

    return func(props as Props, cache, ref);
  });
};

export const withCache = isBrowser ? withCacheClient : withCacheSSR;
