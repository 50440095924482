import { useMemo, useState } from "react";

import { Typography, useBreakpointValue, Button, Flex } from "@nestoca/ui";
import { OverflowMenu } from "@nestoca/ui";
import { useGetAccount } from "@shared/api/hooks/account";
import { useGetApplicationsByApplicantId } from "@shared/api/hooks/applications";
import { TransactionTypeEnum } from "@shared/constants";
import {
  Application,
  APP_STATUSES_MAP,
  TargetProperty,
} from "@shared/constants";
import { formatAddress } from "@shared/utils";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { BsChevronDown } from "react-icons/bs";

import { ApplicationSettingsModal } from "../application-settings-modal";

import { ApplicationAddress } from "./application-address";
import {
  ApplicationSelectorOption,
  applicationSelectorOptionType,
} from "./application-selector-option";
import styles from "./application-selector.module.scss";

type ApplicationWithIndex = Application & { index: number };

export const ApplicationSelector = ({
  applicationId,
  isSmall,
}: {
  applicationId: number;
  isSmall?: boolean;
}) => {
  const isMobile = useBreakpointValue({ default: true, md: false });
  const { data: applications, isError } = useGetApplicationsByApplicantId();
  const { data: account } = useGetAccount();

  const { t } = useTranslation("applications");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const formattedAddress = useMemo(
    () => (property: TargetProperty | undefined) => {
      return formatAddress(property?.address, "street");
    },
    []
  );

  // Reverse the array so that the most recent application is at the top
  // application order is sort in the API response by created ASC
  // so we need to reverse it to have the most recent application at the top
  // and make sure the mortgage N is always the same index and reversed
  const sortedApplications = () => {
    const items: ApplicationWithIndex[] = [];

    if (applications) {
      // group applications by type
      const groupedApplications = applications.reduce(
        (acc, application) => {
          const { type } = application;
          const key = type;

          if (!acc[key]) {
            acc[key] = [];
          }

          acc[key].push(application);

          return acc;
        },
        {} as Record<string, Application[]>
      );

      // then add the index number for this specific type
      Object.keys(groupedApplications).forEach((key) => {
        const applications = groupedApplications[key];

        for (let i = applications.length - 1; i >= 0; i--) {
          const application = applications[i] as ApplicationWithIndex;
          application.index = i + 1;
          // push the item to the array
          items.push(application);
        }
      });
    }

    return items;
  };

  const applicationsList = sortedApplications();

  const getApplicationOptions: applicationSelectorOptionType[] =
    applicationsList.map(({ id, property, applicationState, type, index }) => {
      return {
        id,
        address: formattedAddress(property),
        stage:
          APP_STATUSES_MAP[applicationState as keyof typeof APP_STATUSES_MAP],
        type: type as unknown as TransactionTypeEnum,
        index,
        applicantId: account?.id || 0,
      };
    });

  const getOptionKey = (option: applicationSelectorOptionType) => option.id;

  const Option = ({
    id,
    address,
    type,
    index,
    stage,
    applicantId,
  }: applicationSelectorOptionType) => (
    <ApplicationSelectorOption
      id={id}
      address={address}
      type={type}
      index={index}
      stage={stage}
      applicantId={applicantId}
      applicationId={applicationId}
    />
  );

  const hasOneApplication = applications?.length === 1;

  if (isError) {
    return (
      <Typography className={styles["dashboard-heading"]}>
        {t("failedToLoadApplications", { ns: "common" })}
      </Typography>
    );
  }

  if (isMobile && applications) {
    return (
      <>
        <Button
          variant="ghost"
          onClick={() => setIsModalOpen(!isModalOpen)}
          className={clsx(styles["mobile-selector-button"], {
            [styles["mobile-selector-button--small"]]: isSmall,
          })}
          aria-label={"change"}
          data-dd-action-name="address"
        >
          <Flex direction="column" align="start">
            <ApplicationAddress />
            {!isSmall && (
              <Typography size={"00"}>{t("clickForMore")}</Typography>
            )}
          </Flex>
          <BsChevronDown className={styles.shevron} />
        </Button>
        <ApplicationSettingsModal
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          applicationId={applicationId}
          applicantId={account?.id || 0}
          getApplicationOptions={getApplicationOptions}
        />
      </>
    );
  }

  return (
    <>
      {applicationsList.length > 0 && !hasOneApplication ? (
        <OverflowMenu
          className={styles.menu}
          button={ApplicationAddress}
          options={getApplicationOptions}
          getOptionKey={getOptionKey}
          renderOption={Option}
          e2ePrefix="application-selector"
        />
      ) : (
        <ApplicationAddress />
      )}
    </>
  );
};
