export const State = {
  UNKNOWN: "unknown",
  HIDDEN: "hidden",
  UNREACHED: "unreached",
  OPEN: "open",
  CLOSED: "closed",
} as const;

export const HiddenState = {
  UNKNOWN: "unknown",
  HIDDEN: "hidden",
} as const;

export const HIDDEN_STATES = Object.values(HiddenState);
