import { useMemo } from "react";

import { Typography, VStack, Accordion, HStack, Flex, Box } from "@nestoca/ui";
import {
  useGetMinimumDownPayment,
  useGetRemainingDownPayment,
  useGetIsDownPaymentViable,
} from "@shared/api/hooks/applications";
import { DownPaymentByApplicant } from "@shared/constants";
import { DatadogMask } from "@shared/datadog";
import { formatCurrency, formatPercentage } from "@shared/utils";
import { useRouter } from "next/router";
import { useTranslation, Trans } from "next-i18next";
import {
  BsCurrencyDollar,
  BsExclamationTriangleFill,
  BsFillExclamationCircleFill,
} from "react-icons/bs";

import styles from "./down-payment-banner-mobile.module.scss";

export interface DownPaymentMobileProps {
  /** Down Payment Amount */
  downPayment?: number;
  /** Percentage of the down payment 0-100 */
  percentage?: number;
  missingPurchasePrice: boolean;
  className?: string;
  downPaymentByApplicant?: DownPaymentByApplicant[];
  applicationId: number;
  purchasePrice?: number;
}

export const DownPaymentBannerMobile = ({
  downPayment = 0,
  percentage = 0,
  missingPurchasePrice = true,
  downPaymentByApplicant,
  applicationId,
  purchasePrice = 0,
}: DownPaymentMobileProps) => {
  const { locale } = useRouter();
  const { t } = useTranslation("down-payment");

  const { data: minDownPayment } = useGetMinimumDownPayment(applicationId);
  const { data: remainingDownPayment } =
    useGetRemainingDownPayment(applicationId);
  const { data: isDownPaymentViable } =
    useGetIsDownPaymentViable(applicationId);

  const variant = useMemo(() => {
    if (!isDownPaymentViable && !missingPurchasePrice) {
      return "error";
    } else if (missingPurchasePrice) {
      return "warning";
    }
    return "info";
  }, [isDownPaymentViable, missingPurchasePrice]);

  return (
    <Accordion
      label={<Label downPayment={downPayment} variant={variant} />}
      variant={variant}
      className={styles["down-payment"]}
    >
      <VStack
        gap={variant === "info" ? 0 : 5}
        align="start"
        className={styles["down-payment__container"]}
      >
        <VStack align="start" gap={2}>
          {!missingPurchasePrice && (
            <VStack align="start" gap={0}>
              <Typography size="00">
                {t("banner.percentage", { ns: "down-payment" })}
              </Typography>
              <DatadogMask
                as={Typography}
                size="0"
                weight={7}
                data-dd-action-name="down payment percentage"
              >
                {formatPercentage(percentage, { locale })}
              </DatadogMask>
            </VStack>
          )}
          <HStack gap={5} align="start" wrap="wrap">
            {downPaymentByApplicant?.map(
              ({ firstName, lastName, amount, applicantId }) => {
                return (
                  <VStack align="start" gap={0} key={applicantId}>
                    <DatadogMask
                      as={Typography}
                      size="00"
                      data-dd-action-name="applicant name"
                    >
                      {firstName} {lastName}
                    </DatadogMask>
                    <DatadogMask
                      as={Typography}
                      size="0"
                      weight={7}
                      data-dd-action-name="down payment amount by applicant"
                    >
                      {formatCurrency(amount, locale)}
                    </DatadogMask>
                  </VStack>
                );
              }
            )}
          </HStack>
        </VStack>
        <VStack align="start" gap={2}>
          {!isDownPaymentViable && !missingPurchasePrice && (
            <Trans
              t={t}
              i18nKey="downPaymentMessageInfo"
              values={{
                propertyValue: formatCurrency(purchasePrice, locale),
                minDownPayment: formatCurrency(minDownPayment, locale),
                missingDownpayment: formatCurrency(
                  remainingDownPayment,
                  locale
                ),
              }}
              components={{
                typo: <Typography as="p" size={0} weight={5} />,
                bold: <Typography as="b" size={0} weight={7} />,
                small: <Typography as="p" size="00" />,
              }}
            />
          )}
          {missingPurchasePrice && (
            <Typography as="p" size={0}>
              <Trans t={t} i18nKey="missingPurchasePriceInfo" />
            </Typography>
          )}
        </VStack>
      </VStack>
    </Accordion>
  );
};

const Label = ({
  downPayment,
  variant,
}: {
  downPayment: number;
  variant: "info" | "warning" | "error";
}) => {
  const { locale } = useRouter();
  const { t } = useTranslation("down-payment");

  const icon = {
    info: <BsCurrencyDollar size="16" className={styles.icon} />,
    warning: (
      <BsFillExclamationCircleFill
        size="16"
        color={"var(--color-yellow-500)"}
        className={styles.icon}
      />
    ),
    error: (
      <BsExclamationTriangleFill
        size="16"
        color={"var(--color-status-error-foreground)"}
        className={styles.icon}
      />
    ),
  };

  return (
    <Flex align="center" gap={2}>
      {icon[variant]}
      <Flex wrap="wrap">
        <DatadogMask as={Box} data-dd-action-name="down payment amount">
          <Trans
            t={t}
            i18nKey="accordion.title"
            values={{
              total: formatCurrency(downPayment, locale),
            }}
            components={{
              typo: <Typography as="p" size={0} weight={5} />,
              bold: <Typography as="b" size={0} weight={7} />,
            }}
          />
        </DatadogMask>
      </Flex>
    </Flex>
  );
};
