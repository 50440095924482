export enum DocumentCategoryEnum {
  ALL = "ALL",
  IDENTIFICATION = "IDENTIFICATION",
  INCOMES = "INCOMES",
  PROPERTIES = "PROPERTIES",
  FINAL_DOCUMENTS = "FINAL_DOCUMENTS",
  FINANCIALS = "FINANCIALS",
  LOW_RATE_GUARANTEE = "LOW_RATE_GUARANTEE",
  OTHER = "OTHER",
}
