import { Button, Modal, Typography } from "@nestoca/ui";
import { useGetApplication } from "@shared/api/hooks/applications";
import { useTranslation } from "react-i18next";
import { BsArrowLeft } from "react-icons/bs";

import styles from "./progress-modal-all-stages.module.scss";
import { ProgressTracker } from "./progress-tracker";

type PropsType = {
  applicationId: number;
  setShowAllStages: (showAllStages: boolean) => void;
  setIsModalOpen: (isModalOpen: boolean) => void;
};
export const ProgressModalAllStages = ({
  applicationId,
  setShowAllStages,
}: PropsType) => {
  const { t } = useTranslation("docs");
  const { data: application } = useGetApplication({ id: applicationId });

  return (
    <>
      <Modal.Header
        className={styles.header}
        closeButtonLabel={t("close", { ns: "common" })}
      >
        <Typography size={4} weight={7}>
          {t("progressTracker")}
        </Typography>
      </Modal.Header>
      <Modal.Body className={styles.body}>
        {application && <ProgressTracker application={application} inModal />}
        <Button
          variant="ghost"
          className={styles.button}
          onClick={() => {
            setShowAllStages(false);
          }}
          data-dd-action-name="back"
        >
          <BsArrowLeft />
          {t("back")}
        </Button>
      </Modal.Body>
    </>
  );
};
