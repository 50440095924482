import { useGate } from "@nestoca/gate-react";
import {
  deleteDocumentFile,
  getDocumentFileUrl,
  getDocuments,
  getDocumentsCounts,
  uploadDocument,
  UploadDocumentPayload,
} from "@shared/api/applications/applicants";
import { keyFactory } from "@shared/api/hooks/utils";
import {
  predicateDocumentByCompositeKey,
  predicateFileByRetrieveId,
  updateFileInDocuments,
  updateIn,
} from "@shared/utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useGetAccount } from "../account";
import { useGetIsFileDeletable } from "../applications";
import { useFetchCallback } from "../use-fetch-callback";

import {
  DocumentCountWithApplicantId,
  extendDocumentCountsWithApplicantId,
} from "./utils";

import type { GetDocumentsResponse } from "@shared/api/applications/applicants";
import type { UseQueryResult } from "@tanstack/react-query";

export const documentKeys = keyFactory("document");
export const documentCountKeys = keyFactory("documentCount");

const sortDocsByCompositeKey = <TReturn = GetDocumentsResponse[]>(
  documents: GetDocumentsResponse[]
) => {
  const others = [
    "OTHER_INCOME_DOCUMENT",
    "OTHER_IDENTIFICATION_DOCUMENT",
    "OTHER_PROPERTIES_DOCUMENT",
    "OTHER_FINANCIALS_DOCUMENT",
  ];

  // order by composite key DESC OR put the "other" at the end of the list
  return documents?.sort((a, b) => {
    // put the "other" at the end of the list
    if (others.includes(a.documentType)) return 1;
    if (others.includes(b.documentType)) return -1;

    // order by composite key DESC
    const idA = `${a.applicationId}-${a.applicantId}-${a.documentType}-${
      a.year ?? 0
    }-${a.entityId ?? 0}}`;

    const idB = `${b.applicationId}-${b.applicantId}-${b.documentType}-${
      b.year ?? 0
    }-${b.entityId ?? 0}}`;

    if (idA < idB) {
      return -1;
    }
    if (idA > idB) {
      return 1;
    }

    // names must be equal
    return 0;
  }) as TReturn;
};

export const useGetDocuments = <TReturn = GetDocumentsResponse[]>(
  applicantId: number,
  applicationId: number,
  select?: (data: GetDocumentsResponse[]) => TReturn
) => {
  return useQuery<GetDocumentsResponse[], Error, TReturn>({
    queryKey: documentKeys.list({ id: applicantId }),
    queryFn: () => getDocuments({ applicationId, applicantId }),
    select: select ?? sortDocsByCompositeKey,
    // The query will not execute until the `applicationId` and `applicantId` exists
    enabled: !!applicationId && !!applicantId,
  });
};

export const useGetDocumentByType = ({
  applicationId,
  applicantId,
  documentType,
  entityId = 0,
  year = undefined,
}: {
  applicationId: number;
  applicantId: number;
  documentType: GetDocumentsResponse["documentType"];
  entityId?: number;
  year?: number;
}) => {
  const selectFn = (documents: GetDocumentsResponse[] = []) => {
    return documents.find((document) => {
      return (
        document.documentType === documentType &&
        document.entityId === entityId &&
        document.year === year
      );
    });
  };

  return useGetDocuments<GetDocumentsResponse | undefined>(
    applicantId,
    applicationId,
    selectFn
  );
};

export const useGetDocumentCounts = (
  applicationId: number
): UseQueryResult<DocumentCountWithApplicantId[]> => {
  return useQuery({
    queryKey: documentCountKeys.detail({ id: applicationId }),
    queryFn: ({ signal }) => getDocumentsCounts(applicationId, { signal }),
    enabled: !!applicationId,
    select: ({ countsStateByApplicantId }) =>
      extendDocumentCountsWithApplicantId(countsStateByApplicantId),
  });
};

type UploadDocumentMutation = Pick<
  UploadDocumentPayload,
  | "applicationId"
  | "applicantId"
  | "documentType"
  | "file"
  | "year"
  | "entityId"
> & {
  retrieveId: string;
};

export const useUploadDocument = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      applicationId,
      applicantId,
      documentType,
      file,
      year,
      entityId,
      retrieveId,
    }: UploadDocumentMutation) =>
      uploadDocument({
        applicationId,
        applicantId,
        documentType,
        file,
        year,
        entityId,
        retrieveId,
      }),

    onMutate: async ({
      applicationId,
      applicantId,
      documentType,
      file,
      year,
      entityId,
      retrieveId,
    }: UploadDocumentMutation) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: documentKeys.list({ id: applicantId }),
      });

      // Snapshot the previous value
      const previousDocuments = queryClient.getQueryData<
        GetDocumentsResponse[]
      >(documentKeys.list({ id: applicantId }));

      // Optimistically update to the new value
      queryClient.setQueryData<GetDocumentsResponse[]>(
        documentKeys.list({ id: applicantId }),
        (old = []) => {
          const currentDocument = old.find(
            predicateDocumentByCompositeKey({
              applicationId,
              applicantId,
              documentType,
              year,
              entityId,
            })
          );

          if (!currentDocument) {
            return old;
          }

          // Create a optimistic DocumentFile
          const newFile = {
            contentType: file.type,
            originalFileName: file.name,
            status: "loading",
            fileId: retrieveId,
            retrieveId,
          };

          const optimisticDocument: GetDocumentsResponse = {
            ...currentDocument,
            state: "DOCUMENT_RECEIVED",
            files: [...(currentDocument?.files || []), newFile],
          };

          return updateIn(
            old,
            predicateDocumentByCompositeKey({
              applicationId,
              applicantId,
              documentType,
              year,
              entityId,
            }),
            () => optimisticDocument
          );
        }
      );

      // Return a context object with the snapshotted value
      return { previousDocuments };
    },

    onError: (_, { applicantId }) => {
      // If the mutation fails, invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: documentKeys.list({ id: applicantId }),
      });
    },

    onSettled: (
      newFile,
      error,
      // omit `file` from compositeKey
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      { file, retrieveId, ...compositeKey }
    ) => {
      // skip optimistic update if we get an error
      if (error) return;

      // getting from the queryClient the previous value
      // because we dont want the exact same snapshot collection of documents
      // because the optimistic update could have been removed
      // retrieveId and status are not part of the snapshot coming from backend response
      const previousDocuments = queryClient.getQueryData<
        GetDocumentsResponse[]
      >(documentKeys.list({ id: compositeKey.applicantId }));

      const updateCollection = updateFileInDocuments({
        documentCollections: previousDocuments || [],
        documentPredicateFn: predicateDocumentByCompositeKey(compositeKey),
        filePredicateFn: predicateFileByRetrieveId(retrieveId),
        fileCallback: (item) => ({
          ...item,
          ...newFile,
          status: error ? "error" : "success",
        }),
      });

      queryClient.setQueryData(
        documentKeys.list({ id: compositeKey.applicantId }),
        updateCollection
      );

      // Always refetch after error or success:
      // Refetch the documents count endpoint
      queryClient.invalidateQueries({
        queryKey: documentCountKeys.detail({
          id: compositeKey.applicationId,
        }),
      });
    },
  });
};

/**
 * Get signed document url with TTL.
 */
export const useGetDocumentFileUrl = () =>
  useFetchCallback({ fetchFn: getDocumentFileUrl });

/**
 * Delete document file.
 */
export const useDeleteDocumentFile = () => {
  const queryClient = useQueryClient();

  return useFetchCallback({
    fetchFn: deleteDocumentFile,
    onSuccess: (_data, { documentCompositeId }) => {
      // refresh the document list of the selected applicant
      queryClient.invalidateQueries({
        queryKey: documentKeys.list({ id: documentCompositeId.applicantId }),
      });

      // Refetch the documents count endpoint
      queryClient.invalidateQueries({
        queryKey: documentCountKeys.detail({
          id: documentCompositeId.applicationId,
        }),
      });
    },
  });
};

export const useGetAllowedDocumentActions = ({
  document,
}: {
  document: GetDocumentsResponse;
}) => {
  const { data: account } = useGetAccount();
  const gate = useGate();
  const { data: isFileDeletable } = useGetIsFileDeletable(
    document.applicationId
  );
  return {
    documentCompositeId: {
      applicationId: document.applicationId,
      applicantId: document.applicantId,
      documentType: document.documentType,
      year: document.year,
      entityId: document.entityId,
    },
    showDelete:
      gate.allows("delete:documents") &&
      isFileDeletable &&
      [
        "DOCUMENT_EMPTY",
        "DOCUMENT_RECEIVED",
        "DOCUMENT_BROKER_UNAPPROVED",
      ].includes(document.state),
    showDownload:
      account?.role === "financialadvisor"
        ? gate.allows("view:documents") && Boolean(document?.isDownloadable)
        : gate.allows("view:documents"),
  };
};
