import { SupportedTenant, TenantClient } from "@nestoca/multi-tenant";

import { ExternalConnection, InternalConnection } from "../constants";
type Environment = "prod" | "qa" | "staging" | "demo";
type Connection = ExternalConnection | InternalConnection | undefined;
const isClientSide = typeof window !== "undefined";

export type TenantConnectionConfig<T extends Connection> = Record<
  SupportedTenant,
  Record<Environment, T>
>;

type UseTenantConnectionParams<T extends Connection> = {
  tenant: TenantClient | null;
  config: TenantConnectionConfig<T>;
};

export const useTenantConnection = <T extends Connection>({
  tenant,
  config,
}: UseTenantConnectionParams<T>) => {
  const currentDomain = isClientSide ? window.location.hostname : "";

  if (!tenant) return;

  if (process.env.NEXT_PUBLIC_AUTH0_CONNECTION)
    return process.env.NEXT_PUBLIC_AUTH0_CONNECTION;

  let environment: Environment = "prod";

  /**
   * when we reset the `ig-dev` tenant, the slug becomes `ig-dev-{n}`
   * in those cases, use the `ig-dev` configuration
   * */
  const tenantSlug = tenant.slug.includes("ig-dev") ? "ig-dev" : tenant.slug;

  switch (true) {
    case currentDomain.includes("localhost"):
      environment = "staging";
      break;
    case currentDomain.includes("staging."):
      environment = "staging";
      break;
    case currentDomain.includes("qa."):
      environment = "qa";
      break;
    case currentDomain.includes("demo."):
      environment = "demo";
      break;
    default:
      break;
  }

  return config[tenantSlug as SupportedTenant][environment];
};
