// import { atLeast18 } from "@shared/utils";
import { endOfDay, isBefore, subYears } from "date-fns";
import * as z from "zod";

import { addressSchema } from "./address.validation";
import { RelationToMainApplicantEnum } from "./applicant.enum";

// TODO: remove this after merge of https://github.com/nestoca/public-apps/pull/510
// And use commented import from @shared/utils
export const isBeforeDefinedDate =
  (dateToCompare: number | Date) =>
  /**
   *
   * @param dateString date string in the format of YYYY-MM-DD
   * @returns
   */
  (dateString: string) => {
    if (typeof dateString !== "string") {
      return false;
    }

    // User entered value as start of the day
    // parsing of date strings with the Date constructor
    // is strongly discouraged due to browser differences and inconsistencies.
    // date-only strings (e.g. "1970-01-01") are treated as UTC, not local.
    const [year, month, day] = dateString.split("-");
    const valueAsDate = new Date(+year, +month - 1, +day, 0, 0, 0, 0);

    const compareDate = endOfDay(dateToCompare);

    return isBefore(valueAsDate, compareDate);
  };

export const atLeast18 = isBeforeDefinedDate(subYears(Date.now(), 18));
// END remove section

const DATE_VALIDATION_REGEX =
  /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/;

const registerAddress = z.object({
  address: addressSchema,
});

const schema = z.object({
  relationToMainApplicant: z.nativeEnum(RelationToMainApplicantEnum, {
    errorMap: () => {
      return { message: "form:error.required" };
    },
  }),
  firstName: z
    .string({ required_error: "form:error.required" })
    .trim()
    .min(1, { message: "form:error.required" }),
  lastName: z
    .string({ required_error: "form:error.required" })
    .trim()
    .min(1, { message: "form:error.required" }),
  dateOfBirth: z
    .string()
    .trim()
    .regex(DATE_VALIDATION_REGEX, {
      message: "form:error.invalidDate",
    })
    .refine(atLeast18, { message: "form:error.atLeast18yrsOld" }),

  phone: z
    .string({ required_error: "form:error.required" })
    .trim()
    .min(10, { message: "form:error.invalid" })
    .max(18, { message: "form:error.invalid" }),
  email: z
    .string()
    .trim()
    .email({ message: "form:error.invalidEmail" })
    .min(6, { message: "form:error.required" }),
  language: z.string({ required_error: "form:error.required" }).trim(),
  addresses: z.array(registerAddress).default([]),
});

export const coApplicantCreateSchemaWithoutAddress = schema.omit({
  addresses: true,
});

export const coApplicantCreateSchema = schema;

export type CoApplicantCreate = z.infer<typeof coApplicantCreateSchema>;
