import { useGetDocuments } from "@shared/api/hooks/documents";
import {
  Application,
  ApplicationState,
  CIRCLE_PROGRESS_TRACKER_PHASES,
} from "@shared/constants";
import {
  getCurrentProgressStage,
  getStagesListByPhase,
  getTotalCompletePercent,
  ProgressIconsType,
  ProgressItem,
} from "@shared/ui";

import { useCompleteSectionsCount } from "./use-completed-sections-count";

type ProgressTrackerType = {
  application: Application;
  applicantId: number;
  statusIcons?: ProgressIconsType;
};

type returnType = {
  currentProgressPhase: CIRCLE_PROGRESS_TRACKER_PHASES;
  [CIRCLE_PROGRESS_TRACKER_PHASES.GETTING_TO_KNOW]: {
    phaseNumber: number;
    completePercent: number;
    questionnaireCount: {
      total: number;
      completed: number;
      completePercent: number;
    };
    documentsCount: {
      total: number;
      completed: number;
      completePercent: number;
    };
  };
  [CIRCLE_PROGRESS_TRACKER_PHASES.LENDER_APPROVAL]: {
    phaseNumber: number;
    completePercent: number | undefined;
    statusesList: ProgressItem[];
  };
  [CIRCLE_PROGRESS_TRACKER_PHASES.FUNDING]: {
    phaseNumber: number;
    completePercent: number | undefined;
    statusesList: ProgressItem[];
  };
};

export const useCircleProgressTracker = ({
  application,
  applicantId,
  statusIcons,
}: ProgressTrackerType): returnType => {
  const { data: documents } = useGetDocuments(applicantId, application.id);

  const currentProgressPhase = getCurrentProgressStage(
    application.applicationState
  );

  const {
    totalApplicantSectionsCount,
    completedApplicantSectionsCount,
    totalApplicationSectionsCount,
    completedApplicationSectionsCount,
  } = useCompleteSectionsCount(application.id, applicantId, application.type);

  if (application.applicationState === ApplicationState.Closed || !documents) {
    return {} as returnType;
  }

  const totalDocuments = documents.length;
  const documentsComplete = documents.filter(
    (doc) => doc.state !== "DOCUMENT_EMPTY"
  ).length;

  const gettingToKnowPercent =
    (completedApplicantSectionsCount +
      completedApplicationSectionsCount +
      documentsComplete) /
    (totalApplicantSectionsCount +
      totalApplicationSectionsCount +
      totalDocuments);

  const totalQuestionnaires =
    totalApplicantSectionsCount + totalApplicationSectionsCount;

  const completedQuestionnaires =
    completedApplicantSectionsCount + completedApplicationSectionsCount;

  const lenderApprovalStatusesList = getStagesListByPhase(
    application.applicationState,
    statusIcons || {},
    CIRCLE_PROGRESS_TRACKER_PHASES.LENDER_APPROVAL
  );

  const lenderApprovalCompletePercent =
    lenderApprovalStatusesList &&
    lenderApprovalStatusesList?.filter((state) => state.status === "complete")
      .length / lenderApprovalStatusesList?.length;

  const lenderApprovalHasActiveStates = lenderApprovalStatusesList.find(
    (state) => state.status === "active"
  );

  const totalLenderApprovalCompletePercent = getTotalCompletePercent(
    lenderApprovalCompletePercent,
    !!lenderApprovalHasActiveStates
  );

  const fundingStatusesList = getStagesListByPhase(
    application.applicationState,
    statusIcons || {},
    CIRCLE_PROGRESS_TRACKER_PHASES.FUNDING
  );

  const fundingCompletePercent =
    fundingStatusesList &&
    fundingStatusesList?.filter((state) => state.status === "complete").length /
      fundingStatusesList?.length;

  const fundingHasActiveStates = fundingStatusesList.find(
    (state) => state.status === "active"
  );

  const totalFundingCompletePercent = getTotalCompletePercent(
    fundingCompletePercent,
    !!fundingHasActiveStates
  );

  return {
    currentProgressPhase:
      currentProgressPhase as CIRCLE_PROGRESS_TRACKER_PHASES,
    [CIRCLE_PROGRESS_TRACKER_PHASES.GETTING_TO_KNOW]: {
      phaseNumber: 1,
      completePercent: gettingToKnowPercent,
      questionnaireCount: {
        total: totalQuestionnaires,
        completed: completedQuestionnaires,
        completePercent: completedQuestionnaires / totalQuestionnaires,
      },
      documentsCount: {
        total: totalDocuments,
        completed: documentsComplete,
        completePercent: documentsComplete / totalDocuments,
      },
    },
    [CIRCLE_PROGRESS_TRACKER_PHASES.LENDER_APPROVAL]: {
      phaseNumber: 2,
      completePercent: totalLenderApprovalCompletePercent,
      statusesList: lenderApprovalStatusesList || [],
    },
    [CIRCLE_PROGRESS_TRACKER_PHASES.FUNDING]: {
      phaseNumber: 3,
      completePercent: totalFundingCompletePercent,
      statusesList: fundingStatusesList || [],
    },
  };
};
