import { useState, useEffect } from "react";
import type { PropsWithChildren } from "react";

import { useBreakpointValue } from "@nestoca/ui";
import { Header } from "@shared/ui/components";

import { QuestionnaireLayoutProvider } from "./questionnaire-layout-provider";

export const QuestionnaireLayoutMobile = ({ children }: PropsWithChildren) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const isMobile = useBreakpointValue({ default: true, md: false });

  if (!isClient) return null;

  return (
    <QuestionnaireLayoutProvider>
      {isMobile ? (
        <main>{children}</main>
      ) : (
        <main>
          <Header />
          {children}
        </main>
      )}
    </QuestionnaireLayoutProvider>
  );
};
