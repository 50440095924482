import { addHeadersInterceptor } from "@nestoca/utils";
import { InternalAxiosRequestConfig } from "axios";

import { init } from "./init";

const {
  api,
  setBackendBaseUrl,
  setAuthToken,
  getAuthToken,
  authToken,
  setCommonHeaders,
} = init({
  baseURL: "/api",
});

api.interceptors.request.use((config) => {
  return addHeadersInterceptor(config as InternalAxiosRequestConfig);
});

export {
  api,
  setBackendBaseUrl,
  setAuthToken,
  getAuthToken,
  authToken,
  setCommonHeaders,
};
