import { createContext, useId } from "@nestoca/ui";

import { State } from "./constants";

import type { States } from "./types";

export type ExpandedIndex = number[];

export interface ReachableContext
  extends Pick<
    UseReachableReturn,
    "state" | "itemLength" | "getReachableItemProps"
  > {
  strict?: boolean;
}

export type UseReachableReturn = ReturnType<typeof useReachable>;

export interface UseReachableProps {
  id?: string;

  state: Record<string, States>;

  itemLength?: number;
  showCounter?: boolean;
}

const [ReachableProvider, useReachableContext] =
  createContext<ReachableContext>({
    name: "ReachableContext",
    strict: false,
  });

export { ReachableProvider, useReachableContext };

export const useReachable = (props: UseReachableProps = { state: {} }) => {
  const {
    id: idProp,
    state,
    itemLength = 0,
    showCounter = false,
    ...htmlProps
  } = props;

  /**
   * Generate unique ids for all accordion item components (button and panel)
   */
  const id = useId(idProp, "reachable");

  // TODO type key is one of the items currentState
  const getReachableItemProps = (currentState: States) => {
    const itemState = state[currentState] || State.UNKNOWN;

    const isUnreached = itemState === State.UNREACHED;
    const isReached = !isUnreached;
    const isOpen = itemState === State.OPEN;
    const isClosed = itemState === State.CLOSED;

    return {
      step: currentState,
      state: itemState,
      isUnreached,
      isReached,
      isOpen,
      isClosed,
      showCounter,
    };
  };

  return {
    id,
    state,
    itemLength,
    showCounter,
    // rest
    getReachableItemProps,
    htmlProps,
  };
};
