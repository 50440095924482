type PageViewOptions = {
  url?: string;
  locale?: string;
  tenant?: string;
  mortgageType?: string;
  title?: string;
  location?: string;
  sendPageView?: boolean;
  userId?: number | string;
};

export const pageView = (
  {
    url,
    locale,
    tenant,
    mortgageType,
    title,
    location,
    sendPageView,
    userId,
  }: PageViewOptions = {},
  gtmId?: string
) => {
  const _gtmId = process.env.NEXT_PUBLIC_GTM_ID ?? gtmId;

  if (!_gtmId || !window.gtm) {
    return;
  }

  const pageViewOptions: {
    page_url?: string;
    language_site?: string;
    tenant?: string;
    page_title?: string;
    page_location?: string;
    send_page_view?: boolean;
    user_id?: number | string;
    mortgage_type?: string;
  } = {};

  if (url !== undefined) {
    pageViewOptions.page_url = url;
  }

  if (locale !== undefined) {
    pageViewOptions.language_site = locale;
  }

  if (tenant !== undefined) {
    pageViewOptions.tenant = tenant;
  }

  if (userId !== undefined) {
    pageViewOptions.user_id = userId;
  }

  if (mortgageType !== undefined) {
    pageViewOptions.mortgage_type = mortgageType;
  }

  if (title !== undefined) {
    pageViewOptions.page_title = title;
  }

  if (location !== undefined) {
    pageViewOptions.page_location = location;
  }

  if (sendPageView !== undefined) {
    pageViewOptions.send_page_view = sendPageView;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.gtm({
    event: "pageview",
    ...pageViewOptions,
  });
};
