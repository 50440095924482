import { useApplicationSectionsStates } from "@shared/api/hooks/applications";
import { TransactionTypeEnum } from "@shared/constants";

export const useCompleteSectionsCount = (
  applicationId: number,
  applicantId: number,
  applicationType?: TransactionTypeEnum
) => {
  const { data: sectionsStatuses } =
    useApplicationSectionsStates(applicationId);

  const applicantSectionsStatus = sectionsStatuses?.[applicantId];

  const hasDownpaymentSection = applicationType === "NEW";

  const sanitizedSectionsByApplicant =
    applicantSectionsStatus &&
    Object.entries(applicantSectionsStatus).filter(([key]) => {
      return key !== "id" && key !== "downpayment";
    });

  const totalApplicantSectionsCount = sanitizedSectionsByApplicant?.length ?? 0;

  const completedApplicantSectionsCount =
    sanitizedSectionsByApplicant?.filter(([, value]) => value).length ?? 0;

  const sectionsByApplication =
    sectionsStatuses &&
    Object.entries(sectionsStatuses).filter(([key, value]) => {
      return (
        typeof value === "boolean" &&
        (!hasDownpaymentSection ? key !== "downpayment" : [key, value])
      );
    });

  const totalApplicationSectionsCount = sectionsByApplication?.length ?? 0;

  const completedApplicationSectionsCount =
    sectionsByApplication?.filter(([, value]) => value).length ?? 0;

  return {
    totalApplicantSectionsCount,
    completedApplicantSectionsCount,
    totalApplicationSectionsCount,
    completedApplicationSectionsCount,
  };
};
