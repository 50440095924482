import { useEffect, useState } from "react";

import { Flex, Typography, Box, Button } from "@nestoca/ui";
import { useTranslation, Trans } from "react-i18next";
import { BsArrowRight } from "react-icons/bs";

import styles from "./account-deleted.module.scss";

const REDIRECT_TIME = 15;

export const AccountDeleted = () => {
  const { t } = useTranslation("common");
  const [counter, setCounter] = useState(REDIRECT_TIME);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <Flex direction="column" gap={5} className={styles["account-deleted"]}>
      <Typography
        size={4}
        weight={7}
        className={styles["account-deleted__header"]}
      >
        {t("accountDeletion.feedback.thankYouForFeedback")}
      </Typography>
      <Box className={styles["account-deleted__message"]}>
        {t("accountDeletion.feedback.accountDeleted")}
      </Box>
      <Box className={styles["account-deleted__message"]}>
        {t("accountDeletion.feedback.haveAGreatDay")}
      </Box>
      <Box className={styles["account-deleted__message"]}>
        <Trans
          t={t}
          i18nKey="accountDeletion.feedback.youWillBeRedirected"
          ns="common"
          values={{ count: counter }}
          components={[
            <span
              key={0}
              className={styles["account-deleted__message--bold"]}
            />,
          ]}
        />
      </Box>
      <Button
        as="a"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - polymorphic component show error on unknown props
        // we are going from service CMA to service Documents
        // we can't use the `Link` component because it will break the flow and throw an error
        // should we use a config value from the delete feature?
        href="https://www.nesto.ca"
        rightIcon={<BsArrowRight />}
        className={styles["account-deleted__button"]}
        data-dd-action-name="redirect to website"
      >
        {t("accountDeletion.feedback.redirectToWebsite")}
      </Button>
    </Flex>
  );
};
