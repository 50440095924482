import {
  InterestRateType,
  MortgagePaymentFrequency,
  MortgageType,
  OtherPropertyType,
  Purpose,
  PurposeAfterTransaction,
  RentalIncomeFrequency,
  TermType,
} from "../application";

type PropertyPurposeType = {
  label: string;
  value: Purpose;
};

type RateTypes = {
  label: string;
  value: InterestRateType;
};

type PropertyPurposeAfterTransactionType = {
  label: string;
  value: PurposeAfterTransaction;
};

type RentalIncomeFrequencyType = {
  label: string;
  value: RentalIncomeFrequency;
};

type HeatingFrequencyType = {
  label: string;
  value: RentalIncomeFrequency;
};

type MortgageFrequencyType = {
  label: string;
  value: MortgagePaymentFrequency;
};

type PropertyType = {
  label: string;
  value: OtherPropertyType;
};

type MortgageTypes = {
  label: string;
  value: MortgageType;
};

type TermTypes = {
  label: string;
  value: TermType;
};

export const PROPERTY_PURPOSE: PropertyPurposeType[] = [
  {
    label: "owned-property:ownerOccupied",
    value: "OWNER_OCCUPIED",
  },
  {
    label: "owned-property:ownerOccupiedWithRental",
    value: "OWNER_OCCUPIED_AND_RENTAL",
  },
  {
    label: "owned-property:rental",
    value: "RENTAL",
  },
];

export const PROPERTY_PURPOSE_AFTER_TRANSACTION: PropertyPurposeAfterTransactionType[] =
  [
    ...PROPERTY_PURPOSE,
    {
      label: "owned-property:sold",
      value: "SOLD",
    },
  ];

export const RENTAL_INCOME_FREQUENCY: RentalIncomeFrequencyType[] = [
  {
    label: "owned-property:annually",
    value: "ANNUALLY",
  },
  {
    label: "owned-property:semiannually",
    value: "SEMIANNUALLY",
  },
  {
    label: "owned-property:monthly",
    value: "MONTHLY",
  },
  {
    label: "owned-property:semimonthly",
    value: "SEMIMONTHLY",
  },
  {
    label: "owned-property:weekly",
    value: "WEEKLY",
  },
  {
    label: "owned-property:biweekly",
    value: "BIWEEKLY",
  },
];

export const HEATING_FREQUENCY: HeatingFrequencyType[] = [
  {
    label: "owned-property:annually",
    value: "ANNUALLY",
  },
  {
    label: "owned-property:semiannually",
    value: "SEMIANNUALLY",
  },
  {
    label: "owned-property:monthly",
    value: "MONTHLY",
  },
  {
    label: "owned-property:semimonthly",
    value: "SEMIMONTHLY",
  },
  {
    label: "owned-property:weekly",
    value: "WEEKLY",
  },
  {
    label: "owned-property:biweekly",
    value: "BIWEEKLY",
  },
];

export const MORTGAGE_FREQUENCY: MortgageFrequencyType[] = [
  {
    label: "owned-property:weekly",
    value: "WEEKLY",
  },
  {
    label: "owned-property:weeklyAccelerated",
    value: "ACCELERATED_WEEKLY",
  },
  {
    label: "owned-property:biweekly",
    value: "BIWEEKLY",
  },
  {
    label: "owned-property:biweeklyAccelerated",
    value: "ACCELERATED_BIWEEKLY",
  },
  {
    label: "owned-property:monthly",
    value: "MONTHLY",
  },
  {
    label: "owned-property:semimonthly",
    value: "SEMIMONTHLY",
  },
];

export const OWNED_PROPERTY_TYPE: PropertyType[] = [
  {
    label: "owned-property:house",
    value: "HOUSE",
  },
  {
    label: "owned-property:condo",
    value: "CONDO",
  },
  {
    label: "owned-property:secondaryHome",
    value: "SECONDARY_HOME_OR_COTTAGE",
  },
  {
    label: "owned-property:duplex",
    value: "DUPLEX",
  },
  {
    label: "owned-property:triplex",
    value: "TRIPLEX",
  },
  {
    label: "owned-property:fourplex",
    value: "FOURPLEX",
  },
];

export const MORTGAGE_TYPES: MortgageTypes[] = [
  {
    label: "owned-property:standard",
    value: "STANDARD",
  },
  {
    label: "owned-property:heloc",
    value: "HELOC",
  },
];

export const MORTGAGE_TERM_TYPES: TermTypes[] = [
  {
    label: "owned-property:open",
    value: "OPEN",
  },
  {
    label: "owned-property:closed",
    value: "CLOSED",
  },
  {
    label: "owned-property:convertable",
    value: "CONVERTABLE",
  },
];

export const RATE_TYPES: RateTypes[] = [
  {
    label: "owned-property:fixed",
    value: "FIXED",
  },
  {
    label: "owned-property:variable",
    value: "VARIABLE",
  },
  {
    label: "owned-property:adjustable",
    value: "ADJUSTABLE",
  },
  {
    label: "owned-property:cappedVariable",
    value: "CAPPED_VARIABLE",
  },
];

export const OTHER_PROPERTY_STATUS = [
  {
    value: "NOT_READY_YET",
    label: "owned-property:saleStatus.notReady",
  },
  {
    value: "LISTED_FOR_SALE",
    label: "owned-property:saleStatus.listed",
  },
  {
    value: "CONDITIONALLY_SOLD",
    label: "owned-property:saleStatus.soldConditionally",
  },
  {
    value: "SOLD_FIRM",
    label: "owned-property:saleStatus.soldFirm",
  },
];
