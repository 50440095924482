import { useEffect } from "react";
import type { PropsWithChildren } from "react";

import {
  affiliateMarketingIdAtom,
  analyticsSchema,
  impressionsTrackingIdAtom,
  Partner,
  partnerAtom,
  subPartnerIdAtom,
} from "@shared/store";
import { useTypedRouter } from "@shared/utils";
import { useSetAtom } from "jotai";

export const AnalyticProvider = ({ children }: PropsWithChildren) => {
  const setAffiliateMarketingId = useSetAtom(affiliateMarketingIdAtom);
  const setImpressionsTrackingId = useSetAtom(impressionsTrackingIdAtom);
  const setPartner = useSetAtom(partnerAtom);
  const setSubPartnerId = useSetAtom(subPartnerIdAtom);
  const { query } = useTypedRouter(analyticsSchema);
  const { affiliateMarketingId, impressionsTrackingId, partner, subPartnerId } =
    query;

  // Handle the marketing query string params
  // set them when they are define in the query string
  useEffect(() => {
    if (affiliateMarketingId) {
      setAffiliateMarketingId(affiliateMarketingId);
    }
  }, [affiliateMarketingId, setAffiliateMarketingId]);

  useEffect(() => {
    if (impressionsTrackingId) {
      setImpressionsTrackingId(impressionsTrackingId);
    }
  }, [impressionsTrackingId, setImpressionsTrackingId]);

  useEffect(() => {
    if (partner) {
      setPartner(partner as Partner);
    }
  }, [partner, setPartner]);

  useEffect(() => {
    if (subPartnerId) {
      setSubPartnerId(subPartnerId);
    }
  }, [subPartnerId, setSubPartnerId]);

  return <>{children}</>;
};

AnalyticProvider.displayName = "AnalyticProvider";
