import { Box, ButtonIcon, Flex, Typography } from "@nestoca/ui";
import clsx from "clsx";
import {
  BsFillCheckCircleFill,
  BsFillExclamationCircleFill,
  BsCircle,
  BsTrash,
} from "react-icons/bs";

import styles from "./entry.module.scss";

export type EntryType = {
  variant: "completed" | "error" | "inProgress";
  selected: boolean;
  title: string;
  info: string;
  hasRemoveButton: boolean;
};

export interface EntryProps {
  entry: EntryType;
  onClick?: () => unknown;
  onRemove?: () => unknown;
}

const icons = {
  completed: (
    <BsFillCheckCircleFill
      color="var(--color-status-success-foreground-saturated)"
      size={"1.5em"}
    />
  ),
  error: (
    <BsFillExclamationCircleFill
      color="var(--color-status-error-foreground)"
      size={"1.5em"}
    />
  ),
  inProgress: (
    <BsCircle color="var(--color-status-info-foreground)" size={"1.5em"} />
  ),
};
export const Entry = ({ entry, onClick, onRemove }: EntryProps) => {
  return (
    <Flex
      className={clsx(styles.entry, {
        [styles["entry--selected"]]: entry.selected,
      })}
      role="button"
      tabIndex={0}
      onClick={onClick}
      gap={5}
    >
      <Box aria-hidden="true">{icons[entry.variant]}</Box>

      <Flex direction="column" gap={1}>
        <Typography weight={7}>{entry.title}</Typography>
        <Typography className={styles["entry__info"]}>{entry.info}</Typography>
      </Flex>
      {entry.hasRemoveButton && (
        <ButtonIcon
          icon={
            <BsTrash size={"1em"} color={"var(--color-foreground-muted)"} />
          }
          aria-label={`remove ${entry.title}`}
          variant="ghost"
          size="small"
          className={styles["entry__remove"]}
          onClick={(e) => {
            onRemove && onRemove(), e.stopPropagation();
          }}
          data-dd-action-name="remove entry"
        />
      )}
    </Flex>
  );
};
