import React from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useGate } from "@nestoca/gate-react";
import { Button, Flex, Grid, Heading, Typography } from "@nestoca/ui";
import { isEmpty } from "@shared/utils";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { GrUserPolice } from "react-icons/gr";

import styles from "./unauthorized.module.scss";

export const UnauthorizedPage = () => {
  const { logout } = useAuth0();
  const { t } = useTranslation("common");
  const gate = useGate();
  // this will feel like a hack, and it is, but it's the only way to get the sidebar to show up
  // at the moment of writing this, the sidebar is only shown if the user is not an external broker
  const hasSidebar = !isEmpty(gate) && !gate.allowedRole(["externalbroker"]);

  return (
    <Grid
      className={clsx(styles.unauthorized, {
        [styles["unauthorized--with-sidebar"]]: hasSidebar,
      })}
    >
      <Flex
        direction="column"
        align="center"
        gap={4}
        className={styles.unauthorized__content}
      >
        <GrUserPolice className={styles.icon} size={164} />
        <Flex direction="column" align="center">
          <Heading className={styles.unauthorized__title}>
            {t("unauthorized.title")}
          </Heading>
          <Heading size={6}>{t("unauthorized.subtitle")}</Heading>
        </Flex>
        <Flex direction="column" align="center">
          <Typography>{t("unauthorized.content")}</Typography>
          <Typography>{t("unauthorized.content2")}</Typography>
        </Flex>
        <Button
          data-testid="logout-button"
          variant="primary"
          onClick={() =>
            logout({
              logoutParams: { returnTo: `${window.location.origin}/` },
            })
          }
        >
          {t("unauthorized.logout")}
        </Button>
      </Flex>
    </Grid>
  );
};
