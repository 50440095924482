import { useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
/**
 *
 * *** this tracking can't be use in here until we do have a GTM analytics ***
 * *** in FAD, this file is shared between FAD and Doc Centre ***
 */
// import { useAnalytics } from "@shared/analytics";

export const useValidateRid = (rid?: string) => {
  // const { track } = useAnalytics();
  const { logout } = useAuth0();

  useEffect(() => {
    // Can't validate what we don't have
    if (!rid) return;

    const storedRid = localStorage.getItem("rid");

    // If we don't have a stored rid, or if the stored rid matches the rid from the URL,
    // we can set the rid in local storage and exit.
    if (storedRid === rid || !storedRid) {
      localStorage.setItem("rid", rid || "");
      return;
    }

    // If the stored rid does not match the rid from the URL, we need to log the user out.
    if (storedRid !== rid) {
      localStorage.setItem("rid", rid);
      // track({ event: "logout" });
      logout();
    }
  }, [logout, rid]);
};
