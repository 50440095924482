import { ComponentProps } from "react";

import { useInsertionEffectWithLayoutFallback } from "./hooks";
import { withCache } from "./with-cache";

import type { Cache } from "../cache";

const isBrowser = typeof document !== "undefined";

interface GlobalStylesProps extends ComponentProps<"style"> {
  styles: string;
}

export const GlobalStyles = withCache(
  ({ styles }: GlobalStylesProps, cache) => {
    if (!isBrowser) {
      return <GlobalStylesSSR styles={styles} cache={cache} />;
    }

    if (isBrowser) {
      return <GlobalStylesClient styles={styles} cache={cache} />;
    }

    return null;
  }
);

const GlobalStylesSSR = ({
  styles,
  cache,
}: GlobalStylesProps & { styles: string; cache?: Cache }) => {
  // insert styles into cache so we can add it on the header of _document.jsx
  cache?.insert(styles);

  return null;
};

const GlobalStylesClient = ({
  styles,
  cache,
}: GlobalStylesProps & { cache?: Cache }) => {
  useInsertionEffectWithLayoutFallback(() => {
    cache?.insert(styles);
    cache?.hydrate();

    return () => {
      cache?.flush();
    };
  }, [styles]);

  return null;
};
