import { createContext } from "react";
import type { PropsWithChildren } from "react";

export const TenantEnvVarContext = createContext<Record<string, string>>({});

export const TenantEnvVarProvider = ({
  children,
  tenantPublicEnvVar,
}: PropsWithChildren<{ tenantPublicEnvVar: Record<string, string> }>) => (
  <TenantEnvVarContext.Provider value={tenantPublicEnvVar}>
    {children}
  </TenantEnvVarContext.Provider>
);
