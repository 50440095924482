import { useAuth0 } from "@auth0/auth0-react";
import { useGate } from "@nestoca/gate-react";
import { Button, Divider, Flex, Typography } from "@nestoca/ui";
import { useAnalytics } from "@shared/analytics";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { BsFillPersonFill, BsPerson, BsUiChecksGrid } from "react-icons/bs";
import { IoHomeSharp } from "react-icons/io5";

import styles from "./burger-menu.module.scss";
import { SupportLinks } from "./support-links";
import { SupportLinksHub } from "./support-links-hub";

import type { BurgerMenuPageType } from "./burger-menu";

export const HomeBurgerMenu = ({
  setMenuPage,
}: {
  setMenuPage: (modal: BurgerMenuPageType) => void;
  setMenuOpen: (menuOpen: boolean) => void;
}) => {
  const { locale, defaultLocale } = useRouter();
  const { track } = useAnalytics();
  const { t } = useTranslation("common");
  const { logout } = useAuth0();
  const gate = useGate();
  const isExternalBroker = gate.getRole() === "externalbroker";

  const portfolioHref = locale === defaultLocale ? "/" : `/${locale}`;

  return (
    <>
      <Flex gap={4}>
        <Flex
          align="center"
          justify="center"
          direction="column"
          as="a"
          href={portfolioHref}
          className={styles["burger-menu__button"]}
        >
          {isExternalBroker ? (
            <>
              <BsUiChecksGrid size={32} />
              <Typography size={0} weight={5}>
                {t("sidebar.dashboard")}
              </Typography>
            </>
          ) : (
            <>
              <IoHomeSharp size={36} />
              <Typography size={0} weight={5}>
                {t("portfolio")}
              </Typography>
            </>
          )}
        </Flex>
        <Button
          variant="ghost"
          className={styles["burger-menu__button"]}
          onClick={() => setMenuPage("SETTINGS")}
          data-dd-action-name="settings"
        >
          {isExternalBroker ? (
            <Flex direction="column" align="center">
              <BsPerson size={32} />
              <Typography size={0} weight={5}>
                {t("sidebar.profile")}
              </Typography>
            </Flex>
          ) : (
            <Flex direction="column" align="center">
              <BsFillPersonFill size={36} />
              <Typography size={0} weight={5}>
                {t("settings")}
              </Typography>
            </Flex>
          )}
        </Button>
      </Flex>
      {isExternalBroker ? <SupportLinksHub /> : <SupportLinks />}
      <Divider />
      <Flex direction="column" justify="center" grow={1}>
        <Button
          variant="secondary"
          className={styles["burger-menu--signOut"]}
          onClick={() => {
            track({ event: "logout" });
            logout({
              logoutParams: {
                /**
                 * TODO: This is temporary for a hotfix to prod. We need to figure out a better way to customize this.
                 * Maybe an env variable?
                 */
                returnTo: `${window.location.origin}/`,
                federated: true,
              },
            });
          }}
          data-dd-action-name="logout"
        >
          {t("signOut")}
        </Button>
      </Flex>
    </>
  );
};
