import React from "react";
import { useTranslation } from "next-i18next";
import { Typography } from "@nestoca/ui";
export const Property = ({ property }: any) => {
  const { t } = useTranslation("applications");
  const { isFound, address } = property;

  return (
    <Typography weight={7}>
      {isFound ? `${address.streetNumber} ${address.street}` : t("noAddress")}
    </Typography>
  );
};
