import { APPLICATION_TYPE } from "./constant";

import type { MortgageType } from "./types";
import type { RouteMortgageType } from "@shared/constants/schema";

const routeMortgageTypeMap = {
  "new-mortgage": "NEW",
  refinance: "REFINANCE",
  renewal: "RENEWAL",
} as const satisfies Record<RouteMortgageType, MortgageType>;

const toAngryCase = (string: string): string =>
  string &&
  string
    .split("-")
    .map((s) => s.toUpperCase())
    .join("_");

export const getQuestionName = (questionId: string) => {
  return `GETAQUOTE_${toAngryCase(questionId)}`;
};

export const getHumanMortgageType = (
  mortgageType: MortgageType | (string & {})
) => {
  if (isMortgageType(mortgageType)) {
    return APPLICATION_TYPE[mortgageType];
  }

  return undefined;
};

const isMortgageType = (value: string): value is MortgageType => {
  return value in APPLICATION_TYPE;
};

type MaybeRouteMortgageType = RouteMortgageType | (string & {});
type OptionalRouteMortgageType = RouteMortgageType | undefined;
export const getMortgageTypeFromPath = (path: string) => {
  const urlElements: MaybeRouteMortgageType[] = path.split("/");
  const mortgageType = urlElements.find((element) => {
    if (isRouteMortgageType(element)) {
      return element;
    }
  }) as OptionalRouteMortgageType;

  return mortgageType ? routeMortgageTypeMap[mortgageType] : undefined;
};

const isRouteMortgageType = (value: string): value is RouteMortgageType => {
  const routeKeys = Object.keys(routeMortgageTypeMap);

  return routeKeys.includes(value);
};
