import { Flex } from "@nestoca/ui";

import { Entry, EntryType } from "./entry";

export interface EntriesCollectionProps {
  entries: EntryType[];
  onEntryClick: (entry: EntryType) => unknown;
  onEntryRemove?: (entry: EntryType) => unknown;
}

export const EntriesCollection = ({
  entries,
  onEntryClick,
  onEntryRemove,
}: EntriesCollectionProps) => {
  return (
    <Flex direction="column" gap={2}>
      {entries.map((entry) => {
        return (
          <Entry
            key={entry.title}
            entry={entry}
            onClick={() => onEntryClick(entry)}
            onRemove={() => onEntryRemove && onEntryRemove(entry)}
          />
        );
      })}
    </Flex>
  );
};
