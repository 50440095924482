import React from "react";

import { Button, Card, Typography } from "@nestoca/ui";
import { Application } from "@shared/constants";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import { formatDateToYYYYMMDD } from "../../../utils/time";
import { useApplicationContext } from "../../application-context";

import styles from "./footer.module.scss";

interface FooterProps {
  application: Application;
}

export const SelectApplicationCardFooter = ({ application }: FooterProps) => {
  const { t } = useTranslation("applications");
  const { setApplication } = useApplicationContext();
  const { push } = useRouter();
  const onClick = () => {
    push(`/${application.id}`);
    setApplication(application);
  };

  return (
    <Card.Footer className={styles["select-application-card--footer"]}>
      <Button
        size="small"
        className={styles["select-application-card--footer-cta"]}
        onClick={onClick}
        data-dd-action-name="select"
      >
        {t("select")}
      </Button>
      <Typography size={0}>
        <strong>{t("lastUpdate")}</strong>{" "}
        {formatDateToYYYYMMDD(application.updated)}
      </Typography>
    </Card.Footer>
  );
};
