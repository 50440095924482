import { TFunction } from "i18next";

import { isEmptyString } from "./is-empty-string";
import { isNullOrUndefined } from "./is-null-or-undefined";

import type { Address } from "../constants";

export const formatCurrency = (
  money: number | null | undefined,
  locale = "en",
  currency = "CAD"
) => {
  const isEnglish = locale === "en";

  return !isNullOrUndefined(money)
    ? new Intl.NumberFormat(isEnglish ? "en-CA" : "fr-CA", {
        style: "currency",
        currency,
        currencyDisplay: "narrowSymbol",
        trailingZeroDisplay: "stripIfInteger",
      }).format(money)
    : "-";
};

export type PercentageBased = "ZERO" | "HUNDRED";
export type PercentageBasedOptions = {
  locale?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  /** is percentage value `ZERO` based (0-1) or `HUNDRED` (0-100) */
  based?: PercentageBased;
};
/**
 *
 * @param percentage number between 0-1 or 0-100 based on `options.based`
 * @param options formatting options
 * @param options.locale locale
 * @param options.minimumFractionDigits minimum fraction digits
 * @param options.maximumFractionDigits maximum fraction digits
 * @param options.based is percentage value `ZERO` based (0-1) or `HUNDRED` (0-100)
 * @returns
 */
export const formatPercentage = (
  percentage: number | null | undefined,
  options: PercentageBasedOptions = {}
) => {
  // we let `0` pass through. `0` is a valid percentage value
  if (isNullOrUndefined(percentage)) return "-";

  const {
    locale = "en",
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
    based = "HUNDRED",
  } = options;
  const isEnglish = locale === "en";
  const num = based === "HUNDRED" ? percentage / 100 : percentage;

  return new Intl.NumberFormat(isEnglish ? "en-CA" : "fr-CA", {
    style: "percent",
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(num);
};

/**
 * Format address
 * @param address Address
 */

export const formatAddress = (
  address?: Address,
  shortType?: "street" | "streetAndCity"
): string => {
  if (!address) return "";

  if (shortType === "street") {
    const { unit, streetNumber, street } = address;

    if (!streetNumber || !street) return "";

    return `${unit ? unit + "-" : ""}${streetNumber || ""} ${street || ""}`;
  }

  if (shortType === "streetAndCity") {
    const { unit, streetNumber, street, city } = address;

    if (!streetNumber || !street) return "";

    return `${unit ? unit + "-" : ""}${streetNumber || ""} ${street || ""} ${
      city || ""
    }`;
  }

  const returns: string[] = [];
  let unit = "";

  if (address.unit?.length) {
    unit = `${address.unit}-`;
  }

  returns.push(
    `${unit} ${address.streetNumber} ${address.street}`,
    address.city || "",
    address.postalCode || "",
    address.stateCode || "",
    address.countryCode || ""
  );

  // clean empty string and return joined with comma
  return returns
    .filter((item: string) => (item ? !isEmptyString(item.trim()) : false))
    .join(", ")
    .trim();
};

export const formatDate = (value: string | null | undefined, locale = "en") => {
  const isEnglish = locale === "en";

  return value
    ? new Intl.DateTimeFormat(isEnglish ? "en-CA" : "fr-CA", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      }).format(new Date(value))
    : "-";
};

export const getInitial = (name?: string) =>
  name ? name.charAt(0).toUpperCase() : "";

export const capitalizeFirstLetters = (
  t: TFunction,
  firstName?: string,
  lastName?: string
): string => {
  const unknownName = t("unknownName", { ns: "applications" }).split(" ");
  const [unknownFirstInitial, unknownLastInitial] = unknownName.map(getInitial);

  if (firstName && lastName) {
    return `${getInitial(firstName)}${getInitial(lastName)}`;
  }

  if (firstName || lastName) {
    return getInitial(firstName || lastName);
  }

  return `${unknownFirstInitial}${unknownLastInitial}`;
};

export const formatDecimal = (value: string) => {
  const number = parseFloat(value);
  return parseFloat(number.toFixed(2)).toString();
};
