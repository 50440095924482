import { RegisteredAddress } from "../constants";

export function isRegisteredAddress(
  address: unknown
): address is RegisteredAddress {
  if (typeof address !== "object" || address === null) {
    return false;
  }

  return (
    "occupiedYears" in address &&
    typeof (address as RegisteredAddress).occupiedYears === "number"
  );
}
