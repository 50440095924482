import { useMemo } from "react";

import { getValidChildren, VStack } from "@nestoca/ui";
import clsx from "clsx";

import styles from "./reachable.module.scss";
import { ReachableItemProps } from "./types";
import {
  ReachableItemProvider,
  useReachableItemCreateContext,
} from "./use-reachable-item";
import { isHiddenState } from "./utils";

export const ReachableItem = ({
  className,
  children,
  ...rest
}: ReachableItemProps) => {
  const { htmlProps, ...context } = useReachableItemCreateContext(rest);

  const validChildren =
    typeof children === "function" ? [] : getValidChildren(children);

  const ctx = useMemo(() => context, [context]);

  if (isHiddenState(context.state)) {
    return null;
  }

  return (
    <ReachableItemProvider value={ctx}>
      <VStack
        id={ctx.id}
        align="stretch"
        gap={0}
        className={clsx(styles.reachable__item, className)}
        {...htmlProps}
      >
        {typeof children === "function" ? children(context) : validChildren}
      </VStack>
    </ReachableItemProvider>
  );
};

ReachableItem.id = "ReachableItem";
