import type { ReactElement } from "react";

import { HIDDEN_STATES } from "./constants";

import type { States, HiddenStates, ReachableItemProps } from "./types";

export const isChildReachableItem = (
  child: React.ReactNode
): child is ReactElement<ReachableItemProps> => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return child?.type.id === "ReachableItem";
};

export const isHiddenState = (state: States) =>
  HIDDEN_STATES.includes(state as unknown as HiddenStates);

export const isReachableItemClonable = (
  reachableItem: ReactElement<ReachableItemProps>,
  state: Record<string, States>
) =>
  isChildReachableItem(reachableItem) &&
  !isHiddenState(state[reachableItem.props.stateKey] || "unknown");

export const scrollToReachableItem = (headerId: string) => {
  const element = document.getElementById(headerId);
  const navbarHeight = document
    .querySelector('[class*="navbar_header__"]')
    ?.getBoundingClientRect().height;

  const breadcrumbHeight = document
    .querySelector('[class*="action-bar-wrapper_action-bar"]')
    ?.getBoundingClientRect().height;

  if (navbarHeight && breadcrumbHeight) {
    const headerOffset = navbarHeight + breadcrumbHeight;
    const elementPosition = element?.getBoundingClientRect().top;
    const offsetPosition =
      elementPosition && elementPosition + window.scrollY - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};
