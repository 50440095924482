import { useMemo } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useGate } from "@nestoca/gate-react";
import { useTenantSetting } from "@nestoca/multi-tenant";
import { Box, Button, Divider, Flex, Typography } from "@nestoca/ui";
import { useAnalytics } from "@shared/analytics";
import { useGetAccount } from "@shared/api/hooks/account";
import { DatadogMask } from "@shared/datadog";
import { useTranslation } from "react-i18next";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";

import styles from "../burger-menu.module.scss";
import { CommunicationPreferences } from "../communication-preferences/communication-preferences";
import { DeleteAccount } from "../delete-account";
import { LanguageSelector } from "../language-selector";

import { SettingsEditable } from "./settings-editable";

import type { BurgerMenuPageType } from "../burger-menu";

export type UserInfoField = {
  id: string;
  label: string;
  value?: string;
  disabled: boolean;
};

export const Settings = ({
  setMenuPage,
  setMenuOpen,
}: {
  setMenuPage: (menuPage: BurgerMenuPageType) => void;
  setMenuOpen: (menuOpen: boolean) => void;
}) => {
  const { value: enableDeleteAccount } = useTenantSetting(
    "enableDeleteAccount"
  );
  const { value: enableEditAccountInfo } = useTenantSetting(
    "enableEditAccountInfo"
  );
  const gate = useGate();
  const isExternalBroker = gate.getRole() === "externalbroker";

  const { track } = useAnalytics();
  const { data: account, isPending } = useGetAccount();
  const { t } = useTranslation("common");
  const { logout } = useAuth0();

  const { personalInfo, contactDetails } = useMemo(() => {
    return {
      personalInfo: [
        {
          id: "firstName",
          label: t("firstName"),
          value: account?.firstName,
          disabled: true,
        },
        {
          id: "lastName",
          label: t("lastName"),
          value: account?.lastName,
          disabled: true,
        },
      ],
      contactDetails: [
        {
          id: "phone",
          label: t("userPhoneNumber"),
          value: account?.phone,
          disabled: false,
        },
        {
          id: "email",
          label: t("emailAddress"),
          value: account?.email,
          disabled: true,
        },
      ],
    };
  }, [account, t]);

  if (!account || isPending) {
    return null;
  }

  return (
    <>
      {enableEditAccountInfo && !isExternalBroker ? (
        <SettingsEditable
          setMenuPage={setMenuPage}
          setMenuOpen={setMenuOpen}
          personalInfo={personalInfo}
          contactDetails={contactDetails}
        />
      ) : (
        <>
          <Flex direction="column" align="start">
            <Button
              size="large"
              variant="ghost"
              onClick={() => setMenuPage("HOME")}
              className={styles["back-button"]}
              aria-label="back to home burger menu"
              data-dd-action-name="back to home burger menu"
            >
              <BsFillArrowLeftCircleFill size={24} />
              <Typography size="00" weight={7}>
                {t("back")}
              </Typography>
            </Button>
            <Typography as="h1" size={4} weight={6} className={styles.header}>
              {isExternalBroker ? t("sidebar.profile") : t("settings")}
            </Typography>
            <Flex direction="column">
              <Box className={styles["settings__user-details"]}>
                <Typography size={1} weight={7}>
                  {t("personalInfo")}
                </Typography>
                {personalInfo.map((user) => (
                  <UserDetails
                    key={user.id}
                    label={user.label}
                    value={user.value}
                  />
                ))}
              </Box>
              <Box className={styles["settings__user-details"]}>
                <Typography size={1} weight={7}>
                  {isExternalBroker
                    ? t("communicationPreferences")
                    : t("contactDetails")}
                </Typography>
                {contactDetails.map((user) => (
                  <UserDetails
                    key={user.id}
                    label={user.label}
                    value={user.value}
                  />
                ))}
              </Box>
            </Flex>
          </Flex>
          {!isExternalBroker && (
            <>
              <LanguageSelector />
              <Divider />
              <CommunicationPreferences />
            </>
          )}
          <Flex direction="column" justify="center" grow={1} gap={3}>
            <Button
              variant="secondary"
              className={styles["burger-menu--signOut"]}
              onClick={() => {
                track({ event: "logout" });
                logout({
                  logoutParams: {
                    /**
                     * TODO: This is temporary for a hotfix to prod. We need to figure out a better way to customize this.
                     * Maybe an env variable?
                     */
                    returnTo: `${window.location.origin}/`,
                    federated: true,
                  },
                });
              }}
              data-dd-action-name="logout"
            >
              {t("signOut")}
            </Button>
            {enableDeleteAccount && !isExternalBroker && (
              <DeleteAccount setMenuOpen={setMenuOpen} />
            )}
          </Flex>
        </>
      )}
    </>
  );
};

const UserDetails = ({ label, value }: { label: string; value?: string }) => (
  <Flex
    direction="column"
    className={styles["settings__user-details-item"]}
    key={label}
  >
    <Typography size="00" weight={5}>
      {label}
    </Typography>
    <Typography size={0} weight={7}>
      <DatadogMask data-dd-action-name={`user detail ${label}`}>
        {value}
      </DatadogMask>
    </Typography>
  </Flex>
);
