import { Chip } from "@nestoca/ui";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { BsCheckCircleFill } from "react-icons/bs";

import styles from "./complete-count-chip.module.scss";

export const CompleteCountChip = ({
  totalCount,
  completeCount,
}: {
  totalCount: number;
  completeCount: number;
}) => {
  const { t } = useTranslation("applications");
  const allCompleted = totalCount === completeCount;

  return (
    <Chip
      label={`${completeCount} / ${totalCount} ${t("complete")}`}
      leadingIcon={allCompleted && <BsCheckCircleFill color="white" />}
      size="large"
      className={clsx(styles.chip, {
        [styles["chip-complete"]]: allCompleted,
      })}
    />
  );
};
