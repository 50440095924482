export const isMatchingApiPostalCode = (
  userPostalCode?: string,
  apiPostalCode?: string
): boolean => {
  const shortUserPostalCode =
    userPostalCode && userPostalCode.slice(0, 2).toUpperCase();
  const shortApiPostalCode =
    apiPostalCode && apiPostalCode.slice(0, 3).toUpperCase();

  return shortUserPostalCode === shortApiPostalCode;
};
