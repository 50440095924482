export enum LenderEnum {
  ALTERNA = "ALTERNA",
  B2B_LAURENTIAN = "B2B_LAURENTIAN",
  BMO = "BMO",
  CIBC = "CIBC",
  CMLS = "CMLS",
  DESJARDINS = "DESJARDINS",
  DUCA = "DUCA",
  EQUITABLE_BANK = "EQUITABLE_BANK",
  FIRST_NATIONAL = "FIRST_NATIONAL",
  HOME_TRUST = "HOME_TRUST",
  ICICI_BANK = "ICICI_BANK",
  IG = "IG",
  INDUSTRIAL_ALLIANCE = "INDUSTRIAL_ALLIANCE",
  LAURENTIAN = "LAURENTIAN",
  LENDWISE = "LENDWISE",
  MANULIFE = "MANULIFE",
  MEREDIAN = "MEREDIAN",
  MERIX = "MERIX",
  MCAP = "MCAP",
  NATIONAL_BANK = "NATIONAL_BANK",
  OPTIMUM_MORTGAGE = "OPTIMUM_MORTGAGE",
  PC_FINANCIALS = "PC_FINANCIALS",
  RADIUS_FINANCIAL = "RADIUS_FINANCIAL",
  RBC = "RBC",
  SCOTIA = "SCOTIA",
  STREET_CAPITAL = "STREET_CAPITAL",
  TANGERINE = "TANGERINE",
  TD = "TD",
  SOLUTIONS_BANKING = "SOLUTIONS_BANKING",
  PRIVATE = "PRIVATE",
  OTHER = "OTHER",
}

export const LENDERS = Object.entries(LenderEnum).map(([key, value]) => ({
  label: `common:lender.${key.toLowerCase()}`,
  value,
}));
