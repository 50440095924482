import { Box, Flex, isEmpty, Typography } from "@nestoca/ui";
import { Application, CIRCLE_PROGRESS_TRACKER_PHASES } from "@shared/constants";
import { useCircleProgressTracker } from "@shared/ui";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { BsChevronRight } from "react-icons/bs";

import mobileStyles from "../progress-tracker-mobile.module.scss";

import styles from "./circle-progress-button.module.scss";
import { PhaseHeader } from "./phase-header";

type PropsType = {
  application: Application;
  applicantId: number;
  setIsCircleProgressVisible: (isCircleProgressVisible: boolean) => void;
};

export const CircleProgressButton = ({
  application,
  applicantId,
  setIsCircleProgressVisible,
}: PropsType) => {
  const { t } = useTranslation("docs");

  const tracker = useCircleProgressTracker({
    application,
    applicantId,
  });

  if (isEmpty(tracker)) {
    return (
      <Box className={mobileStyles["tracker-wrapper"]}>
        <Flex className={styles.tracker} direction="column" justify="start">
          <Typography
            className={clsx(
              styles["tracker__closed"],
              styles["tracker__closed--name"]
            )}
          >
            {t("progressTracker")}
          </Typography>
          <Typography weight={7} className={styles["tracker__closed"]}>
            {t("appClosed.header")}
          </Typography>
        </Flex>
      </Box>
    );
  }

  const isGettingToKnowPhase =
    tracker.currentProgressPhase ===
    CIRCLE_PROGRESS_TRACKER_PHASES.GETTING_TO_KNOW;

  const completePercent: number | [number, number] = isGettingToKnowPhase
    ? [
        tracker.getting_to_know.questionnaireCount.completePercent,
        tracker.getting_to_know.documentsCount.completePercent,
      ]
    : tracker[tracker.currentProgressPhase].completePercent || 0;

  return (
    <Box
      className={mobileStyles["tracker-wrapper"]}
      onClick={() => setIsCircleProgressVisible(true)}
    >
      <Flex className={styles.tracker}>
        <PhaseHeader
          phaseNumber={tracker[tracker.currentProgressPhase].phaseNumber || 0}
          phase={
            tracker.currentProgressPhase ||
            CIRCLE_PROGRESS_TRACKER_PHASES.GETTING_TO_KNOW
          }
          isActive={false}
          completePercent={completePercent || 0}
          totalCompletePercent={
            tracker[tracker.currentProgressPhase].completePercent
          }
        />
        <BsChevronRight size={24} color={"var(--color-tomato-500)"} />
      </Flex>
    </Box>
  );
};
