import { Flex, Typography, useBreakpointValue } from "@nestoca/ui";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import trackerStyles from "./circle-progress-button.module.scss";
import styles from "./circle-progress-tracker.module.scss";
export const CircleProgressClosed = () => {
  const isMobile = useBreakpointValue({ default: true, md: false });
  const { t } = useTranslation("docs");

  return (
    <Flex
      direction="column"
      className={clsx(styles.progress, {
        [(styles["progress--desktop"], styles["progress--closed"])]: !isMobile,
      })}
      gap={5}
    >
      <Typography className={styles["progress__header"]}>
        {t("appProgress")}
      </Typography>
      <Flex
        className={trackerStyles.tracker}
        direction="column"
        justify="start"
      >
        <Typography className={trackerStyles["tracker__closed"]} weight={7}>
          {t("appClosed.header")}
        </Typography>
        <Typography
          className={clsx(
            trackerStyles["tracker__closed"],
            trackerStyles["tracker__closed--name"]
          )}
        >
          {t("appClosed.message")}
        </Typography>
      </Flex>
    </Flex>
  );
};
