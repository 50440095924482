import { Flex, Grid, Typography } from "@nestoca/ui";
import { charts } from "@shared/ui/components/charts";
import { useWizard } from "@shared/ui/hooks/use-wizard";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { BsCurrencyDollar, BsHouse, BsRecycle } from "react-icons/bs";

import styles from "./home-page-gaq.module.scss";

import type {
  NewMortgageChartContext,
  RefinanceChartContext,
  RenewalChartContext,
} from "@shared/ui/components/charts";

export const HomePageGAQ = () => {
  const { t } = useTranslation("type-selector");
  const { basePath } = useRouter();
  const baseLink = basePath === "/getaquote" ? "" : "/getaquote";

  const { getNextStep: getNewMortgageStep } =
    useWizard<NewMortgageChartContext>(charts.NEW, "INITIAL");

  const { getNextStep: getRenewalStep } = useWizard<RenewalChartContext>(
    charts.RENEWAL,
    "INITIAL"
  );

  const { getNextStep: getRefinanceStep } = useWizard<RefinanceChartContext>(
    charts.REFINANCE,
    "INITIAL"
  );

  return (
    <Grid className={styles["card-container"]}>
      <Link
        href={`${baseLink}/new-mortgage/${getNewMortgageStep()}`}
        passHref
        legacyBehavior
      >
        <Flex
          as="a"
          data-linkbutton
          className={styles.card}
          data-testid="new-mortgage"
        >
          <BsHouse className={styles["card-icon"]} />
          <Typography className={styles["card-title"]} weight={7} as="h2">
            {t("types.NEW.title")}
          </Typography>
          <Typography size={0}>{t("types.NEW.description")}</Typography>
        </Flex>
      </Link>
      <Link
        href={`${baseLink}/renewal/${getRenewalStep()}`}
        passHref
        legacyBehavior
      >
        <Flex
          as="a"
          data-linkbutton
          className={styles.card}
          data-testid="renewal"
        >
          <BsRecycle className={styles["card-icon"]} />
          <Typography className={styles["card-title"]} weight={7} as="h2">
            {t("types.RENEWAL.title")}
          </Typography>
          <Typography size={0}>{t("types.RENEWAL.description")}</Typography>
        </Flex>
      </Link>
      <Link
        href={`${baseLink}/refinance/${getRefinanceStep()}`}
        passHref
        legacyBehavior
      >
        <Flex
          as="a"
          data-linkbutton
          className={styles.card}
          data-testid="refinance"
        >
          <BsCurrencyDollar className={styles["card-icon"]} />
          <Typography className={styles["card-title"]} weight={7} as="h2">
            {t("types.REFINANCE.title")}
          </Typography>
          <Typography size={0}>{t("types.REFINANCE.description")}</Typography>
        </Flex>
      </Link>
    </Grid>
  );
};
