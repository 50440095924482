import { z } from "zod";

export const targetPropertyCompletedSchema = z.object({
  purpose: z.string().min(1),
  constructionType: z.string().min(1),
  propertyType: z.string().min(1),
  tenure: z.string().min(1),
  waterType: z.string().min(1),
  propertyStyle: z.string().min(1),
  yearBuilt: z.number().min(0),
  estimatedPropertyValue: z.number().min(0),
});

export const targetPropertyNotFoundCompletedSchema = z.object({
  purpose: z.string().min(1),
  propertyType: z.string().min(1),
  purchasePrice: z.number().min(0),
});
