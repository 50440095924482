import * as z from "zod";

// *** NOTE: importing from @shared/utils is not working ***
// *** so we are importing with relative path the functions here ***
// It create a circular dependency
import { addTenYears, currentDate } from "../../../utils/dates";

const DATE_VALIDATION_REGEX =
  /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/;

export const maturityDateSchema = z
  .string({
    required_error: "form:error.required",
    invalid_type_error: "form:error.required",
  })
  .trim()
  .regex(DATE_VALIDATION_REGEX, {
    message: "form:error.invalidDate",
  })
  .refine((value) => value >= currentDate, {
    message: "form:error.dateShouldBeFuture",
  })
  .refine((value) => value <= addTenYears(), {
    message: "form:error.invalidDate",
  });

export const pastDateSchema = z
  .string({
    required_error: "form:error.required",
    invalid_type_error: "form:error.required",
  })
  .trim()
  .regex(DATE_VALIDATION_REGEX, {
    message: "form:error.invalidDate",
  })
  .refine((value) => value <= currentDate, {
    message: "form:error.invalidDate",
  });
