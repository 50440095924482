import { api } from "@shared/api/client";

import type { QuoteMatrix } from "@shared/constants/quotes";
import type { MatrixSchema } from "@shared/constants/schema";

export const getQuotesMatrix = async (
  params: MatrixSchema,
  { signal }: { signal?: AbortSignal } = {}
) => {
  const { data } = await api.get<QuoteMatrix>("/quotes/matrix", {
    params,
    signal,
  });

  return data;
};
