import { useMemo } from "react";

import { Box, Flex } from "@nestoca/ui";
import {
  TRANSACTION_TYPE_LABEL,
  TransactionTypeEnum,
  DocumentStep,
} from "@shared/constants";
import { DatadogMask } from "@shared/datadog";
import { KeepI18ExprHappy } from "@shared/utils";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

import styles from "./application-selector.module.scss";

export type applicationSelectorOptionType = {
  id: number;
  address: string;
  stage: string;
  type: TransactionTypeEnum;
  index: number;
  applicantId: number;
  onClick?: () => void;
};

export const ApplicationSelectorOption = ({
  id,
  address,
  type,
  index,
  stage,
  applicantId,
  applicationId,
  onClick,
}: applicationSelectorOptionType & {
  applicationId: number;
}) => {
  const { t } = useTranslation("applications");
  const { basePath, locale } = useRouter();

  const applicationLink = useMemo(() => {
    return basePath === "/documents"
      ? `/${locale}/${id}/applicant/${applicantId}?step=${DocumentStep.All}`
      : `/applications/${id}/applicant/${applicantId}`;
  }, [basePath, applicantId, id, locale]);

  return (
    <Link
      onClick={onClick}
      href={applicationLink}
      data-dd-action-name="application selector"
    >
      <Flex
        className={clsx(styles.option, {
          [styles["option--selected"]]: id === applicationId,
        })}
      >
        <DatadogMask
          as={Box}
          className={styles["option-address"]}
          data-dd-action-name="application selector address"
        >
          {address
            ? address
            : `${t(TRANSACTION_TYPE_LABEL[type] as KeepI18ExprHappy)} ${index}`}
        </DatadogMask>
        <Box className={styles["option-stage"]}>
          {t(stage as KeepI18ExprHappy)}
        </Box>
      </Flex>
    </Link>
  );
};
