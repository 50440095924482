import { createContext, useId, useIds } from "@nestoca/ui";

import { useReachableContext } from "./use-reachable";

export interface ReachableItemContext
  extends Pick<
    UseReachableItemCreateContextReturn,
    | "index"
    | "id"
    | "headerId"
    | "contentId"
    | "state"
    | "isUnreached"
    | "isReached"
    | "isOpen"
    | "isClosed"
    | "showCounter"
  > {
  strict?: boolean;
}

const [ReachableItemProvider, useReachableItem] =
  createContext<ReachableItemContext>({
    name: "ReachableContext",
    strict: false,
  });

export { ReachableItemProvider, useReachableItem };

export type UseReachableItemCreateContextReturn = ReturnType<
  typeof useReachableItemCreateContext
>;

export function useReachableItemCreateContext(props: any) {
  const { stateKey, index, id: idProp, ...htmlProps } = props;

  const { getReachableItemProps } = useReachableContext();

  /**
   * Generate unique ids for all accordion item components (button and panel)
   */
  const id = useId(idProp, `reachable-item-${stateKey}`);

  const [headerId, contentId] = useIds(id, "header", "content");

  const { state, isUnreached, isReached, isOpen, isClosed, showCounter } =
    getReachableItemProps(stateKey);

  return {
    stateKey,
    index,
    id,
    headerId,
    contentId,
    state,
    isUnreached,
    isReached,
    isOpen,
    isClosed,
    htmlProps,
    showCounter,
  };
}
