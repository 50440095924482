import { z } from "zod";

export const mainApplicantInformationSchema = z.object({
  permissions: z.literal("MAIN_APPLICANT"),
  salutation: z.string().trim().min(2),
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1),
  dateOfBirth: z.string().trim().min(2),
  phone: z.string().trim().min(10),
  maritalStatus: z.string().trim().min(2),
});

export const coApplicantInformationSchema = z.object({
  permissions: z.literal("CO_APPLICANT_DEFAULT"),
  salutation: z.string().trim().min(2),
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1),
  dateOfBirth: z.string().trim().min(2),
  phone: z.string().trim().min(10),
  maritalStatus: z.string().trim().min(2),
  relationToMainApplicant: z.string().trim().min(0).nullable(),
});

export const applicantInformationSchema = z.discriminatedUnion("permissions", [
  mainApplicantInformationSchema,
  coApplicantInformationSchema,
]);
