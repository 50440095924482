import { useMemo, cloneElement } from "react";
import type { HTMLAttributes } from "react";

import { getValidChildren, VStack } from "@nestoca/ui";
import clsx from "clsx";

import styles from "./reachable.module.scss";
import { ReachableProvider, useReachable } from "./use-reachable";
import { isReachableItemClonable } from "./utils";

import type { UseReachableProps } from "./use-reachable";
import type { FlexProps } from "@nestoca/ui";

interface ReachableProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "onChange">, // use `onChange` from UseReachableProps
    Omit<FlexProps, "onChange">,
    Omit<UseReachableProps, "itemLength"> {
  showCounter?: boolean;
}

export const Reachable = ({
  className,
  children,
  state,
  ...rest
}: ReachableProps) => {
  const validChildren = getValidChildren(children);

  let itemIndex = 0;

  // Keep only `<ReachableItem />` components
  const clones = validChildren
    .map((child) => {
      if (isReachableItemClonable(child, state)) {
        const index = itemIndex;
        itemIndex++;

        return cloneElement(child, {
          ...child.props,
          index,
        });
      }

      return null;
    })
    // remove empty children, so we have the right itemLength for the counter
    .filter((n) => n);

  const { id, htmlProps, ...context } = useReachable({
    ...rest,
    state,
    itemLength: clones.length,
  });

  const ctx = useMemo(() => context, [context]);

  return (
    <ReachableProvider value={ctx}>
      <VStack
        id={id}
        align="stretch"
        className={clsx(styles.reachable, className)}
        {...htmlProps}
      >
        {clones}
      </VStack>
    </ReachableProvider>
  );
};
