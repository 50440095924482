import { AccountDeletionReason } from "./account.enum";

export const ACCOUNT_DELETION_REASON_OPTIONS = [
  {
    value: AccountDeletionReason.FundedOtherInstitution,
    label: "common:accountDeletion.feedback.fundedOtherInstitution",
  },
  {
    value: AccountDeletionReason.NestoDifficultToUse,
    label: "common:accountDeletion.feedback.nestoDifficultToUse",
  },
  {
    value: AccountDeletionReason.NotEffective,
    label: "common:accountDeletion.feedback.notEffective",
  },
  {
    value: AccountDeletionReason.WindowShopping,
    label: "common:accountDeletion.feedback.windowShopping",
  },
  {
    value: AccountDeletionReason.NoReason,
    label: "common:accountDeletion.feedback.noReason",
  },
  {
    value: AccountDeletionReason.Other,
    label: "common:accountDeletion.feedback.other",
  },
];
