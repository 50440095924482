export const YES_NO_BOOLEAN_OPTIONS = [
  {
    label: "form:yes",
    value: true,
  },
  {
    label: "form:no",
    value: false,
  },
];

export const YES_NO_OPTIONS = [
  {
    label: "form:yes",
    value: "YES",
  },
  {
    label: "form:no",
    value: "NO",
  },
];
