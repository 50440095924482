import { Card, Center, Heading } from "@nestoca/ui";
import { useTranslation } from "next-i18next";

import styles from "./logout.module.scss";

export const Logout = () => {
  const { t } = useTranslation("common");

  return (
    <Card className={styles["logout"]}>
      <Center className={styles["logout__content"]}>
        <Heading
          weight={7}
          size={5}
          className={styles["logout__header"]}
          aria-label="logged out"
        >
          {t("logout.header")}
        </Heading>
      </Center>
    </Card>
  );
};
