import { PropsWithChildren, forwardRef } from "react";

import {
  PolymorphicComponentPropsWithRef,
  PolymorphicRef,
} from "../../types/polymorhphic-component-types";
import { DatadogPrivacy } from "../datadog-privacy";

export interface _DatadogPrivacyProps {
  level?: "hidden";
}

export type DatadogPrivacyProps<C extends React.ElementType> =
  PolymorphicComponentPropsWithRef<C, PropsWithChildren<_DatadogPrivacyProps>>;

type DatadogPrivacyComponent = <C extends React.ElementType = "span">(
  props: DatadogPrivacyProps<C>
) => React.ReactElement | null;

export const DatadogHidden: DatadogPrivacyComponent = forwardRef(
  <C extends React.ElementType = "span">(
    props: DatadogPrivacyProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    return <DatadogPrivacy {...props} ref={ref} level="hidden" />;
  }
);
