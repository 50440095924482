import { useEffect } from "react";

/**
 * Observes a given element using ResizeObserver.
 * Note: the hook intentionally takes the DOM node, not refs,
 * in order to be able to update when the nodes change.
 * A callback ref is used here to permit this behaviour,
 * and useState is an appropriate way to implement this.
 * @ref: https://legacy.reactjs.org/docs/refs-and-the-dom.html#callback-refs
 */
export const useResizeObserver = (
  // The element to observe, most of the time this will be a state so it can be null
  element: Element | null,
  observerCallback: ResizeObserverCallback,
  options?: ResizeObserverOptions
) => {
  useEffect(() => {
    if (!element || !("ResizeObserver" in window)) {
      return undefined;
    }

    // Create a new observer
    const observer = new ResizeObserver(observerCallback);
    // Observe the given element
    observer.observe(element, options);

    return () => {
      // Remove the observer as soon as the component is unmounted
      observer.disconnect();
    };
  }, [element, options, observerCallback]);
};
