import { Flex, Typography, useBreakpointValue } from "@nestoca/ui";
import { useGetApplicationsByApplicantId } from "@shared/api/hooks/applications";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { BsChevronDown } from "react-icons/bs";

import styles from "../application-selector/application-selector.module.scss";

import { Address } from "./address";

export const ApplicationAddress = () => {
  const { t } = useTranslation("applications");
  const { data: applications } = useGetApplicationsByApplicantId();
  const isMobile = useBreakpointValue({ default: true, md: false });

  const atLeastTwoApplications = applications && applications.length >= 2;

  return (
    <Flex
      className={clsx(styles.dashboard, {
        [styles.button]: atLeastTwoApplications,
      })}
      data-testid="application-address"
    >
      {!isMobile && (
        <Typography className={styles["dashboard-heading"]}>
          {t("application.dashboard")}
        </Typography>
      )}
      <Flex gap={2} align="center" className={styles["dashboard-wrapper"]}>
        <Address />
        {atLeastTwoApplications && !isMobile && <BsChevronDown />}
      </Flex>
    </Flex>
  );
};
