import React from "react";

import { Card, Chip, Typography } from "@nestoca/ui";
import { ApplicationState } from "@shared/constants";
import { useTranslation } from "next-i18next";

import { getApplicationStateI18nKey } from "../utils";

import styles from "./header.module.scss";

export interface HeaderProps {
  state: ApplicationState;
  id: number;
}

const SelectApplicationCardHeader = ({ state, id }: HeaderProps) => {
  const { t } = useTranslation("applications");

  return (
    <Card.Header className={styles["select-application-card--header"]}>
      <Typography size={0}>Application #{id}</Typography>
      <Chip variant="warning" label={t(getApplicationStateI18nKey(state))} />
    </Card.Header>
  );
};

export default SelectApplicationCardHeader;
