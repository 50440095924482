/* eslint-disable @typescript-eslint/no-explicit-any */
export type CallbackFn<Context = unknown> = (
  value: any,
  {
    chart,
    currentStep,
    context,
  }: {
    chart: ChartMachine<Context>;
    currentStep: string;
    context?: Context;
  }
) => string;

type ChartFinalNode = { type: "final" };
type ChartNode<Context = unknown> =
  | string
  | number
  | CallbackFn<Context>
  | ChartFinalNode
  | {
      [index: string]: string | CallbackFn<Context> | ChartNode<Context>;
    };

export type ChartMachine<Context = unknown> = Record<
  string,
  ChartNode<Context>
> & {
  INITIAL: string;
};

export type UseWizardReturn<Context = unknown> = ReturnType<
  typeof useWizard<Context>
>;

export const useWizard = <
  Context = unknown,
  Machine extends ChartMachine<any> = ChartMachine<any>,
>(
  chart: Machine,
  currentStep: string,
  context?: Context
) => {
  const getNextStep = (value?: string | number) => {
    const nextStep = chart[currentStep];

    if (isFunction<Context>(nextStep)) {
      const callback = nextStep;
      const nextStepValue = callback(value, {
        chart,
        currentStep,
        context,
      });

      return nextStepValue;
    }

    if (isObject<Context>(nextStep)) {
      type Val = keyof ChartNode;

      return nextStep[value as Val] ?? nextStep["*" as Val];
    }

    if (isFinalNode(nextStep)) {
      return undefined;
    }

    return nextStep;
  };

  const _getAllStates = () => getAllStates(chart);

  const getFinalNode = () =>
    Object.keys(chart).find((i) => isFinalNode(chart[i]));

  const _isFinalNode = (key: string) => isFinalNode(chart[key]);

  return {
    getNextStep,
    getAllStates: _getAllStates,
    getFinalNode,
    isFinalNode: _isFinalNode,
  };
};

// Utils
const getAllStates = <Context = unknown>(chart: ChartMachine<Context>) =>
  Object.keys(chart).filter((i) => i !== "INITIAL");

const isFunction = <Context = unknown>(
  value: any
): value is CallbackFn<Context> =>
  typeof value === "function" && value.constructor.name === "Function";

const isObject = <Context = unknown>(value: any): value is ChartNode<Context> =>
  typeof value === "object" && value.constructor.name === "Object";

const isFinalNode = (value: any): value is ChartFinalNode =>
  typeof value === "object" && value.type === "final";
