import * as z from "zod";

export const addressSchema = z
  .object({
    countryCode: z
      .string({ required_error: "form:error.required" })
      .trim()
      .min(1, { message: "form:error.required" }),
    streetNumber: z
      .string({ required_error: "form:error.required" })
      .trim()
      .min(1, { message: "form:error.required" }),
    street: z
      .string({ required_error: "form:error.required" })
      .trim()
      .min(1, { message: "form:error.required" }),
    unit: z
      .string()
      .trim()
      .nullish()
      .transform((s) => s ?? ""),
    city: z
      .string({ required_error: "form:error.required" })
      .trim()
      .min(1, { message: "form:error.required" }),
    stateCode: z
      .string({ required_error: "form:error.required" })
      .trim()
      .min(1, { message: "form:error.required" }),
    postalCode: z
      .string({ required_error: "form:error.required" })
      .trim()
      .min(1, { message: "form:error.required" }),
  })
  .superRefine((values, ctx) => {
    // if countryCode is US then we check for US ZIP
    if (values.countryCode === "US") {
      const isOk = z
        .string()
        .trim()
        .regex(/(^\d{5}$)|(^\d{5}-\d{4}$)/, "form:error.invalid")
        .safeParse(values.postalCode);

      if (!isOk.success) {
        isOk.error.issues.forEach((issue) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: issue.message,
            path: [...issue.path, "postalCode"],
          });
        });
      }

      return;
    }

    // test Canadian postal code
    const isOk = z
      .string()
      .trim()
      .toUpperCase()
      .regex(
        /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z] ?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$/i,
        "form:error.invalid"
      )
      .safeParse(values.postalCode);

    if (!isOk.success) {
      isOk.error.issues.forEach((issue) => {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: issue.message,
          path: [...issue.path, "postalCode"],
        });
      });
    }
  });
