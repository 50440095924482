import React from "react";

import { Flex, Sidebar } from "@nestoca/ui";
import { Sidebar as PrimarySidebar } from "@shared/ui/components/layout/sidebar";
import clsx from "clsx";

import styles from "./layout.module.scss";

export type LayoutProps = {
  primarySidebarContent: React.ReactElement;
  secondarySidebarContent?: React.ReactElement;
} & React.HTMLAttributes<HTMLDivElement>;

export const Layout = ({
  children,
  className,
  primarySidebarContent,
  secondarySidebarContent,
  ...rest
}: LayoutProps) => {
  return (
    <Flex className={clsx(styles.layout, className)} {...rest}>
      <PrimarySidebar>{primarySidebarContent}</PrimarySidebar>
      {secondarySidebarContent && (
        <Sidebar className={styles["secondary-sidebar"]} variant="secondary">
          <Sidebar.Content>{secondarySidebarContent}</Sidebar.Content>
        </Sidebar>
      )}
      <Flex className={styles.section} as="section">
        {children}
      </Flex>
    </Flex>
  );
};
