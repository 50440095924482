import { PropsWithChildren } from "react";

import { Flex } from "@nestoca/ui";
import clsx from "clsx";

import styles from "./action-bar-wrapper.module.scss";

type ActionBarWrapperProps = {
  className?: string;
};

export const ActionBarWrapper = ({
  children,
  className,
}: PropsWithChildren<ActionBarWrapperProps>) => {
  return (
    <Flex gap={4} className={clsx([styles["action-bar"], className])}>
      {children}
    </Flex>
  );
};
