import { IncomeOther } from "@../constants";
import { api } from "@shared/api/client";

export const createApplicantOtherIncome = (
  applicationId: number,
  applicantId: number,
  params: IncomeOther
) =>
  api.post(
    `/applications/${applicationId}/applicants/${applicantId}/income/others`,
    params
  );

export const updateApplicantOtherIncome = (
  applicationId: number,
  applicantId: number,
  otherIncomeId: number,
  params: IncomeOther
) =>
  api.put(
    `/applications/${applicationId}/applicants/${applicantId}/income/others/${otherIncomeId}`,
    params
  );

export const deleteApplicantOtherIncome = (
  applicationId: number,
  applicantId: number,
  otherIncomeId: number
) =>
  api.delete(
    `/applications/${applicationId}/applicants/${applicantId}/income/others/${otherIncomeId}`
  );
