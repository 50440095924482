import { useState } from "react";
import type { PropsWithChildren } from "react";

import { QuestionnaireLayoutContext } from "./use-questionnaire-layout";

export const QuestionnaireLayoutProvider = ({
  children,
}: PropsWithChildren) => {
  const [warnBeforeQuit, setWarnBeforeQuit] = useState<boolean>(false);

  return (
    <QuestionnaireLayoutContext.Provider
      value={{ warnBeforeQuit, setWarnBeforeQuit }}
    >
      {children}
    </QuestionnaireLayoutContext.Provider>
  );
};
