import { memo } from "react";
import type { PropsWithChildren } from "react";

import { useDatadog } from "../../hooks";

import type { User } from "../../types";
import type { RumInitConfiguration } from "@datadog/browser-rum";

interface DatadogProps
  extends Omit<
    RumInitConfiguration,
    | "allowedTracingOrigins"
    | "enableExperimentalFeatures"
    | "proxyHost"
    | "replica"
  > {
  readonly enabled?: boolean;
  readonly sessionReplayRecording?: boolean;
  readonly user?: User;
}

export const Datadog = memo(
  ({ children, ...datadogInitProps }: PropsWithChildren<DatadogProps>) => {
    useDatadog(datadogInitProps);

    return <>{children}</>;
  }
);

Datadog.displayName = "Datadog";
