import { isNumber } from "./is-number";
import { isString } from "./is-string";

export const NOT_NUMBER_REGEX = /[^0-9.]/g;

// keep number and dot only
export const getAsNumber = (v: unknown, asInteger = false) => {
  if (isNumber(v)) return asInteger ? Math.floor(v) : v;

  if (isString(v)) {
    const number = asInteger
      ? Math.floor(+v.replace(NOT_NUMBER_REGEX, ""))
      : v.replace(NOT_NUMBER_REGEX, "");
    return Number(number);
  }
};
