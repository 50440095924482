import { Role } from "@nestoca/gate-core";
import { TFunction } from "i18next";

import {
  CIRCLE_PROGRESS_STATUSES,
  CIRCLE_PROGRESS_TRACKER_PHASES,
  PROGRESS_BAR_STATUSES,
  PROGRESS_STAGES,
  TransactionTypeEnum,
} from "./application.enum";
import { ApplicationTypeEnum } from "./application.types";
import { ApplicationType } from "./application.types";
import {
  ApplicationState,
  CLOSED_APPLICATION_STATE,
  STANDARD_APPLICATION_STATUSES,
  FUNDED_APPLICATION_STATE,
  HUB_APPLICATION_STATUSES,
} from "./state.enum";

export const ALL_TRANSACTION_TYPES = Object.values(TransactionTypeEnum);

export const MAIN_APPLICATION_TYPES = [
  TransactionTypeEnum.NEW,
  TransactionTypeEnum.REFINANCE,
  TransactionTypeEnum.RENEWAL,
];

export const PROGRESS_STAGES_MAP: Record<PROGRESS_STAGES, ApplicationState[]> =
  {
    [PROGRESS_STAGES.IN_PROGRESS]: [
      ApplicationState.Created,
      ApplicationState.Submitted,
      ApplicationState.UnderRevision,
      ApplicationState.Reviewed,
    ],
    [PROGRESS_STAGES.SUBMITTED]: [
      ApplicationState.LenderSubmitted,
      ApplicationState.LenderApproved,
      ApplicationState.NotesSubmitted,
    ],
    [PROGRESS_STAGES.CONDITIONAL_APPROVAL]: [
      ApplicationState.PendingCommitmentSignature,
      ApplicationState.PendingConditions,
    ],
    [PROGRESS_STAGES.FINAL_APPROVAL]: [
      ApplicationState.Complete,
      ApplicationState.NotaryAlerted,
      ApplicationState.Funded,
      ApplicationState.Closed,
      ApplicationState.Expired,
    ],
  };

export const APP_STATUSES_MAP: Record<ApplicationState, string> = {
  CREATED: "application-card.chip.in_progress",
  SUBMITTED: "application-card.chip.in_progress",
  IN_PROGRESS: "application-card.chip.in_progress",
  UNDER_REVISION: "application-card.chip.in_progress",
  REVIEWED: "application-card.chip.in_progress",
  NOTES_SUBMITTED: "application-card.chip.submitted",
  LENDER_SUBMITTED: "application-card.chip.submitted",
  LENDER_APPROVED: "application-card.chip.submitted",
  PENDING_COMMITMENT_SIGNATURE: "progressTracker.conditional_approval",
  PENDING_CONDITIONS: "progressTracker.conditional_approval",
  COMPLETE: "progressTracker.final_approval",
  NOTARY_ALERTED: "progressTracker.final_approval",
  FUNDED: "application-card.chip.funded",
  CLOSED: "application-card.chip.closed",
  EXPIRED: "application-card.chip.closed",
  LOCKED: "application-card.chip.locked",
  DELETED: "application-card.chip.deleted",
};

export const CIRCLE_PROGRESS_PHASES_MAP = {
  [CIRCLE_PROGRESS_TRACKER_PHASES.GETTING_TO_KNOW]: ["CREATED"],
  [CIRCLE_PROGRESS_TRACKER_PHASES.LENDER_APPROVAL]: [
    "SUBMITTED",
    "UNDER_REVISION",
    "REVIEWED",
    "NOTES_SUBMITTED",
    "LENDER_SUBMITTED",
    "LENDER_APPROVED",
    "PENDING_COMMITMENT_SIGNATURE",
    "PENDING_CONDITIONS",
  ],
  [CIRCLE_PROGRESS_TRACKER_PHASES.FUNDING]: [
    "COMPLETE",
    "NOTARY_ALERTED",
    "FUNDED",
  ],
};

export const CIRCLE_PROGRESS_STAGES_GROUPS = {
  [CIRCLE_PROGRESS_TRACKER_PHASES.LENDER_APPROVAL]: [
    "SUBMITTED",
    "REVIEW",
    "LENDER_SUBMITTED",
    "LENDER_APPROVED",
    "PENDING_SIGNATURE",
    "PENDING_CONDITIONS",
  ],
  [CIRCLE_PROGRESS_TRACKER_PHASES.FUNDING]: ["COMPLETE", "FUNDED"],
};

export const APP_CIRCLE_PROGRESS_STATUSES_GROUPS = {
  [CIRCLE_PROGRESS_STATUSES.SUBMITTED]: ["SUBMITTED"],
  [CIRCLE_PROGRESS_STATUSES.REVIEW]: [
    "UNDER_REVISION",
    "REVIEWED",
    "NOTES_SUBMITTED",
  ],
  [CIRCLE_PROGRESS_STATUSES.LENDER_SUBMITTED]: ["LENDER_SUBMITTED"],
  [CIRCLE_PROGRESS_STATUSES.LENDER_APPROVED]: ["LENDER_APPROVED"],
  [CIRCLE_PROGRESS_STATUSES.PENDING_SIGNATURE]: [
    "PENDING_COMMITMENT_SIGNATURE",
  ],
  [CIRCLE_PROGRESS_STATUSES.PENDING_CONDITIONS]: ["PENDING_CONDITIONS"],
  [CIRCLE_PROGRESS_STATUSES.COMPLETE]: ["COMPLETE", "NOTARY_ALERTED"],
  [CIRCLE_PROGRESS_STATUSES.FUNDED]: ["FUNDED"],
};

export const APP_CIRCLE_PROGRESS_STATUSES_MAP = {
  [CIRCLE_PROGRESS_STATUSES.SUBMITTED]: "progress.status.submitted",
  [CIRCLE_PROGRESS_STATUSES.REVIEW]: "progress.status.review",
  [CIRCLE_PROGRESS_STATUSES.LENDER_SUBMITTED]:
    "progress.status.lender-submitted",
  [CIRCLE_PROGRESS_STATUSES.LENDER_APPROVED]: "progress.status.lender-approved",
  [CIRCLE_PROGRESS_STATUSES.PENDING_SIGNATURE]:
    "progress.status.pending-signature",
  [CIRCLE_PROGRESS_STATUSES.PENDING_CONDITIONS]:
    "progress.status.pending-conditions",
  [CIRCLE_PROGRESS_STATUSES.COMPLETE]: "progress.status.complete",
  [CIRCLE_PROGRESS_STATUSES.FUNDED]: "progress.status.funded",
};

export const PROGRESS_BAR_STATUS: Record<PROGRESS_BAR_STATUSES, string> = {
  [PROGRESS_BAR_STATUSES.CLOSED]: "CLOSED",
  [PROGRESS_BAR_STATUSES.APPLICATION_RECEIVED]: "APPLICATION_RECEIVED",
  [PROGRESS_BAR_STATUSES.UNDERWRITING_IN_PROGRESS]: "UNDERWRITING_IN_PROGRESS",
  [PROGRESS_BAR_STATUSES.CONDITIONALLY_APPROVED]: "CONDITIONALLY_APPROVED",
  [PROGRESS_BAR_STATUSES.FILE_COMPLETE]: "FILE_COMPLETE",
  [PROGRESS_BAR_STATUSES.FUNDED]: "FUNDED",
};

export const PROGRESS_BAR_STATUS_GROUPS: Record<
  PROGRESS_BAR_STATUSES,
  ApplicationState[]
> = {
  [PROGRESS_BAR_STATUSES.APPLICATION_RECEIVED]: [
    ApplicationState.Created,
    ApplicationState.Submitted,
    ApplicationState.UnderRevision,
    ApplicationState.Reviewed,
  ],
  [PROGRESS_BAR_STATUSES.UNDERWRITING_IN_PROGRESS]: [
    ApplicationState.NotesSubmitted,
    ApplicationState.LenderSubmitted,
  ],
  [PROGRESS_BAR_STATUSES.CONDITIONALLY_APPROVED]: [
    ApplicationState.LenderApproved,
    ApplicationState.PendingCommitmentSignature,
    ApplicationState.PendingConditions,
  ],
  [PROGRESS_BAR_STATUSES.FILE_COMPLETE]: [
    ApplicationState.Complete,
    ApplicationState.NotaryAlerted,
  ],
  [PROGRESS_BAR_STATUSES.FUNDED]: [ApplicationState.Funded],
  [PROGRESS_BAR_STATUSES.CLOSED]: [
    ApplicationState.Closed,
    ApplicationState.Expired,
  ],
};

export const ABOUT_YOU = "aboutYou";

export const PROPERTY_INFO = "propertyInfo";

export const APPLICATION_STATE_GROUP = {
  ACTIVE_GROUP: "ACTIVE_GROUP",
  FUNDED_GROUP: "FUNDED_GROUP",
  CLOSED_GROUP: "CLOSED_GROUP",
} as const;

export const CLOSED_LOST_CATEGORIES = [
  "Not Relevant",
  "Applicant | Demandeur",
  "nesto Product limitations",
  "nesto Service limitations",
  "Pricing (rates & fees)",
] as const;

export const CLOSED_LOST_REASONS = [
  "Test",
  "Duplicate",
  "Application deleted by client",
  "Rollback",
  "Wrong Contact",
  "Spam/Avoid",
  "No Contact",
  "No longer in market / Not in market now",
  "Unqualifiable ratios",
  "Credit",
  "Employment",
  "UW decision or guidelines",
  "Property type",
  "Product Type",
  "Document related",
  "Processing time",
  "Closing date",
  "Internal errors",
  "Tech based transaction (digital)",
  "Trust in nesto",
  "Fees",
  "Rates",
];
export const DIGITAL_STATUSES = ["DIGITAL", "NON_DIGITAL"] as const;

export const CURRENT_STATUS_FILTER = (role: Role) => {
  const isExternalBroker = role === "externalbroker";

  const standardStatuses = STANDARD_APPLICATION_STATUSES.filter(
    (status) => !["FUNDED", "CLOSED", "DELETED"].includes(status)
  );
  const hubStatuses = HUB_APPLICATION_STATUSES.filter(
    (status) => status !== "FUNDED"
  );

  return {
    [APPLICATION_STATE_GROUP.ACTIVE_GROUP]: isExternalBroker
      ? hubStatuses
      : standardStatuses,
    [APPLICATION_STATE_GROUP.FUNDED_GROUP]: FUNDED_APPLICATION_STATE,
    [APPLICATION_STATE_GROUP.CLOSED_GROUP]: CLOSED_APPLICATION_STATE,
  };
};

export const SECTION_STATUS = {
  COMPLETE: "complete",
  INCOMPLETE: "incomplete",
  NEXT: "next",
};

export const AMORTIZATION_OPTIONS = (t: TFunction<"renewing-property">) =>
  Array.from({ length: 30 }, (v, i) => {
    return {
      value: `${i + 1}`,
      label: t("renewing-property:currentAmortization.options.label", {
        count: i + 1,
      }).toString(),
    };
  }).reverse();

export const APPLICATION_TYPE: Record<ApplicationType, string> = {
  NEW: "applications:newMortgage",
  RENEWAL: "applications:renewal",
  REFINANCE: "applications:refinance",
};

export const TRANSACTION_TYPE_LABEL: Record<TransactionTypeEnum, string> = {
  NEW: "common:transactionType.NEW",
  CONSTRUCTION_LOAN: "common:transactionType.CONSTRUCTION_LOAN",
  PORT: "common:transactionType.PORT",
  ASSUMPTION: "common:transactionType.ASSUMPTION",
  PRE_APPROVAL: "common:transactionType.PRE_APPROVAL",
  REFINANCE: "common:transactionType.REFINANCE",
  READVANCE: "common:transactionType.READVANCE",
  CHANGE_OF_COVENANT: "common:transactionType.CHANGE_OF_COVENANT",
  RENEWAL: "common:transactionType.RENEWAL",
  TRANSFER: "common:transactionType.TRANSFER",
  PURCHASE_WITH_IMPROVEMENT: "common:transactionType.PURCHASE_WITH_IMPROVEMENT",
  BRIDGE: "common:transactionType.BRIDGE",
};

export const APPLICATION_TYPES_MAP: Record<
  ApplicationTypeEnum,
  TransactionTypeEnum[]
> = {
  [ApplicationTypeEnum.NEW]: [
    TransactionTypeEnum.NEW,
    TransactionTypeEnum.CONSTRUCTION_LOAN,
    TransactionTypeEnum.PORT,
    TransactionTypeEnum.ASSUMPTION,
    TransactionTypeEnum.PRE_APPROVAL,
    TransactionTypeEnum.PURCHASE_WITH_IMPROVEMENT,
    TransactionTypeEnum.CHANGE_OF_COVENANT,
    TransactionTypeEnum.BRIDGE,
  ],
  [ApplicationTypeEnum.REFINANCE]: [
    TransactionTypeEnum.REFINANCE,
    TransactionTypeEnum.READVANCE,
  ],
  [ApplicationTypeEnum.RENEWAL]: [
    TransactionTypeEnum.RENEWAL,
    TransactionTypeEnum.TRANSFER,
  ],
};

const isProd = process.env.ENV === "production";

// default to 15 minutes
export const INACTIVITY_TIMEOUT_MIN = (timeout = 15) => {
  const minutes =
    process.env.NEXT_PUBLIC_INACTIVITY_TIMEOUT_MIN && !isProd
      ? Number(process.env.NEXT_PUBLIC_INACTIVITY_TIMEOUT_MIN)
      : timeout;

  return 1000 * 60 * minutes;
};

// default to 7 minutes -  must be lower than INACTIVITY_TIMEOUT_MIN in order for prompt to appear
export const PROMPT_BEFORE_LOGOUT_MIN = (timeout = 7) => 1000 * 60 * timeout;
