import { z } from "zod";

export const incomeSchema = z.discriminatedUnion("incomeType", [
  z.object({ incomeType: z.literal("NONE") }),
  z.object({
    incomeType: z.enum([
      "SALARIED",
      "HOURLY",
      "HOURLY_COMMISSIONS",
      "COMMISSIONS",
      "SELF_EMPLOYED",
      "PENSION",
    ]),
    salary: z.object({
      base: z.object({
        amount: z.number(),
        frequency: z.string().min(1),
      }),
    }),
  }),
]);

export const incomeArraySchema = z.array(incomeSchema).min(1);
