import { useCallback, type FC, type PropsWithChildren } from "react";

import { Auth0Provider } from "@auth0/auth0-react";
import { datadogRum } from "@datadog/browser-rum";
import { useTenant } from "@nestoca/multi-tenant";
import { useAnalytics } from "@shared/analytics";
import { useTenantConnection } from "@shared/utils";
import { useRouter } from "next/router";

import { config } from "./utils/tenant-connection-config";

import type { AppState, Auth0ProviderOptions } from "@auth0/auth0-react";

export interface AuthProviderProps {
  rid?: string;
}

export const AuthProvider: FC<PropsWithChildren<AuthProviderProps>> = ({
  children,
}) => {
  const { track } = useAnalytics();
  const tenant = useTenant();
  const connection = useTenantConnection({ tenant, config });

  const { query, locale, replace } = useRouter();

  const applicationId = query.applicationId;

  const onRedirectCallback: Auth0ProviderOptions["onRedirectCallback"] =
    useCallback(
      (appState?: AppState) => {
        // check if the user was redirected from `/forbidden` page
        // then we will send them to the `/` page instead
        if (appState?.returnTo?.includes("/forbidden")) {
          replace("/");

          return;
        }

        track(
          { event: "login", return_to: appState?.returnTo ?? "/" },
          "account"
        );

        replace(appState?.returnTo ?? "/");
      },
      [track, replace]
    );

  let statusCode = 400;
  const msgArr = query.message ? (query.message as string).split("+") : [];
  // take elememt 1 from the array and trim it
  if (msgArr.length >= 2) {
    statusCode = parseInt(msgArr[1].trim(), 10);
  }

  const errorInfo = {
    rid: query.accountRid,
    email: query.email,
    errorCode: statusCode,
  };

  const redirectCount = query.redirectCount
    ? Number(query.redirectCount) + 1
    : 0;

  if (redirectCount > 1 && redirectCount < 5) {
    datadogRum.addError(
      new Error(`User has been redirected ${redirectCount} times`),
      errorInfo
    );
  } else if (redirectCount === 5) {
    datadogRum.addError(
      new Error("User is stuck in a redirect loop"),
      errorInfo
    );
  }

  if (!tenant?.auth0 || typeof window === "undefined") {
    return <>{children}</>;
  }

  const redirectUri = applicationId
    ? `${window.location.origin}/callback?applicationId=${applicationId}`
    : `${window.location.origin}/callback`;

  return (
    <Auth0Provider
      cacheLocation="localstorage"
      domain={tenant.publicDomain ?? tenant.auth0.domain}
      clientId={tenant.auth0.clientId}
      useRefreshTokens
      useRefreshTokensFallback
      authorizationParams={{
        redirect_uri: redirectUri,
        organization: tenant.auth0.organization ?? undefined,
        audience: tenant.auth0.audience ?? "https://nesto.ca/api",
        redirectCount,
        ui_locales: locale,
        connection,
        applicationId,
        // this enables the passwordless Auth0 login flow if the tenant has it enabled
        // this only works for the staging environment
        passwordless: tenant.auth0.passwordlessEnabled ?? true,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
