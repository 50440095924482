export enum AssetTypeEnum {
  SAVINGS = "SAVINGS",
  TFSA = "TFSA",
  RRSP = "RRSP",
  NON_REGISTERED_INVESTMENTS = "NON_REGISTERED_INVESTMENTS",
  GIFT = "GIFT",
  VEHICLE = "VEHICLE",
  PROPERTY = "PROPERTY",
  OTHER = "OTHER",
}

export enum FinancialInstitutionEnum {
  RBC = "RBC",
  TD = "TD",
  EQB = "EQB",
  SCOTIA_BANK = "SCOTIA_BANK",
  BMO = "BMO",
  CIBC = "CIBC",
  NBC = "NBC",
  DESJARDINS = "DESJARDINS",
  SUNLIFE = "SUNLIFE",
  HSBC = "HSBC",
  TANGERINE = "TANGERINE",
  IG = "IG",
  QUESTTRADE = "QUESTTRADE",
  WEALTH_SIMPLE = "WEALTH_SIMPLE",
  ETRADE = "ETRADE",
  ITRADE = "ITRADE",
  QTRADE_INVESTOR = "QTRADE_INVESTOR",
  LAURENTIAN = "LAURENTIAN",
  OTHER = "OTHER",
}

export enum FamilyRelationshipEnum {
  FATHER = "FATHER",
  MOTHER = "MOTHER",
  CHILD = "CHILD",
  BROTHER = "BROTHER",
  SISTER = "SISTER",
  GRAND_FATHER = "GRAND_FATHER",
  GRAND_MOTHER = "GRAND_MOTHER",
  UNCLE = "UNCLE",
  AUNT = "AUNT",
}
