export type PasswordGeneratorRules = {
  lowercase?: boolean;
  uppercase?: boolean;
  numbers?: boolean;
  symbols?: boolean;
};

// shuffle string
const shuffle = (str = "") => {
  const chars = str.split("");
  chars.sort(() => 0.5 - Math.random());
  const shuffled = chars.join("");

  return shuffled;
};

const getRandomChar = (rules: PasswordGeneratorRules) => {
  // Define character sets for lowercase, uppercase, numbers, and symbols
  const charSets = [];

  // add allowed rules
  if (rules.lowercase) {
    charSets.push([97, 122]); // Lowercase letters (a-z)
  }
  if (rules.uppercase) {
    charSets.push([65, 90]); // Uppercase letters (A-Z)
  }
  if (rules.numbers) {
    charSets.push([48, 57]); // Numbers (0-9)
  }
  if (rules.symbols) {
    charSets.push([33, 47]); // Special characters (!"#$%&'()*+,-./)
    charSets.push([91, 96]); // Special characters ([\]^_`)
  }

  // Select a random character set
  const charSet = charSets[Math.floor(Math.random() * charSets.length)];
  // Generate a random character code from the selected set
  const charCode =
    Math.floor(Math.random() * (charSet[1] - charSet[0] + 1)) + charSet[0];
  // Convert the character code to a character
  return String.fromCharCode(charCode);
};

export function passwordGenerator(
  length: number,
  rules: PasswordGeneratorRules = {
    lowercase: true,
    uppercase: true,
    numbers: true,
    symbols: true,
  }
) {
  let password = "";
  const ruleIncluded = Object.keys(rules).reduce(
    (acc, key) => {
      acc[key] = false;
      return acc;
    },
    {} as Record<string, boolean>
  );

  // add 1 char for each rule
  Object.keys(ruleIncluded).forEach((rule) => {
    password += getRandomChar(rules);
    ruleIncluded[rule] = true;
  });

  for (let i = 0; i < length - Object.keys(ruleIncluded).length; i++) {
    const char = getRandomChar(rules);
    password += char;
  }

  return shuffle(password);
}
