import { SupportedTenant, TenantClient } from "@nestoca/multi-tenant";

type Environment = "prod" | "qa" | "staging" | "demo";
const isClientSide = typeof window !== "undefined";

export type TenantRealmConfig<T extends string | undefined> = Record<
  SupportedTenant,
  Record<Environment, T>
>;

type UseTenantRealmParams<T extends string | undefined> = {
  tenant: TenantClient | null;
  config: TenantRealmConfig<T>;
};

export const useTenantRealm = <T extends string | undefined>({
  tenant,
  config,
}: UseTenantRealmParams<T>) => {
  const currentDomain = isClientSide ? window.location.hostname : "";

  if (!tenant) return;

  if (process.env.NEXT_PUBLIC_AUTH0_REALM)
    return process.env.NEXT_PUBLIC_AUTH0_REALM;

  let environment: Environment = "prod";

  /**
   * when we reset the `ig-dev` tenant, the slug becomes `ig-dev-{n}`
   * in those cases, use the `ig-dev` configuration
   * */
  const tenantSlug = tenant.slug.includes("ig-dev") ? "ig-dev" : tenant.slug;

  switch (true) {
    case currentDomain.includes("localhost"):
      environment = "staging";
      break;
    case currentDomain.includes("staging."):
      environment = "staging";
      break;
    case currentDomain.includes("qa."):
      environment = "qa";
      break;
    case currentDomain.includes("demo."):
      environment = "demo";
      break;
    default:
      break;
  }

  return config[tenantSlug as SupportedTenant][environment];
};
