export type TranslationKeys = string;

export enum rateTypes {
  VARIABLE = "VARIABLE",
  FIXED = "FIXED",
  HELOC = "HELOC",
}

export const RateTypes: Record<rateTypes, TranslationKeys> = {
  VARIABLE: "variable",
  FIXED: "fixed",
  HELOC: "heloc",
};
