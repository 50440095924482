import { api } from "@shared/api/client";

import type { Asset, AssetResponse } from "@shared/constants";

export const updateAsset = (
  applicationId: number,
  applicantId: number,
  assetId: number,
  params: Asset
) =>
  // This is actually what we are receiving from the API response
  // `{}`
  // eslint-disable-next-line @typescript-eslint/ban-types
  api.put<{}>(
    `/applications/${applicationId}/applicants/${applicantId}/assets/${assetId}`,
    params
  );

export const createAsset = (
  applicationId: number,
  applicantId: number,
  params: Asset
) =>
  api.post<AssetResponse>(
    `/applications/${applicationId}/applicants/${applicantId}/assets`,
    params
  );

export const deleteAsset = (
  applicationId: number,
  applicantId: number,
  assetId: number
) =>
  api.delete(
    `/applications/${applicationId}/applicants/${applicantId}/assets/${assetId}`
  );
