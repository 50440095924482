import { Box, Button, Flex, Modal, Typography } from "@nestoca/ui";
import { useGetApplication } from "@shared/api/hooks/applications";
import { applicantSectionsRouterSchema } from "@shared/constants";
import { useTypedRouter } from "@shared/utils";
import Link from "next/link";
import { useTranslation } from "react-i18next";

import styles from "./close-questionnaire.module.scss";

type PropsType = {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
};
export const CloseQuestionnaireModal = ({
  isModalOpen,
  setIsModalOpen,
}: PropsType) => {
  const { t } = useTranslation("applications");
  const {
    query: { applicationId, applicantId },
  } = useTypedRouter(applicantSectionsRouterSchema);

  const { data: application } = useGetApplication({
    id: applicationId ? +applicationId : 0,
  });

  return (
    <Modal
      visible={isModalOpen}
      closeOnOutsideClick
      onClose={() => {
        setIsModalOpen(false);
      }}
      className={styles.modal}
    >
      <Modal.Header closeButtonLabel={`${t("close", { ns: "common" })}`}>
        <Typography size={4} weight={7}>
          {t("closeQuestionnaire.question")}
        </Typography>
      </Modal.Header>
      <Modal.Body>
        <Flex gap={4} direction="column">
          <Box className={styles["modal-description"]}>
            {t("closeQuestionnaire.description")}
          </Box>
          <Flex
            direction="column"
            gap={3}
            className={styles["modal-buttons-wrapper"]}
          >
            <Button
              className={styles["modal-button"]}
              variant="secondary"
              onClick={() => setIsModalOpen(false)}
              size="large"
              data-dd-action-name="continue"
            >
              {t("continue", { ns: "form" })}
            </Button>
            <Link
              href={`/applications/${applicationId}/applicant/${
                applicantId || application?.mainApplicantId
              }`}
              passHref
              legacyBehavior
            >
              <Button
                as="a"
                variant="alternative"
                className={styles["modal-button"]}
                data-dd-action-name="exit"
              >
                {t("exit", { ns: "form" })}
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Modal.Body>
    </Modal>
  );
};
