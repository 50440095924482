export enum FrequencyEnum {
  ANNUALLY = "ANNUALLY",
  SEMIANNUALLY = "SEMIANNUALLY",
  MONTHLY = "MONTHLY",
  SEMIMONTHLY = "SEMIMONTHLY",
  BIWEEKLY = "BIWEEKLY",
  WEEKLY = "WEEKLY",
}

export enum CoOwnrshipFeesFrequencyEnum {
  MONTHLY = "MONTHLY",
  ANNUALLY = "ANNUALLY",
  NONE = "NONE",
}

export enum MortgageFrequencyEnum {
  ANNUALLY = "ANNUALLY",
  SEMIANNUALLY = "SEMIANNUALLY",
  MONTHLY = "MONTHLY",
  SEMIMONTHLY = "SEMIMONTHLY",
  BIWEEKLY = "BIWEEKLY",
  BIWEEKLYACCELERATED = "BIWEEKLYACCELERATED",
  WEEKLY = "WEEKLY",
  WEEKLYACCELERATED = "WEEKLYACCELERATED",
}
