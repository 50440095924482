import type { Cache, CacheOptions, FlushFn, InsertFn } from "./types";

const isBrowser = typeof document !== "undefined";

let cache: Cache | undefined = undefined;

function createStyleElement(options: CacheOptions): HTMLStyleElement {
  const tag = document.createElement("style");
  tag.setAttribute("data-n-styles", options.key);
  if (options.nonce !== undefined) {
    tag.setAttribute("nonce", options.nonce);
  }
  tag.appendChild(document.createTextNode(""));
  return tag;
}

export const createCache = (options: CacheOptions) => {
  const { key, nonce } = options;

  if (cache) {
    console.log("cache exists");
    return cache;
  }

  let _css = "";
  let tags: HTMLStyleElement[] = [];

  const css = () => _css;

  let insert: InsertFn;
  let flush: FlushFn;
  let hydrate: FlushFn;
  if (isBrowser) {
    insert = (textCss) => {
      _css = `${textCss}`;

      const tag =
        document.querySelector<HTMLStyleElement>(
          `style[data-n-styles="${cache?.key}"]`
        ) || createStyleElement(options);

      tag.innerHTML = `${_css}`;

      tags.push(tag);
    };

    flush = () => {
      _css = "";
      tags.forEach((tag) => {
        tag.remove();
      });

      // empty tags array
      tags = [];

      //   caches.has(key) && caches.delete(key);
      cache = undefined;
    };

    hydrate = () => {
      tags.forEach((tag) => {
        document.head.appendChild(tag);
      });
    };
  } else {
    insert = (textCss) => {
      _css = `${textCss}`;
    };

    flush = () => {
      _css = "";
      tags = [];

      cache = undefined;
    };

    hydrate = () => {
      return;
    };
  }

  cache = {
    key,
    nonce,
    css,
    insert,
    flush,
    hydrate,
  };

  return cache;
};
