import { useState } from "react";

import { useGate } from "@nestoca/gate-react";
import { Box, Flex } from "@nestoca/ui";
import { useAnalytics } from "@shared/analytics";
import {
  applicantSectionsRouterSchema,
  Application,
  PROGRESS_STAGES,
} from "@shared/constants";
import { useTypedRouter } from "@shared/utils";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import {
  BsFileEarmark,
  BsPencilSquare,
  BsShieldCheck,
  BsUiChecks,
} from "react-icons/bs";

import { ProgressModal } from "./progress-modal";
import { ProgressStep } from "./progress-step";
import styles from "./progress-tracker.module.scss";
import {
  getCurrentProgressArray,
  getCurrentProgressArrayForExternalBroker,
} from "./utils";

import type { ProgressIconsType, ProgressItem } from "./progress-tracker.types";

type PropsType = {
  application: Application;
  inModal?: boolean;
};

export const progressIcons: ProgressIconsType = {
  [PROGRESS_STAGES.IN_PROGRESS]: <BsFileEarmark />,
  [PROGRESS_STAGES.SUBMITTED]: <BsPencilSquare />,
  [PROGRESS_STAGES.CONDITIONAL_APPROVAL]: <BsUiChecks />,
  [PROGRESS_STAGES.FINAL_APPROVAL]: <BsShieldCheck />,
};
export const ProgressTracker = ({
  application,
  inModal = false,
}: PropsType) => {
  const { t } = useTranslation("applications");
  const { track } = useAnalytics();
  const {
    query: { applicantId },
  } = useTypedRouter(applicantSectionsRouterSchema);

  const gate = useGate();
  const isExternalBroker = gate.getRole() === "externalbroker";

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStage, setSelectedStage] = useState<ProgressItem>(
    {} as ProgressItem
  );

  const progressArray = isExternalBroker
    ? getCurrentProgressArrayForExternalBroker(
        application?.applicationState,
        progressIcons
      )
    : getCurrentProgressArray(application?.applicationState, progressIcons);

  const onClick = (step: ProgressItem) => {
    setSelectedStage(step);
    setIsModalOpen(true);

    switch (step.name) {
      case "in_progress":
        track({
          event: "view_progress_tracker",
          tracker_section: "app in progress",
          applicant_id: applicantId,
        });
        break;
      case "submitted":
        track({
          event: "view_progress_tracker",
          tracker_section: "submitted to underwriter",
          applicant_id: applicantId,
        });
        break;
      case "conditional_approval":
        track({
          event: "view_progress_tracker",
          tracker_section: "conditional approval",
          applicant_id: applicantId,
        });
        break;
      case "final_approval":
        track({
          event: "view_progress_tracker",
          tracker_section: "final approval",
          applicant_id: applicantId,
        });
        break;
    }
  };

  return (
    <Flex
      className={clsx(styles.progress, {
        [styles["progress-modal"]]: inModal,
      })}
      gap={3}
    >
      {!inModal && (
        <Box className={styles["progress-header"]}>{t("progressTracker")}</Box>
      )}
      <Flex direction="column" gap={3}>
        {progressArray.map((step) => (
          <ProgressStep
            key={step.name}
            status={step.status}
            name={step.name}
            icon={step.icon}
            onClick={() => onClick(step)}
            inModal
          />
        ))}
      </Flex>
      <ProgressModal
        applicationId={application.id}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        stage={selectedStage}
      />
    </Flex>
  );
};
