import { ButtonIcon, Flex, Typography } from "@nestoca/ui";
import clsx from "clsx";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { IoArrowBackOutline } from "react-icons/io5";

import styles from "./action-bar-mobile.module.scss";
import { Address } from "./application-selector/address";
import { ApplicationSelector } from "./application-selector/application-selector";

interface ApplicationProps {
  applicationId: number;
  applicantId: number;
  shrinkOnScroll?: boolean;
}
export const ActionBarMobile = ({
  applicationId,
  applicantId,
  shrinkOnScroll,
}: ApplicationProps) => {
  const { basePath } = useRouter();
  const { t } = useTranslation("common");

  return (
    <Flex
      align="center"
      id="action-bar-mobile"
      className={clsx(styles["action-bar-mobile"], {
        [styles["action-bar-mobile--small"]]: shrinkOnScroll,
      })}
    >
      {/*
       *** NOTE: if we want to switch between fe service doc -> cma or vice versa
       */}
      {basePath === "/documents" ? (
        <Flex gap={3} align="center" className={styles.external}>
          <a href={`/applications/${applicationId}/applicant/${applicantId}`}>
            <ButtonIcon
              aria-label="go back"
              variant="ghost"
              className={styles["back-button"]}
              size="xsmall"
              icon={<IoArrowBackOutline color="white" />}
              isRound={true}
            />
          </a>
          <Flex direction="column">
            <Address />
            <Typography size={0} weight={7}>
              {t("communicationPreferences.documentCentre")}
            </Typography>
          </Flex>
        </Flex>
      ) : (
        <ApplicationSelector
          applicationId={applicationId}
          isSmall={shrinkOnScroll}
        />
      )}
    </Flex>
  );
};
