import React, { Fragment } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useGate } from "@nestoca/gate-react";
import { Flex, Sidebar, useBreakpointValue } from "@nestoca/ui";
import { Sidebar as PrimarySidebar } from "@shared/ui/components/layout/sidebar";
import { isEmpty } from "@shared/utils";
import clsx from "clsx";

import { AuthenticatedMobileHeader } from "../navbar/authenticated-mobile-navbar";

import styles from "./layout.module.scss";

export type LayoutDesktopMobileProps = {
  primarySidebarContent: React.ReactElement;
  secondarySidebarContent?: React.ReactElement;
} & React.HTMLAttributes<HTMLDivElement>;

export const LayoutDesktopMobile = ({
  children,
  className,
  primarySidebarContent,
  secondarySidebarContent,
  ...rest
}: LayoutDesktopMobileProps) => {
  const { isAuthenticated } = useAuth0();
  const gate = useGate();

  const isExternalBroker =
    isAuthenticated && !isEmpty(gate) && gate.getRole() === "externalbroker";
  const isMobile = useBreakpointValue({ default: true, md: false });

  return (
    <Flex
      className={clsx(styles.layout, className, {
        [styles["layout--mobile"]]: isExternalBroker && isMobile,
      })}
      {...rest}
    >
      {isExternalBroker && isMobile ? (
        <AuthenticatedMobileHeader />
      ) : (
        <Fragment>
          <PrimarySidebar>{primarySidebarContent}</PrimarySidebar>
          {secondarySidebarContent && (
            <Sidebar
              className={styles["secondary-sidebar"]}
              variant="secondary"
            >
              <Sidebar.Content>{secondarySidebarContent}</Sidebar.Content>
            </Sidebar>
          )}
        </Fragment>
      )}
      <Flex className={styles.section} as="main">
        {children}
      </Flex>
    </Flex>
  );
};
