import { useMemo, useState } from "react";

import { useTenant, useTenantSetting } from "@nestoca/multi-tenant";
import { DidomiProvider, GoogleTagManager } from "@shared/analytics";

import type { TenantSettings } from "@nestoca/multi-tenant";

type AnalyticsSettings = TenantSettings["analytics"]["settings"];
type AnalyticsSettingsConsent = AnalyticsSettings["consent"];
type AnalyticsConsent = Exclude<AnalyticsSettingsConsent, null>;

const getIsConsentEnabled = (
  analyticEnabled: boolean,
  forceAnalytics: boolean,
  analyticsConsent: AnalyticsSettingsConsent
): analyticsConsent is AnalyticsConsent => {
  return !forceAnalytics && analyticEnabled && !!analyticsConsent;
};

interface AnalyticsProps {
  /**
   * Forcing the analytics to be enabled without asking the user for consent
   * @default false
   * If true, the consent will not be required
   */
  forceAnalytics?: boolean;
}

export const Analytics = ({ forceAnalytics = false }: AnalyticsProps) => {
  const tenant = useTenant();
  const { settings: analytics, value: analyticEnabled } =
    useTenantSetting("analytics");

  const [gtmReady, setGtmReady] = useState(false);

  const gtmId = analytics?.id || tenant?.analyticId;
  const analyticsConsent = analytics.consent;
  const isConsentEnabled = getIsConsentEnabled(
    analyticEnabled,
    forceAnalytics,
    analyticsConsent
  );

  const isGTMReady = useMemo(() => {
    if (!analyticEnabled) {
      return false;
    }

    // we are forcing the analytics to be enabled
    // without the consent 3rd party tool (Didomi)
    if (forceAnalytics) {
      return true;
    }

    // if analytics is enable but without consent, we still want to render the GTM
    // ex. we can have tracking event for internal tooling (HUB) without consent
    const pairWithConsent = gtmReady && isConsentEnabled && !!gtmId;
    const gtmOnly = !isConsentEnabled && !!gtmId;

    return pairWithConsent || gtmOnly;
  }, [analyticEnabled, forceAnalytics, gtmReady, isConsentEnabled, gtmId]);

  return (
    <>
      {isConsentEnabled && (
        <DidomiProvider
          apiKey={analyticsConsent.pubApiKey}
          noticeId={analyticsConsent.noticeId}
          onReady={() => setGtmReady(true)}
        />
      )}
      {isGTMReady && gtmId && <GoogleTagManager gtmId={gtmId} />}
    </>
  );
};
