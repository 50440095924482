import {
  useGetApplicationClosedStatus,
  useGetApplicationConditionalApproval,
  useGetApplicationFinalApproval,
  useGetApplicationFundedStatus,
  useGetApplicationLockedStatus,
  useGetApplicationSubmittedToUnderwriting,
} from "@shared/api/hooks/applications";

export const useApplicationDisabled = (applicationId: number) => {
  const { data: isLocked } = useGetApplicationLockedStatus(applicationId);
  const { data: isFunded } = useGetApplicationFundedStatus(applicationId);
  const { data: isClosed } = useGetApplicationClosedStatus(applicationId);
  const { data: isSubmittedToUnderwriter } =
    useGetApplicationSubmittedToUnderwriting(applicationId);
  const { data: isConditionalApproval } =
    useGetApplicationConditionalApproval(applicationId);
  const { data: isFinalApproval } =
    useGetApplicationFinalApproval(applicationId);

  return {
    applicationDisabled:
      isLocked ||
      isFunded ||
      isClosed ||
      isSubmittedToUnderwriter ||
      isConditionalApproval ||
      isFinalApproval,
    isLocked,
    isFunded,
    isClosed,
    isSubmittedToUnderwriter,
    isConditionalApproval,
    isFinalApproval,
  };
};
