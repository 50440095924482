import { PropsWithChildren } from "react";

import { Flex } from "@nestoca/ui";
import Link from "next/link";

export type SectionCardWrapperProps = {
  link: string;
  onClick?: () => void;
  className?: string;
  name: string;
};
export const SectionCardWrapper = ({
  children,
  link,
  onClick,
  className,
  name,
}: PropsWithChildren<SectionCardWrapperProps>) => {
  return onClick ? (
    <Flex onClick={onClick} className={className} data-testid={name}>
      {children}
    </Flex>
  ) : (
    <Link passHref href={link} legacyBehavior>
      <Flex
        as="a"
        className={className}
        data-testid={name}
        data-dd-action-name={name}
        data-linkbutton
      >
        {children}
      </Flex>
    </Link>
  );
};
