import { useState } from "react";

export type UseFetchCallbackStateStatus =
  | "idle"
  | "loading"
  | "success"
  | "error";

export interface UseFetchCallbackStateBaseResult<
  TData = unknown,
  TError = unknown
> {
  data: TData | undefined;
  error: TError | null;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  status: UseFetchCallbackStateStatus;
}

export interface UseFetchCallbackStateIdleResult<
  TData = unknown,
  TError = unknown
> extends UseFetchCallbackStateBaseResult<TData, TError> {
  data: undefined;
  error: null;
  isLoading: false;
  isError: false;
  isSuccess: false;
  status: "idle";
}

export interface UseFetchCallbackStateErrorResult<
  TData = unknown,
  TError = unknown
> extends UseFetchCallbackStateBaseResult<TData, TError> {
  data: TData;
  error: TError;
  isLoading: false;
  isError: true;
  isSuccess: false;
  status: "error";
}
export interface UseFetchCallbackLoadingResult<
  TData = unknown,
  TError = unknown
> extends UseFetchCallbackStateBaseResult<TData, TError> {
  data: undefined;
  error: null;
  isLoading: true;
  isError: false;
  isSuccess: false;
  status: "loading";
}

export interface UseFetchCallbackSuccessResult<
  TData = unknown,
  TError = unknown
> extends UseFetchCallbackStateBaseResult<TData, TError> {
  data: TData;
  error: null;
  isLoading: false;
  isError: false;
  isSuccess: true;
  status: "success";
}

export type UseFetchCallbackState<TData = unknown, TError = unknown> =
  | UseFetchCallbackStateIdleResult<TData, TError>
  | UseFetchCallbackStateErrorResult<TData, TError>
  | UseFetchCallbackLoadingResult<TData, TError>
  | UseFetchCallbackSuccessResult<TData, TError>;

export interface FetchCallbackFunctionOptions<
  TData = unknown,
  TError = unknown,
  TVariables = void
> {
  // variables: TVariables;
  /**
   * This callback will fire any time the fetch function successfully fetches new data.
   */
  onSuccess?: (data: TData, variable: TVariables) => void;
  /**
   * This callback will fire if the fetch function encounters an error and will be passed the error.
   */
  onError?: (err: TError, variable: TVariables) => void;
  /**
   * This callback will fire any time the fetch function is either successfully fetched or errors and be passed either the data or error.
   */
  onSettled?: (
    data: TData | undefined,
    error: TError | null,
    variable: TVariables
  ) => void;

  enabled?: boolean;
}

export type FetchCallbackFunction<TData = unknown, TVariables = void> = (
  variables: TVariables
) => Promise<TData>;

export type ExecuteFn<TData = unknown, TError = unknown, TVariables = void> = (
  variables: TVariables,
  {
    onSuccess,
    onError,
    onSettled,
    enabled,
  }: FetchCallbackFunctionOptions<TData, TError, TVariables>
) => unknown;

export interface UseFetchCallbackParams<
  TData = unknown,
  TError = unknown,
  TVariables = void
> extends Omit<
    FetchCallbackFunctionOptions<TData, TError, TVariables>,
    "enabled"
  > {
  fetchFn: FetchCallbackFunction<TData, TVariables>;
}

export const useFetchCallback = <
  TData = unknown,
  TError = unknown,
  TVariables = void
>({
  fetchFn,
  onSuccess: onUseFetchSuccess,
  onError: onUseFetchError,
  onSettled: onUseFetchSettled,
}: UseFetchCallbackParams<TData, TError, TVariables>) => {
  const [state, setState] = useState<UseFetchCallbackState>({
    data: undefined,
    error: null,
    isError: false,
    isLoading: false,
    isSuccess: false,
    status: "idle",
  });

  const execute: ExecuteFn<TData, TError, TVariables> = async (
    variables,
    { onSuccess, onError, onSettled, enabled = true }
  ) => {
    try {
      if (!fetchFn) {
        return Promise.reject("No fetchFn found");
      }

      if (!enabled) {
        return Promise.resolve();
      }

      setState({
        data: undefined,
        error: null,
        isError: false,
        isLoading: true,
        isSuccess: false,
        status: "loading",
      });

      const data = await fetchFn(variables);

      setState({
        data,
        error: null,
        isError: false,
        isLoading: false,
        isSuccess: true,
        status: "success",
      });

      onSuccess?.(data, variables);
      onUseFetchSuccess?.(data, variables);
      onSettled?.(data, null, variables);
      onUseFetchSettled?.(data, null, variables);

      return data;
    } catch (error) {
      setState({
        data: undefined,
        error,
        isError: true,
        isLoading: false,
        isSuccess: false,
        status: "error",
      });

      onError?.(error as TError, variables);
      onUseFetchError?.(error as TError, variables);
      onSettled?.(undefined, error as TError, variables);
      onUseFetchSettled?.(undefined, error as TError, variables);
    }
  };
  return { ...state, execute };
};
