export type Entity = "application" | "document" | "documentCount" | "account";

export interface CacheKeyId {
  id?: number | string;
}

type CacheKeyDefaultOptions = Record<string, unknown>;

type CacheKey<O = CacheKeyDefaultOptions> = O & CacheKeyId;

type KeyFactoryList<O = CacheKeyDefaultOptions, T = Entity> =
  | [T, "list"]
  | [T, "list", CacheKey<O>];
type KeyFactoryDetail<O = CacheKeyDefaultOptions, T = Entity> =
  | [T, "detail"]
  | [T, "detail", CacheKey<O>];

/**
 * This function takes in a string and generates a list of key generators to use for react-query
 *
 * The list can be customized to your liking. This template is meant as a generic solution that should
 * fit most use cases.
 *
 * @param key the string to use as the base cache key identifier
 * @returns an array of generators to use as cache keys
 * @see https://react-query.tanstack.com/guides/query-keys
 */
export function keyFactory<O = CacheKeyDefaultOptions, T = Entity>(key: T) {
  return {
    all: [key],
    list: (cacheKey?: CacheKey<O>) => {
      let identifier: KeyFactoryList<O, T> = [key, "list"];

      if (cacheKey) {
        identifier = [...identifier, cacheKey];
      }

      return identifier;
    },
    detail: (cacheKey?: CacheKey<O>) => {
      let identifier: KeyFactoryDetail<O, T> = [key, "detail"];

      if (cacheKey) {
        identifier = [...identifier, cacheKey];
      }

      return identifier;
    },
  };
}
